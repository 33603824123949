import React, {  useEffect, useState } from 'react';
import {  Card,  Typography,  Input,  Modal, Popconfirm, Select,  message, Spin } from 'antd';

import Table, { ColumnsType } from 'antd/es/table';
import { AiFillEdit } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import TermsConditionService from '../../services/TermsConditionService';
import TermsCondition from '../../models/TermsCondition';
import moment from 'moment';
import { ExclamationCircleOutlined,CloseCircleOutlined } from '@ant-design/icons';
import secureLocalStorage from 'react-secure-storage';
import { User } from '../../models';
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface DataType {
  newInformation: string;
  newStatus: number;
}

interface Item {
  key: string;
  request_no: string;
  owner: string;
  dept: string;
  new_owner: string;
  id: string;
}
const TermsAndConditions = () => {

    const { instance, accounts } = useMsal();
  const [modal, contextHolder] = Modal.useModal();
  const [messageApi, contextHolderMessage] = message.useMessage();
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null


  const [editingKey, setEditingKey] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [termMeta,setTermMeta]= useState<TermsCondition[]>([])
  const [termUpdate,setTermUpdate] = useState<DataType>({newInformation:'',newStatus:0})
  const [isLoad,setIsLoad] = useState(true)
  const [pageSize,setPageSize] = useState(10)

  const getToken = async () => {
    const acc = accounts && accounts.length ? accounts[0] : null;
    apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
    if (acc) {
        const { accessToken: token } = await instance.acquireTokenSilent({
            account: acc,
            scopes: apiRequest.scopes
        });
        return token;
    }
    return ""
}

  const getTersCondition = async () =>{
    let token = await getToken()
    const {data} = await new TermsConditionService(token).getTermsCondition()
    console.log(data)
    setTermMeta(data)
    setIsLoad(false)
}

  const onConfirmUpdate = (record:TermsCondition) => {
    modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Confirm to submit?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => updateTermById(record)
    });
    
  };

  const handleChangeTerm = (value:any) => {

    setTermUpdate({...termUpdate,newInformation:value})
    if(value.length===0){
      setErrorMessage('Information Detail is required!')
      return
    }
    setErrorMessage('')
  }

  const isEditing = (record: any) => {
    if(record.requestTypeId === editingKey){
        return true
    }
  } 
  const cancel = () => {
    setEditingKey(0);
  };
  const edit = (record: Partial<TermsCondition> & { key: React.Key }) => {
    if(record.requestTypeId&&record.information){
      setEditingKey(record.requestTypeId);
      setTermUpdate({...termUpdate,newInformation:record.information,newStatus:record.active||0})
    }
  };



  const updateTermById =async (record:TermsCondition) => {
    console.log("🚀 ~ file: TermsAndConditions.tsx:107 ~ updateTermById ~ record:", record)
   
    let body = {...record,information:termUpdate.newInformation,active:termUpdate.newStatus,updateBy:user.employeeId}
    let token = await getToken()
      const res = await new  TermsConditionService(token).updateTermsCondition(body)
      if (!res)
          return null;
    if(!res.success){
      Modal.error({
        icon: <CloseCircleOutlined />,
        title: 'This is an error message',
        content: res?.message,
      });
    } else {
        if (!res)
            return null;
      getTersCondition()
      setEditingKey(0);
      messageApi.open({
        type: 'success',
        content: 'Save success',
        duration: 1,
      });
    }

  }

  const handleChangeStatus = (value: number) => {
    setTermUpdate({...termUpdate,newStatus:value})
  };

  const handleTableChange = (pagination:any) => {
    console.log("🚀 ~ file: ManageProjectName.tsx:429 ~ handleTableChange ~ pagination:", pagination)
    setPageSize(pagination.pageSize);
  };
  const handleTableChange2 = (currentPage:number,perpage:number) => {
    setPageSize(perpage);
  };
  



  const columnsOngoing: ColumnsType<TermsCondition> = [
    {
      title: 'Information Detail',
      dataIndex: 'information',
      key: 'information',
      width:'50%',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        if (editable) {
          
          return <> 
            <Input.TextArea placeholder={MessageCommon.TitleInput} style={{ minHeight: '150px'}} defaultValue={record.information} onChange={(e)=>handleChangeTerm(e.target.value)}>
                 
            </Input.TextArea>
            {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}

          </> 
        } else {
          return <span>{record.information}</span>
        }
      }
    },
    {
      title: 'Request Type',
      dataIndex: 'requestType',
      key: 'requestType',
    },
    {
      title: 'Updated Date',
      dataIndex: 'updateDateTime',
      key: 'updateDateTime',
      render: (_: any, record: any) => {
        return <span>{moment(record.updateDateTime).format("DD-MMM-YYYY")}</span>
      }
    },
    {
      title: 'Updated By',
      dataIndex: 'updateBy',
      key: 'updateBy',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        if (editable) {
          
          return    <Select placeholder={MessageCommon.TitleSelect} defaultValue={record.active}  onChange={handleChangeStatus}
          options={[{ value: 1, label: 'Active' }, { value: 0, label: 'Inactive' }]}
        />
        } else {
          return <span>{record.active===1?"Active":"Inactive"}</span>
        }
      }
    },
   
    {
      title: 'Edit',
      dataIndex: 'requestTypeId',
      key: 'requestTypeId',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link style={{ marginRight: 8 }} onClick={()=>onConfirmUpdate(record)} disabled={errorMessage.length>0?true:false}>
              Update
            </Typography.Link>
            <Popconfirm title="Confirm to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <AiFillEdit className='icon-table' onClick={() => edit(record)} ></AiFillEdit>

        );
      },
    },
   
  ];

  useEffect(() => {
    const onInit = async () =>{
      await getTersCondition()
    }
    onInit()
  }, [])
  
  return (
    <div>

      <div>

        <br></br>
        <Card >
        
          <div style={{marginBottom:'45px'}}>
              <div style={{float: 'left'}}>
             
              </div>
              { termMeta.length>0?<div style={{float: 'right'}}>
                        <SelectPagination  onSelectChange={handleTableChange2} currentPage={1}/>
                        <span style={{marginLeft:'8px'}}>/ Page</span>
                    </div>:<></>}
          </div>
          {isLoad?
            <div style={{ margin: 'auto',paddingBottom: '30px'}}>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
            :
            <Table 
              columns={columnsOngoing}
              dataSource={termMeta} 
              pagination={{ pageSize: pageSize, showSizeChanger: false}}
              onChange={handleTableChange} />
          }
          </Card>
      </div>
      {contextHolder}
      {contextHolderMessage}


    </div >
  );

}

export default TermsAndConditions
