import {  useEffect, useState } from 'react';
import { Button, Card,  Col, DatePicker, Form, Input, Pagination, Modal,Spin, Popconfirm, Row, Tooltip, message } from 'antd';
import {  AiFillDelete, AiFillSave, AiOutlineCloseCircle, AiOutlineCopy,AiFillEye, AiOutlineSave,AiOutlinePlusCircle } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import dayjs from 'dayjs';
import { ExclamationCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import CartonDetail from '../Share/CartonDetail';
import { CartonList , RequestCollectCarton, Item,User,CollectCartonList} from '../../models';
import {CollectCartonService, HrWebService,StorageCartonService} from '../../services';
import { CartonStatus } from '../../enum';
import { Helper ,InitHelper} from '../../helpers';
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import CollectCartonAdd from "./CollectCartonAdd"

interface Props{
  collectId:number
  items: string[]
  page:string
  page2?:string
  onBehalf:number
  onReload:any
}

interface Error
{
  owner: number
  place: number
  receiveDate:number
  returnDate:number
}

const CollectCartonModal = (props: Props) => {

    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }

    const doIfElse = (flag: any, f1: any, f2: any) => {
        if (flag) {
            if (f1 != null) {
                f1();
            }
        }
        else {
            if (f2 != null) {
                f2();
            }
        }
    }
    const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const pageSizeOptions = user.systemConfig.tablePageSize
  const orgUnit = user.orgUnit ? user.orgUnit : ""
  const position = user.position ? user.position : ""
  const directPhone = user.directPhone ? user.directPhone : ""
  const urlParams = new URLSearchParams(window.location.search);
  const currentDate2 =urlParams.get('date')
  const currentDate = (currentDate2) ? currentDate2:  secureLocalStorage.getItem('date')
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [dataForm] = Form.useForm();
  const [page,setPage] = useState(1)
  const [perpage ,setPerpage] = useState(user.systemConfig.tablePageSize[0]);
  const [selectItems, setSelectItems] = useState<string[]>([])
  const [allCartonItems,setAllCartonItems] = useState<CartonList[]>([])
  const [cartonItems,setCartonItems] = useState<CartonList[][]>([])
  const [isAdd,setIsAdd] = useState(false)
  const [modal, contextHolder] = Modal.useModal();
  const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
  const [owners, setOwners] = useState<Item[]>([])
  const [onBehalfs, setonBehalfs] = useState<Item[]>([])
  const [isLoading ,setIsLoading] = useState(true)
  const [requestNo,setRequestNo] = useState("")
  const [box,setBox] = useState("")

  const [checkAll, setCheckAll] = useState(false)
  const [ownersBgColor,setOwnersBgColor] = useState("")
  const [error,setError] = useState<Error>({
    owner: 0,
    place: 0,
    receiveDate:0,
    returnDate:0
  })
  const onFinish = (values: any) => {
  };


  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
  
  const onOwnerSelect = (item:any) => {
    setOwners(item)
    if(item.length > 0){
      dataForm.setFieldsValue({
        deliverto: item[0].key,
        location: item[0].location,
        place:item[0].location
      });
    }
    else{
        dataForm.setFieldsValue({
            location: "" 
        });
    }
    
  }

  const onBehalfSelect = (item:any) => {
    setonBehalfs(item)
    
    if(item.length > 0){
        setOwners(item)
      dataForm.setFieldsValue({
        onBehalf: item[0].key,
        deliverto: item[0].key,
        location: item[0].location ,
        place:item[0].location 
      });
      
    }
    
  }
  
  const onSearchEmployee = async(key:string) => {
   
      let token = await getToken()
      let result = await new HrWebService(token).SearchEmployee(key)
      if(result && result.success)
      {
        if(props.onBehalf === 1)
        {
            return result.data
        }
        else{
            let _orgUnit = user.orgUnit
            if(user.orgUnit.indexOf("/") > -1) {
                _orgUnit = user.orgUnit.split("/")[0]
            }
            return result.data.filter(x => x.orgUnit.indexOf(_orgUnit) > -1)
        }
      }
      else{
        console.log(result?.message)
        return []
      }
  }

  const onSearchEmployee2 = async(key:string) => {
   
    let token = await getToken()
    let result = await new HrWebService(token).SearchEmployee(key)
    if(result && result.success)
    {
      
          return result.data
      
    }
    else{
      console.log(result?.message)
      return []
    }
}

  const validate = () => {
    let errorData:Error = {
      owner: 0,
      place: 0,
      receiveDate:0,
      returnDate:0
    }

    let errorCount = 0
    setOwnersBgColor('')

    if(owners.length === 0)
    {
      setOwnersBgColor('red')
       errorCount++
    }

    if(!dataForm.getFieldValue('place')){
      errorCount++
      errorData.place = 1
    }

    if(!dataForm.getFieldValue('receiveDate')){
      errorCount++
      errorData.receiveDate = 1
     
    }


    cartonItems.forEach(items => {
      let cartons = items.filter( x => x.returnDate === undefined || x.returnDate === null)
      if(cartons.length > 0)
      {
        errorCount++
        errorData.returnDate = 1
      }
    })
    
    if(errorCount > 0){
      let element = document.getElementById('save-section') as HTMLDivElement
      messageApi.open({
        type: 'error',
        content: errorData.returnDate ? "'Missing return date fields'" : 'Please fill required fields',
        duration: 1.5,
        style: { marginTop: element.getBoundingClientRect().top - 100 , marginLeft: element.getBoundingClientRect().left - 100  }
      });
      setError(errorData)
      return false
    }
    else{
      setError(errorData)
      return true;
    }
   
  }

const handleSave = async (saveType: boolean) => {
  if(validate()){
    let requestCarton:RequestCollectCarton = {
      recordNo:dataForm.getFieldValue('recordNo'),
      requester:user.employeeId,
        onBehalf: returnIfElse(props.onBehalf, dataForm.getFieldValue('onBehalf'), user.employeeId)  ,
      deliverto:dataForm.getFieldValue('deliverto'),
      delivertoName:dataForm.getFieldValue('delivertoName'),
      receiveDate: dataForm.getFieldValue('receiveDate').format("YYYY-MM-DD"),
      location: dataForm.getFieldValue('location'),
      floor: dataForm.getFieldValue('floor'),
      place: dataForm.getFieldValue('place'),
        statusId: returnIfElse(saveType, CartonStatus.WaitAdmin, CartonStatus.SaveDraft),
      cartons:allCartonItems.map((item) => {
          let carton:CollectCartonList = {
              cartonNo: item.cartonNo,
              returnDate: dayjs(item.returnDate).format("YYYY-MM-DD")
          }
          return carton
      })
   }
    console.log(requestCarton)
    let token = await getToken()
    let result:any = null
  
    if(requestCarton.recordNo === 0){
      result = await new CollectCartonService(token).Create(requestCarton);
    }
    else{
      result = await new CollectCartonService(token).Update(requestCarton);
    }
    console.log(result)
    let element = document.getElementById('collect-carton-form') as HTMLElement
    if(result && result.success){

      messageApi.open({
        type: 'success',
        content: 'Save success',
        duration: 1,
        style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
      });
      setTimeout(() => {
        handleCancel()

          doIfElse(props.page === 'collect' ,
              function () {

                  doIfElse(saveType ,
                      function () {
                        props.onReload()
                        if(props.page2){
                            navigate('/collect-carton-tracking')
                        }
                         
                      }
                      ,
                      function () {
                        props.onReload()
                          navigate('/collect-carton-save')
                      }
                  );

              }
              ,
              null
          );

          doIfElse(props.page === 'onBehalf' ,
              function () {
                  doIfElse(saveType ,
                      function () {
                          props.onReload()
                          navigate('/onbehalf-2')
                      }
                      ,
                      function () {
                        props.onReload()
                        navigate('/onbehalf-3')
                      }
                  );
              }
              ,
              null
          );
        
      }, 1000);
     
   }
    else{
      Modal.error({
          icon: <CloseCircleOutlined />,
          title: 'This is an error message',
          content: result?.message,
      });
    }
  }
 
};

  const handleDelete = async () => {
    let _allCartonItems:CartonList[] = Object.assign([],allCartonItems)
    selectItems.forEach(item => { 
      let index = allCartonItems.findIndex( x => x.cartonNo === item)
      if(index > -1){
        _allCartonItems.splice(index,1)
      }
    })
    console.log(selectItems)
    console.log(_allCartonItems)
    let items = Helper.Chunk(_allCartonItems,perpage)
    setAllCartonItems(_allCartonItems)
    setCartonItems(items)
    setPage(1)
    setSelectItems([])
    onCheckAll(false)
    
  }

  const handleDeleteByItem = async (cartonNo:string) => {
  
      let _allCartonItems:CartonList[] = Object.assign([],allCartonItems)
      console.log(cartonNo)
      let index = allCartonItems.findIndex( item => item.cartonNo === cartonNo)
      console.log(index)
      if(index > -1)
      {
        _allCartonItems.splice(index,1)
        let items = Helper.Chunk(_allCartonItems,perpage)
        setAllCartonItems(_allCartonItems)
        setCartonItems(items)
        setPage(1)
        setSelectItems([])
        onCheckAll(false)
      }
      
      let element = document.getElementById('collect-carton-form') as HTMLElement
        messageApi.open({
          type: 'success',
          content: 'Delete success',
          duration: 1,
          style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
      });
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

  

  const confirmSave = () => {
    modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Confirm to submit?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => handleSave(true)
    });
  };


  const handleCancel = () => {
    let elements = document.getElementsByClassName('ant-modal-close') as HTMLCollection
    console.log(elements)
    let btnClose = elements[0] as HTMLButtonElement
    btnClose.click()
  };

  const showModalCarton = (_requestNo:string,_cartonNo:string) => {
    setRequestNo(_requestNo)
    setBox(_cartonNo)
    setIsModalCartonOpen(true);
  };
  const handleCancelCarton = () => {
    setIsModalCartonOpen(false);

  };

  const getCollectData = async () => {
  
      let token = await getToken()
      let result = await new CollectCartonService(token).GetRequest(props.collectId)
    
      console.log(result)
      if(result && result.success && result.data)
      {
        let data = result.data
        let _allCartonItems = data.cartonList
        if(_allCartonItems.length > 0)
        {
          let items = Helper.Chunk(_allCartonItems,perpage)
          setAllCartonItems(_allCartonItems)
          setCartonItems(items)
          setIsLoading(false)
        }
        
        let owners:Item[] = [{
          key:data.deliverto,
          location:data.location,
          name: data.delivertoName,
          floor: "",
          orgUnit:""
        }]
        onOwnerSelect(owners)

        if(props.onBehalf === 1){
          let onBehalfUser:Item[] = [{
            key:data.onBehalf,
            location:data.location,
            name: data.onBehalfName,
            floor: "",
            orgUnit:""
          }]
          onBehalfSelect(onBehalfUser)
        }

        dataForm.setFieldsValue({
          recordNo: props.collectId,
          requester: user.employeeId,
          requesterName: `Name: ${user.employeeName}  Org. Unit: ${orgUnit} Position: ${position}, Officer Services Tel: ${directPhone}`,
          delivertoName:data.delivertoName,
          onBehalfName:data.onBehalfName,
          location:data.location,
          floor: data.floor,
          place: data.place,
          receiveDate: data.receiveDate ? dayjs(data.receiveDate) : undefined
        })
        setTimeout(() => {
          setIsLoading(false)
        }, 500);
       
      }
      else{
        setIsLoading(false)
      }
     

  }

  const getStorageData = async () => {
  
    let token = await getToken()
    let parameter = InitHelper.InitialParameter();
      parameter.page =1
      parameter.limit = 0
      parameter.cartonNoList = props.items

    let result = await new StorageCartonService(token).SearchCarton(parameter)
    
    console.log(result)
    if(result && result.success && result.data)
    {
      let data = result.data
      let _allCartonItems = data.items
      let date = currentDate ? currentDate.toString() : new Date().toString()
      let receiveDate = Helper.add3Days(date)

      if(_allCartonItems.length > 0)
      {
        const returnDate = dayjs(receiveDate).add(1,'month')
        let length = _allCartonItems.length
        for(let i =0 ; i < length ; i++)
        {
          _allCartonItems[i].returnDate = returnDate
        }
        let items = Helper.Chunk(_allCartonItems,perpage)
        setAllCartonItems(_allCartonItems)
        setCartonItems(items)
        setIsLoading(false)
      }
      
      dataForm.setFieldsValue({
        recordNo: props.collectId,
        requester: user.employeeId,
        requesterName: `Name: ${user.employeeName}  Org. Unit: ${orgUnit} Position: ${position}, Officer Services Tel: ${directPhone}`,
        delivertoName: "",
        onBehalfName: "",
        floor: "",
        place: "",
        receiveDate: receiveDate
      })
      
    }
    else{
      setIsLoading(false)
    }
   

  }

  const getStorageData2 = async (items:string[]) => {
  
    let token = await getToken()
    let parameter = InitHelper.InitialParameter();
      parameter.page =1
      parameter.limit = 0
      parameter.cartonNoList = items

    let result = await new StorageCartonService(token).SearchCarton(parameter)
    
    console.log(result)
    if(result && result.success && result.data)
    {
      let data = result.data
      let storageItems = data.items
      if(storageItems.length > 0)
      {
        const receiveDate =  dayjs(currentDate?.toString()).add(3,'days')
        const returnDate = dayjs(receiveDate).add(1,'month')
        let length = storageItems.length
        for(let i =0 ; i < length ; i++)
        {
            storageItems[i].returnDate = returnDate
        }
      }
      let _allCartonItems = storageItems.concat(allCartonItems)
      let items = Helper.Chunk(_allCartonItems,perpage)
      
      setAllCartonItems(_allCartonItems)
      setCartonItems(items)
      setIsLoading(false)
    }
    else{
        
      setIsLoading(false)
    }
   

  }

  const applyAllReturnDate = () => {
    let returnDate = cartonItems[page -1][0].returnDate
    let element = document.getElementById('collect-carton-form') as HTMLElement
    if(!returnDate){
        messageApi.open({
          type: 'error',
          content: 'Please fill first return date',
          duration: 1.5,
          style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
        });
      }
    
    let _allCartonItems:CartonList[] = allCartonItems.map(item => { item.returnDate = returnDate; return item })
    let items = Helper.Chunk(_allCartonItems,perpage)
    setAllCartonItems(_allCartonItems)
    setCartonItems(items)
  }

  
  useEffect(() => {
    console.log(props.collectId)
    if(props.collectId > 0){
        getCollectData()
    }
    else{
       getStorageData()
    }

   

  }, [])

  const onCheckAll = (checked:boolean) => {
    if(checked){
      setCheckAll(true)
      let _selectItems = allCartonItems.map( x => x.cartonNo)
      setSelectItems(_selectItems)
    }
    else{
      setCheckAll(false)
      setSelectItems([])
    }
  };

  const onCheckChange = (checked:boolean,cartonNo:string) => {
    let checkAllElement = document.getElementById('check-item-all') as HTMLInputElement
    let _selectItems:string[] = Object.assign([],selectItems)
    if(checked){
        _selectItems.push(cartonNo)
        if(_selectItems.length === allCartonItems.length){
          checkAllElement.checked = true
          checkAllElement.indeterminate = false
        }
        else{
          checkAllElement.checked = false
          checkAllElement.indeterminate = true
        }
        
    }
    else{
      let index = _selectItems.indexOf(cartonNo)
      if (index > -1) {
        _selectItems.splice(index, 1);
      }

      if(_selectItems.length === 0){
        checkAllElement.checked = false
        checkAllElement.indeterminate = false
      }
      else{
        checkAllElement.checked = false
        checkAllElement.indeterminate = true
      }
    }

    console.log(_selectItems)
    setSelectItems(_selectItems)
  }

  const onPageChange = (_page:number,_perpage: number) => {
    if(_page !== page){
      setPage(_page)
    }
    if(_perpage !== perpage)
    {
      let items = Helper.Chunk(allCartonItems,_perpage)
      setCartonItems(items)
      setPerpage(_perpage)
    }
  }

  const onReturnDateChange = (date:any,cartonNo:string) => {
    let _allCartonItems:CartonList[] = Object.assign([],allCartonItems)
    let index = allCartonItems.findIndex(x => x.cartonNo === cartonNo)
    if(index > -1){
      _allCartonItems[index].returnDate = date
    }
    let items = Helper.Chunk(_allCartonItems,perpage)
    setAllCartonItems(_allCartonItems)
    setCartonItems(items)
  }

  const disabledDate = (value:any) => {
    let date = currentDate ? currentDate.toString() : new Date().toString()
    return Helper.disabledDate(value,date)
  }

  const disableMaxdDate = (value:any) => {
    let date = currentDate ? currentDate.toString() : new Date().toString()
    return Helper.disableMaxdDate(value,date,user.systemConfig.firstPeriod)
  }

  const handleAdd = () =>{
    setIsAdd(true)
  }

  const AddItem = (items:string[]) => {
        console.log(items)
        setIsAdd(false)
        if(items.length > 0)
        {
            getStorageData2(items)
        }
       
  }

  
  return (

    <div>
        { isAdd ?
            <CollectCartonAdd onBehalf={props.onBehalf} onSelect={AddItem} items={allCartonItems}/>:
        
    
      <Card >
      { isLoading ?
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>
          :
        <>
        <Form
          {...layout}
          form={dataForm}
          name="collect-carton-form"
          onFinish={onFinish}
          validateMessages={validateMessages}
          labelAlign="left"
          colon={false}
          labelWrap={true}
        >

          <Row>
            <Col md={22} >
              <Form.Item name="requesterName" label="Requester" labelCol={{ md: 3 }}   >
                <Input disabled style={{ widows: '100%' }} />

              </Form.Item>
            </Col>
          </Row>
        { props.onBehalf ?
          <Row>
            <Col md={11} >
              <Form.Item name="onBehalfName" label="On Behalf"  >
               <EmployeeAutoComplete
                    key={Helper.Uniqid()}
                    placeholder='Search by Name'
                                              selectedItems={returnIfElse(onBehalfs, onBehalfs, []  ) }
                    onChange={item => onBehalfSelect(item)}
                    service={onSearchEmployee}
                    minimumTextLength={3}
                    borderColor={ownersBgColor}
                    pickOnlyOne
                  />

              </Form.Item>
            </Col>
            <Col md={13} ></Col>
          </Row>:<></>
        }
          <Row>
            <Col md={11} >
                                  <Form.Item name="deliverto" label="Please deliver to.." rules={[{ required: true }]} validateStatus={returnIfElse(error.owner, "error", undefined ) }>
                <EmployeeAutoComplete
                    key={Helper.Uniqid()}
                    placeholder='Search by Name'
                                          selectedItems={returnIfElse(owners, owners, []  ) }
                    onChange={item => onOwnerSelect(item)}
                    service={onSearchEmployee2}
                    minimumTextLength={3}
                    borderColor={ownersBgColor}
                    pickOnlyOne
                  />
              </Form.Item>
              <Form.Item name="location" label="Location">
              <Input  disabled/>
              </Form.Item>
                                  <Form.Item name="receiveDate" label="Expected Receive Date" rules={[{ required: true }]} validateStatus={returnIfElse(error.receiveDate, "error", undefined ) }>
                <DatePicker 
                  placeholder={MessageCommon.TitleDate} 
                  format={'DD MMM YYYY'}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
            <Col md={2} ></Col>
            <Col md={11} >

              <Form.Item name="floor" label="Floor">
                 <Input  placeholder={MessageCommon.TitleInput} />
              </Form.Item>

                                  <Form.Item name="place" label="Place" rules={[{ required: true }]} validateStatus={returnIfElse(error.place, "error", undefined ) }>
                <Input.TextArea placeholder='Please specify pick up location...' />
              </Form.Item>
            </Col>

          </Row>
        </Form>

        <div style={{ marginRight: 10, marginTop: 15, marginBottom: 5 }}>

        { selectItems.length > 0 ?
        <Popconfirm
            title="Delete the task"
            description="Confirm to delete?"
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
             onConfirm = { () => handleDelete() }
          >
           <Button type="primary" className='btn-red mr-btn'>
            <AiFillDelete className='icn-btn' /> Delete
          </Button>
        </Popconfirm>
        :
        <Button type="primary" className='btn-red mr-btn' disabled>
            <AiFillDelete className='icn-btn' /> Delete
          </Button>
        }

            <Button key={Helper.Uniqid()} type="primary" className='btn-blue mr-btn' style={{width:'94px'}} onClick={handleAdd}>
                <AiOutlinePlusCircle className='icn-btn' />Add
          </Button>
        </div>
        <br></br>
        
          <div id="table-container">
       
          <table className='idoc-table'>
            <thead id="head-table-fixed">
              <tr>
                <th style={{ textAlign: 'left',width: '50px'}}>
                        <div className="form-check">
                          <input  
                            type="checkbox" 
                            id={'check-item-carton-all'} 
                            className="form-check-input check-item" 
                            checked={checkAll} 
                            onChange={e => onCheckAll(e.target.checked)}
                          />
                          <label className="form-check-label" htmlFor={'check-item-carton-all'}/>
                        </div>
                      
                </th>
                <th >Carton No.</th>
                <th >Description</th>
                <th >Project</th>
                <th >Owner</th>
                <th >Org. Unit</th>
                <th >Carton Status</th>
                <th >
                                          <span style={{ color: returnIfElse(error.returnDate, 'red', '' )}}>Expected Return Date</span>
                  <Tooltip title="Paste data from first to all row">
                    <AiOutlineCopy style={{ cursor: 'pointer' ,fontSize:'25px',color:'#009fda'}} onClick={applyAllReturnDate} />
                  </Tooltip>
                  </th>
                <th>Detail</th>
                <th>Delete</th>
              </tr>
            </thead>
           
            <tbody >
            { cartonItems.length > 0 && cartonItems[page -1].map((item:CartonList) => (
                <tr>
                  <td style={{ textAlign: 'left',width: '50px'}}>
                        <div className="form-check">
                          <input  
                            type="checkbox" 
                            checked={checkAll || selectItems.indexOf(item.cartonNo) > -1}
                            id={'check-item-carton-' + item.cartonNo} 
                            value={item.cartonNo} 
                            className="form-check-input check-item" 
                            onChange={ e => onCheckChange(e.target.checked,item.cartonNo)}
                          />
                          <label className="form-check-label" htmlFor={'check-item-carton-' + item.cartonNo}/>
                        </div>
                      </td>
                      <td style={{textAlign: 'center',width:'120px'}}>
                    <span>{item.cartonNo}</span>
                  </td>
                  <td style={{textAlign: 'left',wordBreak: 'break-word'}}>
                    {item.description}
                    </td>
                  <td style={{textAlign: 'center'}}>{item.project}</td>
                  <td >{item.ownerName}</td>
                  <td style={{textAlign: 'center'}}>{item.orgUnit}</td>
                  <td style={{textAlign: 'center'}}>{item.cartonStatus}</td>
                  <td style={{textAlign: 'center'}}>
                    <DatePicker 
                      key={Helper.Uniqid()}
                            defaultValue={returnIfElse(item.returnDate, dayjs(item.returnDate), undefined ) }
                      placeholder={MessageCommon.TitleDate}
                      format={'DD-MMM-YYYY'}
                      onChange={(date) => onReturnDateChange(date,item.cartonNo)}
                      disabledDate={disableMaxdDate}
                    />
                  </td>
                 <td  style={{textAlign: 'center'}}>
                    <AiFillEye className='icon-table' onClick={() => showModalCarton(item.requestNo,item.cartonNo)}></AiFillEye>
                 </td>
                  <td>
                    <Popconfirm
                          title="Delete the task"
                          description="Confirm to delete?"
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          onConfirm = { () => handleDeleteByItem(item.cartonNo) }
                        >
                          <AiFillDelete size={20} color="red" style={{ cursor: 'pointer'}}/>
                        </Popconfirm>
                  </td>
                </tr>
              ))
            }
            
            </tbody>
  
          </table>
        
          </div>
          <div style={{marginTop:'10px',textAlign:'right'}}>
            { allCartonItems.length > 0 ?
                <Pagination 
                    key={Helper.Uniqid()}
                    current={page} 
                    pageSize={perpage}
                    total={allCartonItems.length} 
                    onChange={onPageChange} 
                    pageSizeOptions={pageSizeOptions}
                    showSizeChanger={true}
                />:<></>
            }
          </div>
        
        <div  id="save-section" style={{ textAlign: 'center' }}>
          <Button type="default" className='mr-btn' onClick={handleCancel}  >
            <AiOutlineCloseCircle className='icn-btn' />Cancel
          </Button>
          <Button key={Helper.Uniqid()} type="primary" className='mr-btn' onClick={() => handleSave(false)} disabled={cartonItems.length === 0 }>
            <AiOutlineSave className='icn-btn' />Save Draft
          </Button>
          <Button key={Helper.Uniqid()} type="primary" onClick={() => confirmSave()} disabled={cartonItems.length === 0} >
            <AiFillSave className='icn-btn' />Submit
          </Button>
        </div>
        </>
      }
        <Modal title="Carton Detail" open={isModalCartonOpen} footer={''} width={1200} onCancel={handleCancelCarton}>
        <CartonDetail key={Helper.Uniqid()} requestNo={requestNo} box={box} />
        </Modal>

      </Card>
      
    }
      {contextHolder}
      {contextHolderMessage}
    </div>
  );

}

export default CollectCartonModal
