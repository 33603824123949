import axios from 'axios'
import { PageDataResponse ,DataObjectResponse } from '../models/PageResponse'
import { Parameter,QueryDataModel,CartonList} from '../models'
import SummaryReport from '../models/SummaryReport'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class QueryService
{
    protected headers = {
        'Content-Type': 'application/json',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    protected token = ""
    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': ""
            }
        }
        
    }
    
    async GetSummary(parameter:Parameter ){
        if (!this.token) return null
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.post<DataObjectResponse<any>>(`${apiUrl}/v1/queries/summary`, JSON.stringify(parameter),options )
        
        return data;
    }

    async GetSummaryDestruction(parameter:Parameter ){
        if (!this.token) return null
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.post<DataObjectResponse<SummaryReport>>(`${apiUrl}/v1/queries/summarydestruction`,JSON.stringify(parameter), options )
        
        return data;
    }
    async Search(parameter: Parameter) {
        if (!this.token) return null
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/queries/search`,JSON.stringify(parameter),options )
        return data;
    }
    async QuerySearch(parameter: Parameter) {
        if (!this.token) return null
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/queries/querysearch`,JSON.stringify(parameter),options )
        return data;
    }
    async SearchApprover(parameter: Parameter) {
        if (!this.token) return null
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/queries/search/approver`,JSON.stringify(parameter),options )
        return data;
    }
    async SearchOnbehalf(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/queries/search/onbehalf/tracking`,JSON.stringify(parameter),options )
        return data;
    }
    async SearchOnbehalfDraft(parameter: Parameter) {
        if (!this.token) return null
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/queries/search/onbehalf/draft`,JSON.stringify(parameter),options )
        return data;
    }

    async SearchMyTask(parameter: Parameter) {
        if (!this.token) return null
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/queries/search/mytask`,JSON.stringify(parameter),options )
        return data;
    }

    async SearchAdminDestruction(parameter: Parameter) {
        if (!this.token) return null
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/queries/search/admin/destruction`,JSON.stringify(parameter),options )
        return data;
    }

    async SearchTransaction(parameter: Parameter) {
        if (!this.token) return null
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/queries/searchtransaction`,JSON.stringify(parameter),options )
        return data;
    }

    async SearchCarton(parameter: Parameter) {
        if (!this.token) return null
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.post<PageDataResponse<CartonList>>(`${apiUrl}/v1/queries/searchcarton`, JSON.stringify(parameter),options )
        
        return data;
    }


}