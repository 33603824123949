import axios from 'axios'
import { config } from '../config'
import { DataObjectResponse } from '../models/PageResponse'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class EmailService
{
    protected headers = {
        'Content-Type': 'application/json',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    protected token = ""
    constructor(token: string | null) {
        if (token) {
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': ""
            }
        }

    }

    async sendToApproverAllRequest(req: string[]) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
        }
        
        const { data }  = await axios.post<DataObjectResponse<[]>>(`${apiUrl}/v1/emails/send/request`,JSON.stringify(req), options )
        
        return data;
    }
    
    async sendDestroyConfirmation(req: string[]) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
        }
        
        const { data }  = await axios.post<DataObjectResponse<[]>>(`${apiUrl}/v1/emails/send/destroy/confirm`,JSON.stringify(req), options )
        
        return data;
    }

    async sendToApproverCarton(req: string[]) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
        }
        
        const { data }  = await axios.post<DataObjectResponse<[]>>(`${apiUrl}/v1/emails/send/approval/carton`,JSON.stringify(req), options )
        
        return data;
    }

}