import React, {  useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./configurations/authConfig";
import  secureLocalStorage  from  "react-secure-storage";

const Authentication = () => {
  const { instance } = useMsal();
 
  useEffect(() => {
      signIn()
  },[])

  const signIn = () => {
    secureLocalStorage.clear()
      instance.loginRedirect(loginRequest).catch(e => {
          console.error(e);
      });
  }
  return (
    <div>
        <span>Autherizing...</span>
    </div>
  );
}

export default Authentication;