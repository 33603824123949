import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Radio, Row ,Spin ,Popconfirm,message } from 'antd';
import {  AiOutlineCheck, AiOutlineClose,AiFillDelete ,AiFillSave , AiOutlineCloseCircle} from 'react-icons/ai';
import { Label } from 'reactstrap';
import {  CloseCircleOutlined ,QuestionCircleOutlined } from '@ant-design/icons';
import CartonList from '../Share/CartonList'
import { RequestDetail,UpdateApproval, User } from '../../models';
import {  RemoveCartonService } from '../../services';
import { InitHelper } from '../../helpers';
import { CartonStatus ,Message,RequestType } from '../../enum';
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import DisplayLogNote from '../Share/DisplayLogNote';
import { useMsal } from "@azure/msal-react";
import dayjs from 'dayjs'

interface Props{
  recordNo:number
  approveLevel: number
  disableDetail?: boolean
}

const RemoveCartonRequestDetail = (props: Props) => {
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }

    const { instance, accounts } = useMsal();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const [isModalRejectOpen,setIsModalRejectOpen] = useState(false)
  const [dataForm] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [detail, setDetail] = useState<RequestDetail>(InitHelper.InitialRequestDetail)
  const [isLoading, setIsLoading] = useState(true)
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
  

  useEffect(() => {
    getData()
  }, [])
  
  const getData = async () => {
    setIsLoading(true)
    let token = await getToken()
    let result = await new RemoveCartonService(token).GetDetail(props.recordNo)
    console.log(result)
    if(result && result.success){
      setDetail(result.data)
    }
    setIsLoading(false)
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const handleReject = () => {
    
    let errorElement = document.getElementById('note-reject-error') as HTMLDivElement
    errorElement.style.display = 'none'
    let element = document.getElementById('note-reject') as HTMLTextAreaElement

    if(element && element.value){
      handleAction(CartonStatus.Reject) 
    }
    else{
      errorElement.style.display = 'inline'
      return false
    }
    
  }

  const handleAction = async (approveStatusId: number) => {
    let element = document.getElementById('note-reject') as HTMLTextAreaElement
    let _noteReject = element && element.value ? element.value : ""

    let token = await getToken()
    let requestUpdateStatus:UpdateApproval = {
      recordIds: [props.recordNo],
      statusId: 0,
      approveStatusId:approveStatusId,
      noteReject: _noteReject,
      updateBy: user.employeeId,
      updateDate: new Date()
    }
   
    let result = await new RemoveCartonService(token).UpdateApproval(requestUpdateStatus)
    if(result && result.success){
        let element = document.getElementById('remove-form') as HTMLElement
        messageApi.open({
            type: 'success',
            content: 'Save success',
            duration: 1.5,
            style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
        });
        setTimeout(() => {
            handleCancel()
        }, 1000);
    }
    else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
    }
  }

const handleCancel = () => {
    let elements: any = Array.from(document.getElementsByClassName('ant-modal-close'))
    elements.forEach((el: any) => {
        el.click()
    })
    setIsModalOpen(false);
    setIsModalRejectOpen(false)
}

  const handleRejectOpen = () => {
 
    setIsModalRejectOpen(true)
    setTimeout(() => {
        let errorElement = document.getElementById('note-reject-error') as HTMLDivElement
            errorElement.style.display = 'none'
        let element = document.getElementById('note-reject') as HTMLTextAreaElement
        element.value = ""
       
    }, 300);

    
 }

 const showNoteModal = () => {
    setIsNoteModalOpen(true);
  };
  const handleNoteModalCancel = () => {
    setIsNoteModalOpen(false);
  };

  return (

    <div style={{ minHeight: '100px'}}>
    { isLoading ?
      <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
        :
      <Card >
           <Form
          {...layout}
          form={dataForm}
          name="remove-form"
          labelAlign="left"
          colon={false}
          labelWrap={true}
        >
          <Row>
            <Col md={22} >
              <Form.Item label="Requester" labelCol={{ md: 3 }}  >

                <Label className='label-det'>{ detail.requesterName }</Label>
                <Label className='mr-label' >Org. Unit</Label>
                <Label className='label-det'>{ detail.orgUnit }</Label>
                <Label className='mr-label' >Position</Label>
                <Label className='label-det'>{ detail.position }</Label>
                <Label className='mr-label' >Tel No.</Label>
                <Label className='label-det'>{ detail.tel }</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
              <Form.Item  label="Request No"  >
                <Label className='label-det'>{ detail.requestNo }</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item label="Status"  >
                <Label className='label-det'>{ detail.status }</Label>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={11} >
              <Form.Item label="Contact Person"  >
                <Label className='label-det'>{ detail.contactPersonName }</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item label="Location"  >
                <Label className='label-det'>{ detail.location }</Label>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={11} >
              <Form.Item label="Floor"  >
                <Label className='label-det'>{ detail.floor }</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item label="Place"  >
                <Label className='label-det'>{ detail.place }</Label>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={11} >
              <Form.Item  label="Carton Quantity"  >
                <Label className='label-det'>{ detail.quantity } carton(s)</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
            
              <Form.Item label="Request Date"  >
                <Label className='label-det'>{ dayjs(detail.requestDate).format('DD MMM YYYY') }</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
            
            { detail.requestTypeId === RequestType.StorageCarton ?
              <Form.Item  label="Expected Receive Date"  >
                <Label className='label-det'>{ dayjs(detail.returnDate).format('DD MMM YYYY') }</Label>
              </Form.Item>
              :<></>
            }
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
            <Form.Item name="Verifier" label={ detail.requestDate && parseInt(dayjs(detail.requestDate).format("YYYYMM")) >= 202310 ? "Verifier" : "Approver"}  >
                <Label className='label-det '>{  detail.approverName } </Label>
              </Form.Item>
            
            {detail.approverDate2 != null ?
              <Form.Item name="approver" label="Approver"  >
                <Label className='label-det '>{  detail.approverName2 } </Label>
              </Form.Item>:<></>
            }
            </Col>
          </Row>
     
          <Row>
                          <Col md={11} >
                              {detail.statusId === CartonStatus.Reject ?
                                  <Form.Item name="noteReject" label="Rejected Reason"  >
                                      <Label className='label-det'>{detail.notApproveReason}</Label>
                                  </Form.Item> : <></>
                              }
              <Form.Item name="last_note" label="Lasted Note"  >
                              {returnIfElse(detail.note.length > 0,
                                  <>
                                      <Label className='label-det'>{detail.note[detail.note.length - 1]}</Label>
                                      <a className='a-table ml-label' onClick={showNoteModal}>Display Log</a>
                                  </>
                                  ,
                                  <></>
                              ) 
                }

              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
            <Form.Item name="serviceDate" label="Service Date"  >
                {detail.serviceDate ? dayjs(detail.serviceDate).format("DD MMM YYYY") : ""}
              </Form.Item>
              
            </Col>
          </Row>
     
        </Form>
        <hr></hr>
        { props.approveLevel === 0 ?
            <></>
            :
            <div style={{ textAlign: 'center' }}>
                 <Button type="default" className='mr-btn' onClick={handleCancel}  >
                    <AiOutlineCloseCircle className='icn-btn' />Cancel
                </Button>
                <Button type="primary" className='btn-red mr-btn' style={{width: '140px'}} onClick={() => handleRejectOpen()}>
                    <AiFillDelete className='icn-btn' /> Reject
                  </Button>
                <Popconfirm
                  title="Approve the task"
                  description="Confirm to approve?"
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  onConfirm = { () => handleAction(CartonStatus.Approve) }
                >
                <div className="idoc-tooltip">
                  <span className="tooltiptext">
                    <ul>
                      <li style={{textAlign: 'left'}}>
                        {Message.Verify}
                      </li>
                      <li style={{textAlign: 'left'}}>
                          {Message.Approve}
                      </li>
                    </ul>
                  </span>
                <Button type="primary" >
                    <AiFillSave className='icn-btn' /> Verify/Approve
                </Button>
              </div>
              </Popconfirm>
          


            </div>
        }
          <CartonList requestNo={detail.requestNo} cartons={detail.cartons} disableDetail={props.disableDetail} statusId={detail.statusId}/>
      </Card>
    }
      <Modal title="" open={isModalOpen} footer={''} width={600} >
        <br></br>
        <Card >
          <Form
            {...layout}
            form={dataForm}
            name="nest-messages"
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
              <Col md={24}  >
                <Form.Item name="owner" label="Request No" labelCol={{ md: 6 }}  >
                  <Radio.Group name="radiogroup" defaultValue={1}>
                    <Radio value={1}>Archive officer format</Radio>
                    <Radio value={2}>User format</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24}  >
                <Form.Item name="owner" label="Export type" labelCol={{ md: 6 }}  >
                  <Radio.Group name="radiogroup" defaultValue={1}>
                    <Radio value={1}>PDF</Radio>
                    <Radio value={2}>Excel</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

          </Form>
          <div style={{ textAlign: 'center' }}>
            <Button type="default" className='mr-btn'   >
              <AiOutlineClose className='icn-btn' />Cancel
            </Button>
            <Button type="primary" className='mr-btn'  >
              <AiOutlineCheck className='icn-btn' />OK
            </Button>
          </div>



        </Card>
      </Modal>
      {/* <Modal title="Carton Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton}>
        <CartonDetail></CartonDetail>
      </Modal> */}

    <Modal title="Confirm to reject?" open={isModalRejectOpen} footer={''} width={700} onCancel={() => setIsModalRejectOpen(false)} maskClosable={false}>
        <br></br>
       
          <Row>
          <Col md={2} >
              Note <span style={{ color: 'red'}}>*</span>
            </Col>
            <Col md={22} >
            <textarea style={{width: '93%',borderColor:"#ccc",borderRadius:"5px"}} id="note-reject" rows={5} defaultValue=""/>
              <div id="note-reject-error" className='error' style={{ display:'none'}}>Note is required.</div>
            </Col>
          </Row>
        
        <br></br>
        <div style={{ textAlign: 'center' }}>
          <Button type="default" className='mr-btn' onClick={() => setIsModalRejectOpen(false)}  >
            <AiOutlineClose className='icn-btn' />Cancel
          </Button>
          <Button type="primary" className='mr-btn'  onClick={() => handleReject()}>
            <AiOutlineCheck className='icn-btn' />OK
          </Button>
        </div>
      </Modal>
      <Modal title="Display Log" open={isNoteModalOpen} footer={''} width={900} onCancel={handleNoteModalCancel}>
        <DisplayLogNote  recordNo={detail.recordNo} requestTypeId={RequestType.RemoveCarton}/>
      </Modal>

          {modal ? <span></span> : <></>}
      {contextHolder}
      {contextHolderMessage}
    </div>
  );

}

export default RemoveCartonRequestDetail
