import { useEffect, useState } from 'react';
import { Collapse, Form,Row,Spin,Card,Col,Input,Pagination,Button,DatePicker } from 'antd';
import { Label } from 'reactstrap';
import { CartonStatus, ExportType } from '../../enum';
import { AiOutlineSync, AiOutlineSearch, AiOutlinePrinter } from 'react-icons/ai';
import { ChangeOwner ,Parameter ,Item} from '../../models'
import { Helper, InitHelper } from '../../helpers';
import { HrWebService,PropertyService,ChangeOrgUnitService, FileManageService} from '../../services';
import dayjs from 'dayjs'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import MessageCommon from '../../enum/Message';
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import { SelectPagination } from '../../components/SelectPagination';
import Loading from '../../components/Loading';
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { User} from '../../models';
import { useNavigate } from 'react-router-dom';

interface AppState {
    currentPage:number
    perpage:number
    total: number
    items:ChangeOwner[]
    orgUnits:string[]
    isLoading:boolean
  }

const AutoChange = () => {
    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const [dataForm] = Form.useForm();
    const { Panel } = Collapse;
    const [appState,setAppState] = useState<AppState>({
      currentPage : 1,
      total : 0,
      perpage: 100,
      items: [],
      orgUnits:[],
      isLoading : true
    })
    const [requestStartDate, setRequestStartDate] = useState<Date|null>(null);
    const [requestEndDate, setRequestEndDate] =  useState<Date|null>(null);
    const [owners, setOwners] = useState<Item[]>([])
    const [orgunits, setOrgUnits] = useState<Item[]>([])
    const [newOrgUnits, setNewOrgUnits] = useState<Item[]>([])
    const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter)
    const [isPrint,setIsPrint] = useState(false)
    const [isLoading,setIsloading] = useState(false)

    useEffect(() => {
        if(user.permissions.orgUnit === 0){
            navigate('/permissiondenided')
        }
        getData(1,appState.perpage,InitHelper.InitialParameter())
    }, [])
    
    const validateMessages = {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!',
        },
        number: {
          range: '${label} must be between ${min} and ${max}',
        },
      };
      const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
     
    
    
    const getData = async (currentPage: number,perpage: number,_parameter:Parameter| null = null) => {
        let _state:AppState = Object.assign({},appState)
           _state.isLoading = true
           setAppState(_state)

        let parameter = Object.assign({},saveParameter)
        if(_parameter !== null){
            parameter = Object.assign({},_parameter);
        }
           
        parameter.page = currentPage
        parameter.limit = perpage
        parameter.statusIds = [CartonStatus.Done]
        parameter.system = 1
        console.log(parameter)
        let token = await getToken()
        let result = await new ChangeOrgUnitService(token).SearchRequest(parameter)
        
        console.log(result)
        if(result && result.success)
        {
            let state = Object.assign({},appState)
              state.total = result.data.total
              state.currentPage = currentPage
              state.items = result.data.items
              state.isLoading = false
              state.perpage = perpage
            
            if(result.data.total>0){
              setIsPrint(false)
            }else{
              setIsPrint(true)
            }
            setTimeout(() => {
                setSaveParameter(parameter)
                setAppState(state)
            }, 500);
        }
        else{
            let state = Object.assign({},appState)
            state.isLoading = false
            setAppState(state)
        }
    }
      
    const searchData = async () => {
        console.log('searchdata')
        let field = dataForm.getFieldsValue()
        console.log(field)
        let parameter = InitHelper.InitialParameter();
           
        if(field.requestNo){
            parameter.requestNoList = field.requestNo.trim().split(",")
        }
           
        if(owners.length > 0){
            parameter.owners = owners.map(item => item.key)
        }
    
        if(orgunits.length > 0){
            parameter.orgUnits = orgunits.map(item => item.key)
        }

        if(newOrgUnits.length > 0){
            parameter.newOrgUnits = newOrgUnits.map(item => item.key)
        }

        if(requestStartDate && requestEndDate){
            parameter.requestStartDate = requestStartDate
            parameter.requestEndDate = requestEndDate
        }
    
        console.log(parameter)
        getData(1,appState.perpage,parameter)
        
    }

    const reset = () => {
        console.log('reset')
        dataForm.resetFields()
        setOwners([])
        setOrgUnits([])
        setNewOrgUnits([])
        getData(1,appState.perpage,InitHelper.InitialParameter())
    }
     
    const onSearchEmployee = async(key:string) => {
       
        let token = await getToken()
        let result = await new HrWebService(token).SearchEmployee(key)
        if(result && result.success)
        {
            return result.data
        }
        else{
            console.log(result?.message)
            return []
        }
    }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    
    
    const onPageChange = (page:number,perpage:number) => {
        if(page !== appState.currentPage && perpage === appState.perpage){
            getData(page,appState.perpage)
        }
    
        if(page === appState.currentPage && perpage !== appState.perpage){
            getData(1,perpage)
        }
    }


    const onOwnerSelect = (item:any) => {
        setOwners(item)
    }


    const onOrgUnitChange = (value:any) => {
       setOrgUnits(value)
    }

    const onNewOrgUnitChange = (value:any) => {
        setNewOrgUnits(value)
    }

    const onRequestStartDateChange = (date: any) => {
        setRequestStartDate(date);
    }
    
    const onRequestEndDateChange = (date: any) => {
        setRequestEndDate(date)
    }

    const onSearchOrgUnit = async(key:string) => {
   
        let token = await getToken()
        let result = await new PropertyService(token).GetOrgUnits(key)
        if(result && result.success)
        {
          return result.data
        }
        else{
          console.log(result?.message)
          return []
        }
    }
    const onFinishPrint = async () => {
      setIsloading(true)
      let token = await getToken()
      if(!saveParameter)return

      try{

          await new FileManageService(token).DownloadReport(saveParameter,ExportType.AUTO_CHANGE_ORG_UNIT);
      }catch(error){
          console.log("🚀 ~ file: ManualUpdate.tsx:392 ~ onFinishPrint ~ error:", error)

      }finally{
        setIsloading(false)
      }
  }

    return (
    <div>
        <Card >
        
        <Collapse>
            {isLoading?<Loading/>:<></>}
            <Panel header="Search" key="1">
                <Form
              {...layout}
              form={dataForm}
              name="change-owner-form"
              validateMessages={validateMessages}
              labelAlign="left"
              colon={false}
              labelWrap={true}
            >
              <Row>
                <Col md={11} >
                    <Form.Item name="requestDate" label="System Change Date">
                      <table style={{width : '100%'}}>
                        <tr>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate} className='date-picker-full' onChange={onRequestStartDateChange}/>
                          </td>
                          <td>
                            <Label style={{ marginLeft: 10, marginRight: 10 }}>To</Label>
                          </td>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate} className='date-picker-full' onChange={onRequestEndDateChange}/>
                          </td>
                        </tr>
                      </table>
                    </Form.Item>
                    <Form.Item name="requestNo" label="Storage Request No."  >
                        <Input placeholder="ex. B230002,B230004" />
                    </Form.Item>
                    <Form.Item name="owner" label="Owner"  >
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={owners ? owners : []}
                            onChange={item => onOwnerSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                    />
                </Form.Item>
                </Col>
                <Col md={2} >
                </Col>
                <Col md={11} >
                <Form.Item name="orgUnit" label="Previous Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={orgunits ? orgunits : []}
                            onChange={item => onOrgUnitChange(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
                    <Form.Item name="newOrgUnit" label="New Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={newOrgUnits ? newOrgUnits : []}
                            onChange={item => onNewOrgUnitChange(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
                </Col>
              </Row>
      
                </Form>
                <div style={{ textAlign: 'center' }}>
                    <Button type="default" className='mr-btn' onClick={reset} >
                        <AiOutlineSync className='icn-btn' /> Reset
                    </Button>
                    <Button type="primary"   onClick={searchData}>
                        <AiOutlineSearch className='icn-btn' /> Search
                    </Button>
                </div>
  
            </Panel>
        </Collapse>
        <br></br>
        <Collapse defaultActiveKey={['1']}>
            <Panel header="Data Result" key="1" >
            <Button type="primary" htmlType="button" className='mr-btn' disabled={isPrint} onClick={()=>onFinishPrint()} >
         <AiOutlinePrinter className='icn-btn' />Print
       </Button>
               { appState.total>0?<div style={{float: 'right'}}>
                        <SelectPagination  onSelectChange={onPageChange} currentPage={appState.currentPage}/>
                        <span style={{marginLeft:'8px'}}>/ Page</span>
                    </div>:<></>}
                    <div id="panel-action" style={{paddingTop: '8px'}}>
                <div>
                  <div className='search-result' style={{ float: 'left'}}>Search found { appState.total } record(s).</div>
                  <div style={{float: 'right'}}>
                     
                  </div>
                </div>
              </div>
                <div id="table-container" style={{marginTop: '20px'}}>
                { appState.isLoading ?
                    <div style={{ margin: 'auto',paddingBottom: '30px'}}>
                        <Spin tip="Loading" size="large">
                            <div className="content" />
                        </Spin>
                    </div>
                    :
                    <table className='idoc-table'>
                    <thead id="head-table-fixed">
                        <tr>
                            <th >Storage Request No.</th>
                            <th >Owner</th>
                            <th >Previous Org. Unit</th>
                            <th >New Org. Unit</th>
                            <th >System Change Date</th>
                        </tr>
                    </thead>
       
                    <tbody >
                        { appState.items.map((item:ChangeOwner) => (
                            <tr>
                                <td style={{textAlign: 'center'}}>{item.requestNo }</td>
                                <td style={{textAlign: 'center'}}>{item.ownerName}</td>
                                <td style={{textAlign: 'center'}}>{item.orgUnit}</td>
                                <td style={{textAlign: 'center'}}>{item.newOrgUnit}</td>
                                <td style={{textAlign: 'center'}}>
                                    {dayjs(item.requestDate).format('DD MMM YYYY')}
                                </td>
                              
                            </tr>
                        ))
                        }
        
                    </tbody>

                </table>
                }
                </div>
                <div style={{marginTop:'10px',textAlign:'right'}}>
                { appState.total > 0 ?
                    <Pagination 
                        key={Helper.Uniqid()}
                        current={appState.currentPage} 
                         pageSize={appState.perpage}
                        total={appState.total} 
                        onChange={onPageChange} 
                        showSizeChanger={false}
                    />:<></>
                }
                </div>
        
        
            </Panel>
        </Collapse>

        </Card>
  
    </div>
    );
  
  }
  
  export default AutoChange
  