import dayjs from 'dayjs'

export default class Helper {
    
    static Chunk(arr:any[], size:number){
        let index = 0;
        let arrayLength = arr.length;
        let tempArray = [];

        for (index ; index < arrayLength; index += size) {
            let chunk = arr.slice(index, index + size);
            tempArray.push(chunk);
        }

        return tempArray;
    }

    static Uniqid(): string {
        return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c => {
            const char = Number(c);
            return ((char ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (char / 4)))).toString(16));
        });
    }
    static ArrayRange(start: number, stop: number, step: number = 1){
        let result = Array.from( { length: (stop - start) / step + 1 },(value, index) => start + index * step) 
        return result
    
    }
    
    static disabledDate = (date:any,currentDate: string) => {
        return (date && date.valueOf() < dayjs(currentDate)) 
    }
    
    static disableMinMaxdDate = (current:any,currentDate: string,maxDate: string) => {
        let now = dayjs(currentDate)
        return current && current.valueOf() < now || current > dayjs(maxDate)
    }

    static disableMaxdDate = (current:any,currentDate: string,months: number) => {
        let now = dayjs(currentDate)
        return current && current.valueOf() < now || current > dayjs(currentDate).add(months,'months')
    }

    static async fileReader(file:any) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => reader.result;
        reader.onerror = (err) => console.log(err);
       
      }

    static add3Days(currentDate:string)
    {

        if(!currentDate){
            return dayjs().add(3,'day')
        }
        
        var day = dayjs(currentDate).day() 
        if(day == 0 || day === 1 || day === 2){
            return dayjs(currentDate).add(3,'day')
        }
        if(day === 3 || day === 4 || day ===5){
            return dayjs(currentDate).add(5,'day')
        }
        if(day === 6){
            return dayjs(currentDate).add(4,'day')
        }
       
    }
     
}