import React, { useEffect,useState } from 'react';
import { Layout } from './components/Layout';
import AppRoutes from './AppRoutes';
import { AuthenticatedTemplate, UnauthenticatedTemplate,useMsal } from "@azure/msal-react";
import Authentication from './Authentication';
import { InteractionRequiredAuthError,InteractionStatus} from "@azure/msal-browser";
import { Route, Routes } from 'react-router-dom';
import { UserService } from './services';
import { User } from './models';
import  secureLocalStorage  from  "react-secure-storage";
import './custom.css';
import { PageOption } from './enum';
import { apiRequest } from "./configurations/authConfig";

const App = () => {
    const { instance, inProgress, accounts } = useMsal();
    const [isLoading,setIsloading] = useState(true)
    const [isPermissionDenied,setIsPermissionDenied] = useState(false)

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const doIfTrue = (f: any, flag: any) => {
        if (flag)
            f();
    }

    const resultCondition = (result: any) => {
        return result && result.success && result.data
    }

    useEffect(() => {
        console.log("v0.12");
      const accessTokenRequest = { 
        scopes: ["user.read"],
        account: accounts[0]
      };
      const controller = new AbortController();
  
        if (inProgress === InteractionStatus.None) {


                getToken().then((token_result) => {
                    new UserService(token_result).getUser(accounts[0].username).then((result) => {
                        console.log(result)
                        console.log(secureLocalStorage.getItem('testFlag'))
                        if (secureLocalStorage.getItem('testFlag') !== 1 && result) {
                            if (resultCondition(result)) {

                                let user: User = result.data
                                console.log(user)
                                secureLocalStorage.setItem('user', JSON.stringify(user))
                                if(result.date){
                                    secureLocalStorage.setItem('date',result.date)
                                }
                                    
                                let storePagination = PageOption.map((item) => { return { value: item.value, label: item.value } })
                                secureLocalStorage.setItem('pagination', JSON.stringify(storePagination))
                                doIfTrue(function () { secureLocalStorage.setItem('date', result.date + "") }, result.date);
                            }
                            else {
                                setIsPermissionDenied(true)
                            }

                        }
                        setIsloading(false)
                })
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance.acquireTokenRedirect(accessTokenRequest);
            }
            console.log(error);
          });
      }
  
      return () => {
          controller.abort();
      };
    }, [instance, accounts, inProgress]);
    
    const signout = () => {
        secureLocalStorage.clear();
        instance.logoutRedirect().catch(e => {
            console.error(e);
        })
    }

  return (
    <div>
        <AuthenticatedTemplate>
            {!isLoading && !isPermissionDenied ?
               
                    <Layout>
                        <Routes>
                            {AppRoutes.map((route, index) => {
                                const { element, ...rest } = route;
                                return <Route key={index} {...rest} element={element} />;
                            })}
                        </Routes>
                    </Layout>
               
                :
                <span style={{ display: 'none'}}>Authentication...</span>
            }           
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Authentication/>
        </UnauthenticatedTemplate>
        {isPermissionDenied?
        <>
            <div style={{textAlign:'center',marginTop: '10%'}}>
                <div style={{color: 'red',fontSize:'30px'}}>
                    Permission denied, please contact administrator.
                </div >
                <div style={{fontSize:'15px'}}><a href='#' onClick={signout}> Sign out</a></div>
            </div>
        </>:<></>
        }
    </div>
  );
}

export default App;