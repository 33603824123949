import React, {  useEffect, useState } from 'react';
import { Button, Card,  Col,  DatePicker, Form, Input, Pagination, Modal,Spin, Popconfirm, Row,  message } from 'antd';
import {  AiFillDelete, AiFillSave, AiOutlineCloseCircle, AiOutlineCheck,AiFillEye ,AiOutlinePlusCircle,AiOutlineClose} from 'react-icons/ai';
import dayjs, { Dayjs } from 'dayjs';
import { Label } from 'reactstrap';
import { ExclamationCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { CartonList , RequestReturnCarton, User} from '../../models';
import {ReturnCartonService,CollectCartonService} from '../../services';
import { CartonStatus, RequestType } from '../../enum';
import { Helper ,InitHelper} from '../../helpers';
import  secureLocalStorage  from  "react-secure-storage";
import { useNavigate } from 'react-router-dom';
import CollectCartonRequestDetail from '../CollectCarton/CollectCartonRequestDetail';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import ReturnCartonAdd  from "./ReturnCartonAdd"

interface Props{
  returnId:number
  items: string[]
  page:string
  onReload:any
}

interface Error
{
  returnDate:number
  extendReason:number
}

const ExtendCartonModal = (props: Props) => {
    const doIfElse = (flag: any, f1: any, f2: any) => {
        if (flag) {
            if (f1 != null) {
                f1();
            }
        }
        else {
            if (f2 != null) {
                f2();
            }
        }
    }

    const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const currentDate = secureLocalStorage.getItem('date')
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [dataForm] = Form.useForm();
  const [page,setPage] = useState(1)
  const [perpage ,setPerpage] = useState(10);
  const [selectItems, setSelectItems] = useState<string[]>([])
  const [allCartonItems,setAllCartonItems] = useState<CartonList[]>([])
  const [cartonItems,setCartonItems] = useState<CartonList[][]>([])
  const [modal, contextHolder] = Modal.useModal();
  const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [checkAll, setCheckAll] = useState(false)
  const [isLoading ,setIsLoading] = useState(true)
  const [recordNo,setRecordNo] = useState(0)
  const [returnDate,setReturnDate] = useState(dayjs())
  const [defaultReturnDate,setDefaultReturnDate] = useState(dayjs())
  const [expectReturnDate,setExpectReturnDate] = useState(dayjs())
  const [error,setError] = useState<Error>({returnDate:0 ,extendReason: 0})
  const [isAdd,setIsAdd] = useState(false)
  const onFinish = (values: any) => {
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
  
  
  const validate = () => {
    
    let error:Error = {
        returnDate : 0,
        extendReason: 0
    }

    if(!returnDate){
        error.returnDate = 1
    }

    if(error.returnDate || error.extendReason){
        let element = document.getElementById('return-carton-form') as HTMLFormElement
        messageApi.open({
            type: 'error',
            content: "Please fill required fields",
            duration: 1.5,
            style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
          });

        setError(error)
        return false
    }
    else{
        setError(error)
        return  true
    }
   

  }

  const validateExtendDate = () => {
    
    let returnValidate = allCartonItems.filter(x => parseInt(returnDate.format('YYYYMMDD')) < parseInt(dayjs(x.returnDate).format('YYYYMMDD')))
    if(returnValidate.length > 0){
        setIsModalConfirmOpen(true)
    }
    else{
       handleSave(true)
    }
  }

const handleSave = async (saveType: boolean) => {
    setIsModalConfirmOpen(false)
  if(validate())
  {
    let requestCarton:RequestReturnCarton = {
          recordNo:props.returnId,
          requestType:'extend',
          requester:user.employeeId,
          onBehalf:user.employeeId,
          contactPerson:user.employeeId,
          contactPersonName:"",
          returnDate: returnDate.format("YYYY-MM-DD"),
          location: "",
          floor: "",
          place: "",
          statusId: saveType ? CartonStatus.Done : CartonStatus.SaveDraft,
          extendReason: dataForm.getFieldValue('extendReason'),
          cartons:allCartonItems.map((x) => { x.returnDate = returnDate.format("YYYY-MM-DD"); return x })
       }
    console.log(requestCarton)
      let token = await getToken()
      
       let result = await new ReturnCartonService(token).Extend(requestCarton);
      
      console.log(result)
      let element = document.getElementById('table-container') as HTMLElement
      if(result && result.success){
    
          messageApi.open({
            type: 'success',
            content: 'Save success',
            duration: 1,
            style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
          });
          setTimeout(() => {
            handleCancel()
            props.onReload()
          }, 1000);
         
      }
      else{
          Modal.error({
              icon: <CloseCircleOutlined />,
              title: 'This is an error message',
              content: result?.message,
          });
        
      }
    }
 
  
};

  const handleDelete = async () => {
    let _allCartonItems:CartonList[] = Object.assign([],allCartonItems)
    allCartonItems.forEach(item => { 
      let index = allCartonItems.findIndex( item1 => item1.cartonNo === item.cartonNo)
      if(index > -1){
        _allCartonItems.splice(index,1)
      }
    })
    console.log(selectItems)
    console.log(_allCartonItems)
    let items = Helper.Chunk(_allCartonItems,perpage)
    setAllCartonItems(_allCartonItems)
    setCartonItems(items)
    setPage(1)
    setSelectItems([])
    onCheckAll(false)
    
  }

  const handleDeleteByItem = async (cartonNo:string) => {
  
    let _allCartonItems:CartonList[] = Object.assign([],allCartonItems)
    console.log(cartonNo)
    let index = allCartonItems.findIndex( item => item.cartonNo === cartonNo)
    console.log(index)
    if(index > -1)
    {
      _allCartonItems.splice(index,1)
      let items = Helper.Chunk(_allCartonItems,perpage)
      setAllCartonItems(_allCartonItems)
      setCartonItems(items)
      setPage(1)
      setSelectItems([])
      onCheckAll(false)
    }
    
    let element = document.getElementById('table-container') as HTMLElement
      messageApi.open({
        type: 'success',
        content: 'Delete success',
        duration: 1,
        style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
    });
}

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const confirmSave = () => {
    modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Confirm to submit?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => validateExtendDate()
    });
  };

  const handleCancel = () => {
    let element = document.getElementsByClassName('ant-modal-close') as HTMLCollection
    let btnClose = element[0] as HTMLButtonElement
    btnClose.click()
  };

  const showModalCarton = async (_recordNo:number) => {
    setRecordNo(_recordNo)
    setIsModalCartonOpen(true);
  };
  const handleCancelCarton = () => {
    setIsModalCartonOpen(false);
  
  };

  const handleCancelConfirm = () => {
    setIsModalConfirmOpen(false);
  
  };


  const getReturnData = async () => {
  
      let token = await getToken()
      let result = await new ReturnCartonService(token).GetRequest(props.returnId)
      console.log(result)
      if(result && result.success && result.data)
      {
        let data = result.data
        let _allCartonItems = data.cartonList
        if(_allCartonItems.length > 0)
        {
          let items = Helper.Chunk(_allCartonItems,perpage)
           let _expectReturnDate = _allCartonItems.map(x => dayjs(x.returnDate).format("YYYYMMDD") ).sort(function(a:string,b:string){ return  parseInt(a) - parseInt(b) ;})[0]
          setAllCartonItems(_allCartonItems)
          setCartonItems(items)
          setExpectReturnDate(dayjs(_expectReturnDate))
          setReturnDate(dayjs(result.data.returnDate))
          setDefaultReturnDate(dayjs(result.data.returnDate))
          dataForm.setFieldValue('extendReason',result.data.extendReason)
          setIsLoading(false)
        }
        
       
        setTimeout(() => {
          setIsLoading(false)
        }, 500);
       
      }
      else{
        setIsLoading(false)
      }
     

  }

  const getCollectData = async () => {
  
    let token = await getToken()
    let parameter = InitHelper.InitialParameter();
      parameter.page =1
      parameter.limit = 0
      parameter.cartonNoList = props.items

    let result = await new CollectCartonService(token).SearchCarton(parameter)
    
    console.log(result)
    if(result && result.success && result.data)
    {
      let data = result.data
      let _allCartonItems = data.items
      if(_allCartonItems.length > 0)
      {
        let items = Helper.Chunk(_allCartonItems,perpage)
        let _expectReturnDate = _allCartonItems.map(x => dayjs(x.returnDate).format("YYYYMMDD") ).sort(function(a:string,b:string){ return  parseInt(a) - parseInt(b) ;})[0]
        let _returnDate = _allCartonItems.map(x => dayjs(x.extendablePeriod).format("YYYYMMDD") ).sort(function(a:string,b:string){ return  parseInt(a) - parseInt(b) ;})[0]
        setAllCartonItems(_allCartonItems)
        setCartonItems(items)
        setExpectReturnDate(dayjs(_expectReturnDate))
        setReturnDate(dayjs(_returnDate))
        setDefaultReturnDate(dayjs(_returnDate))
      }
      setIsLoading(false)
    }
    else{
      setIsLoading(false)
    }
   

  }
  useEffect(() => {
    console.log(props.returnId)
    if(props.returnId > 0){
        getReturnData()
    }
    else{
        getCollectData()
    }
    
  }, [])

  const onCheckAll = (checked:boolean) => {
    if(checked){
      setCheckAll(true)
      let _selectItems = allCartonItems.map( x => x.cartonNo)
      setSelectItems(_selectItems)
    }
    else{
      setCheckAll(false)
      setSelectItems([])
    }
  };

  const onCheckChange = (checked:boolean,cartonNo:string) => {
    let checkAllElement = document.getElementById('check-item-all') as HTMLInputElement
    let _selectItems:string[] = Object.assign([],selectItems)
    if(checked){
        _selectItems.push(cartonNo)
        if(_selectItems.length === allCartonItems.length){
          checkAllElement.checked = true
          checkAllElement.indeterminate = false
        }
        else{
          checkAllElement.checked = false
          checkAllElement.indeterminate = true
        }
        
    }
    else{
      let index = _selectItems.indexOf(cartonNo)
      if (index > -1) {
        _selectItems.splice(index, 1);
      }

      if(_selectItems.length === 0){
        checkAllElement.checked = false
        checkAllElement.indeterminate = false
      }
      else{
        checkAllElement.checked = false
        checkAllElement.indeterminate = true
      }
    }

    console.log(_selectItems)
    setSelectItems(_selectItems)
  }

  const onPageChange = (_page:number,_perpage: number) => {
    if(_page !== page){
      setPage(_page)
    }
    if(_perpage !== perpage)
    {
      let items = Helper.Chunk(allCartonItems,_perpage)
      setCartonItems(items)
      setPerpage(_perpage)
    }
  }

  const onReturnDateChange = (value:any) => {
    setReturnDate(value)
  }

  const disableMinMaxdDate = (value:any) => {
    let date = currentDate ? currentDate.toString() : new Date().toString()
    let maxDate = defaultReturnDate.toString()
    return Helper.disableMinMaxdDate(value,date,maxDate)
  }

  const handleAdd = () =>{
    setIsAdd(true)
  }

  const AddItem = (items:string[]) => {
        console.log(items)
        setIsAdd(false)
        if(items.length > 0)
        {
            getCollectData2(items)
        }
       
  }

  const getCollectData2 = async (items:string[]) => {
  
    let token = await getToken()
    let parameter = InitHelper.InitialParameter();
      parameter.page =1
      parameter.limit = 0
      parameter.cartonNoList = items

    let result = await new CollectCartonService(token).SearchCarton(parameter)
    
    console.log(result)
    if(result && result.success && result.data)
    {
      let data = result.data
      let collectItems = data.items
     
      if(collectItems.length > 0)
      {
        let _allCartonItems = collectItems.concat(allCartonItems)
        let items = Helper.Chunk(_allCartonItems,perpage)
        setAllCartonItems(_allCartonItems)
        setCartonItems(items)
        setIsLoading(false)
      }
      
      
    }
    else{
      setIsLoading(false)
    }
   

  }
  return (

    <div>
        
         { isAdd ?
            <ReturnCartonAdd requestType="extend" onBehalf={0} onSelect={AddItem} items={allCartonItems}/>:
        
      <Card >
      { isLoading ? 
         <Spin tip="Loading" size="large">
            <div className="content" />
        </Spin>
        :
      <>
       <Form
          {...layout}
          form={dataForm}
          name="return-carton-form"
          onFinish={onFinish}
          validateMessages={validateMessages}
          labelAlign="left"
          colon={false}
          labelWrap={true}
        >

      
   
          <Row>
            <Col md={11} >
                <Form.Item name="returnDate" label="Extend Date" rules={[{ required: true }]} validateStatus={error.returnDate ? "error" : undefined}>
                    <DatePicker 
                        defaultValue={returnDate}
                        format={'DD-MMM-YYYY'}
                        onChange={onReturnDateChange}
                        disabledDate={disableMinMaxdDate}
                    />
                </Form.Item>
               
                <Form.Item name="extendReason" label="Extend Reason" >
                    <Input.TextArea  />
                </Form.Item>
            </Col>
            <Col md={2} ></Col>
            <Col md={11} >
                <Form.Item name="expectReturnDate" label="Return Due Date">
                    <Label className='label-det'>{ expectReturnDate.format('DD MMM YYYY') }</Label>
                </Form.Item>
            </Col>

          </Row>
        </Form>

        <div style={{ marginRight: 10, marginTop: 15, marginBottom: 5 }}>

{ selectItems.length > 0 ?
<Popconfirm
    title="Delete the task"
    description="Confirm to delete?"
    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
     onConfirm = { () => handleDelete() }
  >
   <Button type="primary" className='btn-red mr-btn'>
    <AiFillDelete className='icn-btn' /> Delete
  </Button>
</Popconfirm>
:
<Button type="primary" className='btn-red mr-btn' disabled>
    <AiFillDelete className='icn-btn' /> Delete
  </Button>
}
<Button key={Helper.Uniqid()} type="primary" className='btn-blue mr-btn' style={{width:'94px'}} onClick={handleAdd}>
                <AiOutlinePlusCircle className='icn-btn' />Add
          </Button>

        </div>
          <br></br>
          <div id="table-container">
       
       <table className='idoc-table'>
         <thead id="head-table-fixed">
           <tr>
             <th style={{ textAlign: 'left',width: '50px'}}>
                     <div className="form-check">
                     <input  
                            type="checkbox" 
                            id={'check-item-carton-all'} 
                            className="form-check-input check-item" 
                            checked={checkAll} 
                            onChange={e => onCheckAll(e.target.checked)}
                          />
                       <label className="form-check-label" htmlFor={'check-item-carton-all'}/>
                     </div>
                   
             </th>
             <th >Carton No.</th>
             <th >Collect Requested No.</th>
             <th >Collect By</th>
             <th >Received By</th>
             <th >Expected Return Date</th>
             <th >Carton Status</th>
             <th >Detail</th>
             <th> Delete</th>
           </tr>
         </thead>
        
         <tbody >
         { cartonItems.length > 0 && cartonItems[page -1].map((item:CartonList) => (
             <tr>
               <td style={{ textAlign: 'left',width: '50px'}}>
                     <div className="form-check">
                     <input  
                            type="checkbox" 
                            checked={checkAll || selectItems.indexOf(item.cartonNo) > -1}
                            id={'check-item-carton-' + item.cartonNo} 
                            value={item.cartonNo} 
                            className="form-check-input check-item" 
                            onChange={ e => onCheckChange(e.target.checked,item.cartonNo)}
                          />
                       <label className="form-check-label" htmlFor={'check-item-carton-' + item.recordNo}/>
                     </div>
                   </td>
                  <td style={{textAlign: 'center',width:'120px'}}>{item.cartonNo}</td>
                  <td style={{textAlign: 'center'}}>{item.collectRequestNo}</td>
                  <td style={{textAlign: 'center'}}>{item.collectByName}</td>
                  <td style={{textAlign: 'center'}}>{item.receivedByName}</td>
                  <td style={{textAlign: 'center'}}>{item.returnDate ? dayjs(item.returnDate).format("DD MMM YYYY") : "" }</td>
                  <td style={{textAlign: 'center'}}>Collect</td>
                  <td  style={{textAlign: 'center'}}>
                    <a onClick={() => showModalCarton(item.collectRecordNo)}>
                       <AiFillEye className='icon-table'></AiFillEye>
                    </a>
                 </td>
                  <td style={{textAlign: 'center'}}>
                   
                    <Popconfirm
                          title="Delete the task"
                          description="Confirm to delete?"
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          onConfirm = { () => handleDeleteByItem(item.cartonNo) }
      
                        >
                          <AiFillDelete size={20} color="red" style={{ cursor: 'pointer'}}/>
                        </Popconfirm>
                  
                  </td>
             </tr>
           ))
         }
         
         </tbody>

       </table>
     
       </div>
       <div style={{marginTop:'10px',textAlign:'right'}}>
       { allCartonItems.length > 0 ?
             <Pagination 
                key={Helper.Uniqid()}
                current={page} 
                pageSize={perpage}
                total={allCartonItems.length} 
                onChange={onPageChange} 
                showSizeChanger={true}
              />:<></>
       }
       </div>

        <div style={{ textAlign: 'center' }}>
          <Button type="default" className='mr-btn' onClick={handleCancel}  >
            <AiOutlineCloseCircle className='icn-btn' />Cancel
          </Button>
          <Button type="primary" onClick={confirmSave} disabled={cartonItems.length === 0 ? true : false}>
            <AiFillSave className='icn-btn' />Submit
          </Button>
        </div>
      </>
      }
      </Card>
}
      <Modal title="Request Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton}>
          <CollectCartonRequestDetail 
              key={Helper.Uniqid()}
              recordNo={recordNo} 
            />
        </Modal>

        <Modal title="System found extend date less than expected return date." open={isModalConfirmOpen} footer={''} width={700} onCancel={() => handleCancelConfirm()} maskClosable={false}>
       
            <div>Confirm to submit?</div>
        <div style={{ textAlign: 'center' ,marginTop:'20px'}}>
          <Button type="default" className='mr-btn' onClick={() => handleCancelConfirm()}  >
            <AiOutlineClose className='icn-btn' />Cancel
          </Button>
          <Button type="primary" className='mr-btn'  onClick={() => handleSave(true)} style={{width: '100px'}}>
            <AiOutlineCheck className='icn-btn' />OK
          </Button>
        </div>
      </Modal>
      {contextHolderMessage}
      {contextHolder}
    </div>
  );

}

export default ExtendCartonModal
