import { useEffect, useState } from 'react';
import { Button, Col, Collapse, DatePicker, Form, Input, Row, Select,Pagination,Spin } from 'antd';
import {  AiOutlinePrinter, AiOutlineSearch, AiOutlineSync } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import { Label } from 'reactstrap';
import {RequestType, RequestTypeOption, CartonStatus, ExportType} from '../../enum';
import { QueryDataModel ,Parameter ,Item, User, Option} from '../../models'
import { Helper, InitHelper } from '../../helpers';
import { HrWebService, QueryService, PropertyService, WarehouseService, FileManageService} from '../../services';
import dayjs from 'dayjs'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import secureLocalStorage  from  "react-secure-storage";
import Loading from '../../components/Loading';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
  currentPage:number
  perpage:number
  total: number
  totalPgae: number
  items:QueryDataModel[]
  isLoading:boolean
}

const ReportAllTransaction = () => {
    const { instance, accounts } = useMsal();
    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const pageSizeOptions = user.systemConfig.tablePageSize
    
  const [dataForm] = Form.useForm();
  const { Panel } = Collapse;
  const initialRequestTypeIds:number[] = [
    RequestType.NewCarton, 
    RequestType.StorageCarton, 
    RequestType.CollectCarton,
    RequestType.ReturnCarton,  
    RequestType.RemoveCartonStorage,
    RequestType.ExtendCarton, 
    RequestType.DestructionCarton
  ]
 
  const [requestTypeId,setRequestTypeId] = useState(0)
  const [requestTypeIds,setRequestTypeIds] = useState<number[]>(initialRequestTypeIds)
  const [requestStartDate, setRequestStartDate] = useState<Date|null>(null);
  const [requestEndDate, setRequestEndDate] =  useState<Date|null>(null);
  const [warehouseOptions, setWarehouseOptions] = useState<Option[]>([]) 
  const [warehouseId, setWarehouseId] = useState("")
  const [width,setWidth] = useState(0)
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    totalPgae : 0,
    perpage: 10,
    items: [],
    isLoading : true
  })
  const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter())
  const [requesters, setRequesters] = useState<Item[]>([])
  const [owners, setOwners] = useState<Item[]>([])
  const [orgunits, setOrgUnits] = useState<Item[]>([])
  const [isPrint,setIsprint] = useState(true)
  const [isLoadingPrint,setIsLoadingPrint] = useState(false)
  const onFinish = (values: any) => {
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
 

  const onRequestTypeChange = (_requestTypeId: number) => {
    setRequestTypeId(_requestTypeId);
    if(_requestTypeId === 0){
      setRequestTypeIds(initialRequestTypeIds)
    }
    else{
      setRequestTypeIds([_requestTypeId])
    }
    
  };

  const getData = async (currentPage: number,perpage: number,_parameter:Parameter | null = null,print:boolean = true) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)

    let parameter = Object.assign({},saveParameter)
    if(_parameter !== null){
        parameter = Object.assign({},_parameter);
    }
    
      parameter.page = currentPage
      parameter.limit = perpage
      parameter.onBehalf = -1
      parameter.distinctBy ="cartonNo"
      console.log(parameter)
      let token = await getToken()
      try{
        let result = await new QueryService(token).SearchTransaction(parameter)
        console.log("🚀 ~ file: ReportAllTransaction.tsx:127 ~ getData ~ parameter:", parameter)
        console.log(result)
        if(result && result.success)
        {
          let state = Object.assign({},appState)
          state.total = result.data.total
          state.currentPage = currentPage
            state.perpage = perpage

            result.data.items.forEach((item: QueryDataModel) => {
                item.requestType = (item.requestType === "Extend Carton" ? "Destruction Carton" : item.requestType);
            })

          state.items = result.data.items
          state.isLoading = false
        
          if(result.data.total>0 && print){
            setIsprint(false)
          }else{
            setIsprint(true)
          }
        
          setTimeout(() => {
            setSaveParameter(parameter)
            setAppState(state)
          }, 500);
        }
        else{
          let state = Object.assign({},appState)
          state.total = 0
        state.items = []
          state.isLoading = false
          setAppState(state)
        }
      }
      catch(error)
      {
        console.log(error)
        let state = Object.assign({},appState)
        state.total = 0
        state.items = []

          state.isLoading = false
          setAppState(state)
      }
      
      

  }

  const onRequesterSelect = (item:any) => {
    setRequesters(item)
  }
  
  const onOwnerSelect = (item:any) => {
    setOwners(item)
  }

  const onOrgUnitSelect = (item:any) => {
    setOrgUnits(item)
  }
  
  const onSearchEmployee = async(key:string) => {
   
      let token = await getToken()
      let result = await new HrWebService(token).SearchEmployee(key)
      if(result && result.success)
      {
        return result.data
      }
      else{
        console.log(result?.message)
        return []
      }
  }

  const onSearchOrgUnit = async(key:string) => {
   
    let token = await getToken()
    let result = await new PropertyService(token).GetOrgUnits(key)
    if(result && result.success)
    {
      return result.data
    }
    else{
      console.log(result?.message)
      return []
    }
}

    const getWarehouses = async () => {
        let token = await getToken()
        let result = await new WarehouseService(token).GetAllWarehouses()
        if (result && result.success) {
            let options: Option[] = [{ label: 'All', value: '' }]
            let Locationoptions: Option[] = result.data.map((item) => { return { value: item.warehouseName, label: item.warehouseName } })
            setWarehouseOptions(options.concat(Locationoptions))
        }
    }

    useEffect(() => {
        getWarehouses()
        setWidth(0)
    let parameter = Object.assign({},InitHelper.InitialParameter())
    parameter.requestTypeIds = initialRequestTypeIds
    parameter.statusIds = [
        CartonStatus.WaitApproval,
        CartonStatus.WaitAdmin,
        CartonStatus.Inprogress,
        CartonStatus.Cancel,
        CartonStatus.Reject,
        CartonStatus.Approve,
        CartonStatus.Done
    ]
    getData(1,appState.perpage,parameter,false)
  }, [])

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const onPageChange = (page:number,perpage:number) => {
    if(page !== appState.currentPage && perpage === appState.perpage){
        getData(page,appState.perpage)
    }

    if(page === appState.currentPage && perpage !== appState.perpage){
        getData(1,perpage)
    }
    
  }

  const searchData = async () => {
    let field = dataForm.getFieldsValue()
   
    let parameter = InitHelper.InitialParameter();

      parameter.requestTypeIds = JSON.parse(JSON.stringify(requestTypeIds))
        if(requestTypeIds.indexOf(RequestType.DestructionCarton) > -1)
        {
            parameter.requestTypeIds.push(RequestType.ExtendCarton);
      }
      parameter.statusIds = [
          CartonStatus.WaitApproval,
          CartonStatus.WaitAdmin,
          CartonStatus.Inprogress,
          CartonStatus.Cancel,
          CartonStatus.Reject,
          CartonStatus.Approve,
          CartonStatus.Done
      ]
      parameter.cartonLocation = warehouseId
      
        //parameter.warehouseId = warehouseId

        if(requestStartDate && requestEndDate)
        {
            parameter.requestStartDate = new Date(new Date(requestStartDate).getFullYear(),0,1)
            parameter.requestEndDate = new Date(new Date(requestEndDate).getFullYear(),11,31)
        }
       
    if(field.requestNo){
        parameter.requestNoList = field.requestNo.trim().split(',')
    }
    
      if (field.statusId && field.statusId.length>0){
        parameter.statusIds = field.statusId
      }

    if(requesters.length > 0){
      parameter.requesters = requesters.map(item => item.key)
    }
    
    if(owners.length > 0){
      parameter.owners = owners.map(item => item.key)
    }

    if(orgunits.length > 0){
      parameter.orgUnits = orgunits.map(item => item.key)
    }

    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    let cartonNoStart = ""
    let cartonNoEnd = ""
    if(elementStart){
      cartonNoStart = elementStart.value
    }
    if(elementEnd){
      cartonNoEnd = elementEnd.value
    }

    if(cartonNoStart && cartonNoEnd){
        parameter.cartonNo = cartonNoStart.trim() + '&' + cartonNoEnd.trim()
    }

    if(field.cartonNoList){
        parameter.cartonNo = field.cartonNoList.trim()
    }

    if(field.cartonNo){
        parameter.cartonNo = field.cartonNo.trim()
    }

    console.log(parameter)
    getData(1,appState.perpage,parameter,true)
    
  }
  const reset = () => {
    setIsprint(true)
    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    elementStart.value = ""
    elementEnd.value = ""

    dataForm.resetFields()
    setRequestTypeIds(initialRequestTypeIds)
    setOwners([])
    setOrgUnits([])
    setRequesters([])
    setRequestStartDate(null)
    setRequestEndDate(null)

    let parameter = Object.assign({},InitHelper.InitialParameter())
    parameter.requestTypeIds = initialRequestTypeIds
    parameter.statusIds = [
        CartonStatus.WaitApproval,
        CartonStatus.WaitAdmin,
        CartonStatus.Inprogress,
        CartonStatus.Cancel,
        CartonStatus.Reject,
        CartonStatus.Approve,
        CartonStatus.Done
    ]
    getData(1,appState.perpage,parameter,false)
  }

  const onRequestStartDateChange = (date: any) => {
    setRequestStartDate(date);
  }

  const onRequestEndDateChange = (date: any) => {
    setRequestEndDate(date)
  }

  const onFinishPrint = async () => {
    setIsLoadingPrint(true)
    let token = await getToken()
    try{
      await new FileManageService(token).DownloadReport(saveParameter,ExportType.ALL_TRANSACTION);
    }catch(error){
      console.log("🚀 ~ file: ReportAllTransaction.tsx:378 ~ onFinishPrint ~ error:", error)
    }finally{
      setIsLoadingPrint(false)
    }
  }

    const onWarehouseIdChange = (value:string) => {
        setWarehouseId(value)
    }

  return (
    <div id="carton-panel">
          <Collapse>
          {isLoadingPrint?<Loading/>:<></>}
          
            <Panel header="Search" key="1">
              <Form
                {...layout}
                form={dataForm}
                name="search-panel"
                onFinish={onFinish}
                validateMessages={validateMessages}
                labelAlign="left"
                colon={false}
                labelWrap={true}
              >
                <Row>
                  <Col md={11} >
                    <Form.Item name="requestType" label="Request Type" initialValue={0} >
                      <Select 
                        placeholder={MessageCommon.TitleSelect} 
                        onChange={onRequestTypeChange}
                        options={ RequestTypeOption }
                      />
                    </Form.Item>

                    { requestTypeId !== RequestType.NewCarton ?
                    <>
                      <Form.Item name="cartonNoList" label="Carton No (Multiple)" >
                        <Input.TextArea rows={5} placeholder='ex. 03PCS001,03PCS002,03PCS003'/>
                      </Form.Item>
                    
                      <Form.Item name="cartonNo" label="Carton No" >
                        <Input placeholder='ex. 03PCS001'/>
                      </Form.Item>
                      <Form.Item label="Carton No:(Range)" >
                      <table style={{width: '100%'}}>
                        <tr>
                          <td>
                            <input id="cartonNoStart" className='form-control form-control-sm' placeholder='ex. 03PCS001'/>
                          </td>
                          <td>
                            <Label style={{ marginLeft: 10, marginRight: 5 }}>To</Label>
                          </td>
                          <td>
                            <input id="cartonNoEnd" className='form-control form-control-sm' placeholder='ex. 03PCS001'/>
                        
                          </td>
                        </tr>
                      </table>
                      </Form.Item>
                   </>:<></>
                 }
                  { requestTypeId !== RequestType.NewCarton ?
                  <Form.Item name="warehouseId" label="Carton Location"  initialValue={""}>
                    <Select 
                        showSearch
                        onChange={onWarehouseIdChange}
                        placeholder="Please select..." 
                        options={warehouseOptions} 
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                 </Form.Item>
                :<></>
                    }
                  </Col>
                  <Col md={2} >
                  </Col>
                  <Col md={11} >
                  <Form.Item name="requestNo" label="Request No."  >
                      <Input placeholder="ex. A230001" />
                    </Form.Item>
                    <Form.Item name="requester" label="Requester"  >
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={requesters ? requesters : []}
                            onChange={item => onRequesterSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                        />
                  </Form.Item>
                  <Form.Item name="year" label="Year">
                      <table style={{width : '100%'}}>
                        <tr>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate}  picker="year"  className='date-picker-full' onChange={onRequestStartDateChange}/>
                          </td>
                          <td>
                            <Label style={{ marginLeft: 10, marginRight: 10 }}>To</Label>
                          </td>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate}  picker="year"  className='date-picker-full' onChange={onRequestEndDateChange}/>
                          </td>
                        </tr>
                      </table>
                    </Form.Item>

                    <Form.Item name="owner" label="Owner"  >
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={owners ? owners : []}
                            onChange={item => onOwnerSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                        />
                    </Form.Item>
                    <Form.Item name="orgUnit" label="Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={orgunits ? orgunits : []}
                            onChange={item => onOrgUnitSelect(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
                    <Form.Item name="statusId" label="Status">
                        <Select 
                          mode="multiple"
                          allowClear
                          placeholder={MessageCommon.TitleSelect}
                          options={[
                            { value: CartonStatus.WaitApproval, label: 'Wait for Approval' },
                            { value: CartonStatus.WaitAdmin, label: "Wait for Admin Service" },
                            { value: CartonStatus.Inprogress, label: 'On Going' },
                            { value: CartonStatus.Done, label: 'Completed Service' },
                            { value: CartonStatus.Reject, label: 'Rejected' },
                            { value: CartonStatus.Cancel, label: 'Cancel' },
                          ]}
                        />
                      </Form.Item>
                  </Col>
                </Row>
          
              </Form>
              <div style={{ textAlign: 'center' }}>
                <Button type="default" className='mr-btn' onClick={() => reset()}  disabled={appState.isLoading}>
                  <AiOutlineSync className='icn-btn' /> Reset
                </Button>
                <Button type="primary" id="search-btn"   onClick={() => searchData()} disabled={appState.isLoading}>
                  <AiOutlineSearch className='icn-btn'/> Search
                </Button>


              </div>

            </Panel>
          </Collapse>
          <br></br>
       
          <Collapse defaultActiveKey={['1']}>
            <Panel header="Data Result" key="1" >
              <div id="panel-action" style={{paddingTop: '8px'}}>
                <div>
                <Button type="primary" htmlType="button" className='mr-btn' disabled={isPrint} onClick={()=>onFinishPrint()}>
                    <AiOutlinePrinter className='icn-btn' />Print
                </Button>
                </div>
                <div>
                  <div className='search-result' style={{ float: 'left'}}>Search found { appState.total } record(s).</div>
                  <div style={{ float: 'right'}}>
                
                </div>
                </div>
              </div>

        <div id="table-container" style={{marginTop:'60px' ,overflowX:'scroll'}}>
        { appState.isLoading ?
            <div style={{ margin: 'auto',paddingBottom: '30px'}}>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
          :
          	
          <table className='idoc-table'>
            <thead id="head-table-fixed">
              <tr>
                <th style={{width:width}}>No.</th>
                <th style={{width:width}}>Request Type</th>
                <th style={{width:width}}>Request No.</th>
                <th style={{width:width}}>Request Date</th>
                <th style={{width:width}}>Complete Date</th>
                <th style={{width:width}}>Carton No.</th>
                <th style={{width:width}}>Carton Location</th>
                <th style={{width:width}}>Requester</th>
                <th style={{width:width}}>Owner</th>
                <th style={{width:width}}>Org Unit.</th>
                <th style={{width:width}}>Expected Return Date</th>
                <th style={{width:width}}>Return Date</th>
                <th style={{width:width}}>Carton Quantity</th>
                <th style={{width:width}}>Status</th>
                <th style={{width:width}}>Remark</th>
              </tr>
            </thead>
           
            <tbody >
            { appState.items.map((item:QueryDataModel,index) => (
                <tr>
                  <td style={{width:width,textAlign: 'center'}}>{((appState.currentPage -1) * appState.perpage) + index + 1} </td>
                  
                  <td style={{width:width,textAlign: 'center'}}>{item.requestType}</td>
                  <td style={{width:width,textAlign: 'center'}}>{item.requestNo}</td>
                  <td style={{width:width,textAlign: 'center'}}>{ dayjs(item.requestDate).format('DD MMM YYYY') }</td>
                    <td style={{ width: width, textAlign: 'center' }}>{item.closeJobDate ? dayjs(item.closeJobDate).format('DD MMM YYYY') : "-"}</td>
                    <td style={{ width: width, textAlign: 'center', color: (item.storageCartonStatusId === CartonStatus.Remove || item.storageCartonStatusId === CartonStatus.Destruction) ? 'red' : '' }} >{item.cartonNo}</td>
                  <td style={{width:width,textAlign: 'center'}}>{item.cartonLocation}</td>
                  <td style={{width:width,textAlign: 'center'}}>{item.requesterName}</td>
                  <td style={{width:width,textAlign: 'center'}}>{item.ownerName}</td>
                  <td style={{width:width,textAlign: 'center'}}>{item.orgUnit}</td>
                  <td style={{width:width,textAlign: 'center'}}>{
                    item.requestTypeId === RequestType.CollectCarton ? 
                    <>
                    { item.returnDate ? dayjs(item.returnDate).format('DD MMM YYYY')  : ""}
                    </>
                    :<></>
                  }</td>
                  <td style={{width:width,textAlign: 'center'}}>{
                    item.requestTypeId === RequestType.ReturnCarton ? 
                    <>
                    { item.returnDate ? dayjs(item.returnDate).format('DD MMM YYYY')  : ""}
                    </>
                    :<></>
                  }
                  </td>
                  <td style={{width:width,textAlign: 'center'}}>{item.quantity}</td>
                  <td style={{width:width,textAlign: 'center'}}>{item.status}</td>
                    <td style={{ width: width, textAlign: 'center' }}>{item.remark}</td>
                </tr>
              ))
            }
            
            </tbody>
  
          </table>
        }
        </div>
        <div style={{marginTop:'10px',textAlign:'right'}}>
        { appState.total > 0 ?
           <Pagination 
           key={Helper.Uniqid()}
           current={appState.currentPage} 
           pageSize={appState.perpage}
           total={appState.total} 
           showSizeChanger={true} 
           pageSizeOptions={pageSizeOptions}
           onChange={onPageChange} 
          />:<></>
          }
        </div>

            </Panel>
          </Collapse>


      
    </div >
  );

}

export default ReportAllTransaction
