import { useEffect, useState } from 'react';
import { Button, Col, Collapse, DatePicker, Form, Input, Row, Select,Pagination,Spin } from 'antd';
import {  AiOutlinePrinter, AiOutlineSearch, AiOutlineSync } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import { Label } from 'reactstrap';
import {RequestType, CartonStatus, ExportType } from '../../enum';
import { QueryDataModel ,Parameter ,Item ,Option, User} from '../../models'
import { Helper, InitHelper } from '../../helpers';
import { HrWebService,QueryService,PropertyService,WarehouseService, FileManageService} from '../../services';
import dayjs from 'dayjs'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import secureLocalStorage  from  "react-secure-storage";
import Loading from '../../components/Loading';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
  currentPage:number
  perpage:number
  total: number
  totalPgae: number
  items:QueryDataModel[]
  isLoading:boolean
}

const ReportDestructionCartonList = () => {
    const { instance, accounts } = useMsal();
    const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const pageSizeOptions = user.systemConfig.tablePageSize
  const [dataForm] = Form.useForm();
  const { Panel } = Collapse;
  const [destructTypeId,setDestructTypeId] = useState(0)
  const [documentStartYear, setDocumentStartYear] = useState<number|null|undefined>(undefined);
  const [documentEndYear, setDocumentEndYear] = useState<number|null|undefined>(undefined);
  const [warehouseOptions, setWarehouseOptions] = useState<Option[]>([])
  const [width,setWidth] = useState(0)
  const [saveParamter,setSaveParameter] = useState(InitHelper.InitialParameter())
  const [warehouse, setWarehouse] = useState("")
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    totalPgae : 0,
    perpage: 10,
    items: [],
    isLoading : true
  })
  const [requesters, setRequesters] = useState<Item[]>([])
  const [owners, setOwners] = useState<Item[]>([])
  const [orgunits, setOrgUnits] = useState<Item[]>([])
  const [isPrint,setIsprint] = useState(true)
  const [isLoadingPrint,setIsLoadingPrint] = useState(false)
  const onFinish = (values: any) => {
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
 


  const onDestructTypeChange = (_destructTypeId: number) => {
    setDestructTypeId(_destructTypeId);
   
  };

  const getData = async (currentPage: number,perpage: number,_parameter:Parameter | null = null) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)
    let parameter = Object.assign({},saveParamter)
    if(_parameter !== null){
        parameter = Object.assign({},_parameter);
    }
      parameter.requestTypeIds = [RequestType.DestructionCarton, RequestType.ExtendCarton]
      if (parameter.statusIds.length === 0) {

          parameter.statusIds = [
              CartonStatus.WaitApproval
              , CartonStatus.WaitAdmin
              , CartonStatus.Inprogress
              , CartonStatus.Done
              , CartonStatus.Reject
              , CartonStatus.Cancel
          ]
      }


      parameter.page = currentPage
      parameter.limit = perpage
      parameter.totalCarton = 1
    
      console.log(parameter)
      let token = await getToken()
      try{
        let result = await new QueryService(token).SearchTransaction(parameter)

        console.log(result)
        if(result && result.success)
        {
          let state = Object.assign({},appState)
          state.total = result.data.total
          state.currentPage = currentPage
          state.perpage = perpage
          state.items = result.data.items
          state.isLoading = false

        if(result.data.total>0){
          setIsprint(false)
        }else{
          setIsprint(true)
        }
          setTimeout(() => {
            setSaveParameter(parameter)
            setAppState(state)
            console.log("🚀 ~ file: ReportDestructionCartonList.tsx:142 ~ setTimeout ~ state:", state)
          }, 500);
        }
        else{
          let state = Object.assign({},appState)
          state.isLoading = false
          setAppState(state)
        }
      }
      catch(error)
      {
        console.log(error)
        let state = Object.assign({},appState)
          state.isLoading = false
          setAppState(state)
      }
      
      

  }

  const onRequesterSelect = (item:any) => {
    setRequesters(item)
  }
  
  const onOwnerSelect = (item:any) => {
    setOwners(item)
  }

  const onOrgUnitSelect = (item:any) => {
    setOrgUnits(item)
  }
  
  const onSearchEmployee = async(key:string) => {
   
      let token = await getToken()
      let result = await new HrWebService(token).SearchEmployee(key)
      if(result && result.success)
      {
        return result.data
      }
      else{
        console.log(result?.message)
        return []
      }
  }

  const onSearchOrgUnit = async(key:string) => {
   
    let token = await getToken()
    let result = await new PropertyService(token).GetOrgUnits(key)
    if(result && result.success)
    {
      return result.data
    }
    else{
      console.log(result?.message)
      return []
    }
}

    const getWarehouses = async () => {
        let token = await getToken()
    let result = await new WarehouseService(token).GetActiveWarehouses()
    if(result && result.success){
        let options:Option[] = [{ label:'All' , value: "" }]
        let Locationoptions:Option[] = result.data.map((item) => { return { value: item.warehouseName, label: item.warehouseName  }})
        setWarehouseOptions(options.concat(Locationoptions))
    }
  }

  useEffect(() => {
  
    getWarehouses()
    let parameter = Object.assign({},InitHelper.InitialParameter())
    parameter.cartonLocation = ""
   
    getData(1,appState.perpage,parameter)
    let element = document.getElementById('table-container') as HTMLDivElement
    setWidth(element.clientWidth  / 11)
   
   
    
  }, [])

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const onPageChange = (page:number,perpage:number) => {
    if(page !== appState.currentPage && perpage === appState.perpage){
        getData(page,appState.perpage)
    }

    if(page === appState.currentPage && perpage !== appState.perpage){
        getData(1,perpage)
    }
  }

    const fieldParameterMap = (field: any, parameter: any) => {
        if (field.statusId && field.statusId.length > 0) {
            parameter.statusIds = field.statusId
        }

        if (field.requestNo) {
            parameter.requestNoList = field.requestNo.trim().split(',')
        }

        if (field.cartonNoList) {
            parameter.cartonNo = field.cartonNoList.trim()
        }

        if (field.cartonNo) {
            parameter.cartonNo = field.cartonNo.trim()
        }

    }
  const searchData = async () => {
    let field = dataForm.getFieldsValue()
   console.log(field)
      let parameter = InitHelper.InitialParameter();
      fieldParameterMap(field, parameter)
        parameter.page =1
        parameter.limit = appState.perpage
        
       parameter.cartonLocation = warehouse
        
        parameter.destructionTypeId = destructTypeId
       
    if(requesters.length > 0){
      parameter.requesters = requesters.map(item => item.key)
    }
    
    if(owners.length > 0){
      parameter.owners = owners.map(item => item.key)
    }

    if(orgunits.length > 0){
      parameter.orgUnits = orgunits.map(item => item.key)
    }
    
    if(documentStartYear && documentStartYear > 0 )
    {
        parameter.requestStartDate = documentStartYear + "-01-01"
        //parameter.documentStartYear = documentStartYear
    }

    if(documentEndYear && documentEndYear > 0)
    {
        parameter.requestEndDate = documentEndYear + "-12-31"
        //parameter.documentEndYear = documentEndYear
      }


    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    let cartonNoStart = ""
    let cartonNoEnd = ""
    if(elementStart){
      cartonNoStart = elementStart.value
    }
    if(elementEnd){
      cartonNoEnd = elementEnd.value
    }

    if(cartonNoStart && cartonNoEnd){
        parameter.cartonNo = cartonNoStart.trim() + '&' + cartonNoEnd.trim()
    }

    console.log(parameter)
    getData(1,appState.perpage,parameter)
    
  }
  const reset = () => {
    

    dataForm.resetFields()
    setOwners([])
    setOrgUnits([])
    setRequesters([])
      setWarehouse("")
      let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
      let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
      elementStart.value = "";
      elementEnd.value = "";
    let parameter = Object.assign({},InitHelper.InitialParameter())
    parameter.cartonLocation = ""
    
    getData(1,appState.perpage,parameter)
  }

  const updatePosition = () => {
    let searchPanel = document.getElementById('search-panel') as HTMLDivElement
    let formElement = document.getElementById('carton-panel') as HTMLDivElement
    let actionElement = document.getElementById('panel-action') as HTMLDivElement
    let tableElement = document.getElementById('head-table-fixed') as HTMLTableElement
    let startPos = 170
    let maxPos = startPos
    if(searchPanel){
       maxPos = searchPanel.clientHeight + startPos + 100
    }
   
    if(actionElement){
      let pos = window.scrollY;
      if(pos > maxPos){
        if(actionElement.classList.contains('panel-action-fixed') === false){
          console.log(actionElement.classList.contains('panel-action-fixed'))
          actionElement.classList.add('panel-action-fixed')
          actionElement.classList.add('panel-main')
          actionElement.style.width = ( formElement.clientWidth -32 )+ 'px'
          tableElement.classList.add('table-thead-fixed')
          tableElement.style.top = '155px'
          tableElement.style.width = (formElement.clientWidth -32) + 'px'
        }
      }
      else{
        if(actionElement.classList.contains('panel-action-fixed')){
          actionElement.style.width = 'auto'
          actionElement.classList.remove('panel-action-fixed')
          actionElement.classList.remove('panel-main')
          tableElement.classList.remove('table-thead-fixed')
        }
      }
    }
  }

  const updateResize = () => {
      window.scroll({ top: 0, behavior: "smooth"});
      let element = document.getElementById('table-container') as HTMLDivElement
      setWidth(element.clientWidth  / 11)
  }

  const onDocumentStartYearChange = (value: any) => {
    let year = parseInt(dayjs(value).format('YYYY'))
    setDocumentStartYear(year)
  }
  
  const onDocumentEndYearChange = (value: any) => {
    let year = parseInt(dayjs(value).format('YYYY'))
    setDocumentEndYear(year)
  }
  const onFinishPrint = async () => {
    setIsLoadingPrint(true)
    let token = await getToken()

    try{

      await new FileManageService(token).DownloadReport(saveParamter,ExportType.DESTRUCTION_CARTON_LIST);
    }catch(error){
     console.log("🚀 ~ file: ReportDestructionCartonList.tsx:406 ~ onFinishPrint ~ error:", error)

    }finally{
      setIsLoadingPrint(false)
    }
  }
  
  const onWarehouseChange = (value:string) => {
    setWarehouse(value)
}
  return (

    <div>
      <Collapse>
      {isLoadingPrint?<Loading/>:<></>}

        <Panel header="Search" key="1">
          <Form
            {...layout}
            form={dataForm}
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={validateMessages}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
              <Col md={11} >
              <Form.Item name="destructionType" label="Destruction Type"  >
                        <Select placeholder={MessageCommon.TitleSelect} 
                            defaultValue={0}
                          onChange={onDestructTypeChange}
                          options={[
                            { value :0 ,label:'All'},
                            { value: RequestType.ExtendCarton, label: 'Extend' }, 
                            { value: RequestType.DestructionCarton, label: 'Destroy' }
                          ]}
                        />
                      </Form.Item>
                  
                    
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
              <Form.Item name="statusId" label="Status"   >
                  <Select placeholder={MessageCommon.TitleSelect} 
                   mode="multiple"
                   allowClear
                    options={[
                      { value: CartonStatus.WaitApproval, label: 'Wait for Approval' },
                      { value: CartonStatus.WaitAdmin, label: "Wait for Admin Service" },
                      { value: CartonStatus.Inprogress, label: 'On Going' },
                      { value: CartonStatus.Done, label: 'Completed Service' },
                      { value: CartonStatus.Reject, label: 'Rejected' },
                      { value:CartonStatus.Cancel, label: 'Cancel' },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
              <Form.Item name="requestNo" label="Request No."  >
                  <Input placeholder="ex. A230001,B230002,B230004"></Input>
                </Form.Item>
             

              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                <Form.Item name="documentyear" label="Year">
                  <table style={{ width: '100%'}}>
                    <tr>
                      <td>
                        <DatePicker 
                          placeholder={MessageCommon.TitleDate} 
                          picker='year' style={{width: '100%'}} 
                          onChange={onDocumentStartYearChange}
                        />
                      </td>
                      <td>
                        <Label style={{ marginLeft: 10, marginRight: 10 ,verticalAlign:'top'}}>To</Label>
                      </td>
                      <td>
                        <DatePicker 
                          placeholder={MessageCommon.TitleDate} 
                          picker='year' 
                          style={{width: '100%'}}
                          onChange={onDocumentEndYearChange}
                        />
                      </td>
                    </tr>
                  </table>


                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
              <Form.Item name="cartonLocation" label="Carton Location"  initialValue={""}>
                    <Select 
                        showSearch
                        onChange={onWarehouseChange}
                        placeholder="Please select..." 
                        options={warehouseOptions} 
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                 </Form.Item>

              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
              <Form.Item name="orgUnit" label="Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={orgunits ? orgunits : []}
                            onChange={item => onOrgUnitSelect(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
              </Col>
            </Row>


            <Row>
              <Col md={11} >
              <Form.Item label="Carton No:(Range)" >
                      <table style={{width: '100%'}}>
                        <tr>
                          <td>
                            <input id="cartonNoStart" className='form-control form-control-sm' placeholder='ex. 03PCS001'/>
                          </td>
                          <td>
                            <Label style={{ marginLeft: 10, marginRight: 5 }}>To</Label>
                          </td>
                          <td>
                            <input id="cartonNoEnd" className='form-control form-control-sm' placeholder='ex. 03PCS001'/>
                        
                          </td>
                        </tr>
                      </table>
                      </Form.Item>

              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
               <Form.Item name="requester" label="Requester"  >
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={requesters ? requesters : []}
                            onChange={item => onRequesterSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                        />
                  </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
              <Form.Item name="cartonNoList" label="Carton No (Multiple)" >
                        <Input.TextArea rows={5} placeholder='ex. 03PCS001,03PCS002,03PCS003'/>
                      </Form.Item>
             
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
              <Form.Item name="owner" label="Owner"  >
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={owners ? owners : []}
                            onChange={item => onOwnerSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                        />
                    </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                <Form.Item name="cartonNo" label="Carton No" >
                        <Input placeholder='ex. 03PCS001'/>
                      </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
              </Col>
            </Row>
           
          </Form>
          <div style={{ textAlign: 'center' }}>
            <Button type="default" className='mr-btn' onClick={reset} >
              <AiOutlineSync className='icn-btn' /> Reset
            </Button>
            <Button type="primary" onClick={searchData}  >
              <AiOutlineSearch className='icn-btn' /> Search
            </Button>


          </div>

        </Panel>
      </Collapse>


      <br></br>
      <Collapse defaultActiveKey={['1']}>
              <Panel header="Data Result" key="1" >
                  <div id="panel-action" style={{ paddingTop: '8px' }}>
                      <div>
                      <Button type="primary" htmlType="button" className='mr-btn' disabled={isPrint} onClick={()=>onFinishPrint()}>
                        <AiOutlinePrinter className='icn-btn' />Print
                          </Button>
                      </div>
                      
                  </div>
                  <div className='search-result' style={{ float: 'left' }}>Search found {appState.total} record(s).</div>
          <br></br>
          <br></br>
          <div id="table-container">
        { appState.isLoading ?
            <div style={{ margin: 'auto',paddingBottom: '30px'}}>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
          :
        					
          <table className='idoc-table'>
            <thead id="head-table-fixed">
              <tr>
                <th style={{width:width}}>No.</th>
                <th style={{width:width}}>Carton No.</th>
                <th style={{width:width}}>Carton Location</th>
                <th style={{width:width}}>Request No.</th>
                <th style={{width:width}}>Request Date</th>
                <th style={{width:width}}>Document Year</th>
                <th style={{width:width}}>Requester</th>
                <th style={{width:width}}>Owner</th>
                <th style={{width:width}}>Org. Unit</th>
                <th style={{width:width}}>Storage Request No.</th>
                <th style={{width:width}}>Storage Request Date</th>
                <th style={{width:width}}>Destruction Due Date</th>
                <th style={{width:width}}>Destruction Date (Destroy)</th>
                <th style={{width:width}}>Destruction Date (Extention)</th>
              </tr>
            </thead>
           
            <tbody >
            { appState.items.map((item:QueryDataModel,index) => (
                <tr>
                  <td style={{width:width,textAlign: 'center'}}>{((appState.currentPage -1) * appState.perpage) + index + 1} </td>
                  
                    <td style={{ width: width, textAlign: 'center', color: ((item.requestTypeId === RequestType.RemoveCarton || item.requestTypeId === RequestType.RemoveCartonStorage || item.requestTypeId === RequestType.DestructionCarton) && item.statusId === CartonStatus.Done) ? 'red' : '' }}>{item.cartonNo}</td>
                  <td style={{width:width,textAlign: 'center'}}>{item.cartonLocation}</td>
                  <td style={{width:width,textAlign: 'center'}}>{item.requestNo}</td>
                  <td style={{width:width,textAlign: 'center'}}>{ dayjs(item.requestDate).format('DD MMM YYYY') }</td>

                    <td style={{ width: width, textAlign: 'center' }}>{dayjs(item.documentYear).format('MMM YYYY')}</td>
                  <td style={{width:width,textAlign: 'center'}}>{item.requesterName}</td>
                  <td style={{width:width,textAlign: 'center'}}>{item.ownerName}</td>
                  <td style={{width:width,textAlign: 'center'}}>{item.orgUnit}</td>
                  <td style={{width:width,textAlign: 'center'}}>
                    { item.storageRequestNo}
                  </td>
                  <td style={{width:width,textAlign: 'center'}}>
                    { item.storageRequestDate ? dayjs(item.storageRequestDate).format('DD MMM YYYY') : ""}
                  </td>
                 
                  <td style={{width:width,textAlign: 'center'}}>
                  { item.expireDate ? dayjs(item.expireDate).format('DD MMM YYYY') : ""}
                  </td>

                  <td style={{width:width,textAlign: 'center'}}>
                  { item.requestTypeId === RequestType.DestructionCarton && item.statusId == CartonStatus.Done ? dayjs(item.expireDate).format('DD MMM YYYY') : ""}
                  </td>
                  <td style={{width:width,textAlign: 'center'}}>
                  { item.requestTypeId === RequestType.ExtendCarton && item.latestExtensionDate ? dayjs(item.latestExtensionDate).format('DD MMM YYYY') : ""}
                  </td>
                </tr>
              ))
            }
            
            </tbody>
  
          </table>
        }
        </div>
        <div style={{marginTop:'10px',textAlign:'right'}}>
        { appState.total > 0 ?
            <Pagination 
                key={Helper.Uniqid()}
                current={appState.currentPage} 
                pageSize={appState.perpage}
                total={appState.total} 
                showSizeChanger={true} 
                pageSizeOptions={pageSizeOptions}
                onChange={onPageChange} 
              />:<></>
        }
        </div>
        </Panel>
      </Collapse>
     


    </div>
  );

}

export default ReportDestructionCartonList
