import React, { useEffect, useState } from 'react';
import { Button, Col, Collapse, DatePicker, Form, Input, Row, Select,Modal, Pagination,Spin } from 'antd';
import { AiOutlinePrinter, AiOutlineSearch, AiOutlineSync } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import { Label } from 'reactstrap';
import secureLocalStorage  from  "react-secure-storage";
import {RequestType, CartonStatus, ExportType } from '../../enum';
import { QueryDataModel ,Parameter ,Item, User} from '../../models'
import { Helper, InitHelper } from '../../helpers';
import { QueryService ,HrWebService, FileManageService} from '../../services';
import dayjs from 'dayjs'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import NewCartonRequestDetail from '../NewCarton/NewCartonRequestDetail'
import StorageCartonRequestDetail from '../StorageCarton/StorageCartonRequestDetail';
import CollectCartonRequestDetail from '../CollectCarton/CollectCartonRequestDetail';
import ReturnCartonRequestDetail from '../ReturnCarton/ReturnCartonRequestDetail';
import RemoveCartonRequestDetail from '../RemoveCarton/RemoveCartonRequestDetail'
import DestructionCartonRequestDetail from '../DestructionCarton/DestructionCartonRequestDetail';
import Loading from '../../components/Loading';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
  currentPage:number
  perpage:number
  total: number
  totalPgae: number
  items:QueryDataModel[]
  isLoading:boolean
}

const MytaskTracking = () => {
    const { instance, accounts } = useMsal();
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const pageSizeOptions = user.systemConfig.tablePageSize
  const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter)
  const [dataForm] = Form.useForm();
  const { Panel } = Collapse;
  const initialRequestTypeIds:number[] = [
    RequestType.NewCarton, 
    RequestType.StorageCarton, 
    RequestType.CollectCarton,
    RequestType.ReturnCarton,  
    RequestType.RemoveCarton,
    RequestType.RemoveCartonStorage,
    RequestType.ExtendCarton,
    RequestType.DestructionCarton,
    RequestType.ChangeOwner
  ]
  const requestTypeOptions = [
    { value: 0, label: 'All' },
    { value: RequestType.NewCarton, label: 'New Carton' },
    { value: RequestType.StorageCarton, label: 'Storage Carton' },
    { value: RequestType.CollectCarton, label: 'Collect Carton' },
    { value: RequestType.ReturnCarton, label: 'Return Carton' },
    { value: RequestType.RemoveCarton, label: 'Remove Carton' },
    { value: RequestType.DestructionCarton, label: 'Destruction Carton' },
    { value: RequestType.ChangeOwner, label: 'Request to Change Owner' },
  ]

  const statusOptions = [
    { value: CartonStatus.WaitApproval, label: 'Wait Approval' },
    { value: CartonStatus.WaitAdmin, label: 'Wait for Admin Service' },
    { value: CartonStatus.Inprogress, label: 'On Going' },
    { value: CartonStatus.Done, label: 'Complete Service' },
    { value: CartonStatus.Reject, label: 'Rejected' },
    { value: CartonStatus.Cancel, label: 'Cancel' },
    { value: CartonStatus.Delete, label: 'Delete' }
  ]
  const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
  const [recordNo, setRecordNo] = useState(0);
  const [requestTypeId,setRequestTypeId] = useState(0)
  const [requestTypeIds,setRequestTypeIds] = useState<number[]>(initialRequestTypeIds)
  const [requestTypeMenu,setRequestTypeMenu] = useState<number[]>(initialRequestTypeIds)
    const [modal, contextHolder] = Modal.useModal();
  const [isLoadingPrint,setIsLoadingPrint] = useState(false)
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    totalPgae : 0,
    perpage: 10,
    items: [],
    isLoading : true
  })
  const [owners, setOwners] = useState<Item[]>([])
  const [requestStartDate, setRequestStartDate] = useState<Date|null>(null);
  const [requestEndDate, setRequestEndDate] =  useState<Date|null>(null);
  const [searchParameter,setSearchParameter] = useState<Parameter>(InitHelper.InitialParameter)
    const [isPrint, setIsprint] = useState(true)

  useEffect(() => {
    let parameter = Object.assign({},InitHelper.InitialParameter())
    parameter.requestTypeIds = requestTypeIds
    parameter.statusIds = [
        CartonStatus.WaitAdmin,
        CartonStatus.Inprogress,
        CartonStatus.WaitApproval,
        CartonStatus.Done,
        CartonStatus.Reject,
        CartonStatus.Cancel,
        CartonStatus.Delete
      ]
    parameter.condition = ""
    getData(1,appState.perpage,parameter)

  }, [])


  const onFinish = (values: any) => {
  };


  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
 
  const showModalCarton = async (_recordNo:number,_storageRecordNo:number, _requestTypeId: number) => {
    if(requestTypeId === RequestType.ChangeOwner)
    {
        setRecordNo(_storageRecordNo)
    }
    else{
        setRecordNo(_recordNo)
    }
    
    setRequestTypeId(_requestTypeId)
    setIsModalCartonOpen(true);
  };
  const handleCancelCarton = () => {
    setIsModalCartonOpen(false);

  };

  const getData = async (currentPage: number,perpage: number,_parameter:Parameter | null = null) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)
    let parameter = Object.assign({},saveParameter)
    if(_parameter !== null){
        parameter = Object.assign({},_parameter);
    }
    
    parameter.requester = user.employeeId
    parameter.requesters = [user.employeeId]
    parameter.requesterOrgUnit = user.orgUnit
    parameter.employeeLevel = user.level
    
    setSearchParameter(parameter)
     
      let token = await getToken()
      let result = await new QueryService(token).SearchMyTask(parameter)
      console.log(result)
      if (!result)
          return null;
      let {limit,total,items} = result.data
      
      let state = Object.assign({},appState)
          state.total = total
          state.perpage = perpage
          state.currentPage = currentPage
          state.totalPgae = Math.ceil(total / limit)
          state.items = items
          state.isLoading = false
      if(total>0){
        setIsprint(false)
      }else{
        setIsprint(true)
      }

        setTimeout(() => {
            setSaveParameter(parameter)
            setAppState(state)
        }, 500);
  }

  const searchData = async () => {
    let field = dataForm.getFieldsValue()
    console.log(field)
    let parameter = InitHelper.InitialParameter();
    
        parameter.requestTypeIds = requestTypeIds
        parameter.statusIds = [
            CartonStatus.WaitAdmin,
            CartonStatus.Inprogress,
            CartonStatus.WaitApproval,
            CartonStatus.Done,
            CartonStatus.Reject,
            CartonStatus.Cancel,
            CartonStatus.Delete
          ]
    
    if(field.statusIds && field.statusIds.length > 0)
    {
        parameter.statusIds = field.statusIds
    }
    
    if(field.requestNo){
        parameter.requestNoList = field.requestNo.trim().split(',')
    }
    if(requestStartDate && requestEndDate){
        parameter.requestStartDate = requestStartDate
        parameter.requestEndDate = requestEndDate
    }

    if(owners.length > 0){
      parameter.owners = owners.map(item => item.key)
    }

     

    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    let cartonNoStart = ""
    let cartonNoEnd = ""
    if(elementStart){
      cartonNoStart = elementStart.value
    }
    if(elementEnd){
      cartonNoEnd = elementEnd.value
    }

    if(cartonNoStart && cartonNoEnd){
        parameter.cartonNo = cartonNoStart.trim() + '&' + cartonNoEnd.trim()
    }

    if(field.cartonNoList){
        parameter.cartonNo = field.cartonNoList.trim()
    }

    if(field.cartonNo){
        parameter.cartonNo = field.cartonNo.trim()
    }

    if(field.description)
    {
        parameter.description = field.description.trim()
    }

    parameter.condition = "yes"
      
    console.log(parameter)
    setRequestTypeMenu(requestTypeIds)
    getData(1,appState.perpage,parameter)
  
    
 }

 const onSearchEmployee = async(key:string) => {
   
  let token = await getToken()
  let result = await new HrWebService(token).SearchEmployee(key)
  if(result && result.success)
  {
        let _orgUnit = user.orgUnit
            if(user.orgUnit.indexOf("/") > -1) {
                _orgUnit = user.orgUnit.split("/")[0]
            }
            return result.data.filter(x => x.orgUnit.indexOf(_orgUnit) > -1)
  }
  else{
    console.log(result?.message)
    return []
  }
}

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const onRequestStartDateChange = (date: any) => {
    setRequestStartDate(date);
  }

  const onRequestEndDateChange = (date: any) => {
    setRequestEndDate(date)
  }

  const onPageChange = (page:number,perpage:number) => {
    if(page !== appState.currentPage && perpage === appState.perpage){
        getData(page,appState.perpage)
    }

    if(page === appState.currentPage && perpage !== appState.perpage){
        getData(1,perpage)
    }
  }

 const onRequestTypeChange = (_requestTypeId: number) => {
  if(_requestTypeId === 0){
    setRequestTypeIds(initialRequestTypeIds)
  }
  else{
    
    if(_requestTypeId  === RequestType.RemoveCarton)
    {
        setRequestTypeIds([RequestType.RemoveCarton,RequestType.RemoveCartonStorage])
    }
    else{
        setRequestTypeIds([_requestTypeId])
    }
    
  }
  console.log(_requestTypeId)
};

const onOwnerSelect = (item:any) => {
  setOwners(item)
}

const reset = () => {
  let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
  let elementEnd = document.getElementById('cartonNoStart') as HTMLInputElement
  elementStart.value = ""
  elementEnd.value = ""
   dataForm.resetFields()
   setOwners([])
   setRequestStartDate(null)
   setRequestEndDate(null)

   let parameter = Object.assign({},InitHelper.InitialParameter())
   parameter.requesters = [user.employeeId]
   parameter.requestTypeIds = requestTypeIds
    parameter.statusIds = [
        CartonStatus.WaitAdmin,
        CartonStatus.Inprogress,
        CartonStatus.WaitApproval,
        CartonStatus.Done,
        CartonStatus.Reject,
        CartonStatus.Cancel
      ]
    parameter.condition = ""
    
   getData(1,appState.perpage,parameter)
}
  const onFinishPrint = async () => {
    setIsLoadingPrint(true)

    let token = await getToken()
    try{

      await new FileManageService(token).DownloadReport(searchParameter,ExportType.STATUS_TRACKING);
    }catch(error){
      console.log("🚀 ~ file: MytaskTracking.tsx:327 ~ onFinishPrint ~ error:", error)
      
    }finally{
      setIsLoadingPrint(false)

    }
  }

  return (
    <div id="carton-panel">

      <Collapse>
        {isLoadingPrint?<Loading/>:<></>}
        <Panel header="Search" key="1">
          <Form
            {...layout}
            form={dataForm}
            name="search-panel"
            onFinish={onFinish}
            validateMessages={validateMessages}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
              <Col md={11} >
                  <Form.Item name="requestType" label="Request Type" initialValue={0} >
                      <Select placeholder={MessageCommon.TitleSelect}  onChange={onRequestTypeChange}
                        options={ requestTypeOptions }
                      />
                    </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
               
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                  <Form.Item name="requestNo" label="Request No."  >
                    <Input placeholder='A230001,B230005' />
                    </Form.Item>
          
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                <Form.Item name="requestDate" label="Request Date">
                      <table style={{width : '100%'}}>
                        <tr>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate}  format={'DD MMM YYYY'} className='date-picker-full' onChange={onRequestStartDateChange}/>
                          </td>
                          <td>
                            <Label style={{ marginLeft: 10, marginRight: 10 }}>To</Label>
                          </td>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate}  format={'DD MMM YYYY'} className='date-picker-full' onChange={onRequestEndDateChange}/>
                          </td>
                        </tr>
                      </table>
                    </Form.Item>
               
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                  <Form.Item name="cartonRange" label="Carton No:(Range)" >
                    <table style={{width: '100%'}}>
                        <tr>
                          <td>
                            <input id="cartonNoStart" className='form-control form-control-sm' placeholder='ex. 23PCS001'/>
                          </td>
                          <td>
                            <Label style={{ marginLeft: 10, marginRight: 5 }}>To</Label>
                          </td>
                          <td>
                            <input id="cartonNoEnd" className='form-control form-control-sm' placeholder='ex. 23PCS001'/>
                        
                          </td>
                        </tr>
                      </table>
                      </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                  <Form.Item name="owner" label="Owner"  >
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={owners ? owners : []}
                            onChange={item => onOwnerSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                        />
                    </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                  <Form.Item name="cartonNoList" label="Carton No (Multiple)" >
                        <Input.TextArea rows={5} placeholder='ex. 23PCS001,23PCS002,23PCS003'/>
                  </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                  <Form.Item name="description" label="Description" >
                        <Input.TextArea rows={5} />
                      </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                  <Form.Item name="cartonNo" label="Carton No" >
                        <Input placeholder='ex. 23PCS001'/>
                  </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                    <Form.Item name="statusIds" label="Status">
                        <Select 
                          mode="multiple"
                          allowClear
                          placeholder={MessageCommon.TitleSelect}
                          options={statusOptions}
                        />
                      </Form.Item>
              </Col>
            </Row>

           

          </Form>
          <div style={{ textAlign: 'center' }}>
            <Button type="default" className='mr-btn'  onClick={() => reset()}  disabled={appState.isLoading}>
              <AiOutlineSync className='icn-btn' /> Reset
            </Button>
            <Button type="primary"    onClick={() => searchData()} disabled={appState.isLoading}>
              <AiOutlineSearch className='icn-btn' /> Search
            </Button>


          </div>

        </Panel>
      </Collapse>

          <br></br>
       
          <Collapse defaultActiveKey={['1']}>
            <Panel header="Data Result" key="1" >
              <div id="panel-action" style={{paddingTop: '8px'}}>
                <div>
                <Button type="primary" htmlType="button" className='mr-btn' disabled={isPrint} onClick={()=>onFinishPrint()}>
                    <AiOutlinePrinter className='icn-btn' />Print
                </Button>
                </div>
                <div>
                  <div className='search-result' style={{ float: 'left'}}>Search found { appState.total } record(s).</div>
                  <div style={{ float: 'right'}}>
                {/* <Select
                  key = { Helper.Uniqid()}
                  style={{ width: 150 }}
                  defaultValue='20'
                  placeholder="custom dropdown render"
                  dropdownRender={(menu) => (
                    <>
                      <Divider style={{ margin: '8px 0' }} />
                      <Space style={{ padding: '0 8px 4px' }}>
                          <Input
                            placeholder=""
                            ref={inputPageItemRef}
                            value={pageItemName}
                            onChange={onPageItemChange}
                          />
                          
                          <Button type="primary" htmlType="button" className='mr-btn' onClick={addPageItem} >
                               <AiOutlinePlus/>
                          </Button>
                      </Space>
                      {menu}
                    </>
                  )}
                  options={pageItems.map((item) => ({ label: item, value: item }))}
                  onChange={onPerpageChange}
                /> */}
                {/* <span style={{marginLeft: '5px'}}>{' / Page'}</span> */}
                </div>
                </div>
              </div>

        <div id="table-container">
        { appState.isLoading ?
            <div style={{ margin: 'auto',paddingBottom: '30px'}}>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
          :
          <table className='idoc-table'>
            <thead id="head-table-fixed">
              <tr>
                <th >Request No.</th>
                <th >Requester</th>
                { requestTypeMenu.includes(0) || requestTypeMenu.includes(RequestType.StorageCarton) || requestTypeMenu.includes(RequestType.ChangeOwner)    ?
                <th >Owner</th>:<></>
                }
                <th >Org. Unit</th>
                <th >Requested Date</th>
                <th >Carton Quantity</th>
                <th >Request Type</th>
                <th >Status</th>
              </tr>
            </thead>
           
            <tbody >
            { appState.items.map((item:QueryDataModel) => (
                <tr key={Helper.Uniqid()}>
                  <td style={{textAlign: 'center'}}>
                    
                        <a className={item.statusId === CartonStatus.Done && (item.requestTypeId === RequestType.DestructionCarton || item.requestTypeId === RequestType.RemoveCarton || item.requestTypeId === RequestType.RemoveCartonStorage) ? 'a-table c-red' : 'a-table'}
                            onClick={() => showModalCarton(item.recordNo,item.storageRecordNo,item.requestTypeId)} >
                            {item.requestNo ? item.requestNo : item.tempNo}
                      </a>
                  </td>
                  <td style={{textAlign: 'center'}}>{item.requesterName}</td>
                  {requestTypeMenu.includes(0) || requestTypeMenu.includes(RequestType.StorageCarton) || requestTypeMenu.includes(RequestType.ChangeOwner)  ?
                        <td style={{textAlign: 'center'}}>{ item.requestTypeId === RequestType.StorageCarton || item.requestTypeId === RequestType.ChangeOwner ? item.ownerName : ""}</td>:<></>
                    }
                  <td style={{textAlign: 'center'}}>{item.orgUnit ? item.orgUnit : item.requesterOrgUnit}</td>
                  <td style={{textAlign: 'center'}}>{ item.requestDate ? dayjs(item.requestDate).format("DD MMM YYYY") : ""}</td>
                  <td style={{textAlign: 'center'}}>{item.quantity}</td>
                  <td style={{textAlign: 'center'}}>{item.requestType}</td>
                  <td style={{textAlign: 'center'}}>{item.status}</td>
                </tr>
              ))
            }
            
            </tbody>
  
          </table>
        }
        </div>
        <div style={{marginTop:'10px',textAlign:'right'}}>
        { appState.total > 0 ?
            <Pagination 
                key={Helper.Uniqid()}
                current={appState.currentPage} 
                pageSize={appState.perpage}
                total={appState.total} 
                onChange={onPageChange} 
                pageSizeOptions={pageSizeOptions}
                showSizeChanger={true}
              />:<></>
        }
        </div>

            </Panel>
          </Collapse>
    
      <Modal title="Request Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton}>
        {
          requestTypeId === RequestType.NewCarton ?
          <NewCartonRequestDetail  key={Helper.Uniqid()} recordNo={recordNo}/>:<></>
        }
          { requestTypeId === RequestType.StorageCarton || requestTypeId === RequestType.ChangeOwner ?
                  <StorageCartonRequestDetail key={Helper.Uniqid()} recordNo={recordNo} cartonNumber={""}  approveLevel={0}/>:<></>
        }
        { requestTypeId === RequestType.CollectCarton ?
          <CollectCartonRequestDetail key={Helper.Uniqid()} recordNo={recordNo}/>:<></>
        }
        { requestTypeId === RequestType.ReturnCarton ?
          <ReturnCartonRequestDetail key={Helper.Uniqid()} recordNo={recordNo} approveLevel={0} />:<></>
        }
        {
          requestTypeId === RequestType.RemoveCarton || requestTypeId === RequestType.RemoveCartonStorage ?
          <RemoveCartonRequestDetail key={Helper.Uniqid()} recordNo={recordNo} approveLevel={0} />:<></>
        }
        {
          requestTypeId === RequestType.ExtendCarton || requestTypeId === RequestType.DestructionCarton ?
                      <DestructionCartonRequestDetail key={Helper.Uniqid()} recordNo={recordNo} cartonNumber={""}  approveLevel={0} />:<></>
        }
        
          </Modal>
          {modal ? <span></span> : <></>}
        {contextHolder}
    </div >
  );

}

export default MytaskTracking
