import { useEffect, useState } from 'react';
import { Collapse, Form, message,Modal,Row,Spin,Card,Col,Popconfirm,Input,Typography,Pagination,Button,Select,DatePicker } from 'antd';
import secureLocalStorage  from  "react-secure-storage";
import { Label } from 'reactstrap';
import {RequestType, CartonStatus } from '../../enum';
import {  CloseCircleOutlined ,QuestionCircleOutlined} from '@ant-design/icons';
import { AiOutlineSync, AiOutlineSearch, AiFillSave,AiFillDelete,AiFillEdit } from 'react-icons/ai';
import { ChangeOwner ,Parameter ,Item,RequestChangeOrgUnit,RequestChangeOrgUnits} from '../../models'
import { Helper, InitHelper } from '../../helpers';
import { HrWebService,PropertyService,ChangeOrgUnitService} from '../../services';
import dayjs from 'dayjs'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import MessageCommon from '../../enum/Message';
import { useNavigate } from 'react-router-dom';
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import { OrgUnitAutoComplete2 } from '../../autocomplete/OrgUnitAutoComplete2';
import Loading from '../../components/Loading';
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { User} from '../../models';
import { update } from 'lodash';

interface AppState {
    currentPage:number
    perpage:number
    total: number
    items:ChangeOwner[]
    orgUnits:string[]
    isLoading:boolean
  }

const ManualUpdate3 = () => {
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }
    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
   

    const { instance, accounts } = useMsal();
  const navigate = useNavigate();
    const [dataForm] = Form.useForm();
    const { Panel } = Collapse;
    const [modal, contextHolder] = Modal.useModal();
    const [recordNo, setRecordNo] = useState(0);
    const [messageApi, contextHolderMessage] = message.useMessage();
    const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter)
    const [appState,setAppState] = useState<AppState>({
      currentPage : 1,
      total : 0,
      perpage: 100,
      items: [],
      orgUnits:[],
      isLoading : true
    })
    const [requestStartDate, setRequestStartDate] = useState<Date|null>(null);
    const [requestEndDate, setRequestEndDate] =  useState<Date|null>(null);
    const [owners, setOwners] = useState<Item[]>([])
    const [orgunits, setOrgUnits] = useState<Item[]>([])
    const [newOrgUnits, setNewOrgUnits] = useState<Item[]>([])
    const [confidentialLevel, setConfidentialLevel] = useState(1)
    const [selectItems, setSelectItems] = useState<number[]>([])
    const [requestTypeId, setRequestTypeId] = useState(RequestType.ChangeOwner)
    const [isLoadingPrint, setIsLoadingPrint] = useState(false)
    const [disableSubmit,setDisableSubmit] = useState(false)
    const [editNewOwners, setEditNewOwners] = useState<Item[]>([])
    const [editNewOrgUnits, setEditnewOrgUnits] = useState<Item[]>([])
    useEffect(() => {
        if(user.permissions.orgUnit === 0){
            navigate('/permissiondenided')
        }
        setRequestTypeId(RequestType.ChangeOwner)
        setIsLoadingPrint(false)
        let params = InitHelper.InitialParameter();
        params.confidentialLevel = 1;
        dataForm.setFieldValue("confidential",1)
        getData(1, appState.perpage, params)
    }, [])
    
      const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
    
    
    const getData = async (currentPage: number,perpage: number,_parameter:Parameter| null = null) => {
        let _state:AppState = Object.assign({},appState)
           _state.isLoading = true
           setAppState(_state)

        let parameter = Object.assign({},saveParameter)
        if(_parameter !== null){
            parameter = Object.assign({},_parameter);
        }
           
        parameter.page = currentPage
        parameter.limit = perpage
        parameter.statusIds = [CartonStatus.WaitAdmin]
        parameter.manual = 1
        let token = await getToken()
        console.log(parameter)
        let result = await new ChangeOrgUnitService(token).SearchRequest(parameter)
        
        console.log(result)
        if(result && result.success)
        {
            let state = Object.assign({},appState)
              state.total = result.data.total
              state.currentPage = currentPage
              state.items = result.data.items
              state.isLoading = false
              state.perpage = perpage
            setTimeout(() => {
                setSaveParameter(parameter)
              setAppState(state)
            }, 500);
        }
        else{
            let state = Object.assign({},appState)
            state.isLoading = false
            setAppState(state)
        }
    }
      
    const UpdateData = async () => {
        console.log('searchdata')
        let field = dataForm.getFieldsValue()
        console.log(field)
        let parameter = InitHelper.InitialParameter();
        parameter.requestNo = field.requestNo
        parameter.requester = field.requester
        if(field.owner){
            parameter.owners = [field.owner]
        }
        if(field.orgunit){
            parameter.orgUnits = [field.orgunit]
        }
        if(field.cartonNo){
          parameter.cartonNo = field.cartonNo
        }
        if(field.warehouseId){
            parameter.warehouseId = 1
          }
       
        
        console.log(parameter)
        let token = await getToken()
        let result = await new ChangeOrgUnitService(token).ManualUpdate(parameter)
        
        console.log(result)
        if(result && result.success)
        {
           console.log("success")
        }
        else{
           console.log('error')
        }
    }

    
     
  
    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


    return (
    <div>
        <Card >
        
        <Collapse>
            {isLoadingPrint?<Loading/>:<></>}
            <Panel header="Search" key="1">
                <Form
              {...layout}
              form={dataForm}
              name="orgunit-form"
              labelAlign="left"
              colon={false}
              labelWrap={true}
            >
              <Row>
                <Col md={11} >
            
                    <Form.Item name="requestNo" label="Request No."  >
                        <Input  />
                    </Form.Item>
                    <Form.Item name="requester" label="Requester."  >
                        <Input/>
                    </Form.Item>
                    <Form.Item name="owner" label="Owner"  >
                        <Input  />
                    </Form.Item>
                    <Form.Item name="orgunit" label="Org. Unit"  >
                        <Input  />
                    </Form.Item>
                    <Form.Item name="cartonNo" label="Carton No"  >
                        <Input  />
                    </Form.Item>
                    <Form.Item name="warehouseId" label="Warehouse Id"  >
                        <Input />
                    </Form.Item>
                   
                </Col>
                <Col md={2} >
                </Col>
                <Col md={11} >
                 
                </Col>
              </Row>
      
                </Form>
                <div style={{ textAlign: 'center' }}>
                 
                    <Button type="primary"   onClick={UpdateData}>
                        <AiOutlineSearch className='icn-btn' /> UpDate
                    </Button>
                </div>
  
            </Panel>
        </Collapse>
        <br></br>
     
            </Card>
      
        {contextHolderMessage}
        {contextHolder}
  
    </div>
    );
  
  }
  
  export default ManualUpdate3
  