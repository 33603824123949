import { useEffect } from 'react';
import { Card, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import MytaskTracking from './MytaskTracking';
import MytaskSaveDraft from './MytaskSaveDraft';

const StatusTracking = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const tab:string| null| undefined = urlParams.get('t')

  useEffect(() => {
   
  }, [])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Status Tracking`,
      children: <MytaskTracking/>,
    },
    {
      key: '2',
      label: `Save Draft`,
      children: <MytaskSaveDraft/>,
    },

  ];


  return (

    <div>
      <Card >
        <Tabs defaultActiveKey={tab ? tab.toString() : '1'} items={items}  destroyInactiveTabPane/>
      </Card>

    </div>
  );

}

export default StatusTracking
