import { useEffect, useState } from 'react';
import { Collapse, Form, Row,Spin,Card,Col,Input,Pagination,Button,DatePicker } from 'antd';
import secureLocalStorage  from  "react-secure-storage";
import { Label } from 'reactstrap';
import { CartonStatus } from '../../enum';
import { AiOutlineSync, AiOutlineSearch, } from 'react-icons/ai';
import { ChangeOwner ,Parameter ,Item} from '../../models'
import { Helper, InitHelper } from '../../helpers';
import { HrWebService,ChangeOrgUnitService,PropertyService} from '../../services';
import dayjs from 'dayjs'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import MessageCommon from '../../enum/Message';
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { User} from '../../models';
import { useNavigate } from 'react-router-dom';

interface AppState {
    currentPage:number
    perpage:number
    total: number
    items:ChangeOwner[]
    isLoading:boolean
  }

const ManualUpdateHistory = () => {
    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const [dataForm] = Form.useForm();
    const { Panel } = Collapse;
    const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter)
    const [appState,setAppState] = useState<AppState>({
      currentPage : 1,
      total : 0,
      perpage: 100,
      items: [],
      isLoading : true
    })
    const [requestStartDate, setRequestStartDate] = useState<Date|null>(null);
    const [requestEndDate, setRequestEndDate] =  useState<Date|null>(null);
    const [owners, setOwners] = useState<Item[]>([])
    const [orgunits, setOrgUnits] = useState<Item[]>([])

    useEffect(() => {
        if(user.permissions.orgUnit === 0){
            navigate('/permissiondenided')
        }
        getData(1,appState.perpage,InitHelper.InitialParameter())
    }, [])
    
      const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
     
      
    const getData = async (currentPage: number,perpage: number,_parameter:Parameter| null = null) => {
        let _state:AppState = Object.assign({},appState)
           _state.isLoading = true
           setAppState(_state)

        let parameter = Object.assign({},saveParameter)
        if(_parameter !== null){
            parameter = Object.assign({},_parameter);
        }
           
        parameter.page = currentPage
        parameter.limit = perpage
        parameter.statusIds = [CartonStatus.Done]
        parameter.manual = 1
        
        let token = await getToken()
        let result = await new ChangeOrgUnitService(token).Search(parameter)
        
        console.log(result)
        if(result && result.success)
        {
            let state = Object.assign({},appState)
              state.total = result.data.total
              state.currentPage = currentPage
              state.items = result.data.items
              state.isLoading = false
              state.perpage = perpage

            setTimeout(() => {
                setSaveParameter(parameter)
                setAppState(state)
            }, 500);
        }
        else{
            let state = Object.assign({},appState)
            state.isLoading = false
            setAppState(state)
        }
    }
      
    const searchData = async () => {
        console.log('searchdata')
        let field = dataForm.getFieldsValue()
        console.log(field)
        let parameter = InitHelper.InitialParameter();
           
        if(field.requestNo){
            parameter.requestNoList = field.requestNo.trim().split(",")
        }
           
        if(owners.length > 0){
            parameter.owners = owners.map(item => item.key)
        }
    
        if(orgunits.length > 0){
            parameter.orgUnits = orgunits.map(item => item.key)
        }

        if(requestStartDate && requestEndDate){
            parameter.requestStartDate = requestStartDate
            parameter.requestEndDate = requestEndDate
        }
    
        console.log(parameter)
        getData(1,appState.perpage,parameter)
        
    }

    const reset = () => {
        console.log('reset')
        dataForm.resetFields()
        setOwners([])
        setOrgUnits([])
       
        getData(1,appState.perpage,InitHelper.InitialParameter())
    }
     
    const onSearchEmployee = async(key:string) => {
       
        let token = await getToken()
        let result = await new HrWebService(token).SearchEmployee(key)
        if(result && result.success)
        {
            return result.data
        }
        else{
            console.log(result?.message)
            return []
        }
    }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const onPageChange = (page:number,perpage:number) => {
        if(page !== appState.currentPage && perpage === appState.perpage){
            getData(page,appState.perpage)
        }
    
        if(page === appState.currentPage && perpage !== appState.perpage){
            getData(1,perpage)
        }
    }
    
    const onOwnerSelect = (item:any) => {
        setOwners(item)
    }

   
    const onOrgUnitChange = (value:any) => {
       setOrgUnits(value)
    }

    const onRequestStartDateChange = (date: any) => {
        setRequestStartDate(date);
    }
    
    const onRequestEndDateChange = (date: any) => {
        setRequestEndDate(date)
    }

    const onSearchOrgUnit = async(key:string) => {
   
        let token = await getToken()
        let result = await new PropertyService(token).GetOrgUnits(key)
        if(result && result.success)
        {
          return result.data
        }
        else{
          console.log(result?.message)
          return []
        }
    }

    return (
    <div>
        <Card >
        
        <Collapse>
            <Panel header="Search" key="1">
                <Form
              {...layout}
              form={dataForm}
              name="orgunit-form"
              labelAlign="left"
              colon={false}
              labelWrap={true}
            >
              <Row>
                <Col md={11} >
                    <Form.Item name="requestDate" label="Updated Date">
                      <table style={{width : '100%'}}>
                        <tr>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate} className='date-picker-full' onChange={onRequestStartDateChange}/>
                          </td>
                          <td>
                            <Label style={{ marginLeft: 10, marginRight: 10 }}>To</Label>
                          </td>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate} className='date-picker-full' onChange={onRequestEndDateChange}/>
                          </td>
                        </tr>
                      </table>
                    </Form.Item>
                    <Form.Item name="requestNo" label="Request No.">
                        <Input placeholder="ex. B230002,B230004" />
                    </Form.Item>
                </Col>
                <Col md={2} >
                </Col>
                <Col md={11} >
                    <Form.Item name="orgUnit" label="Org. Unit" >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={orgunits ? orgunits : []}
                            onChange={item => onOrgUnitChange(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
                    <Form.Item name="owner" label="Owner">
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={owners ? owners : []}
                            onChange={item => onOwnerSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                    />
                    </Form.Item>
                </Col>
              </Row>
      
                </Form>
                <div style={{ textAlign: 'center' }}>
                    <Button type="default" className='mr-btn' onClick={reset} >
                        <AiOutlineSync className='icn-btn' /> Reset
                    </Button>
                    <Button type="primary"   onClick={searchData}>
                        <AiOutlineSearch className='icn-btn' /> Search
                    </Button>
                </div>
  
            </Panel>
        </Collapse>
        <br></br>
        <Collapse defaultActiveKey={['1']}>
            <Panel header="Data Result" key="1" >
                <div id="table-container">
                { appState.total>0?<div style={{float: 'right'}}>
                        <SelectPagination  onSelectChange={onPageChange} currentPage={appState.currentPage}/>
                        <span style={{marginLeft:'8px'}}>/ Page</span>
                    </div>:<></>}
                    <div id="panel-action" style={{paddingTop: '8px'}}>
                <div>
                  <div className='search-result' style={{ float: 'left'}}>Search found { appState.total } record(s).</div>
                  <div style={{float: 'right'}}>
                     
                  </div>
                </div>
              </div>
           
                { appState.isLoading ?
                    <div style={{ margin: 'auto',paddingBottom: '30px'}}>
                        <Spin tip="Loading" size="large">
                            <div className="content" />
                        </Spin>
                    </div>
                    :
                    <table className='idoc-table'>
                        <thead id="head-table-fixed">
                            <tr>
                                <th >Storage Request No.</th>
                                <th >Owner</th>
                                <th >New Owner</th>
                                <th >Previous Org. Unit</th>
                                <th >New Org. Unit</th>
                                <th >Admin Action Date</th>
                            </tr>
                        </thead>
           
                        <tbody >
                            { appState.items.map((item:ChangeOwner) => (
                                <tr>
                                    <td style={{textAlign: 'center'}}>{item.requestNo }</td>
                                    <td style={{textAlign: 'center'}}>{item.ownerName}</td>
                                    <td style={{textAlign: 'center'}}>{item.newOwnerName}</td>
                                    <td style={{textAlign: 'center'}}>{item.orgUnit}</td>
                                    <td style={{textAlign: 'center'}}>{item.newOrgUnit}</td>
                                    <td style={{textAlign: 'center'}}>
                                        {dayjs(item.updateDate).format('DD MMM YYYY')}
                                    </td>
                                  
                                </tr>
                            ))
                            }
            
                        </tbody>
  
                    </table>
                }
                </div>
                <div style={{marginTop:'10px',textAlign:'right'}}>
                { appState.total > 0 ?
                    <Pagination 
                        key={Helper.Uniqid()}
                        current={appState.currentPage} 
                         pageSize={appState.perpage}
                        total={appState.total} 
                        onChange={onPageChange}
                        showSizeChanger={false}
                    />:<></>
                }
                </div>
        
        
            </Panel>
        </Collapse>

        </Card>
      
  
    </div>
    );
  
  }
  
  export default ManualUpdateHistory
  