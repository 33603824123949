import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./configurations/authConfig";
import ApiAzureService from "./services/ApiAzureService";
import  secureLocalStorage  from  "react-secure-storage";
import { BrowserRouter } from "react-router-dom";

    new ApiAzureService().getClientId().then((result) =>{
        console.log(result);
        msalConfig.auth.clientId = result.clientId
        msalConfig.auth.authority = "https://login.microsoftonline.com/" + result.tenantId
        secureLocalStorage.setItem('clientId',result.clientId)
        secureLocalStorage.setItem('bypass',result.bypassLogin)
        console.log(msalConfig);
        const rootElement = document.getElementById('root');
        const msalInstance = new PublicClientApplication(msalConfig);
        ReactDOM.render(
            <React.StrictMode>
                <MsalProvider instance={msalInstance}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </MsalProvider>
            </React.StrictMode>, rootElement
        );
    }
    )

