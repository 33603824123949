import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin ,Pagination ,Popconfirm,message} from 'antd';
import { AiFillDelete,AiOutlineRotateRight } from 'react-icons/ai';
import {  CloseCircleOutlined ,QuestionCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { QueryDataModel ,RequestUpdateStatus ,Parameter, User} from '../../models';
import { Helper, InitHelper } from '../../helpers';
import { CartonStatus } from '../../enum';
import { StorageCartonService } from '../../services';
import StorageCartonRequestDetail from './StorageCartonRequestDetail';
import dayjs from 'dayjs'
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
  currentPage:number
  perpage:number
  total: number
  totalPgae: number
  items:QueryDataModel[]
  isLoading:boolean
}

const StorageCartonStatusTracking = () => {
    const { instance, accounts } = useMsal();
    const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const pageSizeOptions = user.systemConfig.tablePageSize
  const navigate = useNavigate();
  const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [modal, contextHolder] = Modal.useModal();
  const [recordNo,setRecordNo] = useState(0)
  const [selectItems, setSelectItems] = useState<number[]>([])
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    totalPgae : 0,
    perpage: 10,
    items: [],
    isLoading : true
  })

  useEffect(() => {
      getData(1,appState.perpage)
  }, [])

  const getData = async (currentPage: number,perpage: number) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)
       
    let parameter:Parameter = InitHelper.InitialParameter();
      parameter.page = currentPage
      parameter.limit = perpage
      parameter.orderBy = "StatusId"
      parameter.statusIds = [CartonStatus.WaitAdmin,CartonStatus.WaitApproval,CartonStatus.Inprogress]
      parameter.requesters = [user.employeeId]
      
      let token = await getToken()
      let result = await new StorageCartonService(token).Search(parameter)
      console.log(result)
        if(result && result.success)
        {
            let {page,limit,total,items} = result.data
      
            let state = Object.assign({},appState)
            state.total = total
            state.currentPage = page
            state.totalPgae = Math.ceil(total / limit)
            state.items = items
            state.isLoading = false
            state.perpage = perpage
            setTimeout(() => {
                setAppState(state)
            }, 500);
        }
        else{
            let state = Object.assign({},appState)
            state.isLoading = false
            setAppState(state)
        }
     
       
  }
  
    const handleAction = async (recordIds: number[], statusId: number) => {
        let token = await getToken()
    let requestUpdateStatus:RequestUpdateStatus = {
      recordIds: recordIds,
      statusId: statusId
    }
   
    let result = await new StorageCartonService(token).UpdateStatus(requestUpdateStatus)
    if(result && result.success){
      let element = document.getElementById('table-container') as HTMLElement
      messageApi.open({
        type: 'success',
        content: 'Save success',
        duration: 1,
        style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
      });
      setTimeout(() => {
         getData(1,appState.perpage)
      }, 1000);
    }
    else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
    }
  }


  const showModalCarton = async (_recordNo:number) => {
    setRecordNo(_recordNo)
    setIsModalCartonOpen(true);
  };
  const handleCancelCarton = () => {
    setIsModalCartonOpen(false);

  };

  const onCheckAll = (e:React.ChangeEvent<HTMLInputElement>) => {
    let items:number[] = []
    if(e.target.checked){
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
        if(!element.disabled){
          element.checked = true
          items.push(parseFloat(element.value))
        }
      })
    }
    else{
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
        element.checked = false
      })
    }
    setSelectItems(items)
  };

  const onCheckChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    let checked = e.target.checked
    let value = parseFloat(e.target.value)
    let items = Object.assign([],selectItems)
    if(checked){
        items.push(value)
        let element = document.getElementById('check-item-all') as HTMLInputElement
        if(items.length === appState.items.length){
          element.checked = true
          element.indeterminate = false
        }
        else{
          element.checked = false
          element.indeterminate = true
        }
        
    }
    else{
      let index = items.indexOf(value);
      if (index !== -1) {
        items.splice(index, 1);
      }

      if(items.length === 0){
        let element = document.getElementById('check-item-all') as HTMLInputElement
        element.checked = false
        element.indeterminate = false
      }
    }

    console.log(items)
    setSelectItems(items)
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const onPageChange = (page:number,perpage:number) => {
    if(page !== appState.currentPage && perpage === appState.perpage){
        getData(page,appState.perpage)
    }

    if(page === appState.currentPage && perpage !== appState.perpage){
        getData(1,perpage)
    }
  }

 
  return (
    <div>
      
      <div style={{float: 'left'}}>
      { selectItems.length > 0 ?
        <>
        <Popconfirm
            title="Recall the task"
            description="Confirm to recall?"
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
             onConfirm = { () => handleAction(selectItems,CartonStatus.SaveDraftRecall) }
          >
          <Button type="primary" className='mr-btn'>
            <AiOutlineRotateRight className='icn-btn' /> Recall
          </Button>
        </Popconfirm>
        <Popconfirm
            title="Cancel the task"
            description="Confirm to cancel?"
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
             onConfirm = { () => handleAction(selectItems,CartonStatus.Cancel) }
          >
           <Button type="primary" className='btn-red mr-btn'>
            <AiFillDelete className='icn-btn' /> Cancel
          </Button>
        </Popconfirm>
        </>:
        <>
          <Button type="primary" className='mr-btn' disabled>
            <AiOutlineRotateRight className='icn-btn' /> Recall
          </Button>
          <Button type="primary" className='btn-red mr-btn' disabled>
            <AiFillDelete className='icn-btn' /> Cancel
          </Button>
        </>
      }
      </div>
       
      <div style={{float: 'right'}}>
         
      </div>
      <div></div>
      { appState.isLoading ?
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>
          :
          <>
            <div id="table-container" style={{paddingTop: '45px'}}>
              <table className='idoc-table'>
                <thead id="head-table-fixed">
                  <tr>
                    <th style={{ textAlign: 'left',width: '50px'}}>
                        <div className="form-check">
                          <input  type="checkbox" id={'check-item-all'} className="form-check-input check-item" onChange={(e) => onCheckAll(e)}/>
                          <label className="form-check-label" htmlFor={'check-item-all'}/>
                        </div>
                      
                    </th>
                    <th style={{ textAlign: 'left'}}>Request No.</th>
                    <th >Requested Date</th>
                    <th >Expected  Pick up Date</th>
                    <th >Owner</th>
                    <th >Carton Quantity</th>
                    <th >Status</th>
                  </tr>
                </thead>
                <tbody >
                  { appState.items.map((item:QueryDataModel) => (
                    <tr>
                      <td style={{ textAlign: 'center',width: '50px'}}>
                        <div className="form-check">
                          <input  
                            type="checkbox" 
                            id={'check-item-' + item.recordNo} value={item.recordNo} 
                            disabled = { item.statusId !== CartonStatus.WaitApproval}
                            className="form-check-input check-item" 
                            style={{ backgroundColor: item.statusId !== CartonStatus.WaitApproval ? 'grey' : ""}}
                            onChange={(e) => onCheckChange(e)}
                          />
                          <label className="form-check-label" htmlFor={'check-item-' + item.recordNo}/>
                        </div>
                      </td>
                      <td style={{textAlign: 'center'}}>
                        <a className='a-table' onClick={() => showModalCarton(item.recordNo)}>{item.requestNo}</a>
                      </td>
                      <td style={{textAlign: 'center'}}>{ dayjs(item.requestDate).format("DD MMM YYYY") }</td>
                      <td style={{textAlign: 'center'}}>{ dayjs(item.pickupDate).format("DD MMM YYYY") }</td>
                      <td style={{textAlign: 'center'}}>{ item.ownerName }</td>
                      <td style={{textAlign: 'center'}}>{ item.quantity }</td>
                      <td style={{textAlign: 'center'}}>{ item.status }</td>
                     
                    </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <div style={{marginTop:'10px',textAlign:'right'}}>
            { appState.total > 0 ?
                <Pagination 
                    key={Helper.Uniqid()}
                    current={appState.currentPage} 
                    pageSize={appState.perpage}
                    total={appState.total} 
                    onChange={onPageChange} 
                    pageSizeOptions={pageSizeOptions}
                    showSizeChanger={true}
                />:<></>
            }
            </div>
          </>
        }
        <Modal title="Request Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton}>
            <StorageCartonRequestDetail 
              key={Helper.Uniqid()}
                  recordNo={recordNo} cartonNumber={""}
              approveLevel={0}
            />
          </Modal>
          {modal ? <span></span> : <></>}
       {contextHolderMessage}
        {contextHolder}
    </div >
  );

}

export default StorageCartonStatusTracking
