import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Radio, Row ,Spin ,message,Popconfirm } from 'antd';
import {  AiOutlineCheck, AiOutlineClose, AiFillDelete,AiFillSave} from 'react-icons/ai';
import { Label } from 'reactstrap';
import CartonList from '../Share/CartonList'
import { RequestDetail,UpdateApproval, User } from '../../models';
import {  CloseCircleOutlined,QuestionCircleOutlined} from '@ant-design/icons';
import { DestructionCartonService, FileManageService } from '../../services';
import { InitHelper, Helper } from '../../helpers';
import { CartonStatus , RequestType,Message } from '../../enum';
import secureLocalStorage  from  "react-secure-storage";
import DisplayLogNote from '../Share/DisplayLogNote';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { useLocation } from 'react-router-dom';
import Loading from '../../components/Loading';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

const DestructionCartonView = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id:string| null = urlParams.get('id')
    const recordNo = id ? parseInt(id) : 0
    const { instance, accounts } = useMsal();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const [printForm] = Form.useForm();
  const [dataForm] = Form.useForm();
  const navigate = useNavigate();
  const [detail, setDetail] = useState<RequestDetail>(InitHelper.InitialRequestDetail)
  const [isLoading, setIsLoading] = useState(true)
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [isModalRejectOpen,setIsModalRejectOpen] = useState(false)
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isLoadingPrint,setIsLoadingPrint] = useState(false)
  const [isErrorNote,setIsErrorNote] = useState(false)
  const location = useLocation();
  const [isShow,setIsShow] = useState(false)
  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
  

  useEffect(() => {
    getData()
  }, [])
  
  const getData = async () => {
    setIsLoading(true)
    let token = await getToken()
    let result = await new DestructionCartonService(token).GetDetail(recordNo)
    console.log(result)
    if(result && result.success){
        let detail = result.data
        let owners = detail.owner.split(",")
        if(detail.requester === user.employeeId 
            || owners.indexOf(user.employeeId) > -1
            || detail.onBehalf == user.employeeId
            || detail.contactPerson === user.employeeId
            || detail.deliverto === user.employeeId
            || detail.approver === user.employeeId
            || detail.approver2 === user.employeeId
        ){
            setDetail(detail)
        }
        else{
            navigate('/permissiondenided')
        }
    }
    setIsLoading(false)
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const handleNoteModalCancel = () => {
    setIsNoteModalOpen(false);
  };

  const showNoteModal = () => {
    setIsNoteModalOpen(true);
  };

   
  const handleReject = () => {
    let element = document.getElementById('note-reject') as HTMLInputElement
    if(element && element.value){
      handleAction([recordNo], CartonStatus.Reject,element.value) 
    }
    else{
      setIsErrorNote(true)
      return false
    }
    
  }

  

  const download = async (_requestNo:string,filename:string) => {
      
    let token = await getToken()
    new FileManageService(token).downloadFile(_requestNo,filename);
}

const handleRejectOpen = () => {
    
    setIsModalRejectOpen(true)
    setTimeout(() => {
        let errorElement = document.getElementById('note-reject-error') as HTMLDivElement
            errorElement.style.display = 'none'
        let element = document.getElementById('note-reject') as HTMLTextAreaElement
        element.value = ""
    }, 300);
   
 }
 
  const handlePrintModalCancel = () => {
    setIsPrintModalOpen(false);
  };
  

  const handleAction = async (recordIds:number[],approvesTatusId: number,noteReject:string| null = null) => {

    let token = await getToken()
  let requestUpdateStatus:UpdateApproval = {
    recordIds: recordIds,
    statusId: 0,
    approveStatusId: approvesTatusId,
    noteReject: noteReject ? noteReject : "",
    updateBy: user.employeeId,
    updateDate: new Date()
  }
 console.log(requestUpdateStatus)
  let result = await new DestructionCartonService(token).UpdateApproval(requestUpdateStatus)
  console.log(result)
  if(result && result.success){
    let element = document.getElementById('destroy-form') as HTMLElement
    messageApi.open({
      type: 'success',
      content: 'Save success',
      duration: 1,
      style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
    });
    setTimeout(() => {
      getData()
    }, 1000);
  }
  else{
      Modal.error({
          icon: <CloseCircleOutlined />,
          title: 'This is an error message',
          content: result?.message,
      });
  }
  setIsModalRejectOpen(false)
}

  const handleCancelReject = () => {
    setIsModalRejectOpen(false)
 }

  return (

    <div>
      <Card >
      { isLoading ?
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>:<></>
      }
       {isLoadingPrint?<Loading/>:<></>}
        <Form
          {...layout}
          form={dataForm}
          name="destroy-form"
          labelAlign="left"
          colon={false}
          labelWrap={true}
        >
          <Row>
            <Col md={22} >
              <Form.Item name="requester" label="Requester" labelCol={{ md: 3 }}  >

                <Label className='label-det'>{detail.requesterName}</Label>
                <Label className='mr-label' >Org. Unit</Label>
                <Label className='label-det'>{detail.orgUnit}</Label>
                <Label className='mr-label' >Position</Label>
                <Label className='label-det'>{detail.position}</Label>
                <Label className='mr-label' >Tel No.</Label>
                <Label className='label-det'>{detail.tel}6</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
              <Form.Item  label="Request No"  >
                <Label className='label-det '>{detail.requestNo}</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item label="Status"  >
                <Label className='label-det'>{detail.status}</Label>
              </Form.Item>
            </Col>
          </Row>
    { detail.memo ?
        <Row>
        <Col md={11} >
          <Form.Item  label="Org. Unit Reference/Memo"  >
            <Label className='label-det '>{detail.memo}</Label>
          </Form.Item>
        </Col>
        <Col md={2} >

        </Col>
        <Col md={11} >
          <Form.Item label="Attached File"  >
            <Label className='label-det'>
            { 
              detail.files.map((file) => 
                    (<div><a className='a-table' onClick={() => download("D" + detail.recordNo,file.name)}>{file.name}</a></div>)
            )}
            </Label>
          </Form.Item>
        </Col>
        </Row>:<></>
    }

          <Row>
            <Col md={11} >
              <Form.Item label="Carton Quantity"  >
                <Label className='label-det'>{detail.quantity} carton(s)</Label>
              </Form.Item>
              { detail.requester !== detail.onBehalf  ?
                          <Form.Item name="onbehaft" label="On Behalf"  >
                              <Label className='label-det'>{detail.onBehalfName}</Label>
                          </Form.Item>:<></>
                        }
                         {detail.serviceDate?
              <Form.Item  label="Service Date"  >
                <Label className='label-det'>{ dayjs(detail.serviceDate).format("DD MMM YYYY")  }</Label>
              </Form.Item>:<></>
            }
                <Form.Item name="note" label="Note" >
                  { detail.note.length > 0 ?
                <>
                    <Label className='label-det label-det-warp'>{detail.note[ detail.note.length - 1 ]}</Label>
                    <a className='a-table ml-label' onClick={showNoteModal}>Display Log</a>
                </>:<></>
                }
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
            <Form.Item name="owner" label="Request Type"  >
                <Label className='label-det'>{detail.requestTypeId === RequestType.ExtendCarton ? 'Extend' : 'Destroy'}</Label>
              </Form.Item>
              
              <Form.Item name="Verifier" label={ detail.requestDate && parseInt(dayjs(detail.requestDate).format("YYYY")) >= 2023 ? "Verifier" : "Approver"}  >
                <Label className='label-det '>{  detail.approverName } </Label>
              </Form.Item>
            
            {detail.approverDate2 != null ?
              <Form.Item name="approver" label="Approver"  >
                <Label className='label-det '>{  detail.approverName2 } </Label>
              </Form.Item>:<></>
            }
            </Col>
          </Row>
        
                
        </Form>

        <hr></hr>
    { ((!detail.approverDate && detail.approver === user.employeeId) || (detail.approverDate && detail.approver2 === user.employeeId)) && detail.statusId == CartonStatus.WaitApproval?
        <div style={{textAlign:'center'}}>
               <Button type="primary" className='btn-red mr-btn' onClick={() => handleRejectOpen()}>
          <AiFillDelete className='icn-btn' /> Reject
        </Button>

      <Popconfirm
          title="Approve the task"
          description="Confirm to approve?"
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
           onConfirm = { () => handleAction([recordNo],CartonStatus.Approve) }
        >
         <div className="idoc-tooltip">
          <span className="tooltiptext">
            <ul>
              <li style={{textAlign: 'left'}}>
                {Message.Verify}
              </li>
              <li style={{textAlign: 'left'}}>
                  {Message.Approve}
              </li>
            </ul>
          </span>
          <Button type="primary" >
            <AiFillSave className='icn-btn' /> Verify/Approve
          </Button>
        </div>
      </Popconfirm>
        </div>
        :
        <></>
    }
        <CartonList requestNo={detail.requestNo} cartons={detail.cartons} disableDetail={false} statusId={detail.statusId}/>
      </Card>
     

      <Modal title="" open={isPrintModalOpen} footer={''} width={600} onCancel={handlePrintModalCancel}>
        <br></br>
        <Card >
          <Form
            {...layout}
            form={printForm}
            name="view-from"
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
              <Col md={24}  >
                <Form.Item name="print" label="Export type" labelCol={{ md: 6 }}  >
                  <Radio.Group name="radiogroup" >
                    <Radio value={1}>PDF</Radio>
                    <Radio value={0}>Excel</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <div style={{ textAlign: 'center' }} >
            <Button type="default" className='mr-btn'  onClick={handlePrintModalCancel}>
              <AiOutlineClose className='icn-btn' />Cancel
            </Button>
            <Button type="primary" className='mr-btn'   htmlType='submit' >
              <AiOutlineCheck className='icn-btn' />OK
            </Button>
          </div>
          </Form>
        </Card>
      </Modal>
      
      <Modal title="Display Log" open={isNoteModalOpen} footer={''} width={900} onCancel={handleNoteModalCancel}>
        <DisplayLogNote key={Helper.Uniqid()} recordNo={detail.recordNo} requestTypeId={RequestType.DestructionCarton}/>
      </Modal>

      <Modal title="Confirm to reject?" open={isModalRejectOpen} footer={''} width={700} onCancel={() => setIsModalRejectOpen(false)} maskClosable={false}>
        <br></br>
       
          <Row>
          <Col md={2} >
              Note <span style={{ color: 'red'}}>*</span>
            </Col>
            <Col md={22} >
            <textarea style={{width: '93%',borderColor:"#ccc",borderRadius:"5px"}} id="note-reject" rows={5} defaultValue=""/>
              <div id="note-reject-error" className='error' style={{ display:'none'}}>Note is required.</div>
            </Col>
          </Row>
        
        <br></br>
        <div style={{ textAlign: 'center' }}>
          <Button type="default" className='mr-btn' onClick={() => setIsModalRejectOpen(false)}  >
            <AiOutlineClose className='icn-btn' />Cancel
          </Button>
          <Button type="primary" className='mr-btn'  onClick={() => handleReject()}>
            <AiOutlineCheck className='icn-btn' />OK
          </Button>
        </div>
      </Modal>

      <Modal title="Confirm to reject?" open={isModalRejectOpen} footer={''} width={700} onCancel={() => handleCancelReject()} maskClosable={false}>
        <br></br>
        
          <Row>
            <Col md={2} >
              Note <span style={{ color: 'red'}}>*</span>
            </Col>
            <Col md={22} >
            <textarea style={{width: '93%',borderColor:"#ccc",borderRadius:"5px"}} id="note-reject" rows={5} defaultValue=""/>
            { isErrorNote ? <div className='error'>Note is required.</div> : <></> }
            </Col>
          </Row>
        
        <br></br>
        <div style={{ textAlign: 'center' }}>
          <Button type="default" className='mr-btn' onClick={() => setIsModalRejectOpen(false)}  >
            <AiOutlineClose className='icn-btn' />Cancel
          </Button>
          <Button type="primary" className='mr-btn'  onClick={() => handleReject()}>
            <AiOutlineCheck className='icn-btn' />OK
          </Button>
        </div>
      </Modal>
      {contextHolderMessage}
    </div>
  );

}

export default DestructionCartonView
