import { useEffect, useState } from 'react';
import {  Col, Modal, Row } from 'antd';
import { Helper } from '../../helpers';
import {  CartonName } from '../../models';
import CartonDetail from './CartonDetail';
import { CartonStatus } from '../../enum';

interface Props{
  cartons:CartonName[]
  statusId:number
  requestNo:string
  disableDetail?:boolean
}

const CartonList = (props: Props) => {
    const tempCartons:CartonName[][] = Helper.Chunk(props.cartons,5);
    const [isModalCartonDetailOpen, setIsModalCartonDetailOpen] = useState(false);
    const [box,setBox] = useState("")
    
    const handleCartonDetailCancel = () => {
      setIsModalCartonDetailOpen(false);
    }

    const getData = async (_box:string) => {
      console.log(_box)
      setBox(_box)
      setIsModalCartonDetailOpen(true);
    }



    useEffect(() => {
       
    }, [])

    return (
    <div>
        <h6>Carton No. ({props.cartons.length} cartons)</h6>
         { tempCartons.map( items => (
      <Row>
        { items.map( (item,index) => (
          <Col span={index < 4 ? 5 : 4} order={index + 1}>
            { props.disableDetail ?
                <span>{item.name}</span>
                :
                <a className='a-table' 
                    onClick={() => getData(item.name)} 
                    style={{color: props.statusId === CartonStatus.Done && (item.cartonStatusId === CartonStatus.Remove || item.cartonStatusId === CartonStatus.Destruction) ? 'red' : ''}}
                >
                    { item.name }
                </a>
            }
          </Col>
        ))
        }
      </Row>
        ))
     }
      <Modal title="" open={isModalCartonDetailOpen} footer={''} width={1200} onCancel={handleCartonDetailCancel}>
        <CartonDetail key={Helper.Uniqid()} requestNo={props.requestNo} box={box} />
      </Modal>
    </div>
  );

}

export default CartonList
