import  { useEffect, useState } from 'react';
import { Button, Col, Collapse, Form, Row, Select,Modal,Pagination,Spin } from 'antd';
import {  AiOutlineSearch, AiOutlineSync } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import {RequestType,CartonStatus ,Message } from '../../enum';
import { QueryDataModel ,Parameter ,Item, User} from '../../models'
import { Helper, InitHelper } from '../../helpers';
import { QueryService ,HrWebService} from '../../services';
import dayjs from 'dayjs'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import StorageCartonRequestDetail from '../StorageCarton/StorageCartonRequestDetail';
import RemoveCartonRequestDetail from '../RemoveCarton/RemoveCartonRequestDetail';
import DestructionCartonRequestDetail from '../DestructionCarton/DestructionCartonRequestDetail'
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
  currentPage:number
  perpage:number
  total: number
  totalPgae: number
  items:QueryDataModel[]
  isLoading:boolean
}

const ApprovalTracking = () => {
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }

    const { instance, accounts } = useMsal();
  const [dataForm] = Form.useForm();
  const { Panel } = Collapse;
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const initialRequestTypeIds:number[] = [
    RequestType.StorageCarton,
    RequestType.RemoveCarton,
    RequestType.RemoveCartonStorage,
    RequestType.ExtendCarton,
    RequestType.DestructionCarton
  ]
  const initialApproveStatusIds:number[] = [CartonStatus.Reject,CartonStatus.Approve]
  const requestTypeOptions = [
    { value: 0, label: 'All' },
    { value: RequestType.StorageCarton, label: 'Storage Carton' },
    { value: RequestType.RemoveCarton, label: 'Remove Carton' },
    { value: RequestType.DestructionCarton, label: 'Destruction Carton' },
  ]
  const StatusOption =[
    { value: 0, label: 'All'},
    { value: CartonStatus.Reject, label: Message.RejecteStatus},
    { value: CartonStatus.Approve, label: Message.ApproveStatus }
  ]
  const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
  const [recordNo, setRecordNo] = useState(0);
  const [requestTypeId,setRequestTypeId] = useState(0)
  const [requestTypeIds,setRequestTypeIds] = useState<number[]>(initialRequestTypeIds)
    const [approveStatusIds, setApproveStatusIds] = useState<number[]>(initialApproveStatusIds)
    const [modal, contextHolder] = Modal.useModal();
  const pageSizeOptions = user.systemConfig.tablePageSize

  const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter)
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    totalPgae : 0,
    perpage: 10,
    items: [],
    isLoading : true
  })
  const [owners, setOwners] = useState<Item[]>([])
  useEffect(() => {
    getData(1,appState.perpage,requestTypeIds,InitHelper.InitialParameter())

  }, [])


  const onFinish = (values: any) => {
  };


  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
 
  const showModalCarton = async (_recordNo:number,_requestTypeId: number) => {
    setRecordNo(_recordNo)
    setRequestTypeId(_requestTypeId)
    setIsModalCartonOpen(true);
  };
  const handleCancelCarton = () => {
    setIsModalCartonOpen(false);

  };

  const getData = async (currentPage: number,perpage: number,_requestTypeIds: number[] ,_parameter:Parameter | null = null) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)

    let parameter = Object.assign({},saveParameter)
    if(_parameter !== null){
        parameter = Object.assign({},_parameter);
    }
    
      parameter.requestTypeIds = _requestTypeIds
      parameter.page = currentPage
      parameter.limit = perpage
      parameter.approvers = [user.employeeId]
      parameter.approverCondition = user.employeeId
      parameter.approveStatusIds = approveStatusIds
      let token = await getToken()
      
      let result = await new QueryService(token).SearchApprover(parameter)
      if(result && result.success)
      {
        console.log(result)
        let {limit,total,items} = result.data
      
        let state = Object.assign({},appState)
          state.total = total
          state.currentPage = currentPage
          state.perpage = perpage
          state.totalPgae = Math.ceil(total / limit)
          state.items = items
          state.isLoading = false

          setTimeout(() => {
            setSaveParameter(parameter)
            setAppState(state)
          }, 500);
      }
      else{
        console.log(result)
        let state = Object.assign({},appState)
        state.isLoading = false
        setAppState(state)
      }
      
  }

  const searchData = async () => {
    let field = dataForm.getFieldsValue()
    console.log(field)
    let parameter = InitHelper.InitialParameter()
    parameter.requestTypeIds = requestTypeIds
    parameter.approveStatusIds = approveStatusIds
    
    if(owners.length > 0){
      parameter.owners = owners.map( item => item.key)
    }

    console.log(parameter)
    getData(1,appState.perpage,requestTypeIds,parameter)
    
 }

 const onSearchEmployee = async(key:string) => {
   
  let token = await getToken()
  let result = await new HrWebService(token).SearchEmployee(key)
  if(result && result.success)
  {
    return result.data
  }
  else{
    console.log(result?.message)
    return []
  }
}


    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

  
  const onPageChange = (page:number,perpage:number) => {
    if(page !== appState.currentPage && perpage === appState.perpage){
        getData(page,appState.perpage,requestTypeIds)
    }

    if(page === appState.currentPage && perpage !== appState.perpage){
        getData(1,perpage,requestTypeIds)
    }
 }

 const onRequestTypeChange = (_requestTypeId: number) => {
  if(_requestTypeId === 0){
    setRequestTypeIds(initialRequestTypeIds)
  }
  else{
    if(_requestTypeId == RequestType.RemoveCarton){
        setRequestTypeIds([RequestType.RemoveCarton,RequestType.RemoveCartonStorage])
    }   
    else if(_requestTypeId == RequestType.DestructionCarton){
        setRequestTypeIds([RequestType.DestructionCarton,RequestType.ExtendCarton])
    }
    else{
        setRequestTypeIds([_requestTypeId])
    } 
    
  }
 }

const onStatusChange = (approveStatusId: number) => {
  if(approveStatusId === 0){
    setApproveStatusIds(initialApproveStatusIds)
  }
  else{
    setApproveStatusIds([approveStatusId])
  }
  
};

const onOwnerSelect = (item:any) => {
  setOwners(item)
}

const reset = () => {
   dataForm.resetFields()
   setRequestTypeIds(initialRequestTypeIds)
    setApproveStatusIds(initialApproveStatusIds)
    getData(1, appState.perpage, initialRequestTypeIds, InitHelper.InitialParameter())
}
  return (

    <div>
      <Collapse>
        {/*isLoadingPrint?<Loading/>:<></>*/}
        <Panel header="Search" key="1">
          <Form
            {...layout}
            form={dataForm}
            name="search-panel"
            onFinish={onFinish}
            validateMessages={validateMessages}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
              <Col md={11} >
                <Form.Item name="requestType" label="Request Type"  >
                      <Select 
                        defaultValue={0}
                        key = {Helper.Uniqid()}
                        onChange={onRequestTypeChange}
                        options={ requestTypeOptions }
                      />
                </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                    <Form.Item name="owner" label="Owner"  >
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={owners ? owners : []}
                            onChange={item => onOwnerSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                        />
                    </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                  <Form.Item name="approveStatusId" label="Status">
                        <Select 
                          defaultValue={0}
                          allowClear
                          placeholder={MessageCommon.TitleSelect}
                          options={StatusOption}
                          onChange={onStatusChange}
                        />
                      </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
               
              </Col>
            </Row>



          </Form>
          <div style={{ textAlign: 'center' }}>
            <Button type="default" className='mr-btn'  onClick={() => reset()}  disabled={appState.isLoading}>
              <AiOutlineSync className='icn-btn' /> Reset
            </Button>
            <Button type="primary"    onClick={() => searchData()} disabled={appState.isLoading}>
              <AiOutlineSearch className='icn-btn' /> Search
            </Button>
          </div>

        </Panel>
      </Collapse>


      <br></br>
      <Collapse defaultActiveKey={['1']}>
            <Panel header="Data Result" key="1" >
              <div id="panel-action" style={{paddingTop: '8px'}}>
                <div>
                  <div className='search-result' style={{ float: 'left'}}>Search found { appState.total } record(s).</div>
                  <div style={{float: 'right'}}>
                     
                  </div>
                </div>
              </div>

        <div id="table-container">
        { appState.isLoading ?
            <div style={{ margin: 'auto',paddingBottom: '30px'}}>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
          :
          <table className='idoc-table'>
            <thead id="head-table-fixed">
              <tr>
                <th >Request No.</th>
                <th >Requester</th>
                <th >Owner</th>
                <th >Org. Unit</th>
                <th >Requested Date</th>
                <th >Carton Quantity</th>
                <th >Request Type</th>
                <th >Status</th>
              </tr>
            </thead>
           
            <tbody >
            { appState.items.map((item:QueryDataModel) => (
                <tr>
                  <td style={{textAlign: 'center'}}><a className='a-table'onClick={() => showModalCarton(item.recordNo,item.requestTypeId)} >{item.requestNo}</a></td>
                  <td style={{textAlign: 'center'}}>{item.requesterName}</td>
                  <td style={{textAlign: 'center'}}>{item.ownerName}</td>
                  <td style={{textAlign: 'center'}}>{item.orgUnit}</td>
                  <td style={{textAlign: 'center'}}>{ item.requestDate ? dayjs(item.requestDate).format("DD MMM YYYY") : ""}</td>
                  <td style={{textAlign: 'center'}}>{item.quantity}</td>
                  <td style={{textAlign: 'center'}}>{item.requestType}</td>
                  <td style={{textAlign: 'center'}}>{item.approveStatus}</td>
                </tr>
              ))
            }
            
            </tbody>
  
          </table>
        }
        </div>
        <div style={{marginTop:'10px',textAlign:'right'}}>
            { appState.total > 0 ?
            <Pagination 
                key={Helper.Uniqid()}
                current={appState.currentPage} 
                pageSize={appState.perpage}
                total={appState.total} 
                onChange={onPageChange} 
                showSizeChanger={true}
                pageSizeOptions={pageSizeOptions}
              />:<></>
            }
        </div>

        </Panel>
      </Collapse>
      
      <Modal title="Request Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton}>
        
        { requestTypeId === RequestType.StorageCarton ?
                  <StorageCartonRequestDetail key={Helper.Uniqid()} recordNo={recordNo}
                      cartonNumber={""} approveLevel={0}/>:<></>
        }
        { requestTypeId === RequestType.RemoveCarton || requestTypeId === RequestType.RemoveCartonStorage ?
            <RemoveCartonRequestDetail key={Helper.Uniqid()} recordNo={recordNo} approveLevel={0}/>:<></>
        }
        { requestTypeId === RequestType.ExtendCarton || requestTypeId === RequestType.DestructionCarton ?
                  <DestructionCartonRequestDetail key={Helper.Uniqid()} recordNo={recordNo} cartonNumber={""} approveLevel={0} />:<></>
        }
              {modal ? <span></span> : <></>}
        </Modal>
        <input type="hidden" id="modal-action"/>
      {contextHolder}

    </div>
  );

}

export default ApprovalTracking
