import { useEffect, useState } from 'react';
import { Collapse, Form, message,Modal, Typography,Pagination,Spin,Row,Col,Popconfirm,Input,DatePicker,Button } from 'antd';
import {  CloseCircleOutlined } from '@ant-design/icons';
import secureLocalStorage  from  "react-secure-storage";
import { CartonStatus,UserRole } from '../../enum';
import { AiFillEdit, AiOutlineSearch, AiOutlineSync } from 'react-icons/ai';
import { Label } from 'reactstrap';
import { QueryDataModel ,Parameter ,Item, User,RequestChangeOwner} from '../../models'
import { Helper, InitHelper } from '../../helpers';
import { StorageCartonService ,HrWebService,ChangeOwnerService, PropertyService} from '../../services';
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import MessageCommon from '../../enum/Message';
import StorageCartonRequestDetail from '../StorageCarton/StorageCartonRequestDetail';
import { useNavigate } from 'react-router-dom';
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
  currentPage:number
  perpage:number
  total: number
  items:QueryDataModel[]
  isLoading:boolean
}

const ChangeOwnerRequest = () => {
    const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const pageSizeOptions = user.systemConfig.tablePageSize
  const [dataForm] = Form.useForm();
  const { Panel } = Collapse;
  const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
  const [recordNo, setRecordNo] = useState(0);
  const [messageApi] = message.useMessage();
  const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter)
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    perpage: 10,
    items: [],
    isLoading : true
  })
  const [requesters, setRequesters] = useState<Item[]>([])
  const [owners, setOwners] = useState<Item[]>([])
  const [newOwners, setNewOwners] = useState<Item[]>([])
  const [requestStartDate, setRequestStartDate] = useState<Date|null>(null);
  const [requestEndDate, setRequestEndDate] =  useState<Date|null>(null);
  const [orgunits, setOrgUnits] = useState<Item[]>([])

  useEffect(() => {
    initial()

  }, [])

  const initial = () => {
    let parameter = Object.assign({},InitHelper.InitialParameter())
    parameter.condition = "or"
    parameter.requesters = [user.employeeId]
    parameter.owners = [user.employeeId]
    parameter.employeeLevel = 0
    getData(1,appState.perpage,parameter)
  }

  const handleEdit = (_recordNo:number) => {
    setRecordNo(_recordNo)
 }
  const onFinish = (values: any) => {
  };


  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };


  const getData = async (currentPage: number,perpage: number,_parameter:Parameter | null = null) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)
    let parameter = Object.assign({},saveParameter)
    if(_parameter !== null){
        parameter = Object.assign({},_parameter);
    }
       
      parameter.page = currentPage
      parameter.limit = perpage
      parameter.statusIds = [CartonStatus.Done]
      parameter.userConfidential = user.employeeId
      parameter.requesterOrgUnit = user.orgUnit
      
      console.log(parameter)
      let token = await getToken()
      let result = await new StorageCartonService(token).SearchChangeOwner(parameter)
      console.log(result)
      if(result && result.success)
      {
    
        let state = Object.assign({},appState)
          state.total = result.data.total
          state.currentPage = currentPage
          state.perpage = perpage
          state.items = result.data.items
          state.isLoading = false
          state.perpage = perpage

        setTimeout(() => {
            setSaveParameter(parameter)
            setAppState(state)
        }, 500);
      }
      else{
        let state = Object.assign({},appState)
        state.isLoading = false
        setTimeout(() => {
            setAppState(state)
          }, 500);
      }
      
  }

  const searchData = async () => {
    console.log('searchdata')
    let field = dataForm.getFieldsValue()
    console.log(field)
    let parameter = InitHelper.InitialParameter();
        parameter.condition = 'and'
        if(field.requestNo)
        {
          parameter.requestNoList = field.requestNo.trim().split(",")
        }
       
    if(requestStartDate && requestEndDate){
        parameter.requestStartDate = requestStartDate
        parameter.requestEndDate = requestEndDate
    }

    if(requesters.length > 0){
      parameter.requesters = requesters.map(item => item.key)
    }

    if(owners.length > 0){
      parameter.owners = owners.map(item => item.key)
    }

    if(orgunits.length > 0){
        parameter.orgUnits = orgunits.map(item => item.key)
      }
    
    parameter.employeeLevel = user.level
    console.log(parameter)
    getData(1,appState.perpage,parameter)
    
 }

 const onSearchEmployee = async(key:string) => {
   
    let token = await getToken()
    let result = await new HrWebService(token).SearchEmployee(key)
    if(result && result.success)
    {
        let _orgUnit = user.orgUnit
            if(user.orgUnit.indexOf("/") > -1) {
                _orgUnit = user.orgUnit.split("/")[0]
            }
            return result.data.filter(x => x.orgUnit.indexOf(_orgUnit) > -1)
    }
    else{
      console.log(result?.message)
      return []
    }
  }

 const onSearchEmployee2 = async(key:string) => {
   
  let token = await getToken()
  let result = await new HrWebService(token).SearchEmployee2(key)
  if(result && result.success)
  {
    let _orgUnit = user.orgUnit
            if(user.orgUnit.indexOf("/") > -1) {
                _orgUnit = user.orgUnit.split("/")[0]
            }
            return result.data.filter(x => x.orgUnit.indexOf(_orgUnit) > -1)
  }
  else{
    console.log(result?.message)
    return []
  }
}

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const handleSave = async () => {
    if(newOwners.length === 0){
      return false
    }
    
      let requestChangeOwner: RequestChangeOwner = {
          recordNo: 0,
          recordNos: [],
        storageRecordNo: recordNo,
        requester:user.employeeId,
        onBehalfBy:"",
        newOwner: newOwners[0].key,
        statusId:CartonStatus.WaitAdmin
     }
      console.log(requestChangeOwner)
      let token = await getToken()
      let result = await new ChangeOwnerService(token).CreateChangeOwner(requestChangeOwner);
      
      console.log(result)
      let element = document.getElementById('table-container') as HTMLElement
      if(result && result.success){
  
        messageApi.open({
          type: 'success',
          content: 'Save success',
          duration: 1,
          style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
        });
        setTimeout(() => {
            navigate('/request-change-owner-2')
        
        }, 1000);
     }
      else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
      }
    
   
  };

  const cancelSave = () => {
    setRecordNo(0)
    setNewOwners([])
  }
  const onRequestStartDateChange = (date: any) => {
    setRequestStartDate(date);
  }

  const onRequestEndDateChange = (date: any) => {
    setRequestEndDate(date)
  }

  const onPageChange = (page:number,perpage:number) => {
    if(page !== appState.currentPage && perpage === appState.perpage){
        getData(page,appState.perpage)
    }

    if(page === appState.currentPage && perpage !== appState.perpage){
        getData(1,perpage)
    }
  }

  const onRequesterSelect = (item:any) => {
    setRequesters(item)
  }

  const onOwnerSelect = (item:any) => {
    setOwners(item)
  }

  const onOrgUnitSelect = (item:any) => {
    setOrgUnits(item)
  }

  const onNewOwnerSelect = (item:any) => {
    setNewOwners(item)
  }

  const onSearchOrgUnit = async(key:string) => {
   
    let token = await getToken()
    let result = await new PropertyService(token).GetOrgUnits(key)
    if(result && result.success)
    {
      return result.data
    }
    else{
      console.log(result?.message)
      return []
    }
}
  const reset = () => {
   
    dataForm.resetFields()
    setRequesters([])
    setOwners([])
    setOrgUnits([])
    setRequestStartDate(null)
    setRequestEndDate(null)
    let parameter = Object.assign({},InitHelper.InitialParameter())
    parameter.condition = "or"
    parameter.requesters = [user.employeeId]
    parameter.owners = [user.employeeId]

    getData(1,appState.perpage,parameter)
  }

  const showModalCarton = async (_recordNo:number) => {
    setRecordNo(_recordNo)
    setIsModalCartonOpen(true);
  };

  const handleCancelCarton = () => {
    setRecordNo(0)
    setIsModalCartonOpen(false);
  };

  return (
    <div>
     <Collapse>
        <Panel header="Search" key="1">
          <Form
            {...layout}
            form={dataForm}
            name="change-owner-form"
            onFinish={onFinish}
            validateMessages={validateMessages}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
              <Col md={11} >
                <Form.Item name="owner" label="Owner"  >
                    <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={owners ? owners : []}
                            onChange={item => onOwnerSelect(item)}
                            service={onSearchEmployee2}
                            minimumTextLength={3}
                        />
                </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
              <Form.Item name="requester" label="Requester"  >
                    <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={requesters ? requesters : []}
                            onChange={item => onRequesterSelect(item)}
                            service={onSearchEmployee2}
                            minimumTextLength={3}
                        />
                </Form.Item>
                
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                <Form.Item name="orgUnit" label="Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={orgunits ? orgunits : []}
                            onChange={item => onOrgUnitSelect(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                <Form.Item name="requestNo" label="Request No."  >
                  <Input placeholder="ex. A230001,B230002,B230004" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                <Form.Item name="requestDate" label="Request Date">
                      <table style={{width : '100%'}}>
                        <tr>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate}  format={'DD MMM YYYY'} className='date-picker-full' onChange={onRequestStartDateChange}/>
                          </td>
                          <td>
                            <Label style={{ marginLeft: 10, marginRight: 10 }}>To</Label>
                          </td>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate}  format={'DD MMM YYYY'} className='date-picker-full' onChange={onRequestEndDateChange}/>
                          </td>
                        </tr>
                      </table>
                </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >

              </Col>
            </Row>
           

          </Form>
          <div style={{ textAlign: 'center' }}>
            <Button type="default" className='mr-btn'  onClick={reset}>
              <AiOutlineSync className='icn-btn' /> Reset
            </Button>
            <Button type="primary"  onClick={searchData} >
              <AiOutlineSearch className='icn-btn' /> Search
            </Button>


          </div>

        </Panel>
      </Collapse>


      <br></br>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Data Result" key="1" >
        <div id="table-container">
        { appState.isLoading ?
            <div style={{ margin: 'auto',paddingBottom: '30px'}}>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
          :
          <table className='idoc-table'>
            <thead id="head-table-fixed">
              <tr>
                <th >Request No.</th>
                <th >Org. Unit</th>
                <th >Status</th>
                <th >Current Owner</th>
                <th >New Owner</th>
                <th >Request Change Owner</th>
              </tr>
            </thead>
           
            <tbody >
            { appState.items.map((item:QueryDataModel) => (
                <tr>
                  <td style={{textAlign: 'center'}}><a className='a-table'onClick={() => showModalCarton(item.recordNo)} >{item.requestNo}</a></td>
                  <td style={{textAlign: 'center'}}>{item.orgUnit}</td>
                  <td style={{textAlign: 'center'}}>{item.status}</td>
                  <td style={{textAlign: 'center'}}>{item.ownerName}</td>
                  <td style={{textAlign: 'center'}}>
                    { item.recordNo === recordNo? 
                      <EmployeeAutoComplete
                        key={Helper.Uniqid()}
                        placeholder='Search by Name'
                        selectedItems={newOwners ? newOwners : []}
                        onChange={item => onNewOwnerSelect(item)}
                        service={onSearchEmployee}
                        minimumTextLength={3}
                        pickOnlyOne
                      /> : <></> }
                  </td>
                  <td style={{textAlign: 'center'}}>
                    { item.recordNo === recordNo ?
                      <div>
                        <span>
                          <Popconfirm title="Confirm to update?" onConfirm={handleSave}>
                            <Typography.Link>Update</Typography.Link>
                          </Popconfirm>
                          <a style={{ marginLeft: 8 }} onClick={cancelSave}>
                            Cancel
                          </a>
                        </span>
                      </div>:
                      <AiFillEdit size={20} onClick={() => handleEdit(item.recordNo)} style={{ cursor: 'pointer'}}/>
                    }
                  </td>
                </tr>
              ))
            }
            
            </tbody>
  
          </table>
        }
        </div>
        <div style={{marginTop:'10px',textAlign:'right'}}>
        { appState.total > 0 ?
            <Pagination 
                key={Helper.Uniqid()}
                current={appState.currentPage} 
                pageSize={appState.perpage}
                total={appState.total} 
                onChange={onPageChange} 
                pageSizeOptions={pageSizeOptions}
                showSizeChanger={true}
              />:<></>
        }
        </div>
        </Panel>
      </Collapse>
    
      <Modal title="Request Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton}>
            <StorageCartonRequestDetail 
              key={Helper.Uniqid()}
                  recordNo={recordNo} cartonNumber={""}
              approveLevel={0}
            />
        </Modal>
    </div>
  );

}

export default ChangeOwnerRequest
