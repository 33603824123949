import { useEffect, useState } from 'react';
import { AiOutlinePlusSquare,AiOutlineMinusSquare } from 'react-icons/ai';
import { Helper } from '../../helpers';
import { Color } from '../../enum'
import { Carton } from '../../models';
import { StorageCartonService,FileManageService } from '../../services';
import dayjs from 'dayjs'
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { Button, Col, Form, Modal, Spin, Row, Popconfirm, Radio, Card, message } from 'antd';
import { InitHelper } from '../../helpers';
import { Label } from 'reactstrap';
import { RequestDetail, } from '../../models';
import { CartonStatus  } from '../../enum';
import DisplayLogLocation from '../Share/DisplayLogLocation';

interface Props{
  requestNo:string
  box:string
  expectReturnDate?:string
}

const CartonDetail = (props: Props) => {
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }



    const layout = {
        labelCol: { lg: 6 },
        wrapperCol: { lg: 18 }
    };

    const [dataForm] = Form.useForm();
    const { instance, accounts } = useMsal();

    const [cartonDetails, setCartonDetails] = useState<Carton[]>([])
    const [itemShowAll,setItemShowAll] = useState(false)
    const [itemShow,setItemShow] = useState<number[]>([])
    const [valveRoom,setValveRoom] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [modal, contextHolder] = Modal.useModal();
    const [detail, setDetail] = useState<RequestDetail>(InitHelper.InitialRequestDetail)
    const [isModalLogLocationOpen, setIsModalLogLocationOpen] = useState(false);

    const onItemShowChange = (index:number,status:number) => {
        let _itemShow:number[] = Object.assign([],itemShow)
        _itemShow[index] = status
        setItemShow(_itemShow)
    }
    
    const download = async (_requestNo:string,filename:string,guid:string) => {
      console.log(_requestNo)
      let token = await getToken()
      new FileManageService(token).downloadStorageFile(_requestNo,filename,guid);
  }

    const getData = async () => {
        console.log('getData')
        setIsLoading(true)
        let token = await getToken()
      let result = await new StorageCartonService(token).GetCartonDetail(props.requestNo, props.box)
      console.log(result)
      if(result && result.success){
        let _itemShow:number[] = []
        let length = result.data.length
        for(let i =0 ; i< length ; i++){
          _itemShow[i] = 0
          }

          let result2 = await new StorageCartonService(token).GetDetail(result.data[0].referenceRecNo)
          console.log(result2)
          if (result2 && result2.success) {
              setDetail(result2.data)
          }


        setItemShow(_itemShow)
        setCartonDetails(result.data)
        if(length > 0){
          setValveRoom(result.data[0].valveRoom)
        }
      }
      
      setIsLoading(false)
    }

    const DisplayCondition = (detail: RequestDetail) => {
        return detail.statusId === CartonStatus.Done && detail.cartonLocation != null

    };

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


    useEffect(() => {
        console.log('getData')
       getData()
    }, [])
    return (
        <div>
            <Form
                {...layout}
                form={dataForm}
                name="request-detail-form"
                labelAlign="left"
                colon={false}
                labelWrap={true}
            >
                {cartonDetails.length > 0 ? <>
                <Row>
                    <Col md={11} >
                        <Form.Item name="requestNo" label="Carton No"  >
                                <Label className={cartonDetails[0].cartonStatusId === CartonStatus.Remove || cartonDetails[0].cartonStatusId === CartonStatus.Destruction ? 'label-det c-red' : 'label-det'} >{cartonDetails[0].cartonName}</Label>
                        </Form.Item>
                    </Col>
                    <Col md={2} >

                    </Col>
                    <Col md={11} >
                        <Form.Item name="requester" label="Requester"  >
                                <Label className='label-det'>{detail.requesterName}</Label>

                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col md={11} >
                        <Form.Item name="owner" label="Owner"  >
                                <Label className='label-det'>{detail.ownerName}</Label>
                        </Form.Item>

                    </Col>
                    <Col md={2} >

                    </Col>
                    <Col md={11} >
                            <Form.Item name="org_unit" label="Org. Unit"  >
                                <Label className='label-det'>{detail.ownerOrgUnit}</Label>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col md={11} >
                        <Form.Item name="document_year" label="Confidentiality Level" >
                            <Label className='label-det'>{detail.confidentialityLevel === 0 ? 'Non-Confident' : 'Confidential'}</Label>
                        </Form.Item>
                    </Col>
                    <Col md={2} >

                    </Col>
                    <Col md={11} >
                        <Form.Item name="project" label="Project Name"  >
                            <Label className='label-det'>{detail.projectName}</Label>
                        </Form.Item>

                    </Col>
                </Row>
                <Row>
                    <Col md={11} >
                        <Form.Item name="location" label="Carton Location"  >
                        <Label className='label-det'>{ detail.statusId === CartonStatus.Done ? detail.cartonLocation : "" }</Label>
                                  {DisplayCondition(detail) ?
                            <a className='a-table ml-label' onClick={() => setIsModalLogLocationOpen(true)} >Display Log</a>:<></>
                        }
                        </Form.Item>
                       

                    </Col>
                    <Col md={2} >
                       
                    </Col>
                    <Col md={11} >
                    <Form.Item name="document_year" label="Document Year" >
                            <Label className='label-det'>{dayjs(detail.documentYear).format('MMM YYYY')}</Label>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col md={11} >
                        <Form.Item name="total_storage_year" label="Total Storage Year" >
                            <Label className='label-det'>{detail.totalStorageYear} year(s)</Label>
                        </Form.Item>
                    {props.expectReturnDate ?
                        <Form.Item name="expect_return_date" label="Expect Return Date" >
                            <Label className='label-det'>{props.expectReturnDate}</Label>
                        </Form.Item>:<></>
                    }
                    </Col>
                    <Col md={2} >

                    </Col>
                    <Col md={11} >
                        <Form.Item name="destruction_due_date_month" label="Destruction Due Date" >
                            <Label className='label-det'>{cartonDetails.length > 0 ? cartonDetails[0].expireDate : ""}</Label>
                        </Form.Item>
                    </Col>
                </Row>
                </> : <></>}

            </Form>

       <Card >
          {isLoading ?
            <Spin tip="Loading" size="large">
            <div className="content" />
            </Spin>
          :
            <div id="table-container">
            <table className='idoc-table'>
              <thead id="head-table-fixed">
                <tr>
                  { valveRoom ?
                  <th>
                  { itemShowAll ? <AiOutlineMinusSquare size={15} color={Color.Blue} cursor="pointer"  onClick={() => setItemShowAll(false)}/>
                    :
                    <AiOutlinePlusSquare size={15} color={Color.Blue} cursor="pointer" onClick={() => setItemShowAll(true)}/>
                  }
                  </th>:<></>
                  }
                  <th >Item No.</th>
                  <th >User Reference No.</th>
                  <th >Description</th>
                  <th >Document Start Year</th>
                  <th >Document End Year</th>
                  <th >File</th>
                  <th >Remark</th>
                </tr>
              </thead>
              <tbody >
              {
                cartonDetails.map( (item,index) => (
                <>
                  <tr key={Helper.Uniqid()}>
                    { valveRoom ?
                    <td>
                      { itemShowAll || itemShow[index] ? <AiOutlineMinusSquare size={15} color={Color.Blue} cursor="pointer" onClick={() => onItemShowChange(index,0)}/>
                        :
                        <AiOutlinePlusSquare size={15} color={Color.Blue} cursor="pointer" onClick={() => onItemShowChange(index,1)}/>
                      }
                    </td>:<></>
                  }
                    <td style={{textAlign: 'center'}}>{ index + 1}</td>
                    <td style={{textAlign: 'center'}}> { item.userReferenceNo }</td>
                    <td style={{textAlign: 'left',wordBreak: 'break-word'}}>
                        { item.description }
                    </td>
                    <td style={{textAlign: 'center'}}>{ item.documentStartYear }</td>
                    <td style={{textAlign: 'center'}}>{ item.documentEndYear }</td>
                    <td style={{textAlign: 'left'}}>
                      { 
                        item.files.map((file) => 
                          (<div><a className='a-table' onClick={() => download("B" + item.referenceRecNo, file.name,file.guid)}>{file.name}</a></div>)
                      )}
                    </td>
                    <td style={{textAlign: 'center'}}>{ item.remark }</td>
                  </tr>
                    { valveRoom ?
                      <>
                      { itemShowAll || itemShow[index] ?
                        <tr key={Helper.Uniqid()}>
                        <td></td>
                        <td colSpan={7}>
                          <table style={{ width:'100%'}}>
                            <tr>
                              <th >Document Checking Date</th>
                              <th >Partner Of Contract</th>
                              <th >Category</th>
                              <th >Language</th>
                              <th >Number Of Original</th>
                              <th >Reference DDOK No.</th>
                              <th >Originator</th>
                              <th >Business Unit</th>
                            </tr>
                            <tr>
                                                    <td style={{ textAlign: 'center' }}>{returnIfElse(item.documentCheckingDate, dayjs(item.documentCheckingDate).format('DD MMM YYYY'), ""  )}</td>
                              <td style={{textAlign: 'center'}}>{ item.partnerOfContract }</td>
                              <td style={{textAlign: 'center'}}>{ item.category }</td>
                              <td style={{textAlign: 'center'}}>{ item.language }</td>
                              <td style={{textAlign: 'center'}}>{ item.numberOfOriginal }</td>
                              <td style={{textAlign: 'center'}}>{ item.referenceDDOKNo }</td>
                              <td style={{textAlign: 'center'}}>{ item.originator }</td>
                              <td style={{textAlign: 'center'}}>{ item.businessUnit }</td>
                            </tr>
                          </table>
                        </td>
                        </tr>:<></>
                      }
                      </>:<></>
                    }
                </>
                ))
              }
                 
              </tbody>
            </table>
            </div>
          }
        </Card>
        <Modal title="Display Log" open={isModalLogLocationOpen} footer={''} width={900} onCancel={() => setIsModalLogLocationOpen(false)}>
        <DisplayLogLocation recordNo={detail.recordNo} />
      </Modal>

      {contextHolder}
    </div>
  );

}

export default CartonDetail
