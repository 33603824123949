import { useEffect, useState } from 'react';
import { Button, Card, Col,message, Form, Input, Modal, Radio,Popconfirm,Spin ,Row,Pagination,DatePicker  } from 'antd';
import {  AiFillSave, AiOutlineArrowLeft, AiOutlineCheck,AiFillEye, AiOutlineClose, AiOutlineMessage, AiOutlinePrinter } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import { Label } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { CloseCircleOutlined,QuestionCircleOutlined } from '@ant-design/icons';
import {CartonStatus,ExportType,PageOption,RequestType }  from '../../enum';
import { RequestDetail,User ,UpdateRemoveStorage,CartonList} from '../../models';
import { FileManageService, RemoveCartonService } from '../../services';
import { InitHelper, Helper } from '../../helpers';
import secureLocalStorage  from  "react-secure-storage";
import CartonDetail from '../Share/CartonDetail';
import DisplayLogNote from '../Share/DisplayLogNote';
import Loading from '../../components/Loading';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import dayjs from 'dayjs';

interface Props{
    id:string
    onBack:any
}

const QueryDataRemoveCarton = (props:Props) => {
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }
    const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const pid:string| null| undefined = urlParams.get('id')
  const id = props.id ? props.id : pid
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const currentDate = secureLocalStorage.getItem('date')
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [isModalRejectOpen,setIsModalRejectOpen] = useState(false)
  const [allCartonItems,setAllCartonItems] = useState<CartonList[]>([])
  const [cartonItems,setCartonItems] = useState<CartonList[][]>([])
  const [dataForm] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [detail, setDetail] = useState<RequestDetail>(InitHelper.InitialRequestDetail())
  const [isModalCartonDetailOpen, setIsModalCartonDetailOpen] = useState(false);
  const [requestNo,setRequestNo] = useState("")
  const [box,setBox] = useState("")
  const [width,setWidth] = useState(0)
  const [page,setPage] = useState(1)
  const [perpage ,setPerpage] = useState(20);
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingPrint,setIsLoadingPrint] = useState(false)
  const [isErrorNote,setIsErrorNote] = useState(false)
  const onFinish = (values: any) => {
    console.log('Success:', values);
  };



  const onFinishPrint = async (values:any) => {
    setIsLoadingPrint(true)

    let token = await getToken()
    try{

      await new FileManageService(token).DownloadRequestRemove(detail,values.print);
    }catch(error){
      console.log("🚀 ~ file: QueryDataRemoveCarton.tsx:59 ~ onFinishPrint ~ error:", error)
      
    }finally{
      setIsLoadingPrint(false)

    }

  }

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
  
  const getData = async () => {
    if(id)
    {
      setIsLoading(true)
      let token = await getToken()
      let recordNo = parseFloat(id)
      let result = await new RemoveCartonService(token).GetCollectDetail(recordNo)
        console.log(result)
       
        if(result && result.success){
          setDetail(result.data)
          let date = currentDate ? currentDate.toString() : new Date().toDateString()
          dataForm.setFieldsValue({
              serviceDate: result.data.serviceDate ? dayjs(result.data.serviceDate) : Helper.add3Days(date)
            })

          let items = Helper.Chunk(result.data.cartonList,perpage)
          setAllCartonItems(result.data.cartonList)
          setCartonItems(items)
          setPage(1)
         
        }
        setIsLoading(false)
      
    }
    
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  useEffect(() => {
    if(user.permissions.queryData === 0){
        navigate('/permissiondenided')
    }

    getData()

  }, [])

 

  const handlePrintModalCancel = () => {
    setIsPrintModalOpen(false);
  };
  
  const handleNoteModalCancel = () => {
    setIsNoteModalOpen(false);
  };

  const showNoteModal = () => {
    setIsNoteModalOpen(true);
  };

  const update = async (statusId: number,noteReject:string| null = null) => {
    
    let noteElement = document.getElementById('note') as HTMLInputElement
    let note = ""
    if(noteElement && noteElement.value){
        note = noteElement.value
    }

    let requestCarton:UpdateRemoveStorage = {
      recordNo: detail.recordNo,
      note: note,
      noteReject: noteReject ? noteReject : "",
      requestTypeId:RequestType.RemoveCarton,
      serviceDate: dataForm.getFieldValue('serviceDate') !== undefined ? dataForm.getFieldValue('serviceDate').format('YYYY-MM-DD') : null,
      statusId:statusId,
      updateBy:user.employeeId,
      updateDate: new Date()
    }
    console.log(requestCarton)
    let token = await getToken()
    let result = await new RemoveCartonService(token).UpdateAdmin(requestCarton);
    console.log(result)
    if(result && result.success){
        messageApi.open({
          type: 'success',
          content: 'Save success',
          duration: 1,
          style: {marginTop:200}
        });

        setTimeout(() => {
          if(statusId === 0 || statusId === CartonStatus.WaitAdmin || statusId === CartonStatus.Inprogress){
            getData()
          }
          else{
            props.onBack(true)
          }
        }, 1000);
        
    }
    else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
    }
  }

  

  const onPageChange = (_page:number,_perpage: number) => {
    if(_page !== page){
      setPage(_page)
    }
    if(_perpage !== perpage)
    {
      let items = Helper.Chunk(allCartonItems,_perpage)
      setCartonItems(items)
      setPerpage(_perpage)
    }
  }

  const handleCartonDetailCancel = () => {
    setIsModalCartonDetailOpen(false);
  }

  const handleCartonDetailOpen = async (_requestNo:string, _box:string) => {
    setRequestNo(_requestNo)
    setBox(_box)
    setIsModalCartonDetailOpen(true);
  }

  const prevStep = () => {
    props.onBack(false)
}
  const showPrintModal = () => {

    setIsPrintModalOpen(true);
  };

  const disabledDate = (value:any) => {
    let date = currentDate ? currentDate.toString() : new Date().toString()
    return Helper.disabledDate(value,date)
  }

  const handleRejectOpen = () => {
    setIsErrorNote(false)
    setIsModalRejectOpen(true)
    setTimeout(() => {
        let element = document.getElementById('note-reject') as HTMLTextAreaElement
        element.value = ""
    }, 100);
   
 }

 const handleReject = () => {
    let element = document.getElementById('note-reject') as HTMLInputElement
    if(element && element.value){
      update(CartonStatus.Reject,element.value)
    }
    else{
      setIsErrorNote(true)
      return false
    }
    
  }

  const handleCancelReject = () => {
    let element = document.getElementById('note-reject') as HTMLInputElement
     element.value = ""
     setIsModalRejectOpen(false)
  }

  const onExport = async () => {
    setIsLoadingPrint(true)

    let token = await getToken()
    if(!id)return
    let recordNo = parseFloat(id)
    try{
      
      let result = await new RemoveCartonService(token).GetDetail(recordNo)
      if(result){
        console.log("🚀 ~ file: QueryDataDestructionCarton.tsx:313 ~ onExport ~ result:", result)
        await new FileManageService(token).DownloadReport(result.data.cartonList,ExportType.EXPORT_REMOVE_LIST,recordNo);
      }
    }catch(error){
    console.log("🚀 ~ file: QueryData.tsx:464 ~ onFinishPrint ~ error:", error)

    }finally{
      setIsLoadingPrint(false)

    }
  }
 
  return (

    <div>
      <Card title="Remove Carton Detail">
      {isLoadingPrint?<Loading/>:<></>}
        <Form
          {...layout}
          form={dataForm}
          name="remove-form"
          onFinish={onFinish}
          validateMessages={validateMessages}
          labelAlign="left"
          colon={false}
          labelWrap={true}
        >
          <Row>
            <Col md={22} >
              <Form.Item name="owner" label="Requester" labelCol={{ md: 3 }}  >

              <Label className='label-det'>{ detail.requesterName }</Label>
                <Label className='mr-label' >Org. Unit</Label>
                <Label className='label-det'>{ detail.orgUnit }</Label>
                <Label className='mr-label' >Position</Label>
                <Label className='label-det'>{ detail.position }</Label>
                <Label className='mr-label' >Tel No.</Label>
                <Label className='label-det'>{ detail.tel }</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
              <Form.Item name="requestNo" label="Request No"  >
                <Label className='label-det'>{detail.requestNo}</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item name="status" label="Status"  >
                <Label className='label-det'>{detail.status}</Label>
              </Form.Item>
            </Col>
          </Row>


          <Row>
            <Col md={11} >
              <Form.Item name="quantity" label="Carton Quantity"  >
                <Label className='label-det'>{detail.quantity} carton(s)</Label>
              </Form.Item>
              <Form.Item label="Request Date"  >
                <Label className='label-det'>{ dayjs(detail.requestDate).format('DD MMM YYYY') }</Label>
              </Form.Item>
              <Form.Item name="last_note" label="Lasted Note"  >
              { detail.note.length > 0 ?
                <>
                    <Label className='label-det'>{detail.note[ detail.note.length - 1 ]}</Label>
                    <a className='a-table ml-label' onClick={showNoteModal}>Display Log</a>
                </>:<></>
                }

              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
            <Form.Item name="Verifier" label={ detail.requestDate && parseInt(dayjs(detail.requestDate).format("YYYYMM")) >= 202310 ? "Verifier" : "Approver"}  >
                <Label className='label-det '>{  detail.approverName } </Label>
              </Form.Item>
            
            {detail.approverDate2 != null ?
              <Form.Item name="approver" label="Approver"  >
                <Label className='label-det '>{  detail.approverName2 } </Label>
              </Form.Item>:<></>
            }
             <Form.Item name="serviceDate" label="Service Date"  >
                              {returnIfElse(detail.statusId === CartonStatus.WaitAdmin || detail.statusId === CartonStatus.Inprogress
                                  ,
                                  <DatePicker placeholder={MessageCommon.TitleDate} format={'DD-MMM-YYYY'} disabledDate={disabledDate}/>
                                  ,
                                  <Label className='label-det'>{returnIfElse(detail.serviceDate, dayjs(detail.serviceDate).format('DD MMM YYYY'), "" ) }</Label>
                              ) 
                }
              </Form.Item>
              { detail.statusId === CartonStatus.Reject ?
                 <Form.Item name="noteReject" label="Rejected Reason"  >
                        <Label className='label-det'>{detail.notApproveReason}</Label>
                    </Form.Item>:<></>
            }
              <Form.Item name="note" label="Note"  >
              { detail.statusId === CartonStatus.WaitAdmin || detail.statusId === CartonStatus.Inprogress ?
                <Input.TextArea id="note" placeholder={MessageCommon.TitleInput}  />
                :<></>
              }
              </Form.Item>
            </Col>
          </Row>
        

        </Form>
        <div id="panel-action2" style={{paddingBottom: '15px'}}>
          <hr></hr>
          <div style={{ textAlign: 'center' }}>
        { detail.statusId === CartonStatus.WaitAdmin || detail.statusId === CartonStatus.Inprogress ?
        <>
          <Button type="primary" htmlType="button" className='mr-btn' onClick={() => update(CartonStatus.Inprogress)} disabled={detail.statusId === CartonStatus.Inprogress || isLoading}>
            <AiFillSave className='icn-btn' />Accept
          </Button>
          <Button type="primary" htmlType="button" className='mr-btn' onClick={() => update(CartonStatus.Inprogress)} disabled={detail.statusId === CartonStatus.WaitAdmin || isLoading}>
            <AiOutlineMessage className='icn-btn' />Update
          </Button>

          <Popconfirm
            title="Close the task"
            description="Confirm to close?"
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
             onConfirm = { () => update(CartonStatus.Done) }
          >
           <Button type="primary" htmlType="button" className='mr-btn' disabled={detail.statusId === CartonStatus.WaitAdmin || isLoading}>
            <AiFillSave className='icn-btn' />Close Job
            </Button>
          </Popconfirm>
  
        
          <Button type="primary" htmlType="button" className='mr-btn' disabled={isLoading} onClick={() => handleRejectOpen()}>
                <AiOutlineClose className='icn-btn' />Reject
              </Button>
        </>:<></>
        }
          <div style={{ float: 'left'}}>
            <Button type="primary" htmlType="button" className='mr-btn' onClick={()=>onExport()}  disabled={ isLoading }>
              <AiOutlinePrinter className='icn-btn' />Export
            </Button>
          </div>
          <Button type="primary" htmlType="button" className='mr-btn' onClick={showPrintModal} disabled={isLoading}>
            <AiOutlinePrinter className='icn-btn' />Print
          </Button>
          
          <Button type="primary" htmlType="button" onClick={prevStep} className='mr-btn'  disabled={isLoading}>
            <AiOutlineArrowLeft className='icn-btn' />Back
          </Button>
          </div>
          <br></br>
          <h6>Carton List</h6>
          <br></br>

          <div style={{float: 'right'}}>
          {/* <Select
            defaultValue="20"
            style={{ width: 120 }}
            options={[
              { value: '10', label: '10 / Page' },
              { value: '20', label: '20 / Page' },
              { value: '100', label: '100 / Page' },
              { value: '200', label: '200 / Page' },
            ]}
            /> */}
            </div>
          <br></br>
          <br></br>
        </div>
          <div id="table-container">
          
          <table className='idoc-table'>
            <thead id="head-table-fixed2">
              <tr>
                <th style={{width:50}}>No.</th>
                <th style={{width:50}}>	Carton No.</th>
                <th style={{width:width}}>Carton Location</th>
                <th style={{width:width}}>Carton Status</th>
                <th style={{width:width}}>Collected Request No.</th>
                <th style={{width:width}}>Collected by</th>
                <th style={{width:width}}>Owner</th>
                <th style={{width:width}}>Detail</th>
              
              </tr>
            </thead>
            <tbody >
            {isLoading ?
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            :
            <>
            { cartonItems.length > 0 && cartonItems[page -1].map((item:CartonList,index: number) => (
                <tr>
                    <td style={{width:50,textAlign: 'center'}}>{((page -1) * perpage) + (index + 1)}</td>
                    <td style={{width:width,textAlign: 'center'}}>
                        <div style={{ color: (item.cartonStatusId === CartonStatus.Remove || item.cartonStatusId === CartonStatus.Destruction) ? 'red' : ''}}>{item.cartonNo}</div>
                    </td>
                    <td style={{width:width,textAlign: 'center'}}>{item.cartonLocation}</td>
                    <td style={{width:width,textAlign: 'center'}}>{item.cartonStatus}</td>
                    <td style={{width:width,textAlign: 'center'}}>{item.collectRequestNo}</td>
                    <td style={{width:width,textAlign: 'center'}}>{item.collectByName}</td>
                    <td style={{width:width,textAlign: 'center'}}>{item.ownerName}</td>
                    <td style={{width:width,textAlign: 'center'}}>
                        <a onClick={() => handleCartonDetailOpen(item.requestNo, item.cartonNo)}>
                            <AiFillEye className='icon-table'></AiFillEye>
                        </a>
                    </td>
                </tr>
              ))
            }
            </>
          }
            </tbody>
          </table>
          
        </div>
        <div style={{marginTop:'10px',textAlign:'right'}}>
        { allCartonItems.length > 0 ?
            <Pagination 
                key={Helper.Uniqid()}
                current={page} 
                pageSize={perpage}
                total={allCartonItems.length} 
                onChange={onPageChange} 
                pageSizeOptions={PageOption.map(item=>item.value)}
                showSizeChanger={true}
              />:<></>
        }
        </div>
       
      </Card>


      <Modal title="" open={isPrintModalOpen} footer={''} width={600} onCancel={handlePrintModalCancel}>
        <br></br>
        <Card >
          <Form
            {...layout}
            form={dataForm}
            name="nest-messages"
            onFinish={onFinishPrint}
            validateMessages={validateMessages}
            initialValues={{'print':1}}
            labelAlign="left"
            colon={false}
            labelWrap={true}
            
          >

            <Row>
              <Col md={24}  >
                <Form.Item name="print" label="Export type" labelCol={{ md: 6 }}  >
                  <Radio.Group name="radiogroup">
                    <Radio value={1}>PDF</Radio>
                    <Radio value={0}>Excel</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <div style={{ textAlign: 'center' }}>
            <Button type="default" className='mr-btn' onClick={handlePrintModalCancel} >
              <AiOutlineClose className='icn-btn' />Cancel
            </Button>
            <Button type="primary" className='mr-btn'  htmlType='submit'>
              <AiOutlineCheck className='icn-btn' />OK
            </Button>
          </div>
          </Form>




        </Card>
      </Modal>
      <Modal title="Display Log" open={isNoteModalOpen} footer={''} width={900} onCancel={handleNoteModalCancel}>
        <DisplayLogNote key={Helper.Uniqid()} recordNo={detail.recordNo} requestTypeId={RequestType.RemoveCarton}/>
      </Modal>
      <Modal title="" open={isModalCartonDetailOpen} footer={''} width={1200} onCancel={handleCartonDetailCancel}>
        <CartonDetail key={Helper.Uniqid()} requestNo={requestNo} box={box} />
          </Modal>

          <Modal title="Confirm to reject?" open={isModalRejectOpen} footer={''} width={700} onCancel={() => handleCancelReject()} maskClosable={false}>
        <br></br>
        
          <Row>
            <Col md={2} >
              Note <span style={{ color: 'red'}}>*</span>
            </Col>
            <Col md={22} >
            <textarea style={{width: '93%',borderColor:"#ccc",borderRadius:"5px"}} id="note-reject" rows={5} defaultValue=""/>
            { isErrorNote ? <div className='error'>Note is required.</div> : <></> }
            </Col>
          </Row>
        
        <br></br>
        <div style={{ textAlign: 'center' }}>
          <Button type="default" className='mr-btn' onClick={() => setIsModalRejectOpen(false)}  >
            <AiOutlineClose className='icn-btn' />Cancel
          </Button>
          <Button type="primary" className='mr-btn'  onClick={() => handleReject()}>
            <AiOutlineCheck className='icn-btn' />OK
          </Button>
        </div>
      </Modal>
          {modal ? <span></span> : <></>}
      {contextHolderMessage}
      {contextHolder}
    </div>
  );

}

export default QueryDataRemoveCarton
