import axios from 'axios'
import { PageDataResponse ,PageResponse,DataObjectResponse, DataArrayResponse } from '../models/PageResponse'
import { Parameter,QueryDataModel,RequestReturnCarton,RequestDetail,
    RequestUpdateStatus ,UpdateCollectCarton ,DataNote,CartonList} from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class ReturnCartonService
{
    protected headers = {
        'Content-Type': 'application/json',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    protected token = ""
    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': ""
            }
        }
        
    }
    
    async GetRequest(id:number ){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.post<DataObjectResponse<RequestDetail>>(`${apiUrl}/v1/return/request?id=${id}`,{}, options )
        
        return data;
    }

    async GetDetail(id:number ){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.post<DataObjectResponse<RequestDetail>>(`${apiUrl}/v1/return/detail?id=${id}`,{}, options )
        
        return data;
    }

    async GetCartonList(id:number ){
        if(!this.token) return null
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.get<DataArrayResponse<CartonList>>(`${apiUrl}/v1/return/cartonlist?id=${id}`, options )
        
        return data;
    }

    async GetNote(id:number ){
        if(!this.token) return null
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.get<DataArrayResponse<DataNote>>(`${apiUrl}/v1/return/note?id=${id}`, options )
        
        return data;
    }

    async Search(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/return/search`,JSON.stringify(parameter),options )
        return data;
    }

    async Searchdraft(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/return/searchdraft`,JSON.stringify(parameter),options )
        return data;
    }

    async SearchCarton(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.post<PageDataResponse<CartonList>>(`${apiUrl}/v1/return/searchcarton`, JSON.stringify(parameter),options )
        
        return data;
    }

    async Create(request: RequestReturnCarton) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/return/create`,JSON.stringify(request),options )
        return data;
    }


    async Update(request: RequestReturnCarton) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/return/update`,JSON.stringify(request),options )
        return data;
    }

    async Extend(request: RequestReturnCarton) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/return/extend`,JSON.stringify(request),options )
        return data;
    }

    async UpdateAdmin(request: UpdateCollectCarton) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/return/admin/update`,JSON.stringify(request),options )
        return data;
    }

    async UpdateStatus(requestUpdateStatus: RequestUpdateStatus) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/return/status/update`,JSON.stringify(requestUpdateStatus),options )
        return data;
    }

    async DeleteCarton(ids: number[]) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/return/deletecarton`,JSON.stringify(ids),options )
        return data;
    }

}