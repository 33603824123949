import { Spin } from 'antd';


const Loading = () => {

    return (
      <div className="loading-container">
        <div className="loading-overlay">
          <Spin size="large" />
          
        </div>
        
      </div>
    );
  

};

export default Loading;

