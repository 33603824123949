import React, { useEffect, useState } from 'react';
import { Button, Col, Collapse, DatePicker, Form, Input, Spin, Modal, Row, Select, Pagination } from 'antd';
import { AiOutlineRotateRight, AiOutlineSearch, AiOutlineSync,AiOutlineFile,AiOutlineFileAdd,AiOutlineCloseCircle } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import { Label } from 'reactstrap';
import CartonDetail from '../Share/CartonDetail';
import { Parameter,CartonList, User, Item,RequestDetail} from '../../models'
import { Helper, InitHelper } from '../../helpers';
import { StorageCartonService, HrWebService, PropertyService,CollectCartonService} from '../../services';
import dayjs from 'dayjs'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import { CartonStatus, UserRole } from '../../enum';
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import secureLocalStorage  from  "react-secure-storage";
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';

interface AppState {
  currentPage:number
  perpage:number
  total: number
  items:CartonList[]
  itemNoList:string[]
  isLoading:boolean
}

interface Props {
  onBehalf:number
  onSelect:any
  items:CartonList[]
}


const CollectCartonEdit = (props: Props) => {
   
    const { instance, accounts } = useMsal();
    const navigate = useNavigate();
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const pageSizeOptions = user.systemConfig.tablePageSize
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataForm] = Form.useForm();
  const { Panel } = Collapse;
  const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
  const [selectItems, setSelectItems] = useState<string[]>([])
  const [requesters, setRequesters] = useState<Item[]>([])
  const [owners, setOwners] = useState<Item[]>([])
  const [orgunits, setOrgUnits] = useState<Item[]>([])
  const [requestStartDate, setRequestStartDate] = useState<Date|null|undefined>(null);
  const [requestEndDate, setRequestEndDate] =  useState<Date|null|undefined>(null);
  const [documentStartYear, setDocumentStartYear] = useState<number|null|undefined>(undefined);
  const [documentEndYear, setDocumentEndYear] = useState<number|null|undefined>(undefined);
  const [isModalCartonDetailOpen, setIsModalCartonDetailOpen] = useState(false);
  const [requestNo,setRequestNo] = useState("")
  const [detail,setDetail] = useState<RequestDetail>()
  const [box,setBox] = useState("")
  const [searchStatus,setSearchStatus] = useState(false)
  const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter)
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    perpage: props.onBehalf===1? 100:10,
    items: [],
    itemNoList:[],
    isLoading : !props.onBehalf ? true : false
  })

  useEffect(() => {
    
    if(!props.onBehalf){
        initial()
    }
   

}, [])


//}
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }

const initial = () => {
    setSearchStatus(true)
  setSelectItems([])
  let parameter = InitHelper.InitialParameter()
  parameter.requester =  user.employeeId
  parameter.requesters = [user.employeeId]
  parameter.owners = [user.employeeId]
  parameter.requesterOrgUnit = user.orgUnit
  parameter.condition = "or"
  getData(1,appState.perpage,parameter)
}
  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };

  const showModal = () => {
    console.log(selectItems)
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancelCarton = () => {
    setIsModalOpen(true);
    setIsModalCartonOpen(false);

  }
  
  const onCheckAll = (e:React.ChangeEvent<HTMLInputElement>) => {
    let items:string[] = []
    if(e.target.checked){
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.cartonNo) as HTMLInputElement
        element.checked = true
        items.push(element.value)
      })
    }
    else{
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.cartonNo) as HTMLInputElement
        element.checked = false
      })
    }
    setSelectItems(items)

   };

  const onCheckChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    let checked = e.target.checked
    let value = e.target.value
    let element = document.getElementById('check-item-all') as HTMLInputElement
    let items = Object.assign([],selectItems)
    if(checked){
        items.push(value)
       
        if(items.length === appState.items.length){
          element.checked = true
          element.indeterminate = false
        }
        else{
          element.checked = false
          element.indeterminate = true
        }
        
    }
    else{
      let index = items.indexOf(value);
      if (index !== -1) {
        items.splice(index, 1);
      }

      if(items.length == 0){
        element.checked = false
        element.indeterminate = false
      }
      else{
        element.checked = false
        element.indeterminate = true
      }
    }

    console.log(items)
    setSelectItems(items)
  }
  
  const searchData = async () => {
    let field = dataForm.getFieldsValue()
    console.log(field)
    let parameter = InitHelper.InitialParameter();
    parameter.condition = "and"
    
    if(props.onBehalf === 1)
    {
        parameter.onBehalf = 1
        parameter.confidentialLevel = 2
    }
    else
    {
        parameter.requesters =  []
        parameter.owners = []
        parameter.requester = user.employeeId
        parameter.requesterOrgUnit = user.orgUnit
        
    }

    if(field.requestNo){
        parameter.requestNoList = field.requestNo.split(',')
    }

    if(requestStartDate && requestEndDate){
        parameter.requestStartDate = requestStartDate
        parameter.requestEndDate = requestEndDate
    }

    if(documentStartYear && documentEndYear){
        parameter.documentStartYear = documentStartYear
        parameter.documentEndYear = documentEndYear
    }
    
    if(requesters.length > 0){
     parameter.requesters = requesters.map(item => item.key)
    }

    if(owners.length > 0){
      parameter.owners = owners.map(item => item.key)
    }

    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    let cartonNoStart = ""
    let cartonNoEnd = ""
    if(elementStart){
      cartonNoStart = elementStart.value
    }
    if(elementEnd){
      cartonNoEnd = elementEnd.value
    }

    if(cartonNoStart && cartonNoEnd){
      parameter.cartonNo = cartonNoStart + '&' + cartonNoEnd
    }

    if(field.cartonNoList){
      parameter.cartonNo = field.cartonNoList
    }

    if(field.cartonNo){
      parameter.cartonNo = field.cartonNo
    }

    console.log(parameter)
    setSearchStatus(true)
    getData(1,appState.perpage,parameter)
    
  }
  const reset = () => {
    if(!searchStatus){
        return
    }
    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    elementStart.value =""
    elementEnd.value = ""
    
    setOwners([])
    setRequesters([])
    setOrgUnits([])
    setRequestStartDate(undefined)
    setRequestEndDate(undefined)
    setDocumentStartYear(undefined)
    setDocumentEndYear(undefined)
    dataForm.resetFields()
    let parameter = InitHelper.InitialParameter()
    parameter.condition = "or"
    if(props.onBehalf === 1)
    {
        parameter.onBehalf = 1
        parameter.confidentialLevel = 2
    }
    else{
        parameter.owners = [user.employeeId]
        parameter.requesters = [user.employeeId]
        parameter.requester = user.employeeId
        parameter.requesterOrgUnit = user.orgUnit
    }
   
    getData(1,appState.perpage,parameter)

  }

  const getData = async (currentPage: number,perpage: number,_parameter:Parameter | null = null) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)
       
    let parameter = Object.assign({},saveParameter)
    if(_parameter !== null){
        parameter = _parameter;
    }

    parameter.page = currentPage
    parameter.limit = perpage
    parameter.statusIds = [CartonStatus.Done]
    parameter.cartonStatusIds =[CartonStatus.Storage]
    
    console.log(parameter)
      let token = await getToken()
      let result = await new StorageCartonService(token).SearchCollectRequest(parameter)
      console.log(result)
      if(result && result.success)
      {
        let {page,limit,total,items,itemNoList} = result.data
        let collectItems = props.items.map(x => x.cartonNo)
        items = items.map((x) => { x.checkBox = 0; return x })
        let allCartonNo= items.map(x => x.cartonNo).filter(onlyUnique)
        allCartonNo.forEach(cartonNo => {
            let index = items.findIndex(x => x.cartonNo == cartonNo)
            items[index].checkBox = 1
        })

        let state = Object.assign({},appState)
          state.total = total
          state.currentPage = page
          state.perpage = limit
          state.items = items.filter(x => collectItems.indexOf(x.cartonNo) == -1)
          state.itemNoList = itemNoList
          state.isLoading = false
          setSaveParameter(parameter)
          setAppState(state)
          
        
      }
      else{
        let state = Object.assign({},appState)
        state.isLoading = false
        setAppState(state)
      }
  }

    const onlyUnique = (value:any, index:any, array:any) => {
        return array.indexOf(value) === index;
    }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const onPageChange = (page:number,perpage:number) => {
    if(page !== appState.currentPage && perpage === appState.perpage){
        getData(page,appState.perpage)
    }

    if(page === appState.currentPage && perpage !== appState.perpage){
        getData(1,perpage)
    }
  }

 const onRequesterSelect = (item:any) => {
  setRequesters(item)
}

const onOwnerSelect = (item:any) => {
  setOwners(item)
}

const onOrgUnitSelect = (item:any) => {
    setOrgUnits(item)
}

const onSearchEmployee = async(key:string) => {
 
    let token = await getToken()
    let result = await new HrWebService(token).SearchEmployee(key)
    if(result && result.success)
    {
        if(props.onBehalf === 1)
        {
            return result.data
        }
        else{
            let _orgUnit = user.orgUnit
            if(user.orgUnit.indexOf("/") > -1) {
                _orgUnit = user.orgUnit.split("/")[0]
            }
            return result.data.filter(x => x.orgUnit.indexOf(_orgUnit) > -1)
        }
    }
    else{
      console.log(result?.message)
      return []
    }
}

const onSearchOrgUnit = async(key:string) => {
   
    let token = await getToken()
    let result = await new PropertyService(token).GetOrgUnits(key)
    if(result && result.success)
    {
      return result.data
    }
    else{
      console.log(result?.message)
      return []
    }
}

const onRequestStartDateChange = (date: any) => {
  setRequestStartDate(date);
}

const onRequestEndDateChange = (date: any) => {
  setRequestEndDate(date)
}

const onDocumentStartYearChange = (value: any) => {
  let year = parseInt(dayjs(value).format('YYYY'))
  setDocumentStartYear(year)
}

const onDocumentEndYearChange = (value: any) => {
  let year = parseInt(dayjs(value).format('YYYY'))
  setDocumentEndYear(year)
}

const openDetail = (_requestNo:string, _box:string) => {
  setRequestNo(_requestNo)
  setBox(_box)
  setIsModalCartonDetailOpen(true);
}

const handleCartonDetailCancel = () => {
  setIsModalCartonDetailOpen(false);
}

const handleAdd =() => {
    
   props.onSelect(selectItems)
  
}

const renderPagination =() =>{
  if(appState.total > 0){
    if(props.onBehalf===1){
      return( <Pagination 
        key={Helper.Uniqid()}
        current={appState.currentPage} 
        pageSize={appState.perpage}
        total={appState.total} 
        onChange={onPageChange} 
        showSizeChanger={false}
      />)
    }
    return (<Pagination 
    key={Helper.Uniqid()}
    current={appState.currentPage} 
    pageSize={appState.perpage}
    total={appState.total} 
    onChange={onPageChange} 
    pageSizeOptions={pageSizeOptions}
    showSizeChanger={true}
  />)
  }
 return (<></>)

    }

    

  return (

    <div>
      

          <Collapse defaultActiveKey={returnIfElse(props.onBehalf, [1], [])}>
        <Panel header="Carton Search" key="1">
          <Form
            {...layout}
            form={dataForm}
            name="collect-form"
            onFinish={onFinish}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >

            <Row>
              <Col md={11} >
                <Form.Item label="Carton No:(Range)" >
                    <table style={{width: '100%'}}>
                        <tr>
                          <td>
                            <input id="cartonNoStart" className='form-control form-control-sm' placeholder='ex. 23PSC001'/>
                          </td>
                          <td>
                            <Label style={{ marginLeft: 10, marginRight: 5 }}>To</Label>
                          </td>
                          <td>
                            <input id="cartonNoEnd" className='form-control form-control-sm' placeholder='ex. 23PSC001'/>
                        
                          </td>
                        </tr>
                      </table>
                </Form.Item>

              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                  <Form.Item  name="storageRequestedDate" label="Storage Requested Date">
                      <table style={{width : '100%'}}>
                        <tr>
                          <td>
                            <DatePicker 
                            format="DD MMM YYYY" 
                              placeholder={MessageCommon.TitleDate} 
                              className='date-picker-full'
                              onChange={onRequestStartDateChange}
                            />
                          </td>
                          <td>
                            <Label style={{ marginLeft: 10, marginRight: 10 }}>To</Label>
                          </td>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate} className='date-picker-full' onChange={onRequestEndDateChange}/>
                          </td>
                        </tr>
                      </table>
                    </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                  <Form.Item name="cartonNoList" label="Carton No (Multiple)" >
                    <Input.TextArea rows={5} placeholder='ex. 23PSC001,03PCS002,03PCS003'/>
                  </Form.Item>

                  <Form.Item name="cartonNo" label="Carton No" >
                      <Input placeholder='ex. 23PSC001'/>
                  </Form.Item>

                  <Form.Item name="carton_status" label="Carton Status"  >
                  <Select placeholder={MessageCommon.TitleSelect} defaultValue={'0'} disabled
                    options={[{ value: '0', label: 'Storage' }, { value: '1', label: '1' }]}
                  />
                </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                {
                    returnIfElse(props.onBehalf , <></> ,
                              <Form.Item name="requestNo" label="Storage Requested No."  >
                                  <Input placeholder='ex. B230004,B230005' />
                              </Form.Item>
                              )
                }
                <Form.Item name="documentyear" label="Document Year">
                  <table style={{ width: '100%'}}>
                    <tr>
                      <td>
                        <DatePicker 
                          placeholder={MessageCommon.TitleDate} 
                          picker='year' style={{width: '100%'}} 
                          onChange={onDocumentStartYearChange}
                        />
                      </td>
                      <td>
                        <Label style={{ marginLeft: 10, marginRight: 10 ,verticalAlign:'top'}}>To</Label>
                      </td>
                      <td>
                        <DatePicker 
                          placeholder={MessageCommon.TitleDate} 
                          picker='year' 
                          style={{width: '100%'}}
                          onChange={onDocumentEndYearChange}
                        />
                      </td>
                    </tr>
                  </table>
                </Form.Item>

                <Form.Item name="requester" label="Requester"  >
                  <EmployeeAutoComplete
                     placeholder='Search by Name'
                    selectedItems={requesters ? requesters : []}
                    onChange={item => onRequesterSelect(item)}
                    service={onSearchEmployee}
                    minimumTextLength={3}
                  />
                </Form.Item>
                
                <Form.Item name="owner" label="Owner"  >
                  <EmployeeAutoComplete
                     placeholder='Search by Name'
                                      selectedItems={returnIfElse(owners, owners, [] )  }
                    onChange={item => onOwnerSelect(item)}
                    service={onSearchEmployee}
                    minimumTextLength={3}
                  />
                </Form.Item>
                              {returnIfElse(props.onBehalf, 
                                  <Form.Item name="orgUnit" label="Org. Unit"  >
                                      <OrgUnitAutoComplete
                                          key={Helper.Uniqid()}
                                          placeholder='Search by Name'
                                          selectedItems={returnIfElse(orgunits, orgunits, [] ) }
                                          onChange={item => onOrgUnitSelect(item)}
                                          service={onSearchOrgUnit}
                                          minimumTextLength={2}
                                      />
                                  </Form.Item>
                                  , 
                                  <></>
                              )

            }
              </Col>
            </Row>
          
          </Form>
          <div style={{ textAlign: 'center' }}>
            <Button type="default" className='mr-btn' onClick={reset} >
              <AiOutlineSync className='icn-btn' /> Reset
            </Button>
            <Button type="primary" onClick={searchData}  >
              <AiOutlineSearch className='icn-btn' /> Search
            </Button>


          </div>

        </Panel>
      </Collapse>


      <br></br>
    { searchStatus ?
      <Collapse defaultActiveKey={ ['1']}>
        <Panel header="Data Result" key="1" >
        <div style={{float: 'left'}}>
        <Button type="primary" className='btn-blue mr-btn' style={{width:'94px'}} onClick={handleAdd} disabled={selectItems.length === 0}>
            <AiOutlineFileAdd className='icn-btn' /> Add
          </Button>
          <Button type="default" className='mr-btn' onClick={handleAdd}  >
            <AiOutlineCloseCircle className='icn-btn' />Cancel
          </Button>
        </div>
                      {returnIfElse(appState.total > 0 && props.onBehalf === 1
                          ,  
                          <div style={{ float: 'right' }}>
                              <SelectPagination onSelectChange={onPageChange} currentPage={appState.currentPage} />
                              <span style={{ marginLeft: '8px' }}>/ Page</span>
                          </div>
                          ,  
                          <></>
                      )}
          <br></br>
          <br></br>
          <div id="table-container">
        { appState.isLoading ?
            <div style={{ margin: 'auto',paddingBottom: '30px'}}>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
          :
          <table className='idoc-table'>
            <thead id="head-table-fixed">
              <tr>
                <th style={{ textAlign: 'left',width: '50px'}}>
                { appState.items.length > 0 ?
                  <div className="form-check">
                    <input  type="checkbox" id={'check-item-all'} className="form-check-input check-item" onChange={(e) => onCheckAll(e)}/>
                    <label className="form-check-label" htmlFor={'check-item-all'}/>
                  </div>:<></>
                }
                </th>
                <th >Carton No.</th>
                <th >Description</th>
                <th >Project</th>
                <th >Requester</th>
                <th >Owner</th>
                <th >Org. Unit</th>
                <th >Request Date</th>
                <th >Carton Status</th>
                <th>File</th>
              </tr>
            </thead>
           
            <tbody >
            { appState.items.map((item:CartonList) => (
                <tr>
                  <td style={{ textAlign: 'center',width: '50px'}}>
                    { item.checkBox == 1 ?
                        <div className="form-check">
                          <input  type="checkbox" id={'check-item-' + item.cartonNo} value={item.cartonNo} className="form-check-input check-item" onChange={(e) => onCheckChange(e)}/>
                          <label className="form-check-label" htmlFor={'check-item-' + item.cartonNo}/>
                        </div>:<></>
                    }
                  </td>
                  <td style={{textAlign: 'center',width:'120px'}}>
                    { item.cartonNo }
                  </td>
                  <td style={{textAlign: 'left',wordBreak: 'break-word'}}>
                    <div>{item.description}</div>
                  </td>
                  <td style={{textAlign: 'center'}}>{item.project}</td>
                  <td style={{textAlign: 'center'}}>{item.requesterName}</td>
                  <td style={{textAlign: 'center'}}>{item.ownerName}</td>
                  <td style={{textAlign: 'center'}}>{item.orgUnit}</td>
                  <td style={{textAlign: 'center'}}>{item.requestDate ? dayjs(item.requestDate).format("DD MMM YYYY") : "" }</td>
                  <td style={{textAlign: 'center'}}>{item.cartonStatus}</td>
                  <td style={{textAlign: 'center'}}>
                    <a onClick={() => openDetail(item.requestNo,item.cartonNo)}>
                      <AiOutlineFile className='icon-table'/>
                    </a>
                  </td>
                 
                </tr>
              ))
            }
            
            </tbody>
  
          </table>
        }
          </div>
          <div style={{marginTop:'10px',textAlign:'right'}}>
            { renderPagination()}
          </div>
        </Panel>
      </Collapse>:<></>
    }
      <Modal  title="Carton Detail" open={isModalCartonOpen} width={1550} onCancel={handleCancelCarton}>
        {/* <CartonDetail></CartonDetail> */}
      </Modal>
      <Modal title="Carton Detail" open={isModalOpen} footer={''}  width={1550} onCancel={handleCancel} maskClosable={false}>
        
      </Modal>
      <Modal title="" open={isModalCartonDetailOpen} footer={''} width={1550} onCancel={handleCartonDetailCancel}>
        <CartonDetail key={Helper.Uniqid()} requestNo={requestNo} box={box} />
      </Modal>

    </div>
  );

}

export default CollectCartonEdit
