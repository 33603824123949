import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin ,Pagination ,Popconfirm,message} from 'antd';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import {  CloseCircleOutlined ,QuestionCircleOutlined} from '@ant-design/icons';
import { QueryDataModel ,RequestUpdateStatus} from '../../models';
import { Helper, InitHelper } from '../../helpers';
import { CartonStatus ,RequestType} from '../../enum';
import CollectCartonRequestDetail from '../CollectCarton/CollectCartonRequestDetail';
import StorageCartonRequestDetail from '../StorageCarton/StorageCartonRequestDetail';
import DestructionCartonExtenModal from '../DestructionCarton/DestructionCartonExtenModal';
import DestructionCartonDestroyModal from '../DestructionCarton/DestructionCartonDestroyModal';
import { QueryService,CollectCartonService,ReturnCartonService,DestructionCartonService } from '../../services';
import secureLocalStorage  from  "react-secure-storage";
import dayjs from 'dayjs'
import CollectCartonModal from '../CollectCarton/CollectCartonModal';
import ReturnCartonModal from '../ReturnCarton/ReturnCartonModal'
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { User} from '../../models';
import { useNavigate } from 'react-router-dom';

interface AppState {
  currentPage:number
  perpage:number
  total: number
  totalPgae: number
  items:QueryDataModel[]
  isLoading:boolean
}

interface StorageCarton{
  recordNo:number,
  statusId:number,
  valvRoom:number,
  approveLevel:number
}

const OnBehalfSaveDraft = () => {


    const doIfElse = (flag: any, f1: any, f2: any) => {
        if (flag) {
            if (f1 != null) {
                f1();
            }
        }
        else {
            if (f2 != null) {
                f2();
            }
        }
    }
    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
  const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
    const [storageCarton, setStorageCarton] = useState<StorageCarton>({ recordNo: 0, statusId: 0, valvRoom: 0, approveLevel: 0 });
  const [ requestTypeId,setRequestTypeId] = useState(0)
  const [selectItems, setSelectItems] = useState<string[]>([])
  const [recordNo,setRecordNo] = useState(0)
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    totalPgae : 0,
    perpage: 100,
    items: [],
    isLoading : true
  })
  const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if(user.permissions.onBehalf === 0){
            navigate('/permissiondenided')
        }

        setStorageCarton({ recordNo: 0, statusId: 0, valvRoom: 0, approveLevel: 0 })
    getData(1,appState.perpage)
  }, [])

  const getData = async (currentPage: number,perpage: number) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)

    let parameter = InitHelper.InitialParameter();
    parameter.page = currentPage
    parameter.limit = perpage
    
      let token = await getToken()
      let result = await new QueryService(token).SearchOnbehalfDraft(parameter)
      console.log(result)
      if (!result)
          return null;
      let {page,limit,total,items} = result.data
      
      let state = Object.assign({},appState)
          state.total = total
          state.currentPage = page
          state.totalPgae = Math.ceil(total / limit)
          state.items = items
          state.isLoading = false
          state.perpage = perpage

        setTimeout(() => {
          setAppState(state)
        }, 500);
  }
  
  const handleEdit = (recordNo:number,requestTypeId:number) => {
    
      setRecordNo(recordNo)
      setRequestTypeId(requestTypeId)
      setIsModalOpen(true)
    
    
  }

 
  const handleDelete = async (requestNoList:string[]) => {
    let token = await getToken()
    let allIds:QueryDataModel[] = appState.items.filter(x => requestNoList.indexOf(x.requestNo) > -1 || requestNoList.indexOf(x.tempNo) > -1)
    let collectIds:number[] = allIds.filter(x => x.requestTypeId === RequestType.CollectCarton).map( x =>  x.recordNo )
    let returnIds:number[] = allIds.filter(x =>  x.requestTypeId === RequestType.ReturnCarton).map( x =>  x.recordNo )
    let destructionIds:number[] = allIds.filter(x => x.requestTypeId === RequestType.ExtendCarton || x.requestTypeId === RequestType.DestructionCarton ).map( x =>  x.recordNo )

    if(collectIds.length > 0){
        let requestUpdateStatus:RequestUpdateStatus = {
            recordIds: collectIds,
            statusId: CartonStatus.Delete
        }
        console.log(requestUpdateStatus)
        let result = await new CollectCartonService(token).UpdateStatus(requestUpdateStatus)
        doIfElse(result && result.success ,
            function () {
                let element = document.getElementById('table-container') as HTMLElement
                messageApi.open({
                    type: 'success',
                    content: 'Save success',
                    duration: 1.5,
                    style: { marginTop: element.getBoundingClientRect().top, marginLeft: element.getBoundingClientRect().left }
                });
                setTimeout(() => {
                    setStorageCarton({ recordNo: 0, statusId: 0, valvRoom: 0, approveLevel: 0 })
                    getData(1,appState.perpage)

                }, 1000);
            }
            ,
            function () {
                Modal.error({
                    icon: <CloseCircleOutlined />,
                    title: 'This is an error message',
                    content: result?.message,
                });
            }
        );
    }
    
    if(returnIds.length > 0){
        let requestUpdateStatus:RequestUpdateStatus = {
            recordIds: returnIds,
            statusId: CartonStatus.Delete
        }
         console.log(requestUpdateStatus)
        let result = await new ReturnCartonService(token).UpdateStatus(requestUpdateStatus)
        doIfElse(result && result.success,
            function () {
                let element = document.getElementById('table-container') as HTMLElement
                messageApi.open({
                    type: 'success',
                    content: 'Save success',
                    duration: 1.5,
                    style: { marginTop: element.getBoundingClientRect().top, marginLeft: element.getBoundingClientRect().left }
                });
                setTimeout(() => {

                    getData(1, appState.perpage)

                }, 1000);
            }
            ,
            function () {
                Modal.error({
                    icon: <CloseCircleOutlined />,
                    title: 'This is an error message',
                    content: result?.message,
                });
            }
        );
    }

    if(destructionIds.length > 0){
        let requestUpdateStatus:RequestUpdateStatus = {
            recordIds: destructionIds,
            statusId: CartonStatus.Delete
        }
        console.log(requestUpdateStatus)
        let result = await new DestructionCartonService(token).UpdateStatus(requestUpdateStatus)
        doIfElse(result && result.success ,
            function () {
                let element = document.getElementById('table-container') as HTMLElement
                messageApi.open({
                    type: 'success',
                    content: 'Save success',
                    duration: 1.5,
                    style: { marginTop: element.getBoundingClientRect().top, marginLeft: element.getBoundingClientRect().left }
                });
                setTimeout(() => {
                    getData(1, appState.perpage)

                }, 1000);
            }
            ,
            function () {
                Modal.error({
                    icon: <CloseCircleOutlined />,
                    title: 'This is an error message',
                    content: result?.message,
                });
            }
        );
    }
    
  }


  const handleCancelCarton = () => {
    setIsModalCartonOpen(false);

  };

  const onCheckAll = (e:React.ChangeEvent<HTMLInputElement>) => {
    let items:string[] = []
    if(e.target.checked){
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.requestNo) as HTMLInputElement
        element.checked = true
        items.push(element.value)
      })
    }
    else{
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.requestNo) as HTMLInputElement
        element.checked = false
      })
    }
    setSelectItems(items)
  };

  const onCheckChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    let checked = e.target.checked
    let value = e.target.value
    let items = Object.assign([],selectItems)
    if(checked){
        items.push(value)
        let element = document.getElementById('check-item-all') as HTMLInputElement
        if(items.length === appState.items.length){
          element.checked = true
          element.indeterminate = false
        }
        else{
          element.checked = false
          element.indeterminate = true
        }
        
    }
    else{
      let index = items.indexOf(value);
      if (index !== -1) {
        items.splice(index, 1);
      }

      if(items.length == 0){
        let element = document.getElementById('check-item-all') as HTMLInputElement
        element.checked = false
        element.indeterminate = false
      }
    }

    console.log(items)
    setSelectItems(items)
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const onPageChange = (page:number,perpage:number) => {
    if(page !== appState.currentPage && perpage === appState.perpage){
        getData(page,appState.perpage)
    }

    if(page === appState.currentPage && perpage !== appState.perpage){
        getData(1,perpage)
    }
  }


 const handleCancel = () => {
  setIsModalOpen(false);
};

const onReload = () => {
    getData(1,appState.perpage)
}

  return (
    <div>
      
      <div style={{float: 'left'}}>
        { selectItems.length > 0 ?
        <Popconfirm
            title="Delete the task"
            description="Confirm to delete?"
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
             onConfirm = { () => handleDelete(selectItems) }
          >
           <Button type="primary" className='btn-red mr-btn'>
            <AiFillDelete className='icn-btn' /> Delete
          </Button>
        </Popconfirm>
        :
        <Button type="primary" className='btn-red mr-btn' disabled>
            <AiFillDelete className='icn-btn' /> Delete
          </Button>
        }
       
      </div>
      { appState.total>0?<div style={{float: 'right'}}>
                        <SelectPagination  onSelectChange={onPageChange} currentPage={appState.currentPage}/>
                        <span style={{marginLeft:'8px'}}>/ Page</span>
                    </div>:<></>}
      <div></div>
      { appState.isLoading ?
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>
          :
          <>
            <div id="table-container" style={{paddingTop: '45px'}}>
              <table className='idoc-table'>
                <thead id="head-table-fixed">
                  <tr>
                    <th style={{ textAlign: 'left',width: '50px'}}>
                        <div className="form-check">
                          <input  type="checkbox" id={'check-item-all'} className="form-check-input check-item" onChange={(e) => onCheckAll(e)}/>
                          <label className="form-check-label" htmlFor={'check-item-all'}/>
                        </div>
                      </th>
                        <th >Request No.</th>
                        <th >Requested Date</th>
                        <th >Carton Quantity</th>
                        <th >Request Type</th>
                        <th >Edit</th>
                        <th >Delete</th>
                  </tr>
                </thead>
                <tbody >
                  { appState.items.map((item:QueryDataModel) => (
                    <tr>
                      <td style={{ textAlign: 'left',width: '50px'}}>
                        <div className="form-check">
                          <input  type="checkbox" id={'check-item-' + item.requestNo ? item.requestNo : item.tempNo } value={item.requestNo ? item.requestNo : item.tempNo } className="form-check-input check-item" onChange={(e) => onCheckChange(e)}/>
                          <label className="form-check-label" htmlFor={'check-item-' + item.requestNo ? item.requestNo : item.tempNo }/>
                        </div>
                      </td>
                      <td style={{textAlign: 'center'}}><a className='a-table'onClick={() => handleEdit(item.recordNo,item.requestTypeId)} >
                        {item.requestNo  ? item.requestNo :  item.tempNo }
                        </a></td>
                      <td style={{textAlign: 'center'}}>{ item.requestDate ? dayjs(item.requestDate).format("DD MMM YYYY") : ""}</td>
                      <td style={{textAlign: 'center'}}>{item.quantity}</td>
                      <td style={{textAlign: 'center'}}>{item.requestType}</td>
                      <td style={{textAlign: 'center'}}>
                          <AiFillEdit size={20} onClick={() => handleEdit(item.recordNo,item.requestTypeId)} style={{ cursor: 'pointer'}}/>
                      </td>
                      <td style={{textAlign: 'center'}}>
                        <Popconfirm
                          title="Delete the task"
                          description="Confirm to delete?"
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          onConfirm = { () => handleDelete([item.requestNo ? item.requestNo : item.tempNo]) }
      
                        >
                          <AiFillDelete size={20} color="red" style={{ cursor: 'pointer'}}/>
                        </Popconfirm>
                        
                      </td>
                    </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <div style={{marginTop:'10px',textAlign:'right'}}>
            { appState.total > 0 ?
                <Pagination 
                    key={Helper.Uniqid()}
                    current={appState.currentPage} 
                    pageSize={appState.perpage}
                    total={appState.total} 
                    onChange={onPageChange}
                    showSizeChanger={false}
                />:<></>
            }
            </div>
          </>
        }

      <Modal title="Request Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton}>
         { requestTypeId === RequestType.CollectCarton ?
            <CollectCartonRequestDetail 
              key={Helper.Uniqid()}
              recordNo={storageCarton.recordNo}  
            />:<></>
        }
        { requestTypeId === RequestType.StorageCarton ?
            <StorageCartonRequestDetail 
              key={Helper.Uniqid()}
                      recordNo={storageCarton.recordNo} cartonNumber={""}
              approveLevel={storageCarton.approveLevel}
            />:<></>
        }
      </Modal>

      <Modal title="Carton Detail" open={isModalOpen} footer={''}  width={1550} onCancel={handleCancel} maskClosable={false}>
        { 
          requestTypeId === RequestType.CollectCarton ?
            <CollectCartonModal key={Helper.Uniqid()} collectId={recordNo} items={[]} page="onBehalf" onBehalf={1} onReload={onReload}/>:<></>
        }
        {
          requestTypeId === RequestType.ReturnCarton ?
          <ReturnCartonModal key={Helper.Uniqid()} returnId={recordNo} items={[]} page='onBehalf' onBehalf={1} onReload={onReload}/>:<></>
        }
        { requestTypeId === RequestType.ExtendCarton ?
            <DestructionCartonExtenModal  key={Helper.Uniqid()} destroyId={recordNo} items={[]} page="onBehalf" onBehalf={1} onReload={onReload}/>:<></>
        }
        { requestTypeId === RequestType.DestructionCarton ?
            <DestructionCartonDestroyModal  destroyId={recordNo} items={[]} page="onBehalf" onBehalf={1} onReload={onReload}/>:<></>
        }
      </Modal>

          {modal ? <span></span> : <></>}
        {contextHolderMessage}
        {contextHolder}
    </div >
  );

}

export default OnBehalfSaveDraft
