import { useEffect } from 'react';
import {  Card, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import NewCartonRequest from './NewCartonRequest'
import { useNavigate } from 'react-router-dom';
import NewCartonStatusTracking from './NewCartonStatusTracking'
import NewCartonSaveDraft from './NewCartonSaveDraft'

const NewCarton2 = () => {
  const navigate = useNavigate();

  const items: TabsProps['items'] = [

    {
      key: '1',
      label: `New`,
      children: <NewCartonRequest/>,
      
    },
    {
      key: '2',
      label: `Status Tracking`,
      children: <NewCartonStatusTracking/>,
    },
    {
      key: '3',
      label: `Save Draft`,
      children: <NewCartonSaveDraft/>,
    },
  ];

  const onTabChange = (tab:string) => {
    if(tab === '1'){
      navigate('/new-carton')
    }
    else{
        let tabstr = tab === '2' ? 'tracking' : 'save'
        navigate('/new-carton-' + tabstr)
    }
  }
  
  useEffect(() => {
    
  }, [])

 
  return (

    <div>
      <Card >
        <Tabs defaultActiveKey="2" items={items} onChange={onTabChange} destroyInactiveTabPane/>
      </Card>

    
     
    </div>
  );

}

export default NewCarton2
