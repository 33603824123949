import { useEffect } from 'react';
import { Card, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import StorageCartonRequest from './StorageCartonRequest'
import StorageCartonStatusTracking from './StorageCartonStatusTracking';
import StorageCartonSaveDraft from './StorageCartonSaveDraft';

const StorageCarton3 = () => {
  const navigate = useNavigate();

  useEffect(() => {
  
  }, [])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Storage`,
      children: <StorageCartonRequest/>
    },
    {
      key: '2',
      label: `Status Tracking`,
      children: <StorageCartonStatusTracking/>,
    },
    {
      key: '3',
      label: `Save Draft`,
      children: <StorageCartonSaveDraft/>,
    },

  ];

  const onTabChange = (tab:string) => {
    if(tab === '1'){
      navigate('/storage-carton')
    }
    else{
        let tabstr = tab === '2' ? 'tracking' : 'save'
      navigate('/storage-carton-' + tabstr)
    }
  }

  return (

    <div>
      <Card >
        <Tabs defaultActiveKey='3' items={items} onChange={onTabChange} destroyInactiveTabPane/>
      </Card>

    </div>
  );

}

export default StorageCarton3
