import { useEffect } from 'react';
import { Card, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import ChangeOwnerRequest from './ChangeOwnerRequest'
import ChangeOwnerStatusTracking from './ChangeOwnerStatusTracking'
import { useNavigate } from 'react-router-dom';

const ChangeOwner2 = () => {
    const navigate = useNavigate();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Request`,
      children: <ChangeOwnerRequest/>,
    },
    {
      key: '2',
      label: `Status Tracking`,
      children: <ChangeOwnerStatusTracking/>,
    },

  ];

  useEffect(() => {
  
  }, [])

  const onTabChange = (tab:string) => {
    if(tab === '1'){
      navigate('/request-change-owner')
    }
    else{
      navigate('/request-change-owner-' + tab)
    }
  }

  return (
    <div>
      <Card >
        <Tabs defaultActiveKey="2" items={items} onChange={onTabChange} destroyInactiveTabPane/>
      </Card>

    </div >
  );

}

export default ChangeOwner2
