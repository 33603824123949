import RequestType from "./RequestType"

const RequestTypeOption =[
    { value: 0, label: 'All' },
    { value: RequestType.NewCarton, label: 'New Carton' },
    { value: RequestType.StorageCarton, label: 'Storage Carton' },
    { value: RequestType.CollectCarton, label: 'Collect Carton' },
    { value: RequestType.ReturnCarton, label: 'Return Carton' },
    { value: RequestType.RemoveCarton, label: 'Remove Carton' },
    { value: RequestType.DestructionCarton, label: 'Destruction Carton' },
  ]

export default RequestTypeOption