import React, {  useEffect, useState } from 'react';
import { Button, Card,  Col, Select, Form, Input, Pagination, Modal, Popconfirm, Row, Upload, message,Tooltip } from 'antd';
import {  AiFillDelete, AiFillSave, AiOutlineCloseCircle, AiOutlineDelete, AiOutlineSave,AiOutlinePlusCircle,AiOutlineCopy} from 'react-icons/ai';
import dayjs from 'dayjs';
import { ExclamationCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined,UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { CartonList , RequestDestroyCarton, Item,User,FileItem} from '../../models';
import {DestructionCartonService, HrWebService,StorageCartonService,SystemConfigService} from '../../services';
import { CartonStatus,Message, RequestType } from '../../enum';
import { Helper ,InitHelper} from '../../helpers';
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import  secureLocalStorage  from  "react-secure-storage";
import type { UploadFile } from 'antd/es/upload/interface';
import type { UploadChangeParam } from 'antd/es/upload';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import DestructionCartonAdd from "./DestructionCartonAdd"

interface Props{
  destroyId:number
  items: string[]
  page:string
  onBehalf:number
  onReload:any
}

interface Error
{
  extend:number
  onBehalf:number
  memo:number
  approver:number
  file:number
}

const DestructionCartonExtenModal = (props: Props) => {

    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }


    const doIfElse = (flag: any, f1: any, f2: any) => {
        if (flag) {
            if (f1 != null) {
                f1();
            }
        }
        else {
            if (f2 != null) {
                f2();
            }
        }
    }

    const { instance, accounts } = useMsal();
  const navigate = useNavigate();
    const userStore = secureLocalStorage.getItem('user')
    const user: User = userStore ? JSON.parse(userStore.toString()) : null
  const pageSizeOptions = user.systemConfig.tablePageSize
    const orgUnit = returnIfElse(user.orgUnit, user.orgUnit  ,"")
    const position = returnIfElse(user.position , user.position ,"")
    const directPhone = returnIfElse(user.directPhone, user.directPhone  , "") 
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [dataForm] = Form.useForm();
  const [dataForm2] = Form.useForm();
  const [page,setPage] = useState(1)
  const [perpage ,setPerpage] = useState(user.systemConfig.tablePageSize[0]);
  const [selectItems, setSelectItems] = useState<string[]>([])
  const [allCartonItems,setAllCartonItems] = useState<CartonList[]>([])
  const [cartonItems,setCartonItems] = useState<CartonList[][]>([])
  const [fileItems,setFileItems] = useState<FileItem[]>([])
  const [modal, contextHolder] = Modal.useModal();
  const [checkAll, setCheckAll] = useState(false)
  const [onBehalfs, setonBehalfs] = useState<Item[]>([])
  const [approvers, setOnApprovers] = useState<Item[]>([])
  const [onBehalfsBgColor,setOnBehalfsBgColor] = useState("")
  const [approversBgColor,setApproversBgColor] = useState("")
  const [isAdd,setIsAdd] = useState(false)
  const [storageYearOptions,setStorageYearOptions] = useState("")
  const [extendItems, setExtendItems] = useState(new Map())
  const [error,setError] = useState<Error>({
    extend: 0,
    onBehalf:0,
    memo:0,
    approver:0,
    file:0
  })
  
  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
  const validate = () => {
    let errorData:Error = {
      extend: 0,
      onBehalf:0,
      memo:0,
      approver:0,
      file:0
    }

    let errorCount = 0
    
    if(props.onBehalf){
      setOnBehalfsBgColor('')
      setApproversBgColor('')

        doIfElse(onBehalfs.length === 0 ,
            function () {
                errorCount++
                setOnBehalfsBgColor('red')
                errorData.onBehalf = 1
            }
            ,
            null
        );
        doIfElse(approvers.length === 0 ,
            function () {
                errorCount++
                setApproversBgColor('red')
                errorData.approver = 1
            }
            ,
            null
        );
        doIfElse(!dataForm.getFieldValue('memo') ,
            function () {
                errorCount++
                errorData.memo = 1
            }
            ,
            null
        );
        doIfElse(fileItems.length === 0 ,
            function () {
                errorCount++
                errorData.file = 1
            }
            ,
            null
        );
    }
  
    if(errorCount > 0){
      let element = document.getElementById('table-container') as HTMLDivElement
      let msg = ""
      if(errorData.file){
        msg = "Please attached file."
      }
      if(errorData.extend){
        msg = "Missing extend period fields."
      }
      if(errorData.approver || errorData.memo || errorData.onBehalf)
      {
        msg = "Please fill required fields."
      }
     
      messageApi.open({
        type: 'error',
        content: msg,
        duration: 1.5,
        style: { marginTop: element.getBoundingClientRect().top - 100 , marginLeft: element.getBoundingClientRect().left - 100  }
      });
      setError(errorData)
      return false
    }
    else{
      setError(errorData)
      return true;
    }
   
  }


  const onBehalfSelect = (item:any) => {
    setonBehalfs(item)
    if(item.length > 0){
      dataForm.setFieldsValue({
        onBehalf: item[0].key
      });
    }
    
  }

  const onApproversSelect = (item:any) => {
    setOnApprovers(item)
    if(item.length > 0){
      dataForm.setFieldsValue({
        approver: item[0].key,
        approverLevel: item[0].level
      });
    }
    
  }

  const onSearchEmployee = async(key:string) => {
   
    let token = await getToken()
    let result = await new HrWebService(token).SearchEmployee(key)
    if(result && result.success)
    {
      return result.data
    }
    else{
      console.log(result?.message)
      return []
    }
}

const getOnBehalf = () => {
    return onBehalfs.length > 0 ? onBehalfs[0].key : null
}

const getOnBehalfName = () => {
    return onBehalfs .length > 0 ? onBehalfs[0].name : null
}

const getOnBehalfApprover = () => {
    return onBehalfs .length > 0 ? approvers[0].key : null
}

const getOnBehalfApproverName = () => {
    return onBehalfs .length > 0 ? approvers[0].name : null
}

const onExtendChange = (cartonNo: string,value: number) => {
    let _extendItems = new Map(extendItems)
    _extendItems.set(cartonNo,value)
    setExtendItems(_extendItems)
}

const handleSave = async (saveType: boolean) => {
  if(validate()){
      let statusId = returnIfElse(saveType, CartonStatus.WaitApproval, CartonStatus.SaveDraft) 
    if(props.onBehalf){
        statusId = returnIfElse(saveType, CartonStatus.WaitAdmin, CartonStatus.SaveDraft)  
    }
    
    let requestCarton:RequestDestroyCarton = {
      recordNo:props.destroyId,
      requestTypeId: RequestType.ExtendCarton,
      requester:user.employeeId,
        onBehalf: returnIfElse(props.onBehalf, getOnBehalf(), user.employeeId),
        onBehalfName: returnIfElse(props.onBehalf, getOnBehalfName(), user.employeeName)  ,
        approveBy: returnIfElse(props.onBehalf, getOnBehalfApprover()  , user.approverId) ,
        approveByName: returnIfElse(props.onBehalf,getOnBehalfApproverName(), user.employeeName) ,
      approveLevel: user.approverLevel,
      memo:dataForm.getFieldValue('memo'),
      statusId: statusId,
      files:fileItems,
      cartons:allCartonItems.map( (item) => { 
        item.extendAmt = extendItems.get(item.cartonNo)
        item.cartonStatusId = CartonStatus.Extend 
        return item; 
      })
   }
    console.log(requestCarton)
    let token = await getToken()
    let result:any = null
  
    if(requestCarton.recordNo === 0){
      result = await new DestructionCartonService(token).Create(requestCarton);
    }
    else{
      result = await new DestructionCartonService(token).Update(requestCarton);
    }
    console.log(result)
    let element = document.getElementById('destruction-section') as HTMLElement
    if(result && result.success){

      messageApi.open({
        type: 'success',
        content: 'Save success',
        duration: 1,
        style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
      });
      setTimeout(() => {
          handleCancel()

          doIfElse(props.page === 'destruction' ,
              function () {
                  doIfElse(saveType ,
                      function () {
                        props.onReload()
                          navigate('/destruction-carton-tracking')
                      }
                      ,
                      function () {
                        props.onReload()
                          navigate('/destruction-carton-save')
                      }
                  );

              }
              ,
              null
          );
          doIfElse(props.page === 'onBehalf' ,
              function () {
                  doIfElse(saveType ,
                      function () {
                        props.onReload()
                        navigate('/onbehalf-2')
                      }
                      ,
                      function () {
                        props.onReload()
                        navigate('/onbehalf-3')
                      }
                  );
              }
              ,
              null
          );
       
        
      }, 1000);
     
   }
    else{
      Modal.error({
          icon: <CloseCircleOutlined />,
          title: 'This is an error message',
          content: result?.message,
      });
    }
  }
 
};

  const handleDelete = async () => {
    let _allCartonItems:CartonList[] = Object.assign([],allCartonItems)
    selectItems.forEach(item => { 
      let index = allCartonItems.findIndex( x => x.cartonNo === item)
      if(index > -1){
        _allCartonItems.splice(index,1)
      }
    })
    console.log(selectItems)
    console.log(_allCartonItems)
    let items = Helper.Chunk(_allCartonItems,perpage)
    setAllCartonItems(_allCartonItems)
    setCartonItems(items)
    setPage(1)
    setSelectItems([])
    onCheckAll(false)
    
  }

  const handleDeleteByItem = async (cartonNo:string) => {
  
      let _allCartonItems:CartonList[] = Object.assign([],allCartonItems)
      console.log(cartonNo)
      let index = allCartonItems.findIndex( item => item.cartonNo === cartonNo)
      console.log(index)
      if(index > -1)
      {
        _allCartonItems.splice(index,1)
        let items = Helper.Chunk(_allCartonItems,perpage)
        setAllCartonItems(_allCartonItems)
        setCartonItems(items)
        setPage(1)
        setSelectItems([])
        onCheckAll(false)
      }
      
      let element = document.getElementById('table-container') as HTMLElement
        messageApi.open({
          type: 'success',
          content: 'Delete success',
          duration: 1,
          style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
      });
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const confirmSave = () => {
    modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Confirm to submit?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => handleSave(true)
    });
  };

  
  const handleCancel = () => {
      let elements = document.getElementsByClassName('ant-modal-close') as HTMLCollection
      console.log(elements)
      let ele_len = elements.length
      let i = 0
      while (i < ele_len){
          let btnClose = elements[i] as HTMLButtonElement
          btnClose.click()
          i++
      }

  };


  const getDestructionData = async () => {
    
      let token = await getToken()
      let result = await new DestructionCartonService(token).GetRequest(props.destroyId)
    
      console.log(result)
      if(result && result.success && result.data)
      {
        let data = result.data
        let _allCartonItems = data.cartons
        if(_allCartonItems.length > 0)
        {
          
          let _extendItems = new Map()
          let _storageYearOptions =  await getSystemConfig()
        
          _allCartonItems.forEach((item) => {
            _extendItems.set(item.cartonNo,item.extendAmt)
          })

          let items = Helper.Chunk(_allCartonItems,perpage)
         setExtendItems(_extendItems)
         setStorageYearOptions(_storageYearOptions)
         setAllCartonItems(_allCartonItems)
         setCartonItems(items)
    
        }
        
        if(props.onBehalf === 1){
          let approvers:Item[] = [{
            key:data.approveBy,
            name: data.approveByName,
            location: "",
            floor: "",
            orgUnit:""
          }]
          onApproversSelect(approvers)
  
            let onBehalfUser:Item[] = [{
              key:data.onBehalf,
              name: data.onBehalfName,
              location: "",
              floor: "",
              orgUnit:""
            }]
            onBehalfSelect(onBehalfUser)
          
            setFileItems(data.files)
          dataForm.setFieldsValue({
            recordNo: props.destroyId,
            requester: user.employeeId,
            requesterName: `Name: ${user.employeeName}  Org. Unit: ${orgUnit} Position: ${position}, Officer Services Tel: ${directPhone}`,
            memo:data.memo
          })

        }
        else{
          dataForm2.setFieldsValue({
            requesterName: `Name: ${user.employeeName}  Org. Unit: ${orgUnit} Position: ${position}, Officer Services Tel: ${directPhone}`,
            approverName:data.approveByName
        })
        }

       
      }

     

  }

  const getStorageData = async () => {
  
    let token = await getToken()
    let parameter = InitHelper.InitialParameter();
      parameter.page =1
      parameter.limit = 0
      parameter.cartonNoList = props.items

    let result = await new StorageCartonService(token).SearchCarton(parameter)
    
    console.log(result)
    if(result && result.success && result.data)
    {
      let data = result.data
      let _allCartonItems = data.items
      if(_allCartonItems.length > 0)
      {
        
        let _extendItems = new Map()
        let _storageYearOptions =  await getSystemConfig()
        let defaultExtendItem = _storageYearOptions.split(',')[0]
        _allCartonItems.forEach((item) => {
            _extendItems.set(item.cartonNo,defaultExtendItem)
          })

          let items = Helper.Chunk(_allCartonItems,perpage)

         setExtendItems(_extendItems)
        setStorageYearOptions(_storageYearOptions)
        setAllCartonItems(_allCartonItems)
        setCartonItems(items)
      }
      console.log(user.approverName)
      dataForm2.setFieldsValue({
        requesterName: `Name: ${user.employeeName}  Org. Unit: ${orgUnit} Position: ${position}, Officer Services Tel: ${directPhone}`,
        approverName: user.approverName
    })
      
    }


  }


  useEffect(() => {
    initial()

   

  }, [])

  const initial = async () => {
  
    if(props.destroyId > 0){
        
       await getDestructionData()
    }
    else{
      await getStorageData()
    }
  }

  const getSystemConfig = async () =>{
    let token = await getToken()
     const result = await new SystemConfigService(token).getSystemConfig()
     if(result && result.data){
        return result.data.extendStoreYearValue
     }
     else{
        return ""
     }
      
 }
  const onCheckAll = (checked:boolean) => {
    if(checked){
      setCheckAll(true)
      let _selectItems = allCartonItems.map( x => x.cartonNo)
      setSelectItems(_selectItems)
    }
    else{
      setCheckAll(false)
      setSelectItems([])
    }
  };

  const onCheckChange = (checked:boolean,cartonNo:string) => {
    let checkAllElement = document.getElementById('check-item-all') as HTMLInputElement
    let _selectItems:string[] = Object.assign([],selectItems)
    if(checked){
        _selectItems.push(cartonNo)
        if(_selectItems.length === allCartonItems.length){
          checkAllElement.checked = true
          checkAllElement.indeterminate = false
        }
        else{
          checkAllElement.checked = false
          checkAllElement.indeterminate = true
        }
        
    }
    else{
      let index = _selectItems.indexOf(cartonNo)
      if (index > -1) {
        _selectItems.splice(index, 1);
      }

      if(_selectItems.length === 0){
        checkAllElement.checked = false
        checkAllElement.indeterminate = false
      }
      else{
        checkAllElement.checked = false
        checkAllElement.indeterminate = true
      }
    }

    console.log(_selectItems)
    setSelectItems(_selectItems)
  }

  const onPageChange = (_page:number,_perpage: number) => {
    if(_page !== page){
      setPage(_page)
    }
    if(_perpage !== perpage)
    {
      let items = Helper.Chunk(allCartonItems,_perpage)
      setCartonItems(items)
      setPerpage(_perpage)
    }
  }


  const onUploadRemove = (guid:string,) => {
    let _fileItems = Object.assign([],fileItems)
    let fileIndex = fileItems.findIndex( x => x.guid === guid)
    _fileItems.splice(fileIndex,1)
    setFileItems(_fileItems)
  }
  
  const validateFile = (file:UploadFile<any>,msg:boolean) => {
    let maxFileSzie = 10000000
    let fileExtensions = [ 'pdf']
  
    let type = ""
    if(file.type){
      type = file.type.split('/').reverse()[0].toLowerCase()
    }
    let typename = file.name.split('.').reverse()[0].toLowerCase()
    if(fileExtensions.indexOf(type) === -1 && fileExtensions.indexOf(typename) === -1){
      if(msg){
        message.error(`file type is not allowed.`);
      }
     
      return false 
          
    }

    if(file.size && file.size > maxFileSzie){
      if(msg){
        message.error(`File has over size limit 10MB`);
      }
      
      return false
    }

    return true
  }
  const onUploadChange = async (info: UploadChangeParam<UploadFile>) => {
   
    if(!validateFile(info.file,false)){
      return false
    }
    
    let file = info.file
    let base64string = await toBase64(file.originFileObj)
    let fileItem:FileItem = {
        guid: file.uid,
        name: file.name,
        size: file.size,
        type: file.type,
        byteData: base64string
     }
     
    let _fileItems = Object.assign([],fileItems)
    let fileIndex = fileItems.findIndex( x => x.guid === file.uid)
    if(fileIndex === -1){
       _fileItems.push(fileItem)
      setFileItems(_fileItems)
    }
  }

  const handleBeforUpload = (file:UploadFile<any>) => {
    validateFile(file,true)
  }

  const toBase64 = (file:any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  const handleAdd = () =>{
    setIsAdd(true)
  }
  
  const AddItem = (items:string[]) => {
        console.log(items)
        setIsAdd(false)
        if(items.length > 0)
        {
            getStorageData2(items)
        }
       
  }

  const getStorageData2 = async (items:string[]) => {
  
    let token = await getToken()
    let parameter = InitHelper.InitialParameter();
      parameter.page =1
      parameter.limit = 0
      parameter.cartonNoList = items

    let result = await new StorageCartonService(token).SearchCarton(parameter)
    
    console.log(result)
    if(result && result.success && result.data)
    {
      let data = result.data
      let storageItems = data.items
      if(storageItems.length > 0)
      {
        let _allCartonItems = storageItems.concat(allCartonItems)
        let _extendItems = new Map(extendItems)
        let defaultExtendItem = storageYearOptions.split(',')[0]
        _allCartonItems.forEach((item) => {
            _extendItems.set(item.cartonNo,defaultExtendItem)
          })
        let items = Helper.Chunk(_allCartonItems,perpage)

          setExtendItems(_extendItems)
        setAllCartonItems(_allCartonItems)
        setCartonItems(items)

      }
      
    }
}

const applyAll= () => {
    console.log('app')
    let cartonNo = cartonItems[page -1][0].cartonNo
    let extendAmt = extendItems.get(cartonNo)
    let _allCartonItems:CartonList[] = Object.assign([],allCartonItems)
    let _extendItems = new Map(extendItems)
    _allCartonItems = _allCartonItems.map(x => {x.extendAmt = extendAmt;return x})
    _allCartonItems.forEach((item) => {
        _extendItems.set(item.cartonNo,extendAmt)
      })
      let items = Helper.Chunk(_allCartonItems,perpage)
      console.log(_allCartonItems)
      setExtendItems(_extendItems)
      setAllCartonItems(_allCartonItems)
      setCartonItems(items)
  }

  return (
    
    <div id="destruction-section">
         { isAdd ?
            <DestructionCartonAdd 
                requestTyeId={RequestType.ExtendCarton}
                onBehalf={props.onBehalf} 
                onSelect={AddItem} 
                items={allCartonItems}
                />
            :
      <Card >
      { props.onBehalf ?
        <Form
          {...layout}
          form={dataForm}
          name="nest-messages"
          labelAlign="left"
          colon={false}
          labelWrap={true}
        >

          <Row>
            <Col md={11} >
              <Form.Item name="onBehalfName" label="On Behalf"  rules={[{ required: true }]}>
                <EmployeeAutoComplete
                     placeholder='Search by Name'
                                      selectedItems={returnIfElse(onBehalfs, onBehalfs, [] )}
                    onChange={item => onBehalfSelect(item)}
                    service={onSearchEmployee}
                    minimumTextLength={3}
                    borderColor={onBehalfsBgColor}
                    pickOnlyOne
                  />
              </Form.Item>
              {error.onBehalf ?
            <div style={{color:'red',textAlign:'center',marginTop:'-16px',marginBottom: '10px'}}>On Behalf is Required field</div>:<></>
              }
            </Col>
            <Col md={2} ></Col>
            <Col md={11} >
              <Form.Item name="approverName" label="Approver"  rules={[{ required: true }]}>
                <EmployeeAutoComplete
                    placeholder='Search by Name'
                                      selectedItems={returnIfElse(approvers, approvers, [] )}
                    onChange={item => onApproversSelect(item)}
                    service={onSearchEmployee}
                    borderColor={approversBgColor}
                    minimumTextLength={3}
                    pickOnlyOne
                  />
              </Form.Item>
              { error.approver ?
              <div style={{color:'red',textAlign:'center',marginTop:'-16px',marginBottom: '10px'}}>Approver is Required field</div>:<></>
}
            </Col>

          </Row>
          <Row>
            <Col md={11} >
                              <Form.Item name="memo" label="Org. Unit Reference/Memo" rules={[{ required: true }]} validateStatus={returnIfElse(error.memo, "error" , undefined ) }>
                <Input />
              </Form.Item>
              { error.memo?
              <div style={{color:'red',textAlign:'center',marginTop:'-16px',marginBottom: '10px'}}>Org. Unit Reference/Memo is Required field</div>:<></>
}
            </Col>
            <Col md={2} ></Col>
            <Col md={11} >
                              <Form.Item name="onbehaft" label="Attached" rules={[{ required: true }]} validateStatus={returnIfElse(error.file, "error" , undefined ) }>
                    <Upload 
                           
                            beforeUpload={handleBeforUpload}
                            onChange={onUploadChange}
                            showUploadList={false}
                            multiple
                          >
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                          </Upload>
                         
                 <span style={{
                    marginLeft:'10px', 
                    marginTop:'5px',
                    position: 'absolute',
                                      color: returnIfElse(error.file === 0, '#00338D', 'red'  ),
                    fontWeight:'500'
                  }}>
                                      {returnIfElse(error.file === 0, 'File size Max: 10MB/file Accepted: .pdf only', 'Please attached file.'  )  }
                </span>
                 {fileItems.length > 0 && fileItems.map( file => (
                            <div style={{marginTop:'10px', display: 'flex', justifyContent:'space-between'}}>
                              <div style={{textAlign: 'left'}}>{file.name}</div>
                              <div>
                                
                                <Popconfirm
                                  title="Delete file"
                                  description="Confirm to delete?"
                                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                  onConfirm = { () => onUploadRemove(file.guid) }
                                  >
                                    <AiOutlineDelete size={15} style={{cursor: 'pointer'}} />
                                </Popconfirm>
                              </div>
                            </div>
                            ))}
              </Form.Item>
            </Col>
            
          </Row>
        </Form>
        :
        <Form
        {...layout}
        form={dataForm2}
        name="dest-form"
        labelAlign="left"
        colon={false}
        labelWrap={true}
      >

        <Row>
          <Col md={22} >
            <Form.Item name="requesterName" label="Requester" labelCol={{ md: 3 }}   >
              <Input disabled style={{ widows: '100%' }} />

            </Form.Item>
          </Col>

        </Row>
        <Row>
          <Col md={11} >
            <Form.Item name="approverName" label="Approver">
              <Input disabled/>
              </Form.Item>
          

          </Col>
          <Col md={2} ></Col>
          <Col md={11} >


          </Col>

        </Row>
      </Form>

      }
        <div style={{ marginRight: 10, marginTop: 15, marginBottom: 5 }}>
        { selectItems.length > 0 ?
          <Popconfirm
            title="Delete the task"
            description="Confirm to delete?"
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm = { () => handleDelete() }
          >
            <Button type="primary" className='btn-red mr-btn'>
              <AiFillDelete className='icn-btn' /> Delete
            </Button>
        </Popconfirm>
        :
          <Button type="primary" className='btn-red mr-btn' disabled>
            <AiFillDelete className='icn-btn' /> Delete
          </Button>
        }

        <Button key={Helper.Uniqid()} type="primary" className='btn-blue mr-btn' style={{width:'94px'}} onClick={handleAdd}>
                <AiOutlinePlusCircle className='icn-btn' />Add
          </Button>
        </div>
        <br></br>
        <div id="table-container">
       
       <table className='idoc-table'>
         <thead id="head-table-fixed">
           <tr>
             <th style={{ textAlign: 'left',width: '50px'}}>
                     <div className="form-check">
                       <input  
                         type="checkbox" 
                         id={'check-item-carton-all'} 
                         className="form-check-input check-item" 
                         checked={checkAll} 
                         onChange={e => onCheckAll(e.target.checked)}
                       />
                       <label className="form-check-label" htmlFor={'check-item-carton-all'}/>
                     </div>
                   
             </th>
             <th >Carton No.</th>
             <th >Requested No.</th>
             <th >Requester</th>
             <th >Requested date</th>
             <th >Org. Unit</th>
             <th >Owner</th>
             <th >Latest Extension Date</th>
             <th>Destruction Due Date</th>
             <th>
                <span>Extend Period(Year)</span>
                <Tooltip title="Paste data from first to all row">
                    <AiOutlineCopy style={{ cursor: 'pointer' ,fontSize:'25px',color:'#009fda'}} onClick={applyAll} />
                  </Tooltip>
             </th>
             <th>Delete</th>
           </tr>
         </thead>
        
         <tbody >
         { cartonItems.length > 0 && cartonItems[page -1].map((item:CartonList) => (
             <tr>
               <td style={{ textAlign: 'left',width: '50px'}}>
                     <div className="form-check">
                       <input  
                         type="checkbox" 
                         checked={checkAll || selectItems.indexOf(item.cartonNo) > -1}
                         id={'check-item-carton-' + item.cartonNo} 
                         value={item.cartonNo} 
                         className="form-check-input check-item" 
                         onChange={ e => onCheckChange(e.target.checked,item.cartonNo)}
                       />
                       <label className="form-check-label" htmlFor={'check-item-carton-' + item.cartonNo}/>
                     </div>
                   </td>
                   <td style={{textAlign: 'center',width:'120px'}}>
                 <span>{item.cartonNo}</span>
               </td>
               <td style={{textAlign: 'center'}}>{item.requestNo}</td>
               <td style={{textAlign: 'center'}}>{item.requesterName}</td>
               <td style={{textAlign: 'center'}}>{ dayjs(item.requestDate).format('DD MMM YYYY')}</td>
               <td style={{textAlign: 'center'}}>{item.orgUnit}</td>
               <td style={{textAlign: 'center'}}>{item.ownerName}</td>
                 <td style={{ textAlign: 'center' }}>{returnIfElse(item.latestExtensionDate, dayjs(item.latestExtensionDate).format('DD MMM YYYY'), '-' ) }</td>
               <td style={{textAlign: 'center'}}>{ dayjs(item.expireDate).format('DD MMM YYYY')}</td>
               <td style={{textAlign:'center'}}>
                <Select key={Helper.Uniqid()} style={{ width: '100%' }} 
                    defaultValue={extendItems.get(item.cartonNo)}
                    options={ storageYearOptions.split(",").map(item => { return { value: item, label: item.toString()}}) }
                    onChange={(value) => onExtendChange(item.cartonNo,value)}
                  />
               </td>
               <td style={{textAlign: 'center'}}>
                 <Popconfirm
                       title="Delete the task"
                       description="Confirm to delete?"
                       icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                       onConfirm = { () => handleDeleteByItem(item.cartonNo) }
                     >
                       <AiFillDelete size={20} color="red" style={{ cursor: 'pointer'}}/>
                     </Popconfirm>
               </td>
             </tr>
           ))
         }
         
         </tbody>

       </table>
     
       </div>
       <div style={{marginTop:'10px',textAlign:'right'}}>
       { allCartonItems.length > 0 ?
            <Pagination 
                key={Helper.Uniqid()}
                current={page} 
                pageSize={perpage}
                total={allCartonItems.length} 
                onChange={onPageChange} 
                pageSizeOptions={pageSizeOptions}
                showSizeChanger={true}
            />:<></>
       }
       </div>

       <div style={{ textAlign: 'center' }}>
          <Button type="default" className='mr-btn' onClick={handleCancel}  >
            <AiOutlineCloseCircle className='icn-btn' />Cancel
          </Button>
          <Button type="primary" className='mr-btn' onClick={() => handleSave(false)} disabled={cartonItems.length === 0 }>
            <AiOutlineSave className='icn-btn' />Save Draft
          </Button>
          <Button type="primary" onClick={confirmSave} disabled={cartonItems.length === 0}>
            <AiFillSave className='icn-btn' />Submit
          </Button>
        </div>
        
      </Card>
      }
      {contextHolder}
      {contextHolderMessage}
    </div>
  );

}

export default DestructionCartonExtenModal
