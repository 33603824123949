import { useEffect, useState } from 'react';
import { Card, Col, Form, Modal, Row ,Spin ,Collapse } from 'antd';
import { Label } from 'reactstrap';
import { RequestDetail,CartonList } from '../../models';
import { CollectCartonService } from '../../services';
import { Helper } from '../../helpers';
import dayjs from 'dayjs'
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import {  User} from '../../models'
import { useNavigate } from 'react-router-dom';
import CartonDetail from '../Share/CartonDetail'

const ExtendCartonView = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id:string| null = urlParams.get('id')
    const recordNo:string = id ? id : ""
    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const { instance, accounts } = useMsal();
    const [isModalCartonDetailOpen, setIsModalCartonDetailOpen] = useState(false);
    const [requestNo,setRequestNo] = useState("")
    const [box,setBox] = useState("")
  const { Panel } = Collapse;
  const navigate = useNavigate();
  const [details, setDetails] = useState<RequestDetail[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
  

    useEffect(() => {
       
    getData()
  }, [])
  
  const getData = async () => {
    if(!recordNo) return

      setIsLoading(true)
      let token = await getToken()
    let result = await new CollectCartonService(token).GetExtendDetail(recordNo)
    console.log(result)
    if(result && result.success){
        let details = result.data.filter((item:RequestDetail) => {
             let cartonList = item.cartonList.filter( x => x.owner == user.employeeId)
             if(cartonList.length > 0)
             {
               return cartonList.sort((a:CartonList,b:CartonList) => {
                    if(a.cartonNo > b.cartonNo){
                        return 1
                    }
                    else if(b.cartonNo > a.cartonNo){
                        return -1
                    }
                    else{
                        return 0
                    }
               })
             }
        })

        if(details.length > 0)
        {
            setDetails(details)
        }
        else
        {
            navigate('/permissiondenided')
        }
    }
    else{
        navigate('/permissiondenided')
    }
    setIsLoading(false)
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const handleCartonDetailCancel = () => {
        setIsModalCartonDetailOpen(false);
      }
  
      const handleCartonDetailOpen = async (_requestNo:string,_box:string) => {
        setRequestNo(_requestNo)
        setBox(_box)
        setIsModalCartonDetailOpen(true);
      }

  return (

    <div style={{minHeight: '150px'}}>
{ isLoading ?
       <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
        :
      <Card >
    { details.map((detail,index) => (
        <div>
        <Collapse defaultActiveKey={[index]}  style={{marginTop: '10px'}}>
            <Panel header={detail.requestNo} key={index} >
                <Form 
                    {...layout}
                    key={Helper.Uniqid()}
                    name={'form-' + index}
                    labelAlign="left"
                    colon={false}
                    labelWrap={true}
                >
          <Row>
            <Col md={22} >
              <Form.Item  label="Requester" labelCol={{ md: 2 }}  >

              <Label className='label-det'>{ detail.requesterName }</Label>
                <Label className='mr-label' >Org. Unit</Label>
                <Label className='label-det'>{ detail.orgUnit }</Label>
                <Label className='mr-label' >Position</Label>
                <Label className='label-det'>{ detail.position }</Label>
                <Label className='mr-label' >Tel No.</Label>
                <Label className='label-det'>{ detail.tel }</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={8} >
              <Form.Item  label="Request No"  >
                <Label className='label-det '>{detail.requestNo}</Label>
              </Form.Item>
             
              <Form.Item  label="Floor"  >
                <Label className='label-det'>{detail.floor}</Label>
              </Form.Item>
              <Form.Item  label="Return Date"  >
                <Label className='label-det'>{dayjs(detail.returnDate).format('DD MMM YYYY')}</Label>
              </Form.Item>
              
             
            </Col>
            <Col md={8} >
                 <Form.Item  label="Carton Quantity"  >
                    <Label className='label-det'>{detail.quantity} carton(s)</Label>
                </Form.Item>
                <Form.Item  label="Location"  >
                <Label className='label-det'>{detail.location}</Label>
              </Form.Item>
             
            
            </Col>
            <Col md={8} >
              <Form.Item  label="Status"  >
                <Label className='label-det'>{detail.status}</Label>
              </Form.Item>
              
              <Form.Item  label="Place"  >
                <Label className='label-det'>{detail.place}</Label>
              </Form.Item>
              
            
               
              
            </Col>
          </Row>
       
                </Form>
        
                <table className='idoc-table'>
            <thead id="head-table-fixed">
              <tr>

                <th >Carton No.</th>
                <th >Storage Requested No.</th>
                <th >Owner</th>
                <th >OrgUnit</th>
                <th >Extend Amount</th>
                <th >Expect Return Date</th>
                <th >Extend Date</th>
                <th>Extend Reason</th>
              </tr>
            </thead>
           
            <tbody >
            { detail.cartonList.map((item:CartonList) => (
                <tr>
                  <td style={{textAlign: 'center',width:'120px'}}>
                  <a className='a-table' onClick={() => handleCartonDetailOpen(item.requestNo,item.cartonNo)} >
                    { item.cartonNo }
                </a>
                  </td>
                  <td style={{textAlign: 'center'}}>
                    {item.requestNo}
                  </td>
                  <td style={{textAlign: 'center'}}>{item.ownerName}</td>
                  <td style={{textAlign: 'center'}}>{item.orgUnit}</td>
                  <td style={{textAlign: 'center'}}>{item.extendAmt}</td>
                  <td  style={{textAlign: 'center'}}>{dayjs(item.returnDate).format("DD MMM YYYY")}</td>
                  <td  style={{textAlign: 'center'}}>{dayjs(item.extendDate).format("DD MMM YYYY")}</td>
                  <td style={{textAlign: 'center'}}>{item.extendReason}</td>
                </tr>
              ))
            }
            
            </tbody>
  
          </table>
            </Panel>
        </Collapse>
          
        </div>
        ))
    }
      </Card>
}
    <Modal title="" open={isModalCartonDetailOpen} footer={''} width={1200} onCancel={handleCartonDetailCancel}>
        <CartonDetail key={Helper.Uniqid()} requestNo={requestNo} box={box} />
      </Modal>
   
     
    </div>
  );

}

export default ExtendCartonView
