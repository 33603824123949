import { useEffect, useState } from 'react';
import { Button, Card, Checkbox, Col, Collapse, Form, Input, Row, Select,Pagination,Typography,Popconfirm, Spin, Modal, message } from 'antd';
import type { SelectProps } from 'antd';
import { AiFillEdit, AiOutlineCheckSquare,AiOutlineSearch,AiOutlinePlusSquare,AiOutlineCloseSquare,
   AiOutlineSave, AiOutlineBorder,AiOutlineSync } from 'react-icons/ai';

import MessageCommon from '../../enum/Message';
import Color from '../../enum/Color';
import { Employee, Item, User } from '../../models'
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { CheckboxValueType } from 'antd/es/checkbox/Group';
import UserService from '../../services/UserService';
import moment from 'moment';
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete';
import { Helper } from '../../helpers';
import { HrWebService } from '../../services';
import { ExclamationCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import secureLocalStorage from 'react-secure-storage';
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";


const ManageUser = () => {
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }



    const { instance, accounts } = useMsal();
  const [dataForm] = Form.useForm();
  const [searchForm] = Form.useForm();

  const [modal, contextHolder] = Modal.useModal();
  const [messageApi, contextHolderMessage] = message.useMessage();

  const [userData, setUserData] = useState<User[]>([]);
  const [userDataDisplay, setUserDataDisplay] = useState<User[]>([]);
  const { Panel } = Collapse;
  const [roleId, setRoleId] = useState<number[]>([]);
  const [isAdd,setIsAdd] = useState(false)
  const [editId,setEditId] = useState(0)
  const [isReporter,setIsReporter] = useState(false)
  const [editStatus,setEditStatus] = useState(0)
  const [editEmailSend,setEditEmailSend] = useState(false)
  const [owners, setOwners] = useState<Item[]>([])
  const [newUser,setNewUser] = useState<Employee>()
  const [newUserFake,setNewUserFake] = useState<Item[]>()
  const [isLoad,setIsLoad] = useState(false)
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const [pagination,setPagination] = useState({currentPage:1,pageSize:Number(100)})
  const [isValidRole,setIsValidRole] = useState(false);
 
  const roles:SelectProps['options'] = [
    { value: 2, label: 'System Admin' }, 
    { value: 3, label: 'Archive Officer' },
    { value: 4, label: 'User Valve Room' },
    { value: 5, label: 'Report Viewer' },
    { value: 1, label: 'Super Admin' }
  ]


  const getUsers = async (body:any) =>{

    try {
      setIsLoad(true)
        let token = await getToken()
        const { data } = await new UserService(token).getUsers(body)
      setUserData(data);  
      let start = (pagination.currentPage-1)*pagination.pageSize
      let until = start+pagination.pageSize
      const  display:User[] = data.filter((item,index)=>{
        if(index>=start&&index<until){
          return item;
        }
      })
    
      setUserDataDisplay(display)

    } catch (error) {
    }finally{
      setIsLoad(false)
    }
  }

  let allDataHr:Employee[]

  const handleResetAddForm = () => {
    dataForm.setFieldsValue({
      "addRoles": [],
      "email":undefined,
      "name":undefined,
      "sand_email":undefined,
      "status":"1"
    });
    setNewUserFake([])
    setIsReporter(false)
  };
  const handleResetSearchForm = async () => {
    searchForm.setFieldsValue({
      "name":undefined,
      "id":undefined,
      "search_role":undefined,
      "status":""
    });
    setOwners([])
    await getUsers({
      "search_role":"",
      "id":"",
      "status":""
    })
  };

  const onFinishAddForm =async (values: any) => {

    let data = {...values,sendEmail:values.sendEmail?1:0,status:values.status==='1'?1:0}

    let confident = {
      confident:0,
      noneConfident:0,
    };

    let indexNoneConfident = data.addRoles.indexOf('Non-Confident')
    if(indexNoneConfident>-1){
      confident.noneConfident = 1;
      data.addRoles.splice(indexNoneConfident,1);
    }

    let indexConfident = data.addRoles.indexOf('Confident')
    if(indexConfident>-1){
      confident.confident = 1;
      data.addRoles.splice(indexConfident,1);
    }
    let AddRole = data.addRoles
    let body = {
      user:{...data,AddRole:AddRole,confident:confident,sendEmail:data.sendEmail,updateBy:user.employeeId
      },
      emp:newUser
    }

    try {
        let token = await getToken()
        const res = await new UserService(token).createUser(body)
        if (!res)
            return null;
      if(!res.success){
        Modal.error({
          icon: <CloseCircleOutlined />,
          title: 'This is an error message',
          content: res?.message,
        });
      }else{
        messageApi.open({
          type: 'success',
          content: 'Save success',
          duration: 1,
        });
      }
      await getUsers({"search_role":"","name":"","status":""})
    } catch (error) {
      console.log("🚀 ~ file: ManageUser.tsx:113 ~ onFinishAddForm ~ error:", error)
    }finally{
      dataForm.setFieldValue('addRoles',[])

    }

  };
  const onFinishSearchForm = async (values: any) => {
    console.log("🚀 ~ file: ManageUser.tsx:160 ~ onFinishSearchForm ~ values:", values)
    await getUsers(values)
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onEdit = (userId: number,item:User) => {
    let roleId = item.roleIds.map(item=>item.roleId)
    setEditEmailSend(item.emailSend==1?true:false)
    setRoleId(roleId)
    setEditStatus(item.active)
    setEditId(userId)
  }

  const onEditCancel = () => {
    setEditId(0)
  }


  const onChange = (checkedValues: CheckboxValueType[]) => {
    let roles = dataForm.getFieldsValue(true).addRoles
    if(checkedValues.includes("5")){
      setIsReporter(true)
      if(!checkedValues.includes("Non-Confident")){
        dataForm.setFieldsValue({ 'addRoles': [...roles,'Non-Confident'] });
      }
      return
    }
    let index = roles.indexOf('Non-Confident')
    if(index>-1){
      roles.splice(index,1);
    }
    index = roles.indexOf('Confident')
    if(index>-1){
      roles.splice(index,1);
    }
    dataForm.setFieldValue('addRoles',roles );
    setIsReporter(false)
  };

  const onUpdateById = async (item:User) => {
    let body = {
      addRoles:roleId,
      sendEmail:editEmailSend?1:0,
      status:editStatus,
      userId:editId,
      updateBy:user.employeeId
    }
    let token = await getToken()
      let res = await new UserService(token).updateUser(body);
      if (!res)
          return null;
    if(!res.success){
      Modal.error({
        icon: <CloseCircleOutlined />,
        title: 'This is an error message',
        content: res?.message,
      });
    }else{
      messageApi.open({
        type: 'success',
        content: 'Save success',
        duration: 1,
      });
    }
    await getUsers({ "search_role":"","id":"","status":""})
    setEditId(0)
  }

  const confirmAdd = (values:any) => {
      
    modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Confirm to submit?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => onFinishAddForm(values)
    });
  
}
  const confirmSave = (item:User) => {
      
      modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Confirm to submit?',
        okText: 'OK',
        cancelText: 'Cancel',
        onOk: () => onUpdateById(item)
      });
    
  }
  const onEditRoleChange = (items:any) => {

    setRoleId(items)
    if(items.length===0){
      setIsValidRole(true) 
      return
    }
    setIsValidRole(false) 
  }
  const onEmailSendChange = (e:CheckboxChangeEvent) =>{
    setEditEmailSend(e.target.checked)
  }
  const onChangeStatus = (status:any) =>{
    setEditStatus(status)
  }
  const onOwnerSelect = (item:any,formType:string) => {
    switch (formType) {
      case 'SEARCH':
        if(allDataHr&&item){
          setOwners(item)
          searchForm.setFieldValue('id',item[0].key);
        }else{
          searchForm.setFieldsValue({'id':undefined,'email':''});
          setOwners([])
        }
        break;
      case 'ADD':
        if(allDataHr&&item){
          let emp = allDataHr.find(emp =>emp.employeeId === item[0].key )
          dataForm.setFieldsValue({'email':emp?.email});
          dataForm.setFieldValue('name',emp?.employeeName)
          setNewUserFake(item)
          setNewUser(emp)
        }else{
          dataForm.setFieldsValue({'email':undefined,'name':undefined});
          setNewUserFake([])
          setNewUser(undefined)
        }
        break;
      default:
        break;
    }
  }
  const onSearchEmployee = async(key:string) => {
   
    let token = await getToken()
    let result = await new HrWebService(token).SearchEmployee(key)
    if(result && result.success)
    {
      allDataHr = result.data
      return result.data
    }
    else{
      console.log(result?.message)
      return []
    }
    }
    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const handleChangeCurrentPageAction = (currenrPage: any, pageSize: any) => {

        setPagination({ ...pagination, currentPage: currenrPage })

        let start = (currenrPage - 1) * pagination.pageSize
        let until = (currenrPage - 1) * pagination.pageSize + pagination.pageSize

        const display: User[] = userData.filter((item, index) => {
            if (start <= index && until > index) {
                return item;
            }
        })

        setUserDataDisplay(display)
    }
    const handleChangePageSizeAction = (currenrPage: any, pageSize: any) => {

        setPagination({ ...pagination, pageSize: pageSize })
        if (userData.length < pageSize) {
            const display: User[] = userData.filter((item, index) => {
                if (index < pageSize) {
                    return item;
                }
            })
            setUserDataDisplay(display)
            setPagination({ ...pagination, currentPage: 1, pageSize: pageSize })
            return
        }

        let start = (pagination.currentPage - 1) * pagination.pageSize
        let until = (pagination.currentPage - 1) * pagination.pageSize + pageSize

        const display: User[] = userData.filter((item, index) => {
            if (start <= index && until > index) {
                return item;
            }
        })

        setUserDataDisplay(display)
    }

  const handleChangeCurrentPage = (currenrPage:any,pageSize:any) => {

      if (currenrPage !== pagination.currentPage) {
          handleChangeCurrentPageAction(currenrPage, pageSize)
    }

      if (pageSize !== pagination.pageSize) {
          handleChangePageSizeAction(currenrPage, pageSize)
    }
    
    }

  useEffect( () => {
    
    const onInit =async () => {
      setIsLoad(true)
      await getUsers({
        "search_role":"",
        "id":"",
        "status":""
      })
    }
    onInit()
  }, [])

  return (
    <div>
      <Collapse>
        <Panel header="Search" key="1">
          <Form
            {...layout}
            form={searchForm}
            name="nest-messages"
            onFinish={onFinishSearchForm}
            validateMessages={validateMessages}
            initialValues={{'status':''}}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
             <Row>
              <Col md={11} >
              <Form.Item name="id" label="Employee Name">
                <EmployeeAutoComplete
                    key={Helper.Uniqid()}
                    placeholder='Search by Name'
                    selectedItems={owners ? owners : []}
                    onChange={item => onOwnerSelect(item,'SEARCH')}
                    service={onSearchEmployee}
                    minimumTextLength={3}
                    pickOnlyOne
                  />
              </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                <Form.Item name="search_role" label="Role"  >
                  <Select  placeholder={MessageCommon.TitleSelect} 
                    options={roles}
                  />
                </Form.Item>

              </Col>
            </Row>
       
            <Row>
              <Col md={11} >
                <Form.Item name="status" label="Status"  >
                  <Select placeholder={MessageCommon.TitleSelect}
                    options={[{ value: '', label: 'All' },{ value: '1', label: 'Active' }, { value: '0', label: 'Inactive' }]}
                  />
                </Form.Item>

              </Col>
              <Col md={2} >

              </Col>
              <Col md={11} >

              </Col>
            </Row>

            <div style={{ textAlign: 'center' }}>
              <Button type="default" className='mr-btn'  onClick={handleResetSearchForm}>
                <AiOutlineSync className='icn-btn' /> Reset
              </Button>
              <Button type="primary" htmlType='submit'>
                <AiOutlineSearch className='icn-btn'  /> Search
              </Button>
            </div>
          </Form>

        </Panel>
      </Collapse>
      <br></br>
      <div>

        <Card >
          { isAdd ?
            <>
              <div style={{marginTop: '6px',paddingBottom: '15px'}}>
                  <div style={{marginBottom: '10px'}}>
                      <span style={{cursor: 'pointer'}} onClick={() => setIsAdd(false)}>
                        <AiOutlineCloseSquare  size={25} color={Color.Grey} style={{ verticalAlign: 'bottom'}}/>
                        <span style={{paddingLeft: '3px'}}>Close</span>
                    </span>
                  </div>
                  <Form
                    {...layout}
                    form={dataForm}
                    name="nest-messages"
                    onFinish={confirmAdd}
                    validateMessages={validateMessages}
                    initialValues={{ 'status':'1','sendEmail':true }}
                    labelAlign="left"
                    colon={false}
                    labelWrap={true}
                  >
                  <Row>
                    <Col md={11} >
                    <Form.Item name="name" label="Employee Name"  rules={[{ required: true, message: 'Employee Name is required!' }]} >
                      <EmployeeAutoComplete
                          key={Helper.Uniqid()}
                          placeholder='Search by Name'
                          selectedItems={newUserFake ? newUserFake : []}
                          onChange={item => onOwnerSelect(item,'ADD')}
                          service={onSearchEmployee}
                          minimumTextLength={3}
                          pickOnlyOne
                        />
                    </Form.Item>
                    <Form.Item name="email" label="Email"  rules={[{ required: true, message: 'email is required!' }]}  >
                      <Input disabled />
                      
                    </Form.Item>
                    <Form.Item name="status" label="Status"  >
                      <Select placeholder={MessageCommon.TitleSelect} 
                        options={[{ value: '1', label: 'Active' }, { value: '2', label: 'Inactive' }]}
                      />
                    </Form.Item>
                    <Form.Item name="sendEmail" valuePropName="checked" label="Send Email"  >
                        <Checkbox className='ml-checkbox' ></Checkbox>
                    </Form.Item>
                     
                    </Col>
                    <Col md={2} ></Col>
                    <Col md={4} >
                      <Form.Item name="addRoles" label="Role"   rules={[{ required: true, message: 'Role is required!' }]} >
                        <Checkbox.Group  onChange={onChange} style={{display:'block'}}>
                          <div style={{marginBottom: '5px'}}>
                            <Checkbox value="2">System Admin</Checkbox>
                          </div>
                          <div style={{marginBottom: '5px'}}>
                            <Checkbox value="3">Archive Officer</Checkbox>
                          </div>
                          <div style={{marginBottom: '5px'}}>
                            <Checkbox value="4">User Valve Room</Checkbox>
                          </div>
                          <div style={{marginBottom: '5px'}} >
                            <Checkbox value="5" >Report Viewer</Checkbox>
                          </div>
                          {/* { isReporter ? */}
                            <div style={{display:isReporter?'block':'none'}}>
                              <div style={{marginBottom: '5px',marginLeft: '22px'}}>
                                  <Checkbox value="Non-Confident" disabled>Non-Confident</Checkbox>
                              </div>
                              <div style={{marginBottom: '5px',marginLeft: '22px'}}>
                                  <Checkbox value="Confident">Confident</Checkbox>
                              </div>
                          </div>
                          {/* :<></>
                          } */}
                           <div style={{marginBottom: '5px'}}>
                            <Checkbox value="1">Super Admin</Checkbox>
                          </div>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                  </Row>
           
              <div style={{ textAlign: 'center' }}>

                <Button type="default" className='mr-btn' onClick={handleResetAddForm} >
                    <AiOutlineSync className='icn-btn' /> Reset
                </Button>
                <Button type="primary" htmlType='submit'>
                    <AiOutlineSave className='icn-btn'/> Add
                </Button>
              </div>
            </Form>
              </div>
              <hr/>
            </>:<></>
          }
          <div>
              <div style={{float: 'left'}}>
              { !isAdd ?
                <span style={{cursor: 'pointer'}} onClick={() => setIsAdd(true)}>
                  <AiOutlinePlusSquare size={25} color='#009FDA' style={{ verticalAlign: 'bottom'}}/>
                  <span style={{paddingLeft: '3px',color:'#009FDA'}}>Add User</span>
                </span>
                :<></>
              }
              </div>
              { userData.length>0?<div style={{float: 'right'}}>
                        <SelectPagination  onSelectChange={handleChangeCurrentPage} currentPage={1}/>
                        <span style={{marginLeft:'8px'}}>/ Page</span>
                    </div>:<></>}
          </div>

          <div id="panel-action" style={{marginTop: '30px'}}>
                <div>
                  <div className='search-result' style={{ float: 'left'}}>Search found { userData.length } record(s).</div>
                  <div style={{float: 'right'}}>
                     
                  </div>
                </div>
              </div>

        <div id="table-container" style={{marginTop:'55px'}}>
        {isLoad?
              <div style={{ margin: 'auto',paddingBottom: '30px'}}>
                <Spin tip="Loading" size="large">
                  <div className="content" />
                </Spin>
              </div>
              :
            
          <table className='idoc-table'>
            <thead id="head-table-fixed">
              <tr>
                <th style={{width:50}}>No.</th>
                <th >Name</th>
                <th style={{width:400}}>Role</th>
                <th >Email</th>
                <th >Send Email</th>
                <th >Status</th>
                <th >Update Date</th>
                <th >Update By</th>
                <th >Edit</th>
              </tr>
            </thead>
            <tbody >
            
            { userDataDisplay.map((item:User,index) => (
                <tr key={index}>
                  <td style={{width:50}}>{((pagination.currentPage-1)*pagination.pageSize + index + 1)}</td>
                  <td>
                    <span>{item.employeeName}</span>
                  </td>
                  <td style={{textAlign: 'center'}}>
                  { editId === item.userId ?
                    <div style={{display:'block'}}>
                    
                      <Select mode="multiple" placeholder={MessageCommon.TitleSelect} options={roles} defaultValue={item.roleIds.map(r=>r.roleId)} onChange={onEditRoleChange} style={{minWidth:200}}/>
                                {returnIfElse(isValidRole
                                    ,
                                    <span style={{ display: 'block', color: 'red' }}>Role is required!</span>
                                    ,
                                    <></>
                                )
                      }
                    </div>
                    :
                    <span>{item.roleIds.map( x => { return x.roleName}).join(",")}</span>
                  }                   
                  </td>
                  <td>
                    <span>{item.email}</span> 
                  </td>
                  <td style={{textAlign: 'center'}}>
                    { editId === item.userId ?

                         <Checkbox onChange={onEmailSendChange} defaultChecked ={item.emailSend === 1 }></Checkbox>
                            : returnIfElse(item.emailSend === 1 , <AiOutlineCheckSquare size={20} color='#1677FF' /> , <AiOutlineBorder size={20} color='#1677FF' />)
                       
                    }
                  </td>
                  <td style={{textAlign: 'center'}}>
                    { editId === item.userId ? 
                        <Select placeholder={MessageCommon.TitleSelect} defaultValue={ item.active+"" }
                          options={[{ value: '1', label: 'Active' }, { value: '0', label: 'Inactive' }]}
                          onChange={onChangeStatus}
                        />
                        :
                            <p>{returnIfElse(item.active === 1, 'Active', 'Inactive'  )  }</p>
                    }
                 
                  </td>
                  <td style={{textAlign: 'center'}}>
                    {moment(item.updateDate).format("DD-MMM-YYYY")}
                  </td>
                  <td>
                        {returnIfElse(item.updateBy === null, item.createByName, item.updateByName )}
                  </td>
                  <td style={{textAlign: 'center'}}>
                    { editId === item.userId ?
                      <>
                        <Typography.Link disabled={isValidRole} style={{ marginRight: 8 }} onClick={()=>confirmSave(item)}> Update </Typography.Link>
                        <Popconfirm title="Confirm to cancel?" onConfirm={onEditCancel}>
                        <a>Cancel</a>
                        </Popconfirm>
                      </>
                      :
                      <AiFillEdit className='icon-table' onClick={() => onEdit(item.userId,item)}></AiFillEdit>
                    }
                  </td>
                </tr>
              ))
            }
            
            </tbody>
          </table>
}
        </div>
        <div style={{marginTop:'10px',textAlign:'right'}}>
          <Pagination 
            current={pagination.currentPage} 
            pageSize={pagination.pageSize} 
            total={userData.length} 
            showSizeChanger={false}
            onChange={handleChangeCurrentPage} />
          
        </div>
      </Card>
    </div>
    
    {contextHolder}
    {contextHolderMessage}
    </div >
  );

}

export default ManageUser
