import { useEffect, useState } from 'react';
import { Collapse, Form, message,Modal,Row,Spin,Card,Col,Popconfirm,Input,Typography,Pagination,Button,Select,DatePicker } from 'antd';
import secureLocalStorage  from  "react-secure-storage";
import { Label } from 'reactstrap';
import {RequestType, CartonStatus } from '../../enum';
import {  CloseCircleOutlined ,QuestionCircleOutlined} from '@ant-design/icons';
import { AiOutlineSync, AiOutlineSearch, AiFillSave,AiFillDelete,AiFillEdit } from 'react-icons/ai';
import { ChangeOwner ,Parameter ,Item,RequestChangeOrgUnit,RequestChangeOrgUnits} from '../../models'
import { Helper, InitHelper } from '../../helpers';
import { HrWebService,PropertyService,ChangeOrgUnitService} from '../../services';
import dayjs from 'dayjs'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import MessageCommon from '../../enum/Message';
import { useNavigate } from 'react-router-dom';
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import { OrgUnitAutoComplete2 } from '../../autocomplete/OrgUnitAutoComplete2';
import Loading from '../../components/Loading';
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { User} from '../../models';

interface AppState {
    currentPage:number
    perpage:number
    total: number
    items:ChangeOwner[]
    orgUnits:string[]
    isLoading:boolean
  }

const ManualUpdate = () => {
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }
    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
   

    const { instance, accounts } = useMsal();
  const navigate = useNavigate();
    const [dataForm] = Form.useForm();
    const { Panel } = Collapse;
    const [modal, contextHolder] = Modal.useModal();
    const [recordNo, setRecordNo] = useState(0);
    const [messageApi, contextHolderMessage] = message.useMessage();
    const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter)
    const [appState,setAppState] = useState<AppState>({
      currentPage : 1,
      total : 0,
      perpage: 100,
      items: [],
      orgUnits:[],
      isLoading : true
    })
    const [requestStartDate, setRequestStartDate] = useState<Date|null>(null);
    const [requestEndDate, setRequestEndDate] =  useState<Date|null>(null);
    const [owners, setOwners] = useState<Item[]>([])
    const [orgunits, setOrgUnits] = useState<Item[]>([])
    const [newOrgUnits, setNewOrgUnits] = useState<Item[]>([])
    const [confidentialLevel, setConfidentialLevel] = useState(1)
    const [selectItems, setSelectItems] = useState<number[]>([])
    const [requestTypeId, setRequestTypeId] = useState(RequestType.ChangeOwner)
    const [isLoadingPrint, setIsLoadingPrint] = useState(false)
    const [disableSubmit,setDisableSubmit] = useState(false)
    const [editNewOwners, setEditNewOwners] = useState<Item[]>([])
    const [editNewOrgUnits, setEditnewOrgUnits] = useState<Item[]>([])
    useEffect(() => {
        if(user.permissions.orgUnit === 0){
            navigate('/permissiondenided')
        }
        setRequestTypeId(RequestType.ChangeOwner)
        setIsLoadingPrint(false)
        let params = InitHelper.InitialParameter();
        params.confidentialLevel = 1;
        dataForm.setFieldValue("confidential",1)
        getData(1, appState.perpage, params)
    }, [])
    
      const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
    
    
    const getData = async (currentPage: number,perpage: number,_parameter:Parameter| null = null) => {
        let _state:AppState = Object.assign({},appState)
           _state.isLoading = true
           setAppState(_state)

        let parameter = Object.assign({},saveParameter)
        if(_parameter !== null){
            parameter = Object.assign({},_parameter);
        }
           
        parameter.page = currentPage
        parameter.limit = perpage
        parameter.statusIds = [CartonStatus.WaitAdmin]
        parameter.manual = 1
        let token = await getToken()
        console.log(parameter)
        let result = await new ChangeOrgUnitService(token).SearchRequest(parameter)
        
        console.log(result)
        if(result && result.success)
        {
            let state = Object.assign({},appState)
              state.total = result.data.total
              state.currentPage = currentPage
              state.items = result.data.items
              state.isLoading = false
              state.perpage = perpage
            setTimeout(() => {
                setSaveParameter(parameter)
              setAppState(state)
            }, 500);
        }
        else{
            let state = Object.assign({},appState)
            state.isLoading = false
            setAppState(state)
        }
    }
      
    const searchData = async () => {
        console.log('searchdata')
        let field = dataForm.getFieldsValue()
        console.log(field)
        let parameter = InitHelper.InitialParameter();
           
        if(field.requestNo){
            parameter.requestNoList = field.requestNo.trim().split(",")
        }

       
            parameter.confidentialLevel = field.confidential
        
           
        if(owners.length > 0){
            parameter.owners = owners.map(item => item.key)
        }
    
        if(orgunits.length > 0){
            parameter.orgUnits = orgunits.map(item => item.key)
        }

        if(newOrgUnits.length > 0){
            parameter.newOrgUnits = newOrgUnits.map(item => item.key)
        }

        if(requestStartDate && requestEndDate){
            parameter.requestStartDate = requestStartDate
            parameter.requestEndDate = requestEndDate
        }
    
        console.log(parameter)
        getData(1,appState.perpage,parameter)
        
    }

    const reset = () => {
        console.log('reset')
        dataForm.resetFields()
        dataForm.setFieldValue("confidential",1)
        let parameter = InitHelper.InitialParameter()
        parameter.confidentialLevel = 1
        setOwners([])
        setOrgUnits([])
        setNewOrgUnits([])
        setRequestStartDate(null)
        setRequestEndDate(null)
        getData(1,appState.perpage,parameter)
    }
     
    const onSearchEmployee = async(key:string) => {
       
        let token = await getToken()
        let result = await new HrWebService(token).SearchEmployee(key)
        if(result && result.success)
        {
            return result.data
        }
        else{
            console.log(result?.message)
            return []
        }
    }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    
    const handleSave = async (statusId:number) => {
        console.log('save')
        let requestChangeOwner:RequestChangeOrgUnits = {
            recordIds: selectItems,
            statusId: statusId
        }
        console.log(requestChangeOwner)
        let token = await getToken()
        let result = await new ChangeOrgUnitService(token).AdminUpdate(requestChangeOwner);
          
        console.log(result)
        let element = document.getElementById('table-container') as HTMLElement
        if(result && result.success){
      
            messageApi.open({
              type: 'success',
              content: 'Save success',
              duration: 1,
              style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
            });
            setTimeout(() => {
              if(statusId === CartonStatus.Approve){
                navigate('/manage-change-owner-2')
              }
              else{
                getData(1,appState.perpage)
              }
            }, 1000);
        }
        else{
            Modal.error({
                icon: <CloseCircleOutlined />,
                title: 'This is an error message',
                content: result?.message,
            });
        }
    };
    
    const handleSaveOrgUnit = async () => {
      
        let index = appState.items.findIndex(x => x.recordNo === recordNo)
        let item = appState.items[index]
        let state = Object.assign({},appState)

    
        let requestChangeOrgUnit:RequestChangeOrgUnit = {
            recordNo: recordNo,
            newOwner: editNewOwners.length > 0 ? editNewOwners[0].key : "",
            newOrgUnitId: editNewOrgUnits.length > 0 ? editNewOrgUnits[0].key : "",
            newOrgUnit: editNewOrgUnits.length > 0 ? editNewOrgUnits[0].name : "",
            confidentialLevel: item.confidentialLevel 
        }
        console.log(requestChangeOrgUnit)
        let token = await getToken()
        let result = await new ChangeOrgUnitService(token).UpdateChangeOrgUnit(requestChangeOrgUnit);
    
        console.log(result)
        let element = document.getElementById('table-container') as HTMLElement
        if(result && result.success){
    
          messageApi.open({
            type: 'success',
            content: 'Save success',
            duration: 1,
            style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
          });
          
          if(editNewOwners.length > 0)
          {
            state.items[index].newOwner = editNewOwners[0].key
            state.items[index].newOwnerName = editNewOwners[0].name
         }
         else{
            state.items[index].newOwner = ""
            state.items[index].newOwnerName = ""
         }

         if(editNewOrgUnits.length > 0){
            state.items[index].newOrgUnit = editNewOrgUnits[0].name
          }
         else{
            state.items[index].newOrgUnit = ""
         }
          state.items[index].confidentialLevel = requestChangeOrgUnit.confidentialLevel
          setRecordNo(0)
          setAppState(state)
          setEditNewOwners([])
          setEditnewOrgUnits([])
          onCheckItem(selectItems)
       }
        else{
          Modal.error({
              icon: <CloseCircleOutlined />,
              title: 'This is an error message',
              content: result?.message,
          });
        }
      
     
    };

    const cancelSave = () => {
        setRecordNo(0)
        setEditNewOwners([])
        setEditnewOrgUnits([])
    }
    
    const onPageChange = (page:number,perpage:number) => {
        if(page !== appState.currentPage && perpage === appState.perpage){
            getData(page,appState.perpage)
        }
    
        if(page === appState.currentPage && perpage !== appState.perpage){
            getData(1,perpage)
        }
    }
    
    const onCheckAll = (e:React.ChangeEvent<HTMLInputElement>) => {
        let items:number[] = []
        let _disableSubmit = false
        if(e.target.checked){
          appState.items.forEach(item => {
            let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
            element.checked = true
            items.push(parseFloat(element.value))
            if(!item.newOwner && !item.newOrgUnit)
            {
                _disableSubmit = true
            }
          })
        }
        else{
          appState.items.forEach(item => {
            let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
            element.checked = false
          })
        }
       
        onCheckItem(items)
      };
    
      const onCheckChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let checked = e.target.checked
        let value = parseFloat(e.target.value)
        let items = Object.assign([],selectItems)
        

        if(checked){
            items.push(value)
            let element = document.getElementById('check-item-all') as HTMLInputElement
            if(items.length === appState.items.length){
              element.checked = true
              element.indeterminate = false
            }
            else{
              element.checked = false
              element.indeterminate = true
            }
           
        }
        else{
          let index = items.indexOf(value);
          if (index !== -1) {
            items.splice(index, 1);
          }
    
          if(items.length == 0){
            let element = document.getElementById('check-item-all') as HTMLInputElement
            element.checked = false
            element.indeterminate = false
          }
    }
    
        console.log(items)
        
        onCheckItem(items)
      }
      
      const onCheckItem = (_selectItems:number[]) => {
        
        let _disableSubmit = false
        _selectItems.forEach(item => {
            let itemData = appState.items.find( x => x.recordNo === item)
            if(itemData && !itemData.newOwner && !itemData.newOrgUnit)
            {
                _disableSubmit = true
            }
           
        })
        
        setSelectItems(_selectItems)
        setDisableSubmit(_disableSubmit)
      }

      const handleEdit = (_recordNo:number) => {
        let item = appState.items.find(x => x.recordNo === _recordNo)
       
        if(item){
            if(item.newOwner)
            {
                let _newOwner:Item = { key:item.newOwner, name: item.newOwnerName, orgUnit:"",location:"",floor:""}
                setEditNewOwners([_newOwner])
            }
            if(item.newOrgUnit)
            {
                let _newOrgUnit:Item = { key:item.newOrgUnit, name: item.newOrgUnit, orgUnit:"",location:"",floor:""}
                setEditnewOrgUnits([_newOrgUnit])
            }
            
            setRecordNo(_recordNo)
        }
        

     }

    const onOwnerSelect = (item:any) => {
        setOwners(item)
    }

    const onEditNewOwnerSelect = (item:any) => {
        setEditNewOwners(item)
    }
    
    const onOrgUnitChange = (value:any) => {
       setOrgUnits(value)
    }

    const onNewOrgUnitChange = (value:any) => {
        setNewOrgUnits(value)
    }

    const onRequestStartDateChange = (date: any) => {
        setRequestStartDate(date);
    }
    
    const onRequestEndDateChange = (date: any) => {
        setRequestEndDate(date)
    }

    const onSearchOrgUnit = async(key:string) => {
   
        let token = await getToken()
        let result = await new PropertyService(token).GetOrgUnits(key)
        if(result && result.success)
        {
          return result.data
        }
        else{
          console.log(result?.message)
          return []
        }
    }

    const onSearchHrOrgUnit = async(key:string) => {
   
        let token = await getToken()
        let result = await new PropertyService(token).GetHrOrgUnits(key)
        if(result && result.success)
        {
          return result.data
        }
        else{
          console.log(result?.message)
          return []
        }
    }

    const onEditNewOrgUnitSelect = (item:any) => {
        setEditnewOrgUnits(item)
      }
    
    
    const ConfidentialChange =(value:number) =>{
        let _state = Object.assign([],appState)
        let index = appState.items.findIndex(x => x.recordNo == recordNo)
        _state.items[index].confidentialLevel = value

        setAppState(_state)
    
    }
    return (
    <div>
        <Card >
        
        <Collapse>
            {isLoadingPrint?<Loading/>:<></>}
            <Panel header="Search" key="1">
                <Form
              {...layout}
              form={dataForm}
              name="orgunit-form"
              labelAlign="left"
              colon={false}
              labelWrap={true}
            >
              <Row>
                <Col md={11} >
                    <Form.Item name="requestDate" label="Change Date">
                      <table style={{width : '100%'}}>
                        <tr>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate}  format={'DD MMM YYYY'} className='date-picker-full' onChange={onRequestStartDateChange}/>
                          </td>
                          <td>
                            <Label style={{ marginLeft: 10, marginRight: 10 }}>To</Label>
                          </td>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate}  format={'DD MMM YYYY'} className='date-picker-full' onChange={onRequestEndDateChange}/>
                          </td>
                        </tr>
                      </table>
                    </Form.Item>
                    <Form.Item name="requestNo" label="Storage Request No."  >
                        <Input placeholder="ex. B230002,B230004" />
                    </Form.Item>
                    <Form.Item name="owner" label="Owner"  >
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={owners ? owners : []}
                            onChange={item => onOwnerSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                    />
                </Form.Item>
                </Col>
                <Col md={2} >
                </Col>
                <Col md={11} >
                    <Form.Item name="orgUnit" label="Previous Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={orgunits ? orgunits : []}
                            onChange={item => onOrgUnitChange(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
                    <Form.Item name="newOrgUnit" label="New Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={newOrgUnits ? newOrgUnits : []}
                            onChange={item => onNewOrgUnitChange(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
                    <Form.Item name="confidential" label="Confidential Level"  >
                <Select placeholder={MessageCommon.TitleSelect} 
                  options={[
                    { value: -1, label: 'All' },
                    { value: 1, label: 'Confidential' },
                    { value: 0, label: 'Non-Confidential' },
                
                  ]}
                />
              </Form.Item>
                </Col>
              </Row>
      
                </Form>
                <div style={{ textAlign: 'center' }}>
                    <Button type="default" className='mr-btn' onClick={reset} >
                        <AiOutlineSync className='icn-btn' /> Reset
                    </Button>
                    <Button type="primary"   onClick={searchData}>
                        <AiOutlineSearch className='icn-btn' /> Search
                    </Button>
                </div>
  
            </Panel>
        </Collapse>
        <br></br>
        <Collapse defaultActiveKey={['1']}>
            <Panel header="Data Result" key="1" >
            { requestTypeId === RequestType.ChangeOwner ?
                <div style={{ marginBottom: '25px'}}>
                    { selectItems.length > 0 ?
                        <>
                            <Popconfirm
                                title="Cancel the task"
                                description="Confirm to reject?"
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                onConfirm = { () =>  handleSave(CartonStatus.Cancel)}
                            >
                                <Button type="primary" className='btn-red mr-btn'>
                                    <AiFillDelete className='icn-btn' /> Reject
                                </Button>
                            </Popconfirm>
                             <Popconfirm
                                title="Update the task"
                                description="Confirm to submit?"
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                onConfirm = { () =>  handleSave(CartonStatus.Done) }
                            >
           
                                <Button type="primary" disabled={disableSubmit}>
                                    <AiFillSave className='icn-btn' /> Submit
                                </Button>
          
                            </Popconfirm>
                        </>:
                        <>
                            <Button type="primary" className='btn-red mr-btn' disabled>
                                <AiFillDelete className='icn-btn' /> Reject
                            </Button>
          
                            <Button type="primary" disabled>
                                <AiFillSave className='icn-btn' /> Submit
                            </Button>
                            
                        </>
                    }
                   
                    { appState.total>0?<div style={{float: 'right'}}>
                        <SelectPagination  onSelectChange={onPageChange} currentPage={appState.currentPage}/>
                        <span style={{marginLeft:'8px'}}>/ Page</span>
                    </div>:<></>}
                </div>
                :<></>
            }
             <div id="panel-action" >
                <div>
                  <div className='search-result' style={{ float: 'left'}}>Search found { appState.total } record(s).</div>
                  <div style={{float: 'right'}}>
                     
                  </div>
                </div>
              </div>
                <div>
                
                <label className='f-right' >ระบบจะมีการแจ้ง Pending Request List ทุกๆ 15 วัน</label>
                
                </div>
                <div id="table-container">
                { appState.isLoading ?
                    <div style={{ margin: 'auto',paddingBottom: '30px'}}>
                        <Spin tip="Loading" size="large">
                            <div className="content" />
                        </Spin>
                    </div>
                    :
                    <table className='idoc-table'>
                        <thead id="head-table-fixed">
                            <tr>
                                 <th style={{ textAlign: 'left',width: '50px'}}>
                                    <div className="form-check">
                                        <input  type="checkbox" id={'check-item-all'} className="form-check-input check-item" onChange={(e) => onCheckAll(e)}/>
                                        <label className="form-check-label" htmlFor={'check-item-all'}/>
                                    </div>
                      
                                </th>
                            
                                <th >Storage Request No.</th>
                                <th >Owner</th>
                                <th >Previous Org. Unit</th>
                                <th >New Owner</th>
                                <th >New Org. Unit</th>
                                <th >Confidentiality Level</th>
                                <th >System Date</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
           
                        <tbody >
                            { appState.items.map((item:ChangeOwner) => (
                                <tr>
                                    <td style={{ textAlign: 'center',width: '50px'}}>
                                        <div className="form-check">
                                            <input  type="checkbox" id={'check-item-' + item.recordNo} value={item.recordNo} className="form-check-input check-item" onChange={(e) => onCheckChange(e)}/>
                                            <label className="form-check-label" htmlFor={'check-item-' + item.recordNo}/>
                                        </div>
                                    </td>
                                    <td style={{textAlign: 'center'}}>{item.requestNo }</td>
                                    <td style={{textAlign: 'center'}}>{item.ownerName}</td>
                                    <td style={{textAlign: 'center'}}>{item.orgUnit}</td>
                                    <td style={{textAlign: 'center'}}>
                                      { item.recordNo === recordNo ? 
                                        <EmployeeAutoComplete
                                            key={Helper.Uniqid()}
                                            placeholder='Search by Name'
                                                selectedItems={returnIfElse(editNewOwners, editNewOwners, [] )}
                                            onChange={item => onEditNewOwnerSelect(item)}
                                            service={onSearchEmployee}
                                            minimumTextLength={3}
                                            pickOnlyOne
                                        /> : <>{item.newOwnerName}</> }
                                    </td>
                                    <td style={{textAlign: 'center',paddingTop: '6px'}}>
                                        { item.recordNo === recordNo ? 
                                             <OrgUnitAutoComplete2
                                             pickOnlyOne
                                             key={Helper.Uniqid()}
                                             placeholder='Search by Name'
                                             selectedItems={editNewOrgUnits ? editNewOrgUnits : []}
                                             onChange={item => onEditNewOrgUnitSelect(item)}
                                             service={onSearchHrOrgUnit}
                                             minimumTextLength={2}
                                         />
                                            :
                                            <span>{item.newOrgUnit}</span>
                                        }
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                            { item.recordNo === recordNo ?
                                             <Select placeholder={MessageCommon.TitleSelect} 
                                             defaultValue={item.confidentialLevel}
                                             onChange={ConfidentialChange}
                                             options={[
                                               { value: 1, label: 'Confidential' },
                                               { value: 0, label: 'Non-Confidential' },
                                           
                                             ]}
                                             />
                                             :
                                            <span>{returnIfElse(item.confidentialLevel === 0, 'Non-Confident', 'Confident'  )  }</span>
                                            }
                                    </td>
                        
                                    <td style={{textAlign: 'center'}}>
                                        {dayjs(item.requestDate).format('DD MMM YYYY')}
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        { item.recordNo === recordNo ?
                                             <div>
                                                <span>
                                                    <Popconfirm title="Confirm to update?" onConfirm={handleSaveOrgUnit}>
                                                        <Typography.Link>Update</Typography.Link>
                                                    </Popconfirm>
                                                        <a style={{ marginLeft: 8 }} onClick={cancelSave}>
                                                            Cancel
                                                        </a>
                                                </span>
                                            </div>:
                                            <AiFillEdit size={20} onClick={() => handleEdit(item.recordNo)} style={{ cursor: 'pointer'}}/>
                                        }
                                    </td>
                                </tr>
                            ))
                            }
            
                        </tbody>
  
                    </table>
                }
                </div>
                <div style={{marginTop:'10px',textAlign:'right'}}>
                { appState.total > 0 ?
                    <Pagination 
                        key={Helper.Uniqid()}
                        current={appState.currentPage} 
                         pageSize={appState.perpage}
                        total={appState.total} 
                        onChange={onPageChange}
                        showSizeChanger={false}
                    />:<></>
                }
                </div>
        
        
            </Panel>
        </Collapse>

            </Card>
            {modal ? <span></span> : <></>}
        {contextHolderMessage}
        {contextHolder}
  
    </div>
    );
  
  }
  
  export default ManualUpdate
  