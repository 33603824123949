import { useEffect, useState } from 'react';
import { Card, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import CollectCartonRequest from './CollectCartonRequest'
import CollectCartonStatusTracking from './CollectCartonStatusTracking';
import CollectCartonSaveDraft from './CollectCartonSaveDraft';
import { TermsConditionService } from '../../services';
import { RequestType } from '../../enum'
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

const CollectCarton = () => {
    const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const [termActive,setTermActive] = useState(0)
  const [termText,setTermText] = useState("")

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

const getData = async () => {
    let token = await getToken()
    let result = await new TermsConditionService(token).getTermsConditionById(RequestType.CollectCarton)
    console.log(result)
    if(result && result.success)
    {
        if(result.data.active === 1){
            setTermActive(1)
            setTermText(result.data.information)
            setTimeout(() => {
                dragElement()
            }, 500);
   
        }
    }
}

useEffect(() => {
    getData()

}, [])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Collect`,
      children: <CollectCartonRequest onBehalf={0}/>
    },
    {
      key: '2',
      label: `Status Tracking`,
      children: <CollectCartonStatusTracking/>,
    },
    {
      key: '3',
      label: `Save Draft`,
      children: <CollectCartonSaveDraft/>,
    },

  ];

  const closePosit = () => {
    let element = document.getElementById('sticky-container') as HTMLDivElement
    element.style.display = 'none'
  }

  function dragElement() {
    let elmnt = document.getElementById("sticky-container") as HTMLDivElement
    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      elmnt.onmousedown = dragMouseDown;
    
  
    function dragMouseDown(e:any) {
      console.log('mouseDown')
      e.preventDefault();
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    }
  
    function elementDrag(e:any) {
      console.log('drag')
      e.preventDefault();
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
      elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    }
  
    function closeDragElement() {
      console.log('close')
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }

  const onTabChange = (tab:string) => {
    if(tab === '1'){
        navigate('/collect-carton')
    }
    else{
        let tabstr = tab === '2' ? 'tracking' : 'save'
        navigate('/collect-carton-' + tabstr )
    }
  }

  return (

    <div>
      <Card >
        <Tabs defaultActiveKey={'1'} items={items} onChange={onTabChange} destroyInactiveTabPane/>
      </Card>

      { termActive ?
      <div id="sticky-container" className="sticky-container">
        <div className="sticky-outer">
          <div className="sticky">
            <svg width="0" height="0">
              <defs>
                <clipPath id="stickyClip" clipPathUnits="objectBoundingBox">
                  <path d="M 0 0 Q 0 0.69, 0.03 0.96 0.03 0.96, 1 0.96 Q 0.96 0.69, 0.96 0 0.96 0, 0 0" strokeLinejoin="round" strokeLinecap="square"/>
                </clipPath>
              </defs>
            </svg>
            <div className="sticky-content" >
                <div>
                <AiOutlineCloseCircle size={24} style={{cursor: 'pointer',float: 'right',marginRight: '10px'}} onClick={closePosit}/>
                <br/>
                  {termText}
              </div>
            </div>
          </div>
        </div>
      </div>:<></>
    }
    </div>
  );

}

export default CollectCarton
