export const getCookie = (cname: string) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let c of ca) {
        let val = c.trimStart();
        if (val.indexOf(name) === 0) {
            return c.substring(name.length, val.length);
        }
    }
    return "";
};
