import { useEffect, useState } from 'react';
import {  Card, Col, Form,  Modal, Row,Spin ,message} from 'antd';
import { RequestDetail } from '../../models';
import dayjs from 'dayjs';
import { Label } from 'reactstrap';
import { NewCartonService } from '../../services';
import { InitHelper, Helper } from '../../helpers';
import {   User} from '../../models';
import DisplayLogNote from '../Share/DisplayLogNote';
import { RequestType } from '../../enum';
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';

const NewCartonView = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id:string| null = urlParams.get('id')
    const recordNo = id ? parseInt(id) : 0
    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const { instance, accounts } = useMsal();
  const [dataForm] = Form.useForm();
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [detail, setDetail] = useState<RequestDetail>(InitHelper.InitialRequestDetail)
  const [modal, contextHolder] = Modal.useModal();
  const [isLoading, setIsLoading] = useState(true)
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const navigate = useNavigate();
  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
  

  useEffect(() => {
    getData()
  }, [])
  
  const getData = async () => {
    let token = await getToken()
    let result = await new NewCartonService(token).GetDetail(recordNo)
    if(result && result.success){
        let detail = result.data
        if(detail.requester === user.employeeId 
            || detail.owner === user.employeeId 
            || detail.onBehalf == user.employeeId
            || detail.contactPerson === user.employeeId
            || detail.deliverto === user.employeeId
            || detail.approver === user.employeeId
            || detail.approver2 === user.employeeId
        ){
            setDetail(detail)
        }
        else{
            navigate('/permissiondenided')
        }
    }
    else{
        navigate('/permissiondenided')
    }
    setIsLoading(false)
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

  
  const handleNoteModalCancel = () => {
    setIsNoteModalOpen(false);
  };

  const showNoteModal = () => {
    setIsNoteModalOpen(true);
  };

  return (

    <div>
      <Card >
      { isLoading ?
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
        :
        <>
        
        <Form
          {...layout}
          form={dataForm}
          name="detail-form"
          labelAlign="left"
          colon={false}
          labelWrap={true}
        >
          <Row style={{ marginBottom: '10px'}}>
            <Col md={22} >
               
                <Label className='mr-label' style={{ marginLeft: 0}}>Requester</Label>
                <Label className='label-det'>{ detail.requesterName}</Label>
                <Label className='mr-label' >Org. Unit</Label>
                <Label className='label-det'>{ detail.orgUnit}</Label>
                <Label className='mr-label' >Position</Label>
                <Label className='label-det'>{ detail.position}</Label>
                <Label className='mr-label' >Tel No.</Label>
                <Label className='label-det'>{ detail.tel}</Label>
             
            </Col>
          </Row>
         
          <Row>
            <Col md={11} >
              <Form.Item label="Request No"  >
                <Label className='label-det'>{ detail.requestNo}</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item label="Status"  >
                <Label className='label-det'>{ detail.status }</Label>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={11} >
              <Form.Item label="Deliver to.."  >
                <Label className='label-det'>{ detail.ownerName}</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
                <Form.Item label="Location"  >
                <Label className='label-det'>{ detail.location}</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
                <Form.Item  label="Floor"  >
                <Label className='label-det'>{ detail.floor}</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item label="Place"  >
                <Label className='label-det'>{ detail.place}</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
                <Form.Item label="Carton Quantity"  >
                <Label className='label-det'>{ detail.quantity}</Label>
              </Form.Item>
             
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
            <Form.Item  label="Request Date"  >
                <Label className='label-det'>{ dayjs(detail.requestDate).format("DD MMM YYYY")  }</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
            <Form.Item label="Outstanding Quantity"  >
                <Label className='label-det'>{ detail.outstandingQuantity  }</Label>
              </Form.Item>
             
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item  label="Expected Receive Date"  >
                <Label className='label-det'>{ dayjs(detail.receiveDate).format("DD MMM YYYY")  }</Label>
              </Form.Item>
            
            </Col>
          </Row>
   
          <Row>
            <Col md={11} >
          
              <Form.Item  label="Asssign Quantity"  >
                <Label className='label-det'>{ detail.assignDelivery}</Label>
              </Form.Item>
          
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
            {detail.serviceDate?
              <Form.Item  label="Service Date"  >
                <Label className='label-det'>{ dayjs(detail.serviceDate).format("DD MMM YYYY")  }</Label>
              </Form.Item>:<></>
            }
              <Form.Item label="Note"  >
              { detail.note.length > 0 ?
                <>
                  <Label className='label-det'>{detail.note[0]}</Label>
                  <a className='a-table ml-label'  onClick={showNoteModal}>Display Log</a>
                </>:<></>
              }
              </Form.Item>
            </Col>
          </Row>
   
        </Form>
   
            </>
      }
      </Card>
      <Modal title="Display Log" open={isNoteModalOpen} footer={''} width={900} onCancel={handleNoteModalCancel}>
        <DisplayLogNote key={Helper.Uniqid()} recordNo={detail.recordNo} requestTypeId={RequestType.NewCarton}/>
      </Modal>
          {modal?<span></span>:<></>}
      {contextHolder}
      {contextHolderMessage}
    </div>
  );

}

export default NewCartonView
