import { useEffect, useState } from 'react';
import { Spin,Card,Pagination,Button,Modal } from 'antd';
import { AiOutlinePrinter } from 'react-icons/ai';
import {CartonStatus, ExportType } from '../../enum';
import { ChangeOwner, Parameter } from '../../models'
import { Helper, InitHelper } from '../../helpers';
import { ChangeOwnerService, FileManageService } from '../../services';
import dayjs from 'dayjs'
import secureLocalStorage  from  "react-secure-storage";
import StorageCartonRequestDetail from '../StorageCarton/StorageCartonRequestDetail'
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { User} from '../../models';
import { useNavigate } from 'react-router-dom';

interface AppState {
    currentPage:number
    perpage:number
    total: number
    items:ChangeOwner[]
    isLoading:boolean
  }

const HistoricalList = () => {
    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const navigate = useNavigate();

    const { instance, accounts } = useMsal();
    const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
    const [recordNo, setRecordNo] = useState(0);
    const [modal, contextHolder] = Modal.useModal();
    const [isPrint,setIsPrint] = useState(true)
    const [param,setParam] = useState<Parameter>()
    const [appState,setAppState] = useState<AppState>({
      currentPage : 1,
      total : 0,
      perpage: 100,
      items: [],
      isLoading : true
    })

    useEffect(() => {
        if(user.permissions.changeOwner === 0){
            navigate('/permissiondenided')
        }
        getData(1,appState.perpage)
    }, [])
    
      const getData = async (currentPage: number,perpage: number) => {
        let _state:AppState = Object.assign({},appState)
           _state.isLoading = true
           setAppState(_state)
      
          let token = await getToken()
          let parameter = InitHelper.InitialParameter();
            parameter.page = currentPage
            parameter.limit = perpage
            setParam(parameter)
          let result:any = await new ChangeOwnerService(token).SearchHistory(parameter)
          console.log(result)
          if(result && result.success)
          {
            let state = Object.assign({},appState)
              state.total = result.data.total
              state.currentPage = currentPage
              state.items = result.data.items
              state.isLoading = false
              state.perpage = perpage
            if(result.data.total > 0){
              setIsPrint(false)
            }else{
              setIsPrint(true)
            }
            setTimeout(() => {
              setAppState(state)
            }, 500);
          }
          else{
            let state = Object.assign({},appState)
            state.isLoading = false
            setAppState(state)
          }
          
      }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


    const onPageChange = (page:number,perpage:number) => {
        if(page !== appState.currentPage && perpage === appState.perpage){
            getData(page,appState.perpage)
        }
    
        if(page === appState.currentPage && perpage !== appState.perpage){
            getData(1,perpage)
        }
    }
    
    const showModalCarton = async (_recordNo:number) => {
        setRecordNo(_recordNo)
        setIsModalCartonOpen(true);
    };
    
    const handleCancelCarton = () => {
        setIsModalCartonOpen(false);
    
    };
    
    const onFinishPrint = async () => {
  
      let token = await getToken()
      if(!param)return
      try{
  
        await new FileManageService(token).DownloadReport(param,ExportType.HISTORY);
      }catch(error){
        console.log("🚀 ~ file: MytaskTracking.tsx:327 ~ onFinishPrint ~ error:", error)
        
      }
    }
    return (
    <div>
        <Card >
        
      
            
                <div style={{ marginBottom: '25px'}}>
                    
                <Button type="primary" htmlType="button" className='mr-btn' disabled={isPrint} onClick={()=>onFinishPrint()}  >
                  <AiOutlinePrinter className='icn-btn' />Print
                </Button>
                { appState.total>0?<div style={{float: 'right'}}>
                        <SelectPagination  onSelectChange={onPageChange} currentPage={appState.currentPage}/>
                        <span style={{marginLeft:'8px'}}>/ Page</span>
                    </div>:<></>}
                </div>

                <div id="panel-action" style={{paddingTop: '4px'}}>
                <div>
                  <div className='search-result' style={{ float: 'left'}}>Search found { appState.total } record(s).</div>
                  <div style={{float: 'right'}}>
                     
                  </div>
                </div>
              </div>
                <div id="table-container">
                { appState.isLoading ?
                    <div style={{ margin: 'auto',paddingBottom: '30px'}}>
                        <Spin tip="Loading" size="large">
                            <div className="content" />
                        </Spin>
                    </div>
                    :
                    <table className='idoc-table'>
                        <thead id="head-table-fixed">
                            <tr>
                               
                                <th>Request No.</th>
                                <th >Requested Date</th>
                                <th >Requester</th>
                                <th >Current Owner</th>
                                <th >New Owner</th>
                                <th>Org. Unit</th>
                                <th>Action</th>
                                <th>Update Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
           
                        <tbody >
                            { appState.items.map((item:ChangeOwner) => (
                                <tr>
                                   
                                    <td style={{textAlign: 'center'}}>
                                    <a className='a-table' onClick={() => showModalCarton(item.storageRecordNo)}>{item.storageRequestNo}</a>
                                    </td>
                                    <td style={{textAlign: 'center'}}>{dayjs(item.requestDate).format('DD MMM YYYY')}</td>
                                    <td style={{textAlign: 'center'}}>{item.requesterName}</td>
                                    <td style={{textAlign: 'center'}}>{item.ownerName}</td>
                                    
                                    <td style={{textAlign: 'center'}}>{item.newOwnerName}</td>
                                    <td style={{textAlign: 'center'}}>{item.orgUnit}</td>
                                    <td style={{textAlign: 'center'}}>{item.requestType }</td>
                                    <td style={{textAlign: 'center'}}>{dayjs(item.updateDate).isValid() ? dayjs(item.updateDate).format('DD MMM YYYY') : ""}</td>
                                    <td style={{textAlign: 'center'}}>{item.status}</td>
                                </tr>
                            ))
                            }
            
                        </tbody>
  
                    </table>
                }
                </div>
                <div style={{marginTop:'10px',textAlign:'right'}}>
                { appState.total > 0 ?
                    <Pagination 
                        key={Helper.Uniqid()}
                        current={appState.currentPage} 
                         pageSize={appState.perpage}
                        total={appState.total} 
                        onChange={onPageChange}
                        showSizeChanger={false}
                    />:<></>
                }
                </div>
        
        
           
                <Modal title="Request Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton}>
            <StorageCartonRequestDetail 
              key={Helper.Uniqid()}
              recordNo={recordNo}  
                        approveLevel={0} cartonNumber={""}
            />
        </Modal>

            </Card>
            {modal ? <span></span> : <></>}
        {contextHolder}

    </div>
    );
  
  }
  
  export default HistoricalList
  