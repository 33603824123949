import {  useEffect, useState } from 'react';
import { Button, Card, Tabs } from 'antd';
import type { TabsProps } from 'antd';

import Table, { ColumnsType } from 'antd/es/table';
import { AiFillDelete, AiFillSave } from 'react-icons/ai';

import TablePageList from '../../enum/TablePageList';
import AdminServiceStatus from '../../enum/AdminServiceStatus';


interface DataType {
  key: string;
  request_no: string;
  request_date: string;
  expecrted_receive_date: string;
  box_quantity: string;
  status: string;
}


const CollectCartonApproveList = () => {

  const [dataSaveDraft, setDataSaveDraft] = useState([]);
  const [dataOnGoing, setDataOnGoing] = useState([]);


  const showModalEdit = (values: any) => {
    console.log('showModalEdit:', values);

    setDataSaveDraft([]);
  };
  const columns: ColumnsType<DataType> = [
    {
      title: 'Request No.',
      dataIndex: 'request_no',
      key: 'request_no',
      render: (text) => <a className='a-table' onClick={showModalEdit}>{text}</a>,
    },
    {
      title: 'Requested Date',
      dataIndex: 'request_date',
      key: 'request_date',
    },
    {
      title: 'Requester',
      dataIndex: 'requester',
      key: 'requester',
    },
    {
      title: 'Expected Receive Date',
      dataIndex: 'expecrted_receive_date',
      key: 'expecrted_receive_date',
    },
    {
      title: 'Carton Quantity',
      dataIndex: 'box_quantity',
      key: 'box_quantity',
    },
    {
      title: 'Reject',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <div><AiFillDelete className='icon-table icon-red'></AiFillDelete></div>,
    },
  ];


  const WaitForAdminData = () => {
    return <div>
      <div style={{ marginRight: 10, marginTop: 15, marginBottom: 5 }}>
        <Button type="primary" className='btn-red mr-btn'   >
          <AiFillDelete className='icn-btn' /> Reject
        </Button>
        <Button type="primary"  >
          <AiFillSave className='icn-btn' />On Going
        </Button>
      </div>
      <br></br>
      <Table columns={columns}
        dataSource={dataSaveDraft}
        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: TablePageList }}
        rowSelection={{
          type: "checkbox",
        }} />
    </div >;
  }

  const onGoingData = () => {
    return <div>
      <div style={{ marginRight: 10, marginTop: 15, marginBottom: 5 }}>
        <Button type="primary" className='btn-red mr-btn'   >
          <AiFillDelete className='icn-btn' /> Reject
        </Button>
        <Button type="primary"  >
          <AiFillSave className='icn-btn' />Complete Service
        </Button>
      </div>
      <br></br>
      <Table columns={columns}
        dataSource={dataOnGoing}
        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: TablePageList}}
        rowSelection={{
          type: "checkbox",
        }} />
    </div >;
  }

  const items: TabsProps['items'] = [

    {
      key: '1',
      label: AdminServiceStatus,
      children: WaitForAdminData(),
    },
    {
      key: '2',
      label: `On Going`,
      children: onGoingData(),
    }
  ];

  const onChange = (key: string) => {
    console.log(key);
  };

  useEffect(() => {
    const saveDraftList: any = [
      {
        key: '1',
        request_no: 'CL230006',
        requester: 'Name Surname',
        request_date: '23 Mar 23',
        expecrted_receive_date: '28 Mar 23',
        box_quantity: '10',
        status: AdminServiceStatus,
      },
    ];
    setDataSaveDraft(saveDraftList);

    const onGoingList: any = [
      {
        key: '1',
        request_no: 'CL230004',
        requester: 'Name Surname',
        request_date: '23 Mar 23',
        expecrted_receive_date: '28 Mar 23',
        box_quantity: '10',
        status: 'Approved',
      },
    ];
    setDataOnGoing(onGoingList);

  }, [])

  return (

    <div>
      <Card >
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </Card>
    </div>
  );

}

export default CollectCartonApproveList
