import { useEffect, useState } from 'react';
import { Button, Card, Col,  Form, Input,Row, Modal, message } from 'antd';
import { AiOutlineSave } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import 'react-quill/dist/quill.snow.css';
import { Color } from '../../enum';
import SystemConfigDto from '../../models/SystemConfig';
import SystemConfigService from '../../services/SystemConfigService';
import { ExclamationCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import secureLocalStorage from 'react-secure-storage';
import { User } from '../../models';
import moment from 'moment';
import { useMsal } from "@azure/msal-react";
import { apiRequest } from "../../configurations/authConfig";

interface ICounterState {
  currentCount: number;
}
interface DataType {
  key: string;
  request_no: string;
  request_date: string;
  expecrted_receive_date: string;
  box_quantity: string;
  status: string;
}

const SystemConfig = () => {
    const { instance, accounts } = useMsal();


  const [dataForm] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [messageApi, contextHolderMessage] = message.useMessage();


  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null

  const [systemConfig,setSystemConfig] = useState<SystemConfigDto>()
 
  const accessToken = "token"
  //const systemConfigService = new SystemConfigService(accessToken)

  const onFinish = (values: any) => {
   const body = {...systemConfig}
   body.updateBy = user.employeeId
   console.log("🚀 ~ file: SystemConfig.tsx:51 ~ onFinish ~ user:", user)
  
    modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Confirm to submit?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => updateConfig(body)
    });
    
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const onChangeUserExtendablePeriod = () => {
    let systemExtendablePeriod = dataForm.getFieldValue("systemExtendablePeriod")
    let extendableTime = dataForm.getFieldValue("extendableTime")
    if(!systemConfig)return
    dataForm.setFieldValue("userExtendablePeriod",systemExtendablePeriod*extendableTime||0)
    dataForm.validateFields(['userExtendablePeriod'])
     
    let config = systemConfig
    config.userExtendablePeriod = Number(systemExtendablePeriod*extendableTime)
    config.systemExtendablePeriod = systemExtendablePeriod
    config.extendableTime = extendableTime
    setSystemConfig(config)
  }

  const getToken = async () => {
    const acc = accounts && accounts.length ? accounts[0] : null;
    apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
    if (acc) {
        const { accessToken: token } = await instance.acquireTokenSilent({
            account: acc,
            scopes: apiRequest.scopes
        });
        return token;
    }
    return ""
}

  const getConfig = async () =>{
     let token = await getToken()
      const result = await new SystemConfigService(token).getSystemConfig()
      if(result && result.data)
        setSystemConfig(result.data)
  }
  const updateConfig = async (body:any) =>{

    if(body){
      try {
        let token = await getToken()
          const res = await new SystemConfigService(token).updateSystemConfig(body)
          if (!res)
              return null;
        if(!res.success){
          Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: res?.message,
          });
        } else {
            if (!res)
                return null;
          setSystemConfig(res.data)
          messageApi.open({
            type: 'success',
            content: 'Save success',
            duration: 1,
          });
        }
        
      } catch (error) {
        console.log("🚀 ~ file: SystemConfig.tsx:78 ~ updateConfig ~ error", error)
      }
    }

}

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  

  const CustomLabel = ({ label, required }:any) => (
    <label  style={{fontWeight:'normal',marginTop:4}}>
      {label}
     
    </label>
  );
  const validateInputNumber = (rule:any, value:any, callback:any) => {
      const regexPattern = /^\d+$/;

    if (value && !regexPattern.test(value)) {
      callback('Please enter only numbers.');
    } else {
      callback();
    }
  };
  const validateInputNumberUserExtendablePeriod = (rule:any, value:any, callback:any) => {
    
    if (value <= 0) {
      callback('User Extendable Period(System Extendable Period x Extendable Time) must not be more than 0.');
    } else {
      callback();
    }
  };
  const validateInputIntWithComma = (rule:any, value:any, callback:any) => {
    const regexPattern = /^(\d+,)*\d+$/;
    const numbers = value.split(",").map(Number);

    const uniqueNumbers = numbers.filter((x:number, i:number, a:number[]) => a.indexOf(x) === i)
  
    if (value && !regexPattern.test(value)) {
      callback('Please enter a valid character of comma-separated number and end with number.');
    } else if(numbers.length !== uniqueNumbers.length) {
      callback('Duplicates are not allowed.');
    }else{
      callback();
    }
  };
  useEffect(() => {
    
    const onInit =async () =>{
      await getConfig()
    }
    onInit()
  }, [])

  if(!systemConfig){
    return <></>
  }

  return (
    <div>

      <div>


        <Card>
          <Form
            {...layout}
            form={dataForm}
            name="nest-messages"
            onFinish={onFinish}
            
            validateMessages={validateMessages}
            initialValues={{
              'a':systemConfig.storeYearValue,
              'b':systemConfig.extendStoreYearValue,
              'c':systemConfig.maxSizePerBox,
              'd':systemConfig.maximumCartonAmt,
              'e':systemConfig.tablePageSize,
              'firstPeriod':systemConfig.firstPeriod,
              'systemExtendablePeriod':systemConfig.systemExtendablePeriod,
              'extendableTime':systemConfig.extendableTime,
              'userExtendablePeriod':systemConfig.userExtendablePeriod
            }}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
               <Row>
              <Col md={11} >
                
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                 <div style={{ float: 'right',fontWeight: 'bold',color:Color.Grey}}>
                  <span>Update By:</span> {user.name} ,Update Date:{moment(systemConfig.updateData).format("DD-MMM-YYYY HH:mm")}
                 </div>
                
              </Col>
            </Row>
            <br/>
            <Row>
              <Col md={11} >
                <Form.Item name="a" label="Storage Year Value"  rules={[{ required: true, message: 'Storage Year Value is required!' },{validator:validateInputIntWithComma}]}>
                  <Input 
                  placeholder={MessageCommon.TitleInput}
                  onChange={(e)=>setSystemConfig(()=>{
                    return {...systemConfig,storeYearValue:e.target.value}
                  })}
                  />
                </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                <Form.Item name="b" label="Extend Store Year Value" rules={[{ required: true, message: 'Extend Store Year Value is required!' },{validator:validateInputIntWithComma}]} >
                  <Input placeholder={MessageCommon.TitleInput}
                  onChange={(e)=>setSystemConfig(()=>{
                    return {...systemConfig,extendStoreYearValue:e.target.value}
                  })}
                  />
                </Form.Item>

              </Col>
            </Row>
            <Row>
              <Col md={11} >
                <Form.Item name="c" label="Maximun Storage Quantity" 
                  rules={[{ required: true, message: 'Maximun Storage Quantity is required!' },{
                          validator: validateInputNumber,
                        },]} >
                  <Input placeholder={MessageCommon.TitleInput}
                   onChange={(e)=>setSystemConfig(()=>{
                    return {...systemConfig,maxSizePerBox:e.target.value}
                  })} />
                </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                <Form.Item name="d" label="Maximum New Carton Quantity" 
                  rules={[{ required: true, message: 'Maximum New Carton Quantity is required!' },
                    {
                      validator: validateInputNumber,
                    },
                    ]} >
                  <Input placeholder={MessageCommon.TitleInput}
                   onChange={(e)=>setSystemConfig(()=>{
                    return {...systemConfig,maximumCartonAmt:e.target.value}
                  })}
                  />
                </Form.Item>

              </Col>
            </Row>
            <Row>
              <Col md={11} >
                <Form.Item name="e" label="Table Page Size"  rules={[{ required: true, message: 'Table Page Size is required!' },{validator:validateInputIntWithComma}]}>
                  <Input placeholder={MessageCommon.TitleInput}
                   onChange={(e)=>setSystemConfig(()=>{
                    return {...systemConfig,tablePageSize:e.target.value}
                  })}/>
                </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >


              </Col>
            </Row>

            <Row>
              <Col md={11} >
                <Row>
                  <Col span={8}>
                    <label form="nest-messages_f" className="ant-form-item-no-colon" title="">
                      <label>Collect Carton Valid Period</label>
                    </label>
                  </Col>
                  <Col span={14}>

                    <Form.Item
                      label={<CustomLabel label="First Period"/>}
                      name="firstPeriod"
                      labelAlign="left"
                      labelCol={{span:11}} 
                      rules={[
                        {
                          required: true,
                          message: 'First Period is required!',
                        },{
                          validator: validateInputNumber,
                        },
                      ]}
                    >

                      <Input style={{marginLeft:-9}} placeholder={MessageCommon.TitleInput} 
                      onChange={(e)=>setSystemConfig(()=>{
                          return {...systemConfig,firstPeriod:parseInt(e.target.value)}
                        })}/>

                    </Form.Item>
                  </Col>
                  <Col span={2}>                      
                    <span className="ant-form-text" style={{ margin: 4 }}>Month</span>
                  </Col>
                </Row>
                {/* <Form.Item name="f" label={<CustomLabel label="Collect Carton Valid Period" required={true}/>} >
                  <table style={{ width : '100%'}}>
                  <tbody>
                    <tr>
                      <td width={165}>
                        First Period
                      </td>
                      <td width={5}></td>
                      <td>
                        <Input  placeholder={MessageCommon.TitleInput} defaultValue={systemConfig.firstPeriod}
                         onChange={(e)=>setSystemConfig(()=>{
                          return {...systemConfig,firstPeriod:parseInt(e.target.value)}
                        })}
                        />
                        
                      </td>
                      <td width={5}></td>
                      <td width={74}>
                        Month
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </Form.Item> */}
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >


              </Col>
            </Row>
            <Row>
              <Col md={11} >
                {/* <Form.Item name="g" label=" "  >
                <table style={{ width : '100%'}}>
                    <tbody>
                    <tr>
                      <td width={165}>
                        System Extendable Period
                      </td>
                      <td width={5}></td>
                      <td>
                        <Input placeholder={MessageCommon.TitleInput} defaultValue={systemConfig.systemExtendablePeriod}
                         onChange={(e)=>setSystemConfig(()=>{
                          return {...systemConfig,systemExtendablePeriod:parseInt(e.target.value)}
                        })}
                        />
                      </td>
                      <td width={5}></td>
                      <td>
                        Month/Time
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </Form.Item> */}
                <Row>
                  <Col span={14} offset={8}>
                    <Form.Item
                      label={<CustomLabel label="System Extendable Period"/>}
                      name="systemExtendablePeriod"
                      labelAlign="left"
                      labelCol={{span:11}} 
                      rules={[
                        {
                          required: true,
                          message: 'System Extendable Period is required',
                        },{
                          validator: validateInputNumber,
                        },
                      ]}
                    >
                      <Input style={{marginLeft:-9,width:'90%'}} placeholder={MessageCommon.TitleInput} 
                      onChange={(e)=>{setSystemConfig(()=>{
                          return {...systemConfig,systemExtendablePeriod:parseInt(e.target.value)}
                        });onChangeUserExtendablePeriod()}}/>
                    </Form.Item>
                  </Col>
                  <Col span={2}>                      
                    <span className="ant-form-text" style={{ marginLeft: -20,marginTop: 5 }}>Month/Time</span>
                  </Col>
                </Row>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >

              </Col>
            </Row>
            <Row>
              <Col md={11} >
                {/* <Form.Item className='ant-form-item-required' name="g" label=" "  >
                <table style={{ width : '100%'}}>
                  <tbody>
                    <tr>
                      <td width={165}>
                        Extendable Time
                      </td>
                      <td width={5}></td>
                      <td>
                        <Input placeholder={MessageCommon.TitleInput} defaultValue={systemConfig.extendableTime}
                         onChange={(e)=>setSystemConfig(()=>{
                          return {...systemConfig,extendableTime:parseInt(e.target.value)}
                        })}/>
                      </td>
                      <td width={5}></td>
                      <td width={74}>
                        Time
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </Form.Item> */}
                <Row>
                  <Col span={14} offset={8}>
                    <Form.Item
                      label={<CustomLabel label="Extendable Time"/>}
                      name="extendableTime"
                      labelAlign="left"
                      labelCol={{span:11}} 
                      rules={[
                        {
                          required: true,
                          message: 'Extendable Time is required',
                        },{
                          validator: validateInputNumber,
                        },
                      ]}
                    >
                      <Input style={{marginLeft:-9}} placeholder={MessageCommon.TitleInput} onChange={(e)=>{setSystemConfig(()=>{
                          return {...systemConfig,extendableTime:parseInt(e.target.value)}
                          
                        });onChangeUserExtendablePeriod()}} />
                    </Form.Item>
                  </Col>
                  <Col span={2}>                      
                    <span className="ant-form-text" style={{ marginTop: 4 }}>Time</span>
                  </Col>
                </Row>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >


              </Col>
            </Row>
            <Row>
              <Col md={11} >
                
                {/* <Form.Item name="g" label=" "  >
                  <table style={{ width : '100%'}}>
                    <tbody>
                      <tr>
                      <td width={165}>
                        User Extendable Period
                      </td>
                      <td width={5}></td>
                      <td>
                        <Input placeholder={MessageCommon.TitleInput} defaultValue={systemConfig.userExtendablePeriod} disabled/>
                      </td>
                      <td width={5}></td>
                      <td width={74}>
                        Month
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </Form.Item> */}
                <Row>
                  <Col span={14} offset={8}>
                    <Form.Item
                      label={<CustomLabel label="User Extendable Period"/>}
                      name="userExtendablePeriod"
                      
                      labelAlign="left"
                      labelCol={{span:11}} 
                      rules={[
                        {
                          required: true,
                          message: 'User Extendable Period Time is required',
                        },{
                          validator: validateInputNumberUserExtendablePeriod,
                        },
                      ]}
                    >
                      <Input style={{marginLeft:-9}} placeholder={MessageCommon.TitleInput} disabled value={systemConfig.userExtendablePeriod}/>
                    </Form.Item>
                  </Col>
                  <Col span={2}>                      
                    <span className="ant-form-text" style={{ marginTop: 4 }}>Month</span>
                  </Col>
                </Row>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >


              </Col>
            </Row>
         
            <Row>
              <Col md={24} >
              <div style={{ textAlign: 'center',marginTop:'20px'}}>
                    <Button type="primary"  htmlType='submit' >
                        <AiOutlineSave className='icn-btn' /> Update
                        </Button>
                </div>

              </Col>
            </Row>
          </Form>
        </Card>

      </div>
    
      {contextHolder}
      {contextHolderMessage}


    </div >
  );

}

export default SystemConfig
