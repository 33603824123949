import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { AiOutlineInbox, AiOutlineAppstoreAdd, AiOutlineRotateRight, 
  AiOutlineRotateLeft, AiOutlineCloseSquare, AiOutlineMinusCircle, 
  AiOutlineHdd, AiOutlineBarChart, AiOutlineFileDone } from "react-icons/ai";
import {  MenuProps,Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import { User } from '../models';
import './LeftBar.css';

interface INavMenuProps { }
interface INavMenuState {
  collapsed: boolean;
}

interface MenuItem
{
    label:string
    key:string
    icon: JSX.Element
    children:any[]
}

export const LeftBar: FunctionComponent<INavMenuProps> = () => {
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const [collapsed, setCollapsed] = useState(false);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState();
  const [isLoading,setIsloading] = useState(true)

  const onClick: MenuProps['onClick'] = (e) => {
    navigate('/' + e.key)
  };

  let items:any = []

  const documentArchiveItem = {
    icon: <AiOutlineFileDone />,
    key: "doc_archive",
    label: "Document Archive",
    children:[
      { label: "New Carton", key: "new-carton", icon: <AiOutlineInbox /> },
      { label: "Storage Carton", key: "storage-carton", icon: <AiOutlineAppstoreAdd /> },
      { label: "Collect Carton", key: "collect-carton", icon: <AiOutlineRotateRight /> },
      { label: "Return Carton", key: "return-carton", icon: <AiOutlineRotateLeft /> },
      { label: "Remove Carton", key: "remove-carton", icon: <AiOutlineCloseSquare /> },
      { label: "Destruction Carton", key: "destruction-carton", icon: <AiOutlineMinusCircle /> },
      { label: "Request to Change Owner", key: "request-change-owner", icon: <AiOutlineMinusCircle /> }
    ]
  }

  const approvalItem = {
    icon: <AiOutlineFileDone />,
    key: "approvalItem",
    label: "Approval",
    children: [
      { label: "Waiting for Approval", key: "approval", icon: <AiOutlineInbox /> },
      { label: "My Approval Tracking", key: "approve-tracking", icon: <AiOutlineFileDone /> }
    ]
  }

  const myTaskChildren = [
    { label: "Status Tracking", key: "status-tracking", icon: <AiOutlineFileDone /> },
    { label: "My Org. Unit Document", key: "my-document", icon: <AiOutlineMinusCircle /> },
    { label: "Reminder Email", key: "reminder-email", icon: <AiOutlineMinusCircle /> }
  ]

  const myTaskItem = {
    icon: <AiOutlineFileDone />,
    key: "mytask",
    label: "My Task",
    children: myTaskChildren
  }

  
  const systemAdminItem:MenuItem = {
    icon: <AiOutlineFileDone />,
    key: "systemAdmin",
    label: "System Admin",
    children:[]
  }
  const queryDataItem = { label: "Query Data", key: "query-data", icon: <AiOutlineInbox />}
  const destructionCartonItem = { label: "Destruction Carton", key: "officer-destruction-carton", icon: <AiOutlineAppstoreAdd />}
  const onBehalfItem = { label: "On Behalf", key: "onbehalf", icon: <AiOutlineMinusCircle />}

  const masterDataItem:MenuItem = { label: "Master Data", key: "master", icon: <AiOutlineHdd /> ,
      children:[]
  }

  const systemManagementItem = 
    { label: "System Management", key: "system", icon: <AiOutlineHdd /> ,
      children:[
        { label: "Manage User", key: "manage-user" },
        { label: "System Config", key: "system-config" },
        { label: "Mail Message", key: "mail-message" },
        { label: "Terms and Conditions", key: "terms-and-conditions" }
      ]
    }
  

  const reportItem = 
      { label: "Report", key: "sub2", icon: <AiOutlineBarChart /> ,
      children:[
        { label: "All Transaction", key: "report-all-transaction" },
        { label: "Total Carton List", key: "report-total-carton-list" },
        { label: "Destruction Carton List", key: "report-destruction-carton-list" },
        { label: "Carton Quantity by Request Type", key: "report-carton-quantity" },
        { label: "Destruction Carton Quantity", key: "report-destruction-carton-quantity" }
      ]
    }
  
 
  useEffect(() => {
    
    if(user)
    {
        documentArchiveMenu();
        queryDataMenu();
        destructionCartonMenu()
        onBehalfMenu()
        masterDataMenu();
        systemMenu()
        myTaskMenu()
        approvalMenu()
        systemAdminMenu()
        reportMenu()
    }
    setMenuItems(items);
    setIsloading(false)
    if (windowSize.current[0] < 993) {
      setCollapsed(true);
    }

  }, [])

    const documentArchiveMenu = () => {
        if (user.permissions.documentArchive > 0)
        {
            items.push(documentArchiveItem)
        }
    }

    const queryDataMenu = () => {
        if (user.permissions.queryData > 0)
        {
            systemAdminItem.children.push(queryDataItem)
        }
    }

    const destructionCartonMenu = () => {
        
        if (user.permissions.destructionCarton > 0)
        {
            systemAdminItem.children.push(destructionCartonItem)
        }
    }
    const onBehalfMenu = () => {
        
        if (user.permissions.onBehalf > 0)
        {
            systemAdminItem.children.push(onBehalfItem)
        }
    }
    const systemMenu = () => {
        
        if (user.permissions.system > 0)
        {
            systemAdminItem.children.push(systemManagementItem)
        }
    }
    const myTaskMenu = () => {
        
        if (user.permissions.myTask > 0)
        {
            items.push(myTaskItem)
        }
    }
    const approvalMenu = () => {
        
        if (user.approveLevel == 2)
        {
            items.push(approvalItem)
        }
    }
    const systemAdminMenu = () => {
        
        if (systemAdminItem.children.length > 0)
        {
            items.push(systemAdminItem)
        }
    }
    const reportMenu = () => {
        
        if (user.permissions.report > 0)
        {
            items.push(reportItem)
        }
    }

    const masterDataMenu = () => {
        if (user.permissions.warehouse == 1)
        {
            masterDataItem.children.push( { label: "Manage External Warehouse", key: "manage-external-warehouse" })
        }
        if (user.permissions.changeOwner == 1)
        {
            masterDataItem.children.push( { label: "Manage Change Owner", key: "manage-change-owner" })
        }
        if (user.permissions.orgUnit == 1)
        {
            masterDataItem.children.push({ label: "Manage Org. Unit", key: "manage-org-unit" })
        }
        if (user.permissions.projectName == 1)
        {
            masterDataItem.children.push({ label: "Manage Project Name", key: "manage-project-name" })
        }
        if(masterDataItem.children.length > 0)
        {
            systemAdminItem.children.push(masterDataItem)
        }
    }
  const toggleCollapsed = () => {

    if (!collapsed) {
      if (windowSize.current[0] >= 993) {
        document.body.setAttribute('data-sidebar-size', 'sm');
      }
      document.body.classList.remove('sidebar-enable');
    } else {
      document.body.setAttribute('data-sidebar-size', 'lg');
      document.body.classList.add('sidebar-enable');
    }


    setCollapsed(!collapsed);
  };

  const login = () => {
    navigate('/login')
  }

  const logout = () => {
    navigate('/logout')
  }

  return (
    <div>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            {/* LOGO */}
            <div className="navbar-brand-box">
              <a href="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src="images/logo-pttep.png" height={22} />
                </span>
                <span className="logo-lg">
                  <img src="images/logo-pttep.png" height={22} />
                </span>
              </a>
              <a href="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src="images/logo-pttep.png" height={22} />
                </span>
                <span className="logo-lg">
                  <img src="images/logo-pttep.png" height={22} />
                </span>
              </a>
            </div>
            <button type="button" onClick={toggleCollapsed} className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn">
              <i className="fa fa-fw fa-bars" />
            </button>

          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block">
            
              <button type="button" className="btn header-item user text-start d-flex align-items-center" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img className="rounded-circle header-profile-user" src={user && user.gender === 'F' ? 'images/user-f.png': 'images/user.png' } alt="Header Avatar" />
                <span className="ms-2 d-none d-sm-block user-item-desc">
                  <span className="user-name">{user ? user.employeeName : ""}</span>
                  <span className="user-sub-title">{user ? user.roleIds.map( x=> { return x.roleName}).join(",") : ""}</span>
                </span>
               
              </button>
            
             
              <div className="dropdown-menu dropdown-menu-end pt-0">
                <div className="p-3 bg-primary border-bottom">
                    {window.location.hostname.indexOf("localhost") > -1 || window.location.hostname.indexOf("idocarchive-dev") > -1 ?
                         <a className="mb-0 text-white a-table" onClick={() => login()}>Login</a>:<></>
                    }
                   
                  <br></br>
                  

                  {/* <h6 className="mb-0 text-white">Name Surname</h6>
                  <p className="mb-0 font-size-11 text-white-50 fw-semibold">name@email.com</p> */}
                </div>
                
                <a className="dropdown-item" href="auth-signout-basic.html" onClick={() => logout()}>
                    <i className="mdi mdi-logout text-muted font-size-16 align-middle me-1" />
                     <span className="align-middle">Logout</span>
                </a>
              </div>
            </div>
            {/* <div className="dropdown d-inline-block">
              <img src="images/manual.png" style={{width: '60px'}}/>
            </div> */}
          </div>
        </div>
      </header>
      <div className="vertical-menu">
        {/* LOGO */}
        <div className="navbar-brand-box">
          <a href="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src="images/logo-pttep.png" height={22} />
            </span>
            <span className="logo-lg">
              <img src="images/logo-pttep.png" height={62} />
            </span>
          </a>
          <a href="/" className="logo logo-light">
            <span className="logo-lg">
              <img src="images/logo-pttep.png" height={22} />
            </span>
            <span className="logo-sm">
              <img src="images/logo-pttep.png" height={62} />
            </span>
          </a>
        </div>

        <button type="button" onClick={toggleCollapsed} className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn">
          <i className="fa fa-fw fa-bars" />
        </button>
        <div  className="sidebar-menu-scroll">
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" >
              <li className="menu-title" data-key="t-dashboards">
               <span>Menu v1.2.3</span>
               {/* <span>  <img src="images/manual.png" style={{width: '60px'}}/></span> */}
              </li>
              <li>
              </li>
            </ul>
              { !isLoading ?
                <Menu
                  onClick={onClick}
                  defaultSelectedKeys={['new-carton-list']}
                  inlineCollapsed={collapsed}
                  mode="inline"
                  items={menuItems}
                />:<></>
              }
              
          </div>
        </div>
      </div>
    </div>
  );
}
