enum Color {
    Red = "#D52B1E",
    Red2 = "#FF6384",
    Green = "rgb(166, 206, 57)",
    Blue = "#009FDA",
    Grey = "#747678",
    Yellow = "#FFC107",
    Orange = "#FF9F40",
    LightGreen = "#03D403",
    LightGrey = "rgb(194 194 195)",
    LightBlue = "#8faadc",
    DarkRed = "#E91909",
    DarkGreen = "#045F04",
    DarkBlue = "#00338D",
    DarkGrey = "#212529"
  }

  export default Color