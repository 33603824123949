import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Collapse, DatePicker, Form,  Modal, Radio, Row, Select } from 'antd';
import { AiOutlineCheck, AiOutlineClose, AiOutlinePrinter, AiOutlineSearch, AiOutlineSync } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import { Label } from 'reactstrap';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { QueryService, PropertyService ,WarehouseService, FileManageService} from '../../services';
import SummaryReport from '../../models/SummaryReport';
import SummaryLocation from '../../models/SummaryLocation';
import { Option , Item,Parameter} from '../../models'
import { InitHelper,Helper } from '../../helpers';
import {RequestTypeOption, CartonStatus,RequestType } from '../../enum';
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import dayjs from 'dayjs'
import Loading from '../../components/Loading';
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

let barChart:Chart
let myChart:Chart

const ReportCartonQuantity = () => {
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }

    const { instance, accounts } = useMsal();
  const [dataForm] = Form.useForm();
  const { Panel } = Collapse;
  const [warehouseOptions, setWarehouseOptions] = useState<Option[]>([])
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isLoadingPrint,setIsLoadingPrint] = useState(false)
    const [documentStartYear, setDocumentStartYear] = useState<number | null | undefined>((new Date().getFullYear() - 4) );
    const [documentEndYear, setDocumentEndYear] = useState<number | null | undefined>((new Date().getFullYear()) );
  const [orgunits, setOrgUnits] = useState<Item[]>([])
  const initialRequestTypeIds:number[] = [
    RequestType.NewCarton, 
    RequestType.StorageCarton, 
    RequestType.CollectCarton,
    RequestType.ReturnCarton,  
    RequestType.RemoveCarton, 
    RequestType.DestructionCarton
  ]
  const [requestTypeIds,setRequestTypeIds] = useState<number[]>(initialRequestTypeIds)
  const [warehouseId, setWarehouseId] = useState("")
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [printForm] = Form.useForm();
  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
 


  const createChart = (chartData:SummaryLocation[]) => {

      let _requestTypeId = dataForm.getFieldValue("requestType")
      if (!_requestTypeId) {
          _requestTypeId = 0
      }
      let temp = RequestTypeOption.filter(x => x.value === parseInt(_requestTypeId));
      let _requestType = "Selected Carton";
      let field = dataForm.getFieldsValue()
      if (!field.requestType)
          _requestType = "All Carton";
      else if (field.requestType.length <= 1 && temp.length > 0)
          _requestType = temp[0].label 
          
    if(barChart){
        barChart.destroy()
    }
    const labels = chartData.map( x => x.year)
    let normal:any[]= []
    let valveRoom:any [] = []
    labels.forEach( year => {
        let cData = chartData.find(x =>x.year == year)
        if(cData)
        {
            normal.push(returnIfElse(cData.normal, cData.normal, null)  )
            valveRoom.push(returnIfElse(cData.valveRoom, cData.valveRoom, null)  )
        }
        else{
            normal.push(null)
            valveRoom.push(null)
        }

    })

    const data = {
        labels: labels,
        datasets: [
          {
            label: 'Normal',
            data: normal,
            backgroundColor: '#36A2EB',
            },
          {
            label: 'Valve Room',
            data:valveRoom,
            backgroundColor:'#FF6384',
          },
         
      ]
    };
    const canvasElement = document.getElementById('bar-chart') as HTMLCanvasElement
    barChart = new Chart(canvasElement,
    {
        type: 'bar',
        data: data,
        plugins: [ChartDataLabels],
        options: {
          plugins: {
              title: {
                display: true,
                  text: `Quantity Of ${_requestType} By Carton Location`,
                color: '#929daf',
                font:{
                  size: 14,
                  family: 'tahoma',
                  weight: 'bold',
                },
                position: 'top'
              },
              tooltip: {
              },
              datalabels: {
                color: 'white',
                font:function(context) {
                  let width = context.chart.width;
                    let size = Math.round(width / 32);
                    size = returnIfElse(size > 16, 16, size)
                    size = returnIfElse(size < 10, 10, size)
                  return {
                      weight: 'bold',
                      size: size
                  };
                },
                padding: 6,
              },
              legend: {
                labels: {
                  font:function(context) {
                    let width = context.chart.width;
                    let size = 12
                        size = returnIfElse(width > 200 && width <= 250, 9, size)
                        size = returnIfElse(width <= 200, 6, size)
                    return {
                        size: size
                    };
                  },
                } 
              },
              subtitle: {
                display: false,
                text: 'Summary as of Mar 03,2022',
                color: '#00338D',
                font: {
                  size: 12,
                  family: 'tahoma',
                  weight: 'bold',
                  style: 'italic'
              },
              padding: {
                bottom: 10
              }
            }
          },
          scales: {
            x: {
                stacked: true,
                grid: {
                  display: false
                },
                ticks:{
                  font:function(context) {
                    let width = context.chart.width;
                    let size = 12
                    if(width > 200 && width <= 250){
                      size = 9
                    }
                    if(width <= 200){
                      size = 6
                    }
                    return {
                        size: size
                    };
                  },
                }
            },
            y: {
                stacked: true,
                ticks: {
                  precision: 0
                },
                grid: {
                  display: false
                }
            },
            
          },
          responsive: true,
            aspectRatio	:2,
            maintainAspectRatio: false,
        }
    }
  )
 
  }

  const createDonutChart = (data:any[]) => {
    
    if(myChart){
      myChart.destroy()
  }
   
    console.log(data)
    let backgroundColors = ['#36A2EB','#cc65fe','#4BC0C0','#FF9F40','#FFCD56','#FF6384','#3C5B6F','#A79277','#40679E']
    let _requestTypeId = dataForm.getFieldValue("requestType")
    if(!_requestTypeId){
        _requestTypeId = 0
      }
      let temp = RequestTypeOption.filter(x => x.value === parseInt(_requestTypeId));
      let _requestType = "Selected Carton";
      let field = dataForm.getFieldsValue()
      if (!field.requestType)
          _requestType = "All Carton";
      else if (field.requestType.length <= 1 && temp.length>0)
          _requestType = temp[0].label 
    const canvasElement = document.getElementById('pie-chart')  as HTMLCanvasElement
    
    let backgroundColor:string[] = []
    let allKeys =  Object.keys(data)
    let allValues = Object.values(data)
    let labels:any = []
    let values:any = []

  
      if( !field.requestType || field.requestType.indexOf(RequestType.NewCarton) > -1){
        labels.push(allKeys[0])
        values.push(allValues[0])
        backgroundColor.push(backgroundColors[0])
      }
      if(!field.requestType || field.requestType.indexOf(RequestType.StorageCarton) > -1){
        labels.push(allKeys[1])
        values.push(allValues[1])
        backgroundColor.push(backgroundColors[1])
      }

      if(!field.requestType || field.requestType.indexOf(RequestType.CollectCarton) > -1){
        labels.push(allKeys[2])
        values.push(allValues[2])
        backgroundColor.push(backgroundColors[2])
      }
      if(!field.requestType || field.requestType.indexOf(RequestType.ReturnCarton) > -1){
        labels.push(allKeys[3])
        values.push(allValues[3])
        backgroundColor.push(backgroundColors[3])
      }
      if(!field.requestType || field.requestType.indexOf(RequestType.RemoveCarton) > -1){
        labels.push(allKeys[4])
        values.push(allValues[4])
        backgroundColor.push(backgroundColors[4])
      }
      if(!field.requestType || field.requestType.indexOf(RequestType.DestructionCarton) > -1){
        labels.push(allKeys[5])
        values.push(allValues[5])
        backgroundColor.push(backgroundColors[5])
      }
    
   

    const pieLabelsLine = {
        id: "pieLabelsLine",
        afterDraw(chart:any) {
          const {
            ctx,
            chartArea: { width, height },
          } = chart;

          const cx = chart._metasets[0].data[0].x;
          const cy = chart._metasets[0].data[0].y;

          const sum = chart.data.datasets[0].data.reduce((a:any, b:any) => a + b, 0);

          chart.data.datasets.forEach((dataset:any, i:any) => {
            chart.getDatasetMeta(i).data.forEach((datapoint:any, index:number) => {
              const { x: a, y: b } = datapoint.tooltipPosition();

              const x = 2 * a - cx;
              const y = 2 * b - cy;

              // draw line
              const halfwidth = width / 2;
              const halfheight = height / 2;
              const xLine = x >= halfwidth ? x + 20 : x - 20;
              const yLine = y >= halfheight ? y + 20 : y - 20;

              const extraLine = x >= halfwidth ? 10 : -10;

              ctx.beginPath();
              ctx.moveTo(x, y);
              ctx.arc(x, y, 2, 0, 2 * Math.PI, true);
              ctx.fill();
              ctx.moveTo(x, y);
              ctx.lineTo(xLine, yLine);
              ctx.lineTo(xLine + extraLine, yLine);
              // ctx.strokeStyle = dataset.backgroundColor[index];
              ctx.strokeStyle = "black";
              ctx.stroke();

              // text
              const textWidth = ctx.measureText(chart.data.labels[index]).width;
              ctx.font = "12px Arial";
              // control the position
              const textXPosition = x >= halfwidth ? "left" : "right";
              const plusFivePx = x >= halfwidth ? 5 : -5;
              ctx.textAlign = textXPosition;
              ctx.textBaseline = "middle";
              // ctx.fillStyle = dataset.backgroundColor[index];
              ctx.fillStyle = "black";

              ctx.fillText(
                chart.data.datasets[0].data[index],
                xLine + extraLine + plusFivePx,
                yLine
              );
            });
          });
        },
      };

    myChart = new Chart(
      canvasElement,
      {
        type: 'pie',
        data:{
          labels: labels,
          datasets: [{
            data: values ,
            backgroundColor: backgroundColor ,
            hoverOffset: 4
          }]
      },
      plugins: [ChartDataLabels],
      options: {
        plugins: {
          title: {
            display: true,
            text: `Quantity Of ${_requestType} By Year`,
            color: '#929daf',
                font:{
                  size: 14,
                  family: 'tahoma',
                  weight: 'bold',
                },
                position: 'top'
          },
          datalabels: {
            color: 'white',
            font:function(context) {
              let width = context.chart.width;
              let size = Math.round(width / 32);
              if(size > 16){
                size = 16
              }
              if(size < 10){
                size = 10
              }
              return {
                  weight: 'bold',
                  size: size
              }
            },
            padding: 6,
            formatter: function(value) {
              if(value >0 ){
                  return value;
              }else{
                  value = "";
                  return value;
              }
            }
          },
          legend: {
            position: 'chartArea',
              labels: {
                font: {
                    size: 14
                }
            },
            
          },
        },
        responsive: true,
        aspectRatio	:2,
        maintainAspectRatio: false
      }
    })
    
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }



  const getData = async (_parameter:Parameter) => {
    setIsLoading(true)
    let token = await getToken()
    let result = await new QueryService(token).GetSummary(_parameter);
    console.log(result)
    if(result && result.success)
    {
      let data = result.data
      let gData:any = genData(data)
      createDonutChart(gData)
      createChart(result.data.locations)
    
    }

    setIsLoading(false)
  }

  const genData = (data:any) => {
    console.log(requestTypeIds)
    let gData:any = []
     
        gData["New"] =  data.newcarton
        gData["Storage"] =  data.storage
        gData["Collect"] =  data.collect
        gData["Return"] =  data.return
        gData["Remove"] =  data.remove
        gData["Destruction"] =  data.destruction
      
    
    return gData
  }

  const getWarehouses = async () => {
    let token = await getToken()
      let result = await new WarehouseService(token).GetAllWarehouses()
    if(result && result.success){
        let options:Option[] = [{ label:'All' , value: "" }]
        let Locationoptions: Option[] = result.data.map((item) => { return { value: item.warehouseName, label: item.warehouseName  }})
        setWarehouseOptions(options.concat(Locationoptions))
    }
  }


    useEffect(() => {
        setRequestTypeIds(initialRequestTypeIds)
    getWarehouses()
    searchData()
    
  }, [])

  const searchData = async () => {
    let field = dataForm.getFieldsValue()
   console.log(field)
    let parameter = InitHelper.InitialParameter();
      //parameter.warehouseId = warehouseId > 0 ? warehouseId : -1
      parameter.cartonLocation = warehouseId+""
      parameter.requestTypeIds = requestTypeIds
        parameter.statusIds = [
            CartonStatus.WaitApproval,
            CartonStatus.WaitAdmin,
            CartonStatus.Inprogress,
            CartonStatus.Done,
            CartonStatus.Reject,
            CartonStatus.Cancel
        ]

    if(field.requestType){
        parameter.requestTypeIds = field.requestType
        if (parameter.requestTypeIds.length===0)
            parameter.requestTypeIds = initialRequestTypeIds
    }

    if(field.statusId && field.statusId.length > 0){
      parameter.statusIds = field.statusId
    }

    if(orgunits.length > 0){
      parameter.orgUnits = orgunits.map(item => item.key)
    }

      if (documentStartYear && documentStartYear > 0 && documentEndYear && documentEndYear > 0) {
          parameter.requestStartDate = documentStartYear + "-01-01"
          parameter.requestEndDate = documentEndYear + "-12-31"
          //parameter.documentYear1 = documentStartYear
          //parameter.documentYear2 = documentEndYear
      } else {
          parameter.requestStartDate = (new Date().getFullYear()-4) + "-01-01"
          parameter.requestEndDate = new Date().getFullYear() + "-12-31"
      }

    console.log(parameter)
    getData(parameter)
    
  }

  const reset = () => {
    
    dataForm.resetFields()
    
    setOrgUnits([])
    setDocumentStartYear(new Date().getFullYear() - 4)
    setDocumentEndYear(new Date().getFullYear())
    setWarehouseId("")
    let parameter = Object.assign({},InitHelper.InitialParameter())
    parameter.requestTypeIds = initialRequestTypeIds
    parameter.statusIds = [
        CartonStatus.WaitApproval,
        CartonStatus.WaitAdmin,
        CartonStatus.Inprogress,
        CartonStatus.Done,
        CartonStatus.Reject,
        CartonStatus.Cancel
    ]
    
      parameter.requestStartDate = documentStartYear + "-01-01"
      parameter.requestEndDate = documentEndYear + "-12-31"
     
    parameter.warehouseId = 0
    getData(parameter)
  }

  const onWarehouseIdChange = (value:string) => {
    setWarehouseId(value)
}
  const handlePrintModalCancel = () => {
    setIsPrintModalOpen(false);
  };

  const onDocumentStartYearChange = (value: any) => {
    let year = parseInt(dayjs(value).format('YYYY'))
      setDocumentStartYear(year)
      if (documentEndYear == null || (year + 4) < parseInt(documentEndYear + "")) {
          setDocumentEndYear(year + 4)
      }
  }

  const showPrintModal = () => {
    setIsPrintModalOpen(true);
  };
  
  
  const onDocumentEndYearChange = (value: any) => {
    let year = parseInt(dayjs(value).format('YYYY'))
      setDocumentEndYear(year)
      if (documentStartYear == null || (year - 4) > parseInt(documentStartYear + ""))
          setDocumentStartYear(year - 4)
  }

  const onOrgUnitSelect = (item:any) => {
    setOrgUnits(item)
  }

  const onSearchOrgUnit = async(key:string) => {
   
    let token = await getToken()
    let result = await new PropertyService(token).GetOrgUnits(key)
    if(result && result.success)
    {
      return result.data
    }
    else{
      console.log(result?.message)
      return []
    }
  }
  const onFinishPrint = async (values:any) => {
    setIsLoadingPrint(true)
    try{

      let a = document.createElement('a');
      let pie = myChart.toBase64Image();
      let bar = barChart.toBase64Image();
  
      const canvas = canvasRef.current;
      if (!canvas) return;
      const context = canvas.getContext('2d');
      if (!context) return;
      context.clearRect(0,0,100,2000)
     

      const image1 = new Image();
      image1.src = pie;
      const image2 = new Image();
      image2.src = bar;
      let mergedImageDataURL = ""
  
      const image1Promise = new Promise<HTMLImageElement>((resolve) => {
        image1.onload = () => resolve(image1);
      });
  
      const image2Promise = new Promise<HTMLImageElement>((resolve) => {
        image2.onload = () => resolve(image2);
      });
  


      const [loadedImage1, loadedImage2] = await Promise.all([image1Promise, image2Promise]);
      context.drawImage(loadedImage1, 0, 0, loadedImage1.width,loadedImage1.height);
      context.drawImage(loadedImage2, loadedImage1.width, 0, loadedImage2.width, loadedImage2.height);
      
      mergedImageDataURL = canvas.toDataURL('image/png');
  
      if(values.print === 1){
        let token = await getToken()
        let body ={content:mergedImageDataURL}
        await new FileManageService(token).DownloadPdfImgage(body,"CartonQuantitybyRequestType");
      }else{
  
        a.href = mergedImageDataURL
        a.download = 'CartonQuantity.png';
        a.click();
      }
    }catch(error){
    console.log("🚀 ~ file: ReportCartonQuantity.tsx:568 ~ onFinishPrint ~ error:", error)

    }finally{
      setIsLoadingPrint(false)
    }


  }

  return (

    <div>
      <canvas style={{display:'none'}} ref={canvasRef} width={1470} height={425}></canvas>
      <Collapse>
      {isLoadingPrint?<Loading/>:<></>}

        <Panel header="Search" key="1">
          <Form
            {...layout}
            form={dataForm}
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={validateMessages}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
              <Col md={11} >
                <Form.Item name="requestType" label="Request Type" >
                      <Select 
                       mode="multiple"
                        placeholder={MessageCommon.TitleSelect} 
                       
                        options={ [
                            { value: RequestType.NewCarton, label: 'New Carton' },
                            { value: RequestType.StorageCarton, label: 'Storage Carton' },
                            { value: RequestType.CollectCarton, label: 'Collect Carton' },
                            { value: RequestType.ReturnCarton, label: 'Return Carton' },
                            { value: RequestType.RemoveCarton, label: 'Remove Carton' },
                            { value: RequestType.DestructionCarton, label: 'Destruction Carton' },
                          ] }
                      />
                    </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >

              </Col>
            </Row>
            <Row>
              <Col md={11} >
              <Form.Item name="documentyear" label="Year">
                  <table style={{ width: '100%'}}>
                    <tr>
                      <td>
                        <DatePicker 
                          placeholder={MessageCommon.TitleDate} 
                          picker='year' style={{width: '100%'}} 
                          onChange={onDocumentStartYearChange}
                                                  value={dayjs(documentStartYear+"-01-01")}
                        />
                      </td>
                      <td>
                        <Label style={{ marginLeft: 10, marginRight: 10 ,verticalAlign:'top'}}>To</Label>
                      </td>
                      <td>
                        <DatePicker 
                          placeholder={MessageCommon.TitleDate} 
                          picker='year' 
                          style={{width: '100%'}}
                                                  onChange={onDocumentEndYearChange}
                                                  value={dayjs(documentEndYear + "-12-31")}
                        />
                      </td>
                    </tr>
                  </table>


                </Form.Item>

              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
              <Form.Item name="statusId" label="Status"   >
                  <Select 
                    placeholder={MessageCommon.TitleSelect} 
                    mode="multiple"
                    allowClear
                    options={[
                      { value: CartonStatus.WaitApproval, label: 'Wait for Approval' },
                      { value: CartonStatus.WaitAdmin, label: "Wait for Admin Service" },
                      { value: CartonStatus.Inprogress, label: 'On Going' },
                      { value: CartonStatus.Done, label: 'Completed Service' },
                      { value: CartonStatus.Reject, label: 'Rejected' },
                      { value: CartonStatus.Cancel, label: 'Cancel' },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
              <Form.Item name="cartonLocation" label="Carton Location"  initialValue={""}>
                    <Select 
                        showSearch
                        onChange={onWarehouseIdChange}
                        placeholder="Please select..." 
                        options={warehouseOptions} 
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                 </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
              <Form.Item name="orgUnit" label="Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={orgunits ? orgunits : []}
                            onChange={item => onOrgUnitSelect(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
              </Col>
            </Row>



          </Form>
          <div style={{ textAlign: 'center' }}>
            <Button type="default" className='mr-btn' onClick={reset}>
              <AiOutlineSync className='icn-btn' /> Reset
            </Button>
            <Button type="primary"  onClick={searchData} >
              <AiOutlineSearch className='icn-btn' /> Search
            </Button>


          </div>

        </Panel>
      </Collapse>


      <br></br>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Data Result" key="1" >
          <Button type="primary" htmlType="button" className='mr-btn' onClick={() => showPrintModal()}>
            <AiOutlinePrinter className='icn-btn' />Print
          </Button>
          { isLoading ? <Loading/> : <></> }
          <br></br>
          <br></br>
        
          <Row >
              <Col md={11} >
                <div  className="chart-container" style={{ minHeight: '340px',width:'100%', margin: 'auto',paddingBottom:'80px' }}>
                  <canvas id="pie-chart"></canvas>
                </div>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                <div  className="col-md-6 chart-container" style={{ minHeight: '340px',width:'100%', margin: 'auto' }}>
                      <canvas id="bar-chart"></canvas>
                </div>
              </Col>
          </Row>
        
      
        </Panel>
      </Collapse>
    
      <Modal title="" open={isPrintModalOpen} footer={''} width={600} onCancel={handlePrintModalCancel}>
        <br></br>
        <Card >
          <Form
            {...layout}
            form={printForm}
            name="nest-messages"
            onFinish={onFinishPrint}
            validateMessages={validateMessages}
            initialValues={{'print':1}}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            
            <Row>
              <Col md={24}  >
                <Form.Item name="print" label="Export type" labelCol={{ md: 6 }}  >
                  <Radio.Group name="radiogroup">
                    <Radio value={1}>PDF</Radio>
                    <Radio value={0}>PNG</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <div style={{ textAlign: 'center' }} >
            <Button type="default" className='mr-btn'  onClick={handlePrintModalCancel}>
              <AiOutlineClose className='icn-btn' />Cancel
            </Button>
            <Button type="primary" className='mr-btn'   htmlType='submit' >
              <AiOutlineCheck className='icn-btn' />OK
            </Button>
          </div>
          </Form>
        </Card>
      </Modal>
    
    </div>
  )

}

export default ReportCartonQuantity
