import axios from 'axios'
import { DataObjectResponse,DataArrayResponse} from '../models/PageResponse'
import { User }  from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'
import UserRole  from '../models/UserRole'

const apiUrl = config.API_URL

export default class UserService
{
    protected headers = {
        'Content-Type': 'application/json',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': ""
            }
        }
    }
    
    async getUser(email: string){
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
            params: {
                email: email
            }
        }

        console.log(options)
        const { data }  = await axios.post<DataObjectResponse<User>>(`${apiUrl}/v1/users/user`,{}, options )
        
        return data;
    }

    async getUserById(id: string){

        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
            params: {
                id: id
            }
        }
        
        const { data }  = await axios.post<DataObjectResponse<User>>(`${apiUrl}/v1/users/userid`,{}, options )
        
        return data;
    }

    async getUsers(body: any) {

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
         
        }
        
        const { data }  = await axios.post<DataObjectResponse<User[]>>(`${apiUrl}/v1/users/users`,body, options )
        
        return data;
    }
    async getRoles(employeeId: string){
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
            params: {
                employeeId: employeeId
            }
        }

        console.log(options)
        const { data }  = await axios.post<DataArrayResponse<UserRole>>(`${apiUrl}/v1/users/roles`,{}, options )
        
        return data;
    }
    async createUser(body: any) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
        }
        
        const { data }  = await axios.post<DataObjectResponse<User[]>>(`${apiUrl}/v1/users/create-user`,body, options )
        
        return data;
    }
    async updateUser(body: any) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
        }
        
        const { data }  = await axios.post<DataObjectResponse<User[]>>(`${apiUrl}/v1/users/update`,body, options )
        
        return data;
    }
    }