import axios from 'axios'
import { PageDataResponse } from '../models/PageResponse'
import { LocationLog, OwnerLog } from '../models'
import { config } from '../config'

const apiUrl = config.API_URL

export default class DisplayLogService {
    protected headers = {
        'Content-Type': 'application/json',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    protected token = ""
    constructor(token: string | null) {
        if (token) {
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': ""
            }
        }

    }

    async GetLocation(page:number,perpage:number,recordNo:number){
        if(!this.token) return null

        let options = {
            headers:this.headers
        }
        
        const { data }  = await axios.get<PageDataResponse<LocationLog>>(`${apiUrl}/v1/displaylogs/location?page=${page}&perpage=${perpage}&recordNo=${recordNo}`, options )
        
        return data;
    }

    async GetOwner(page:number,perpage:number,recordNo:number){
        if(!this.token) return null

        let options = {
            headers:this.headers
        }
        
        const { data }  = await axios.get<PageDataResponse<OwnerLog>>(`${apiUrl}/v1/displaylogs/owner?page=${page}&perpage=${perpage}&recordNo=${recordNo}`, options )
        
        return data;
    }


}