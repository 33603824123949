import { useEffect } from 'react';
import {  Card,Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import DestructionCartonRequest from './DestructionCartonRequest';
import DestructionCartonStatusTracking from './DestructionCartonStatusTracking';
import DestructionCartonSaveDraft from './DestructionCartonSaveDraft';

const DestructionCarton3 = () => {
  const navigate = useNavigate();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Destruction`,
      children: <DestructionCartonRequest onBehalf={0}/>,
    },
    {
      key: '2',
      label: `Status Tracking`,
      children: <DestructionCartonStatusTracking/>,
    },
    {
      key: '3',
      label: `Save Draft`,
      children: <DestructionCartonSaveDraft/>,
    },

  ];

  const onTabChange = (tab:string) => {
    if(tab === '1'){
        navigate('/destruction-carton')
    }
    else{
        let tabstr = tab === '2' ? 'tracking' : 'save'
        navigate('/destruction-carton-' + tabstr)
    }
  }

  useEffect(() => {

  
  }, [])

  return (

    <div>
      <Card >
        <Tabs defaultActiveKey="3" items={items} onChange={onTabChange} destroyInactiveTabPane/>
      </Card>
    </div>
  );

}

export default DestructionCarton3
