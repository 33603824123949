import axios from 'axios'
import { config } from '../config'
import { DataObjectResponse } from '../models/PageResponse'
import { Project } from '../models/MasterDataDto'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class MasterDataService
{
    protected headers = {
        'Content-Type': 'application/json',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': ""
            }
        }
    }
    async getManageProjectName(projectName:string,location:number){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.get<DataObjectResponse<Project[]>>(`${apiUrl}/v1/master-data/getProjects?projectName=${projectName}&location=${location}`, options )
        
        return data;
    }

    async updateManageProjectName(body: any) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
        }

        const { data }  = await axios.post<DataObjectResponse<Project>>(`${apiUrl}/v1/master-data/updateProjects`, body,options )
        
        return data;
    }

    async createManageProjectName(body: any) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
        }

        const { data }  = await axios.post<DataObjectResponse<Project>>(`${apiUrl}/v1/master-data/createProjects`, body,options )
        
        return data;
    }


}