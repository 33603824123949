import React, { useEffect, useState } from 'react';
import { Button, Col, Collapse, DatePicker, Form, Input, Spin, Modal, Row, Select, Pagination } from 'antd';
import {  AiOutlineSearch, AiOutlineSync ,AiOutlineCloseSquare ,AiFillEye} from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import { Label } from 'reactstrap';
import RemoveCartonModalCollect from './RemoveCartonModalCollect';
import RemoveCartonModalStorage from './RemoveCartonModalStorage';
import { Parameter,CartonList,Item, User} from '../../models'
import { Helper, InitHelper } from '../../helpers';
import { StorageCartonService, CollectCartonService,HrWebService } from '../../services';
import dayjs from 'dayjs'
import {  CartonStatus, UserRole } from '../../enum'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import CollectCartonRequestDetail from '../CollectCarton/CollectCartonRequestDetail';
import StorageCartonRequestDetail from '../StorageCarton/StorageCartonRequestDetail';
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
  currentPage:number
  perpage:number
  total: number
  items:CartonList[]
  itemNoList:string[]
  isLoading:boolean
}

const RemoveCartonRequest = () => {
    const { instance, accounts } = useMsal();
    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const pageSizeOptions = user.systemConfig.tablePageSize
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataForm] = Form.useForm();
  const { Panel } = Collapse;
  const [modal, contextHolder] = Modal.useModal();
  const [selectItems, setSelectItems] = useState<string[]>([])
  const [isModalRequestOpen, setIsModalRequestOpen] = useState(false);
  const [requestTypeId,setRequestTypeId] = useState(CartonStatus.Collect)
  const [requestTypeId2,setRequestTypeId2] = useState(CartonStatus.Collect)
  const [documentStartYear, setDocumentStartYear] = useState<number|null|undefined>(undefined);
  const [documentEndYear, setDocumentEndYear] = useState<number|null|undefined>(undefined);
  const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter)
  const [recordNo,setRecordNo] = useState(0)
  const [requesters, setRequesters] = useState<Item[]>([])
  const [owners, setOwners] = useState<Item[]>([])
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    perpage: 10,
    items: [],
    itemNoList:[],
    isLoading : true
  })
  const [requestStartDate, setRequestStartDate] = useState<Date|null>(null);
  const [requestEndDate, setRequestEndDate] =  useState<Date|null>(null);

  useEffect(() => {
    initial()

  }, [])

  const initial = () => {
      setSelectItems([])
      let parameter = InitHelper.InitialParameter()
      parameter.requesters = [user.employeeId]
      parameter.owners = [user.employeeId]
      parameter.requesterOrgUnit = user.orgUnit
      parameter.employeeLevel = user.level
      parameter.userConfidential = user.employeeId
      parameter.condition = "or"
      getData(1, appState.perpage,parameter)
  }

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOpenModalRequest = async (_recordNo:number) => {
    setRecordNo(_recordNo)
    setIsModalRequestOpen(true)
  
  }

  const handleCancelModalRequest = () => {
    setIsModalRequestOpen(false)
  }

  
  const onCheckAll = (e:React.ChangeEvent<HTMLInputElement>) => {
    let items:string[] = []
    if(e.target.checked){
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.cartonNo) as HTMLInputElement
        element.checked = true
        items.push(element.value)
      })
    }
    else{
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.cartonNo) as HTMLInputElement
        element.checked = false
      })
    }
    setSelectItems(items)
  };

  const onCheckChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    console.log('test-check')
    let checked = e.target.checked
    let value = e.target.value
    let element = document.getElementById('check-item-all') as HTMLInputElement
    let items = Object.assign([],selectItems)
    if(checked){
      console.log('check')
        items.push(value)
       
        if(items.length === appState.items.length){
          element.checked = true
          element.indeterminate = false
        }
        else{
          element.checked = false
          element.indeterminate = true
        }
        
    }
    else{
      console.log('uncheck')
      let index = items.indexOf(value);
      if (index > -1) {
        console.log(index)
        items.splice(index, 1);
      }

      if(items.length === 0){
        element.checked = false
        element.indeterminate = false
      }
      else{
        element.checked = false
        element.indeterminate = true
      }
    }

    console.log(items)
    setSelectItems(items)
  }


  const searchData = async () => {
    let field = dataForm.getFieldsValue()
    console.log(field)
    let parameter = InitHelper.InitialParameter();
    parameter.condition = "and"
        parameter.requesters =  []
        parameter.owners = []
        parameter.requester = user.employeeId
        parameter.requesterOrgUnit = user.orgUnit
        parameter.employeeLevel = user.level
        
        if (field.requestNo) {
            parameter.requestNoList = field.requestNo.trim().split(",")
        }

        if(requestStartDate && requestEndDate){
            parameter.requestStartDate = requestStartDate
            parameter.requestEndDate = requestEndDate
        }
        
    if(documentStartYear && documentEndYear){
        parameter.documentStartYear = documentStartYear
        parameter.documentEndYear = documentEndYear
    }

    if(requesters.length > 0){
     parameter.requesters = requesters.map(item => item.key)
    }

    if(owners.length > 0){
        parameter.owners = owners.map(item => item.key)
       }
   
    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    let cartonNoStart = ""
    let cartonNoEnd = ""
    if(elementStart){
      cartonNoStart = elementStart.value
    }
    if(elementEnd){
      cartonNoEnd = elementEnd.value
    }

    if(cartonNoStart && cartonNoEnd){
      parameter.cartonNo = cartonNoStart + '&' + cartonNoEnd
    }

    if(field.cartonNoList){
      parameter.cartonNo = field.cartonNoList
    }

    if(field.cartonNo){
      parameter.cartonNo = field.cartonNo
    }

    console.log(parameter)
    setSelectItems([])
    setRequestTypeId(field.requestTypeId)
    getData(1, appState.perpage, parameter)
    
  }
  const reset = () => {
    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    elementStart.value = ""
    elementEnd.value = ""

    dataForm.resetFields()
    setRequesters([])
    setOwners([])
    setDocumentStartYear(undefined)
    setDocumentEndYear(undefined)
    setRequestStartDate(null)
    setRequestEndDate(null)
    
    let parameter = InitHelper.InitialParameter()
    parameter.requesters = [user.employeeId]
      parameter.owners = [user.employeeId]
      parameter.requesterOrgUnit = user.orgUnit
      parameter.employeeLevel = user.level
      parameter.userConfidential = user.employeeId
      parameter.condition = "or"
    getData(1,appState.perpage,parameter)
  }

  const getData = async (currentPage: number,perpage: number , _parameter:Parameter | null = null) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)

    let parameter = Object.assign({},saveParameter)
    if(_parameter !== null){
        parameter = _parameter;
    }

    parameter.page = currentPage
    parameter.limit = perpage
    parameter.statusIds = [CartonStatus.Done]
   
    console.log(parameter)

      let token = await getToken()
      let _requestTypeId = dataForm.getFieldValue('requestTypeId')
      let result:any = null
      if(!_requestTypeId || _requestTypeId === CartonStatus.Collect){
        parameter.cartonStatusIds = [CartonStatus.Collect]
        result = await new CollectCartonService(token).SearchRemoveRequest(parameter)
      }
      if(_requestTypeId === CartonStatus.Storage){
        parameter.cartonStatusIds = [CartonStatus.Storage]
        result = await new StorageCartonService(token).SearchRemoveRequest(parameter)
      }
      
      console.log(result)
      if(result && result.success)
      {
        let {page,limit,total,items,itemNoList} = result.data
      
        let state = Object.assign({},appState)
          state.total = total
          state.currentPage = page
          state.perpage = limit
          state.items = items
          state.itemNoList = itemNoList
          state.isLoading = false


        setTimeout(() => {
            setSaveParameter(parameter)
            setAppState(state)
        }, 500);
      }
      else{
        let state = Object.assign({},appState)
          state.isLoading = false
          setAppState(state)
      }

  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

  const onPageChange = (page:number,perpage:number) => {
    if(page !== appState.currentPage && perpage === appState.perpage){
        getData(page,appState.perpage)
    }

    if(page === appState.currentPage && perpage !== appState.perpage){
        getData(1,perpage)
    }
  }


const onRequesterSelect = (item:any) => {
  setRequesters(item)
}

const onOwnerSelect = (item:any) => {
    setOwners(item)
  }

const onSearchEmployee = async(key:string) => {
 
  let token = await getToken()
  let result = await new HrWebService(token).SearchEmployee(key)
  if(result && result.success)
  {
    
    let _orgUnit = user.orgUnit
    if(user.orgUnit.indexOf("/") > -1) {
        _orgUnit = user.orgUnit.split("/")[0]
    }
    return result.data.filter(x => x.orgUnit.indexOf(_orgUnit) > -1)
    
  }
  else{
    console.log(result?.message)
    return []
  }
}

const onDocumentStartYearChange = (value: any) => {
    let year = parseInt(dayjs(value).format('YYYY'))
    setDocumentStartYear(year)
}
  
  const onDocumentEndYearChange = (value: any) => {
    let year = parseInt(dayjs(value).format('YYYY'))
    setDocumentEndYear(year)
}

const onRequestStartDateChange = (date: any) => {
    setRequestStartDate(date);
  }

  const onRequestEndDateChange = (date: any) => {
    setRequestEndDate(date)
  }

  const onRequestTypeIdChange = (value:number) => {
    setRequestTypeId2(value)
  }
  return (

    <div>
      <Collapse>
        <Panel header="Carton Search" key="1">
          <Form
            {...layout}
            form={dataForm}
            name="remove-form"
            onFinish={onFinish}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
              <Col md={11} >
                <Form.Item name="cartonRange" label="Carton No:(Range)" >
                  <Input style={{display:'none'}} />
                  <table style={{width: '100%'}}>
                        <tr>
                          <td>
                            <input id="cartonNoStart" className='form-control form-control-sm' placeholder='ex. 03PCS001'/>
                          </td>
                          <td>
                            <Label style={{ marginLeft: 10, marginRight: 5 }}>To</Label>
                          </td>
                          <td>
                            <input id="cartonNoEnd" className='form-control form-control-sm' placeholder='ex. 03PCS001'/>
                        
                          </td>
                        </tr>
                      </table>
                </Form.Item>
              </Col>
              <Col md={2} ></Col>
              <Col md={11} >
                <Form.Item name="requestTypeId" label="Carton Status" initialValue={CartonStatus.Collect}>
                  <Select 
                  onChange={onRequestTypeIdChange}
                    options={[
                      { value: CartonStatus.Collect, label: 'Collect' },
                      { value: CartonStatus.Storage, label: 'Storage' },
                     
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                <Form.Item name="cartonNoList" label="Carton No (Multiple)" >
                  <Input.TextArea placeholder='ex. 03PCS001,03PCS002,03PCS003' rows={5} />
                </Form.Item>
                <Form.Item name="cartonNo" label="Carton No" >
                  <Input placeholder='ex. 03PCS001'/>
                </Form.Item>
                <Form.Item name="requestNo" label={ requestTypeId2 === CartonStatus.Collect ? 'Collect Request No.' : 'Storage Request No.'} >
                      <Input placeholder="ex. B230001,B230002,B230003" />
                    </Form.Item>
              </Col>
              <Col md={2} ></Col>
              <Col md={11} >
              <Form.Item name="documentyear" label="Document Year">
                  <table style={{ width: '100%'}}>
                    <tr>
                      <td>
                        <DatePicker 
                          placeholder={MessageCommon.TitleDate} 
                          picker='year' style={{width: '100%'}} 
                          onChange={onDocumentStartYearChange}
                        />
                      </td>
                      <td>
                        <Label style={{ marginLeft: 10, marginRight: 10 ,verticalAlign:'top'}}>To</Label>
                      </td>
                      <td>
                        <DatePicker 
                          placeholder={MessageCommon.TitleDate} 
                          picker='year' 
                          style={{width: '100%'}}
                          onChange={onDocumentEndYearChange}
                        />
                      </td>
                    </tr>
                  </table>
                </Form.Item>
                <Form.Item name="requester" label="Requester" >
                   <EmployeeAutoComplete
                     placeholder='Search by Name'
                    selectedItems={requesters ? requesters : []}
                    onChange={item => onRequesterSelect(item)}
                    service={onSearchEmployee}
                    minimumTextLength={3}
                  />
                </Form.Item>
                <Form.Item name="owner" label="Owner" >
                   <EmployeeAutoComplete
                     placeholder='Search by Name'
                    selectedItems={owners ? owners : []}
                    onChange={item => onOwnerSelect(item)}
                    service={onSearchEmployee}
                    minimumTextLength={3}
                  />
                </Form.Item>
                
                <Form.Item name="requestDate" label={ requestTypeId2 === CartonStatus.Collect ? 'Collect Request Date' : 'Storage Request Date'}>
                      <table style={{width : '100%'}}>
                        <tr>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate} format={'DD MMM YYYY'} className='date-picker-full' onChange={onRequestStartDateChange}/>
                          </td>
                          <td>
                            <Label style={{ marginLeft: 10, marginRight: 10 }}>To</Label>
                          </td>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate} format={'DD MMM YYYY'} className='date-picker-full' onChange={onRequestEndDateChange}/>
                          </td>
                        </tr>
                      </table>
                    </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
              
               
              </Col>
              <Col md={2} ></Col>
              <Col md={11} >
              
              </Col>
            </Row>
        
          </Form>
          <div style={{ textAlign: 'center' }}>
            <Button type="default" className='mr-btn' onClick={reset} >
              <AiOutlineSync className='icn-btn' /> Reset
            </Button>
            <Button type="primary" onClick={searchData}  >
              <AiOutlineSearch className='icn-btn' /> Search
            </Button>


          </div>

        </Panel>
      </Collapse>


      <br></br>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Data Result" key="1" >
          
          <div style={{float: 'left'}}>
          <Button type="primary" className='btn-red mr-btn' onClick={showModal}  disabled={selectItems.length === 0 ? true : false} >
            <AiOutlineCloseSquare className='icn-btn' /> Remove Carton
          </Button>
        </div>
          <div style={{float: 'right'}}>
            
          </div>
          <br></br>
          <br></br>
          <div id="table-container">
        { appState.isLoading ?
            <div style={{ margin: 'auto',paddingBottom: '30px'}}>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
          :
          <table className='idoc-table'>
            <thead id="head-table-fixed">
              <tr>
                <th style={{ textAlign: 'left',width: '50px'}}>
                { appState.items.length > 0 ?
                  <div className="form-check">
                    <input  type="checkbox" id={'check-item-all'} className="form-check-input check-item" onChange={(e) => onCheckAll(e)}/>
                    <label className="form-check-label" htmlFor={'check-item-all'}/>
                  </div>:<></>
                }
                </th>
                <th >Carton No.</th>
                <th >{ requestTypeId === CartonStatus.Collect ? 'Collect Request No.' : 'Storage Request No.' }</th>
                <th >Requested Date</th>
                <th >Requester</th>
                <th >Owner</th>
                { requestTypeId === CartonStatus.Collect ?
                    <th >Collect By</th>:<></>
                }
                <th >Carton Status</th>
                <th>Detail</th>
              </tr>
            </thead>
           
            <tbody >
            { appState.items.map((item:CartonList) => (
                <tr >
                   <td style={{ textAlign: 'center',width: '50px'}}>
                      <div className="form-check">
                          <input  type="checkbox" id={'check-item-' + item.cartonNo} value={item.cartonNo} className="form-check-input check-item" onChange={(e) => onCheckChange(e)}/>
                          <label className="form-check-label" htmlFor={'check-item-' + item.cartonNo}/>
                        </div>
                  </td>
                  <td style={{textAlign: 'center',width:'120px'}}>
                   {item.cartonNo}
                  </td>
                  <td style={{textAlign: 'center'}}>
                   {item.requestNo}
                  </td>
                  <td style={{textAlign: 'center'}}>{item.requestDate ? dayjs(item.requestDate).format("DD MMM YYYY") : ""}</td>
                  <td style={{textAlign: 'center'}}>{item.requesterName}</td>
                  <td style={{textAlign: 'center'}}>{item.ownerName}</td>
                 
                  { requestTypeId === CartonStatus.Collect ?
                        <td style={{ textAlign: 'center' }}>{item.collectByName}</td>:<></>
                  }
                  <td style={{textAlign: 'center'}}>{item.cartonStatus}</td>
                  <td style={{textAlign: 'center'}}>
                    { requestTypeId === CartonStatus.Collect ?
                        <a onClick={() => handleOpenModalRequest(item.collectRecordNo)}>
                            <AiFillEye className='icon-table'></AiFillEye>
                        </a>
                        :
                        <a onClick={() => handleOpenModalRequest(item.storageRecordNo)}>
                            <AiFillEye className='icon-table'></AiFillEye>
                        </a>
                    }
                  </td>
                 
                </tr>
              ))
            }
            
            </tbody>
  
          </table>
        }
          </div>
          <div style={{marginTop:'10px',textAlign:'right'}}>
          { appState.total > 0 ?
                <Pagination 
                key={Helper.Uniqid()}
                current={appState.currentPage} 
                pageSize={appState.perpage}
                total={appState.total} 
                onChange={onPageChange} 
                pageSizeOptions={pageSizeOptions}
                showSizeChanger={true}
              />:<></>
          }
          </div>
        </Panel>
      </Collapse>
      <Modal title="Request Detail" open={isModalOpen} footer={''}  width={1550} onCancel={handleCancel} maskClosable={false}>
        { requestTypeId2 === CartonStatus.Collect ?
          <RemoveCartonModalCollect key={Helper.Uniqid()} removeId={0} items={selectItems} page="remove" onReload={null}/>:<></>
        }
        { requestTypeId2 === CartonStatus.Storage ?
          <RemoveCartonModalStorage key={Helper.Uniqid()} removeId={0} items={selectItems} page="remove" onReload={null}/>:<></>
        }
      </Modal>

        <Modal title="Request Detail" open={isModalRequestOpen} footer={''} width={1550} onCancel={handleCancelModalRequest}>
        { requestTypeId2 === CartonStatus.Collect ?
          <CollectCartonRequestDetail key={Helper.Uniqid()} recordNo={recordNo}/>
          :
                  <StorageCartonRequestDetail key={Helper.Uniqid()} recordNo={recordNo} cartonNumber={""} approveLevel={0}/>
        }
          </Modal>
          {modal ? <span></span> : <></>}
      {contextHolder}
    </div>
  );

}

export default RemoveCartonRequest
