import React, { useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import  secureLocalStorage  from  "react-secure-storage";

const Logout = () => {
    const { instance } = useMsal();
    useEffect(() => {
      signout()
    },[])

    const signout = () => {
        secureLocalStorage.setItem('testFlag', 0)
        secureLocalStorage.clear();
        instance.logoutRedirect().catch(e => {
            console.error(e);
        })
    }
    

    return (
      <div className="container-fluid">
        <span>Signout...</span>
      </div>
 
    );

}

export default Logout