enum Message {
    TitleInput = "Please insert...",
    TitleSelect = "Please select...",
    TitleDate = "Please select...",
    Verify = `Verify means check and agree with the requester to remove and/or destroy the document as per requests before final approval`,
    Approve = `Approve means  approve storage requests (by N+1) and approve to remove or destroy cartons as per requests (by N+2) or approve either storage, remove, or destruction requests (if N+1 and N+2 is the same person)`,
    ApproveStatus = 'Approved',
    RejecteStatus = 'Rejected'
}

  export default Message