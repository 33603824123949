import { useEffect, useRef, useState } from 'react';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber,
   Modal, message, Row, Select, Space, Spin, Popconfirm, Tooltip, Upload } from 'antd';
import { AiFillDelete, AiFillFileExcel, AiFillSave, AiOutlineArrowLeft, 
  AiOutlineArrowRight, AiOutlineDownload, AiOutlineExclamation, AiOutlinePlus, 
  AiOutlineSave, AiOutlineDelete
} from 'react-icons/ai'
import {CiSquarePlus ,CiSquareMinus} from 'react-icons/ci'
import MessageCommon from '../../enum/Message';
import secureLocalStorage  from  "react-secure-storage";
import dayjs from 'dayjs';
import { ExclamationCircleOutlined,QuestionCircleOutlined,CloseCircleOutlined ,UploadOutlined } from '@ant-design/icons';
import { HrWebService, PropertyService, StorageCartonService ,FileManageService,SystemConfigService, UserService} from '../../services';
import { Item, User ,RequestDetail, Project,RequestStorageCarton, Option, Carton, Employee, FileItem} from '../../models';
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import { Helper, InitHelper } from '../../helpers';
import { CartonStatus, Color, ConfidentialOption, UserRole } from '../../enum';
import { useNavigate } from 'react-router-dom';
import { Label } from 'reactstrap';
import type { UploadFile } from 'antd/es/upload/interface';
import type { UploadChangeParam } from 'antd/es/upload';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";


interface OptionItem{
  label: string 
  value: number
}
interface FileItemList{
  cartonNo: number,
  itemNo: number,
  files:FileItem[]
}
interface Role
{
    roleId:number
    roleName:string
}
interface Error
{
  owner: number
  quantity: number
  projectId:number
  documentYear: number
  totalStorageYear: number
  place: number
  pickupDate:number
  confidentialityLevel:number
  remark:number,
  approver:number
}

interface StorageCarton
{
  recordNo: number
  requester:string
  requesterName:string
  quantity: number
  projectLocation: string
  projectId:number | undefined
  projectName:string
  floor:string,
  location:string
  place:string
  documentYear:any
  totalStorageYear:number
  destructionDueDate:any
  destructionDueDateName:string
  pickupDate:any
  confidentialityLevel:number | undefined
  statusId: number
  remark:string
  approver:string
  approverName: string
}

const StorageCartonRequest = () => {
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }


    const doIfElse = (flag: any, f1: any, f2: any) => {
        if (flag) {
            if (f1 != null) {
                f1();
            }
        }
        else {
            if (f2 != null) {
                f2();
            }
        }
    }
    
    const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const id:string| null| undefined = urlParams.get('id')
  const currentDate2 =urlParams.get('date')
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
    const orgUnit = returnIfElse(user.orgUnit , user.orgUnit,"") 
    const position = returnIfElse(user.position , user.position , "")
    const directPhone = returnIfElse(user.directPhone, user.directPhone , "")
  const currentDate = (currentDate2) ? currentDate2:  secureLocalStorage.getItem('date')
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [dataForm] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [modal, contextHolder] = Modal.useModal();
  const [storageYearOptions,setStorageYearOptions] = useState("")
  const inputRef = useRef<HTMLInputElement>(null);
  const [owners, setOwners] = useState<Item[]>([])
  const [contactPerson, setContactPerson] = useState<Item[]>([])
  const [projects,setProjects] = useState<Project[]>([])
  const [projectOptions,setProjectOptions] = useState<Option[]>([])
  const [ownersBgColor,setOwnersBgColor] = useState("")
  const [cartonItems,setCartonItems] = useState<Carton[]>([])
  const [selectItems, setSelectItems] = useState<number[]>([])
  const [prev, setPrev] = useState(false);
  
  const [error,setError] = useState<Error>({
      owner: 0,
      quantity: 0,
      projectId:0,
      documentYear: 0,
      totalStorageYear: 0,
      place: 0,
      pickupDate:0,
      confidentialityLevel:0,
      remark:0,
      approver: 0
  })
  const storageCarton:StorageCarton = {
      recordNo: 0,
      requester: user.employeeId,
      requesterName: `Name: ${user.employeeName}  Org. Unit: ${orgUnit} Position: ${position}, Officer Services Tel: ${directPhone}`,
      quantity: 1,
      projectLocation: "",
      projectId: undefined,
      projectName: "",
      floor: "",
      location: "",
      place: "",
      documentYear: null,
      totalStorageYear: 1,
      destructionDueDate: null,
      destructionDueDateName: "",
      pickupDate: null,
      confidentialityLevel: user.roleIds.findIndex( x => x.roleId === UserRole.UserValveRoom) > -1 ? 1 : 0,
      statusId: 0,
      remark: "",
      approver:"",
      approverName: ""
  }
 
  const [isLoading,setIsLoading] = useState(true)
  const [cartonOptions,setCartonOptions] = useState<OptionItem[]>([])
  const [cartonNo,setCartonNo] = useState(1)
  const [valveCheck,setValveCheck] = useState(false)
  const [remarkRequire,setRemarkRequire] = useState(false)
  const [isApproverLoading,setIsApproverLoading] = useState(false)
 

  useEffect(() => {
    getProjects()
    getSystemConfig()
    if(id){
      getData(parseInt(id))
    }
    else{
     
      let storageCartonData = Object.assign({},storageCarton)
      let date = new Date().toString()
      if(currentDate){
        date = currentDate.toString()
      }
      storageCartonData.pickupDate = Helper.add3Days(date)
      setOwners([])
      setContactPerson([])
      dataForm.setFieldsValue(storageCartonData);
      setIsLoading(false)
  }

  }, [])
  const validateMessages = {
    required: '${label} is required!'
  };

  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
  
  const confirmSave = (saveType:boolean) => {
      if(saveType){
        modal.confirm({
          title: 'Confirm',
          icon: <ExclamationCircleOutlined />,
          content: 'Confirm to submit?',
          okText: 'OK',
          cancelText: 'Cancel',
          onOk: () => handleSave(true)
        });
      }
      else{
        handleSave(false)
      }
    
  }

  const validate = (_cartonItems:Carton[]) =>{
    console.log(_cartonItems)
    if(_cartonItems.length == 0){
      Modal.error({
        icon: <CloseCircleOutlined />,
        title: 'This is an error message',
        content: 'Please check, system found empty document in carton.'
      });
      return false
    }

    let countEmpty = 0
    cartonOptions.forEach(opt => {
        let count = _cartonItems.filter( x => x.cartonNo === opt.value)
        console.log(opt.value + '=' + count.length)
        if(count.length === 0){
            countEmpty = 1
        }
    })

    if(countEmpty > 0)
    {
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: 'System found empty document in carton.'
          });
          return false
    }

    let emptyFields = _cartonItems.filter( x => x.description.length === 0)
    if(emptyFields.length > 0){
      Modal.error({
        icon: <CloseCircleOutlined />,
        title: 'This is an error message',
        content: 'Description is required fields '
      });
      return false
    }

    return true
  }

  const validateDateCheck = (_cartonItems:Carton[]) => {
    let checkDate = true
    _cartonItems.forEach(item => {
        if(item.documentStartYear &&  item.documentEndYear)
        {
            if(item.documentEndYear < item.documentStartYear){
                checkDate = false
            }
          }
    })

    if(!checkDate){
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: 'Found document start year greater than end year.'
        });
    }
    return checkDate
  }

  const handleSave = async (saveType: boolean,redirect:boolean = true) => {
    let cartons = onCacheStorage()
    if(saveType){
      let validateCheck = validate(cartons)
      if(!validateCheck){
        return
      }
    }

    if(!validateDateCheck(cartons)){
        return false
    }
    let statusId = saveType ? CartonStatus.WaitApproval : CartonStatus.SaveDraft
    
      let location = dataForm.getFieldValue('location')
      let floor = dataForm.getFieldValue('floor')
      
      let requestCarton:RequestStorageCarton = {
        recordNo: dataForm.getFieldValue('recordNo'),
        requester: storageCarton.requester,
        owner: dataForm.getFieldValue('owner'),
          contactPerson: returnIfElse(dataForm.getFieldValue('contactPerson'), dataForm.getFieldValue('contactPerson')  ,""),
        orgUnit: dataForm.getFieldValue('orgUnit'),
        totalCartonQuantity:dataForm.getFieldValue('quantity'),
        projectLocation:dataForm.getFieldValue('projectLocation'),
        projectId:dataForm.getFieldValue('projectId'),
        projectName:dataForm.getFieldValue('projectName'),
          location: returnIfElse(location, location, "")  ,
          floor: returnIfElse(floor, floor, "")  ,
        place:dataForm.getFieldValue('place'),
        statusId:statusId,
        documentYear: dataForm.getFieldValue('documentYear').format('YYYY-MM'),
        totalStorageYear:dataForm.getFieldValue('totalStorageYear'),
        expectedPickupDate:dataForm.getFieldValue('pickupDate'),
        confidentialityLevel:dataForm.getFieldValue('confidentialityLevel'),
        approver:dataForm.getFieldValue('approver'),
        remark:dataForm.getFieldValue('remark'),
          valveRoom: returnIfElse(valveCheck , 1 ,0) ,
        cartons:cartons
      }
      console.log(requestCarton)
      try {
          let token = await getToken()
            let result:any = null
      
            if(requestCarton.recordNo === 0){
                result = await new StorageCartonService(token).Create(requestCarton);
            }
            else{
                result = await new StorageCartonService(token).Update(requestCarton);
            }
            console.log(result)
            let element = document.getElementById('storage-carton-form') as HTMLElement
            if(result && result.success){
                if(redirect)
                {
                    messageApi.open({
                        type: 'success',
                        content: 'Save success',
                        duration: 1,
                        style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
                    });
                    setTimeout(() => {
                   
                        doIfElse(saveType,
                            function () {
                                navigate('/storage-carton-tracking')
                            }
                            ,
                            function () {
                                navigate('/storage-carton-save')
                            }
                        );
                    
                    
                    }, 1000);
                }
            }
            else{
                Modal.error({
                    icon: <CloseCircleOutlined />,
                    title: 'This is an error message',
                    content: result?.message,
                });
            }
    
      }
      catch(err:any){
        console.log(err)
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: err.message,
        });
      }
      
  };

  const validateNextStep = () => {
    let errorData:Error = {
      owner: 0,
      quantity: 0,
      projectId:0,
      documentYear: 0,
      totalStorageYear: 0,
      place: 0,
      pickupDate:0,
      confidentialityLevel:0,
      remark:0,
      approver: 0
    }

    let errorCount = 0
    setOwnersBgColor('')

    if(owners.length === 0)
    {
      console.log('e_owner')
      setOwnersBgColor('red')
       errorCount++
    }

    let quantity:number = dataForm.getFieldValue('quantity')
    if(!quantity || quantity < 1 || quantity > user.systemConfig.maxStorageQty){
      errorCount++
      errorData.quantity = 1
    }
   
    if(!dataForm.getFieldValue('projectId')){
      errorCount++
      errorData.projectId = 1
    }

    if(!dataForm.getFieldValue('documentYear')){
      errorCount++
      errorData.documentYear = 1
    }

    if(!dataForm.getFieldValue('totalStorageYear')){
      errorCount++
      errorData.totalStorageYear = 1
    }
    if(!dataForm.getFieldValue('place')){
      errorCount++
      errorData.place = 1
    }

    if(!dataForm.getFieldValue('pickupDate')){
      errorCount++
      errorData.pickupDate = 1
    }

    let confidentialityLevel = dataForm.getFieldValue('confidentialityLevel')
    if(confidentialityLevel === null || confidentialityLevel === undefined){
      errorCount++
      errorData.confidentialityLevel = 1
    }

      if (remarkRequire) {

          doIfElse(!dataForm.getFieldValue('remark') ,
              function () {
                  errorCount++
                  errorData.remark = 1
              }
              ,
             null
          );
    }

    if(!dataForm.getFieldValue('approver')){
        errorCount++
        errorData.approver = 1
    }

    setError(errorData)

    if(errorCount !== 0){
        let message = returnIfElse(errorData.approver === 0, 'Please fill required fields', 'Approver not found.') 
      let element = document.getElementById('storage-form') as HTMLFormElement
      messageApi.open({
        type: 'error',
        content: message,
        duration: 1.5,
        style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
      });
      return false
    }
    else{
      return true;
    }
   
  }

  const prevStep = () => {
    let items = onCacheStorage()
    setCartonItems(items)
    setPrev(true)
    setCurrent(0);
    
  }

    const setCarton = (
        data: Carton[]
        , quantity: number
    ) => {

            let _cartonItems: Carton[] = []
            for (let i = 1; i <= quantity; i++) {
                let item = data.filter(x => x.cartonNo === i)
                _cartonItems = _cartonItems.concat(item)
            }
            console.log(_cartonItems)
            setCartonItems(_cartonItems)
    }
  const nextStep = async () => {
    if(validateNextStep())
    {
      let quantity:number = dataForm.getFieldValue('quantity')
      let options:OptionItem[] = Helper.ArrayRange(1,quantity).map(item => { return { value: item, label: 'Carton ' + item}})
      setCartonOptions(options)
        if (!prev && id)
      {
              let token = await getToken()
              let result = await new StorageCartonService(token).GetCartonList(parseFloat(id))

              doIfElse(result && result.success,
                  function () {
                      if (result != null) {
                          setCarton(result.data, quantity)
                      }
                  }
                  ,
                  function () {
                      setCartonItems([])
                  }
              );
      }
      
      setCurrent(1);
    }
   
   
  }

  const addCartonItem = () => {
    let items = onCacheStorage();
    let element = document.getElementById('row-amount') as HTMLInputElement
    let rowAmount = parseInt(element.value)
    if(isNaN(rowAmount)){
      return false
    }
    let itemNo = 0
    if(items.length > 0)
    {
        itemNo = Math.max(...items.map(o => o.itemNo))
    }
    let checkAllElement = document.getElementById('check-item-all') as HTMLInputElement
    console.log(rowAmount)
    for(let i= 0 ; i < rowAmount; i ++){
      itemNo++
      let item:Carton = {
        recordNo: 0,
        referenceRecNo: 0,
        requestNo:"",
        cartonNo: cartonNo,
        itemNo: itemNo,
        cartonName: "",
        userReferenceNo:"",
        description:"",
        documentStartYear: null,
        documentEndYear: null,
        files:[],
        remark:"",
        documentCheckingDate: undefined,
        partnerOfContract:"",
        category: "",
        locationNo: "",
        sealNo: "",
        warehouseBarcode: "",
        cost: 0,
        language: "",
        numberOfOriginal:"",
        referenceDDOKNo:"",
        originator:"",
        businessUnit:"",
        valveRoom: valveCheck ? 1 : 0,
          checked: checkAllElement.checked,
          cartonStatusId:0,
        expireDate: ""
      }
      items.push(item)
      
    }
  
    setCartonItems(items)
  
  
}

  const removeCartonItem = (itemNoList:number[]) => {
    let items:Carton[] = Object.assign([],cartonItems)
    itemNoList.forEach(itemNo => {
      let index = items.findIndex(x => x.cartonNo === cartonNo && x.itemNo === itemNo)
      items.splice(index,1)
    })
    setCartonItems(items)
    setSelectItems([])
    let element = document.getElementById('check-item-all') as HTMLInputElement
    element.checked = false
    element.indeterminate = false
    
  }

  const confirmApplyToAll = () => {
    modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'All descriptions of every cartons in this request will be the same?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: applyToAll
    });
  };
 
const handleUploadTemplate = async () => {
    let fileInput= document.getElementById("upload-template") as HTMLInputElement
    let element= document.getElementById("action-panel") as HTMLInputElement
   
    let files:FileList|null = fileInput.files
    if(files){
        let file = files[0]
        let type = file.type.split('/').reverse()[0].toLowerCase()
        let typename = file.name.split('.').reverse()[0].toLowerCase()
        let fileExtensions = [ 'xls','xlsx']

        if(fileExtensions.indexOf(type) === -1 && fileExtensions.indexOf(typename) === -1){
            messageApi.open({
                  type: 'error',
                  content: 'file type is not allowed.',
                  duration: 1.5,
                  style: { marginTop: element.getBoundingClientRect().top - 100 , marginLeft: element.getBoundingClientRect().left - 100  }
            });
            return false 
        }

        let formData = new FormData();
            formData.append('recordNo',dataForm.getFieldValue('recordNo'))
            formData.append('boxNo',cartonNo.toString())
            formData.append('file[0]', file);

            try {
                let token = await getToken()
                let result = await new FileManageService(token).UploadTemplateFiles(formData);
                console.log(result)
                if(result && result.success){
          
                    messageApi.open({
                        type: 'success',
                        content: 'Save success',
                        duration: 1,
                        style: { marginTop: fileInput.getBoundingClientRect().top , marginLeft: fileInput.getBoundingClientRect().left  }
                    });
                    
                    let allItems:Carton[] = []
                    cartonOptions.forEach( opt =>{
                        let items = result?.data.filter( x => x.cartonNo == opt.value)
                        if(items && items.length > 0)
                        {
                            allItems =allItems.concat(items)
                        }
                       
                    })
                    console.log(allItems)
                    setCartonItems(allItems)
                }
                else{
                    messageApi.open({
                      type: 'error',
                      content: "Upload failed ,Please try again.",
                      duration: 1.5,
                      style: { marginTop: fileInput.getBoundingClientRect().top - 100 , marginLeft: fileInput.getBoundingClientRect().left - 100  }
                    });
                 }
            }
            catch(err:any) {
                messageApi.open({
                    type: 'error',
                    content: err,
                    duration: 1.5,
                    style: { marginTop: fileInput.getBoundingClientRect().top - 100 , marginLeft: fileInput.getBoundingClientRect().left - 100  }
                  });
            }
    }
    
}

  const handleUploadTemplateClick = async() => {
    let element = document.getElementById('upload-template') as HTMLInputElement
    element.click()
  }

  const onOwnerSelect = async (items:any) => {
    setOwners(items)
    setContactPerson(items)

    if(items.length > 0){
      setIsApproverLoading(true)
      let employee:Employee | null = await getMasterEmployee(items[0].key)
      let roles:Role[] = await getRoles(items[0].key)
      setIsApproverLoading(false)
      dataForm.setFieldsValue({
        owner: items[0].key,
        orgUnit: items[0].orgUnit,
        contactPerson: items[0].key ,
        contactPersonName: items[0].name,
        location: items[0].location ?  items[0].location : "",
        place: items[0].location ?  items[0].location : "",
        approver: employee ? employee.employeeId : "",
        approverName: employee ? employee.employeeName : "",
        confidentialityLevel: roles.findIndex(x => x.roleId === UserRole.UserValveRoom) > -1 ? 1 : 0
      });
      
    }
    else{
      dataForm.setFieldsValue({
        owner: "",
        orgUnit: "",
        location: "",
        approver:"",
        approverName: "",
      });
    }
    
    
  }
  const oncontactPersonSelect = (items:any) => {
    setContactPerson(items)
    if(items.length > 0){
      dataForm.setFieldsValue({
        contactPerson: items[0].key ,
        contactPersonName: items[0].name,
        location: items[0].location ? items[0].location : "",
      });
    }
    else{
      dataForm.setFieldsValue({
        contactPerson: "",
        contactPersonName: "",
        location: "",
      });
    }
    
  }
  
  const onSearchEmployee = async(key:string) => {
   
      let token = await getToken()
      let result = await new HrWebService(token).SearchEmployee(key)
      if(result && result.success)
      {
        let _orgUnit = user.orgUnit
        if(user.orgUnit.indexOf("/") > -1) {
            _orgUnit = user.orgUnit.split("/")[0]
        }
        return result.data.filter(x => x.orgUnit.indexOf(_orgUnit) > -1)
      }
      else{
        console.log(result?.message)
        return []
      }
  }

  const onSearchEmployee2 = async(key:string) => {
   
    let token = await getToken()
    let result = await new HrWebService(token).SearchEmployee(key)
    if(result && result.success)
    {
      return result.data
    }
    else{
      console.log(result?.message)
      return []
    }
}

    const getMasterEmployee = async (key: string) => {

        let token = await getToken()
        let parameter = InitHelper.InitialParameter();
        parameter.employeeID = key

        let result = await new HrWebService(token).GetMasterEmployee(parameter)
    if(result && result.success)
    {
        console.log(result)
      return result.data
    }
    else{
      console.log(result?.message)
      return null
    }
}

const getRoles = async (key: string) => {

    let token = await getToken()
    
    let result = await new UserService(token).getRoles(key)
    if(result && result.success)
    {
   
        return result.data
    }
    else{
        console.log(result?.message)
        return []
    }
}
  
  const onProjectChange = (id:number) => {
    
      if(id === 0){
        return ""
      }
     
      let project = projects.find( p => p.projectId === id)
      if(project){
        dataForm.setFieldsValue({
          projectId: id,
          projectName: project.projectName,
          projectLocation: project.projectLocationName
        })
      }
  }

  const onDocumentYearChange = (value:any) => {
    let totalStorageYear = dataForm.getFieldValue('totalStorageYear')
    let destructionDueDate = dayjs(value).add(totalStorageYear,'year')
    dataForm.setFieldsValue({
      destructionDueDate: destructionDueDate,
      destructionDueDateName: destructionDueDate.format("MMM YYYY")
    })
    
  }

  const onTotalStorageYearChange = (value:number) => {
    let documentYear = dataForm.getFieldValue('documentYear')
    if(documentYear){
      let destructionDueDate = dayjs(documentYear).add(value,'year')
      dataForm.setFieldsValue({
        destructionDueDate: destructionDueDate,
        destructionDueDateName: destructionDueDate.format("MMM YYYY")
      })
    }
  }

    const getData = async (id: number) => {
        let token = await getToken()
    let result = await new StorageCartonService(token).GetDetail(id)
      console.log(result)
      if(result && result.success)
      {
        let item:RequestDetail = result.data
        let storageCartonData = Object.assign({},storageCarton)  
          storageCartonData.recordNo = item.recordNo
          storageCartonData.quantity = item.quantity
          storageCartonData.projectId = item.projectId
          storageCartonData.projectName = item.projectName
          storageCartonData.projectLocation = item.projectLocation
          storageCartonData.floor = item.floor
          storageCartonData.location = item.location
          storageCartonData.place = item.place
          storageCartonData.documentYear = dayjs(item.documentYear)
          storageCartonData.totalStorageYear =  item.totalStorageYear
          storageCartonData.destructionDueDate= dayjs(item.destructionDueDate)
          storageCartonData.destructionDueDateName = dayjs(item.destructionDueDate).format("MMM YYYY")
          storageCartonData.pickupDate = dayjs(item.pickupDate)
          storageCartonData.confidentialityLevel = item.confidentialityLevel
          storageCartonData.statusId = item.statusId
          storageCartonData.remark = item.remark
          storageCartonData.approver = item.approver
          storageCartonData.approverName = item.approverName

        dataForm.setFieldsValue(storageCartonData);
        setIsLoading(false)

        let owners:Item[] = [{
          key:item.owner,
          name:item.ownerName,
          location: item.location,
          floor: item.floor,
          orgUnit: item.orgUnit
        }]
        setOwners(owners)

        let contacter:Item[] = [{
          key:item.contactPerson,
          name:item.contactPersonName,
          location: "",
          floor: "",
          orgUnit: ""
        }]
        setContactPerson(contacter)

        dataForm.setFieldsValue({
          owner: item.owner,
          orgUnit: item.orgUnit,
          location: item.location,
          contactPerson: item.contactPerson,
          contactPersonName: item.contactPersonName
        });
        setValveCheck(item.valveRoom ? true : false)
      }
  }

    const getProjects = async () => {
        let token = await getToken()
    let result = await new PropertyService(token).GetProjects()
    if(result && result.success){
      let options:Option[] = result.data.map( item => { return { label:item.projectName , value: item.projectId }})
        setProjects(result.data)
        setProjectOptions(options)
    }
  }

  const getSystemConfig = async () =>{
    let token = await getToken()
     const result = await new SystemConfigService(token).getSystemConfig()
     if(result && result.data){
       
        setStorageYearOptions(result.data.storeYearValue)

     }
      
 }

  const onCheckAll = (checked:boolean) => {
    let items = onCacheStorage()
    let checkItems:number[] = []
    items.forEach((item,index) => {
      if(item.cartonNo === cartonNo){
        if(checked){
          items[index].checked = true
          checkItems.push(item.itemNo)
        }
        else{
          items[index].checked = false
        }
        
      }
    })

    setCartonItems(items)
    setSelectItems(checkItems)

    let element = document.getElementById('check-item-all') as HTMLInputElement
    element.checked = checked

   
  };

  const onCheckChange = (itemNo:number,checked:boolean) => {
    let items:Carton[] = Object.assign([],cartonItems)
    let checkItems:number[] = []
    let countAll = 0;
    let countChecked = 0;
    items.forEach((item,index) => {
      if(item.cartonNo === cartonNo){
        countAll++;
          if (item.itemNo === itemNo) {

              doIfElse(checked ,
                  function () {
                      countChecked++;
                      items[index].checked = true
                      checkItems.push(item.itemNo)
                  }
                  ,
                  function () {
                      items[index].checked = false
                  }
              );
        }
        else {

            doIfElse(item.checked,
                function () {
                    countChecked++;
                    checkItems.push(item.itemNo)
                }
                ,
                null
            );
        }
      }
    })


    let element = document.getElementById('check-item-all') as HTMLInputElement
    if(countAll === countChecked){
      element.checked =true
      element.indeterminate = false
    }
    else{
      if(countChecked === 0){
        element.checked = false
        element.indeterminate = false
      }
      else{
        element.checked = false
        element.indeterminate = true
      }
    }
 
    setCartonItems(items)
    setSelectItems(checkItems)
  }



    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

  
  const onCacheStorage = () => {
    let items:Carton[] = Object.assign([],cartonItems)
    items.forEach((item,i) => {
      if(item.cartonNo === cartonNo)
      {
        let referInput = document.getElementById('ref-' + item.cartonNo + '-' + item.itemNo) as HTMLInputElement
        let descriptionInput = document.getElementById('des-' + item.cartonNo + '-' + item.itemNo) as HTMLInputElement
        let remarkInput = document.getElementById('remark-' + item.cartonNo + '-' + item.itemNo) as HTMLInputElement
        let dateStart = document.getElementById('date-start-' + item.cartonNo + '-' + item.itemNo) as HTMLInputElement
        let dateEnd = document.getElementById('date-end-' + item.cartonNo + '-' + item.itemNo) as HTMLInputElement
        let docCheckInput = document.getElementById('doc-check-' + item.cartonNo + '-' + item.itemNo) as HTMLInputElement
        let partnerInput = document.getElementById('partner-' + item.cartonNo + '-' + item.itemNo) as HTMLInputElement
        let categoryInput = document.getElementById('category-' + item.cartonNo + '-' + item.itemNo) as HTMLInputElement
        let languageInput = document.getElementById('language-' + item.cartonNo + '-' + item.itemNo) as HTMLInputElement
        let numberOfInput = document.getElementById('number-of-' + item.cartonNo + '-' + item.itemNo) as HTMLInputElement
        let referDokInput = document.getElementById('refer-dok-' + item.cartonNo + '-' + item.itemNo) as HTMLInputElement
        let originatorInput = document.getElementById('originator-' + item.cartonNo + '-' + item.itemNo) as HTMLInputElement
        let businessInput = document.getElementById('business-' + item.cartonNo + '-' + item.itemNo) as HTMLInputElement
      
        let carton:Carton = {
          recordNo: 0,
          referenceRecNo: 0,
          requestNo:"",
          cartonNo: item.cartonNo,
          itemNo: item.itemNo,
          cartonName: "",
            userReferenceNo: returnIfElse(referInput.value, referInput.value ,"")  ,
            description: returnIfElse(descriptionInput.value, descriptionInput.value , "") ,
            remark: returnIfElse(remarkInput.value, remarkInput.value , "")  ,
            documentStartYear: returnIfElse(dateStart.value, parseInt(dayjs(dateStart.value).format('YYYY')), null) ,
            documentEndYear: returnIfElse(dateEnd.value, parseInt(dayjs(dateEnd.value).format('YYYY')), null)  ,
            documentCheckingDate: returnIfElse(docCheckInput.value, dayjs(docCheckInput.value) , "") ,
            partnerOfContract: returnIfElse(partnerInput.value , partnerInput.value , "") ,
          files:item.files,
            category: returnIfElse(categoryInput.value , categoryInput.value , "")  ,
          locationNo: "",
          sealNo: "",
          warehouseBarcode:"",
          cost: 0,
            language: returnIfElse(languageInput.value, languageInput.value , "")  ,
            numberOfOriginal: returnIfElse(numberOfInput.value , numberOfInput.value , "")  ,
            referenceDDOKNo: returnIfElse(referDokInput.value , referDokInput.value , "")   ,
            originator: returnIfElse(originatorInput.value, originatorInput.value  , "")  ,
            businessUnit: returnIfElse(businessInput.value, businessInput.value , "")  ,
            valveRoom: returnIfElse(valveCheck , 1 , 0)  ,
            checked: item.checked,
            cartonStatusId: 0,
            expireDate:""
        }
        
        items[i] = carton
      }
      
    })
    
    return items
    
  }

  const onCartonNoChange = (_cartonNo:number) => {
      let items = onCacheStorage()
      setCartonItems(items)
      setCartonNo(_cartonNo)
  }

  const onValveChange = (checked:boolean) => {
        let _cartonItems = onCacheStorage()
        setCartonItems(_cartonItems)
        setValveCheck(checked)
        if(!checked){
            let items = _cartonItems.filter(x => x.cartonNo === cartonNo)
            items.forEach(item => {
                onValveMinusChange(item.itemNo)
            })
        }
}

  const onValvePlusChange = (itemNo: number) => {
    let plusElement = document.getElementById('valve-plus-' + cartonNo + '-' + itemNo) as HTMLElement
    let minusElement = document.getElementById('valve-minus-' + cartonNo + '-' + itemNo) as HTMLElement
    let element = document.getElementById('valve-' + cartonNo + '-' + itemNo) as HTMLElement
    plusElement.style.display = 'none'
    minusElement.style.display = 'inline'
    element.style.display = 'inline'
    
  }

  const onValveMinusChange = (itemNo: number) => {
    let plusElement = document.getElementById('valve-plus-' + cartonNo + '-' + itemNo) as HTMLElement
    let minusElement = document.getElementById('valve-minus-' + cartonNo + '-' + itemNo) as HTMLElement
    let element = document.getElementById('valve-' + cartonNo + '-' + itemNo) as HTMLElement
   
    plusElement.style.display = 'inline'
    minusElement.style.display = 'none'
    element.style.display = 'none'
   
  }

  const onConfidentialLevelChange = (value:number) => {
      setRemarkRequire( value === 2 ? true : false)
  }

const applyToAll = () => {
    let allCartonItems:Carton[] = []
    let _cartonItems = onCacheStorage()
    if(_cartonItems.length === 0){
      return
    }

    cartonOptions.forEach( carton => 
    {
        let tempCartonItems = onCacheStorage().filter(x => x.cartonNo === cartonNo)
        console.log(tempCartonItems)
        let items = Array.from(tempCartonItems).map( x => { x.cartonNo = carton.value ; return x})
        allCartonItems = allCartonItems.concat(items)

    })
    setCartonItems(allCartonItems)
}

  

  const handleDownload = async () => {
    try{
        console.log('download')
       
        let token = await getToken()
      let refId = id ? parseFloat(id) : 0
        let valveRoom = valveCheck ? 1 : 0
        let total = cartonOptions.length
        let result = await new FileManageService(token).DownloadTemplate(refId,valveRoom,total)
        console.log(result)
      
    }
    catch(err){
      console.log(err)
    }
   
  }

  const disabledDate = (value:any) => {
    let date = currentDate ? currentDate.toString() : new Date().toString()
    return Helper.disabledDate(value,date)
  }

  const onUploadRemove = (guid:string,_cartonNo: number,_itemNo: number) => {
    let _cartonItems:Carton[] = Object.assign([],cartonItems)
    let index = _cartonItems.findIndex( x => x.cartonNo === _cartonNo && x.itemNo === _itemNo)
    let fileIndex = _cartonItems[index].files.findIndex( x => x.guid === guid)
    _cartonItems[index].files.splice(fileIndex,1)
    setCartonItems(_cartonItems)
  }
  
  const validateFile = (file:UploadFile<any>,msg:boolean) => {
    let maxFileSzie = 15000000
    let fileExtensions = [ 'pdf','xls','xlsx','jpg','jpeg','png','doc','docx']

    let type = ""
    if(file.type){
      type = file.type.split('/').reverse()[0].toLowerCase()
    }
    let typename = file.name.split('.').reverse()[0].toLowerCase()
    if(fileExtensions.indexOf(type) === -1 && fileExtensions.indexOf(typename) === -1){
      if(msg){
        let element = document.getElementById('table-container') as HTMLDivElement
        messageApi.open({
            type: 'error',
            content: 'file type is not allowed.',
            duration: 1.5,
            style: { marginTop: element.getBoundingClientRect().top - 100 , marginLeft: element.getBoundingClientRect().left - 100  }
          });
      }
     
      return false 
          
    }

    if(file.size && file.size > maxFileSzie){
      if(msg){
        let element = document.getElementById('table-container') as HTMLDivElement
        messageApi.open({
            type: 'error',
            content: 'File has over size limit 10MB',
            duration: 1.5,
            style: { marginTop: element.getBoundingClientRect().top - 100 , marginLeft: element.getBoundingClientRect().left - 100  }
          });
      }
      
      return false
    }

    return true
  }
  const onUploadChange = async (info: UploadChangeParam<UploadFile>,_itemNo: number) => {
   
    if(!validateFile(info.file,false)){
      return false
    }
    
    let file = info.file
    let element = document.getElementById('uploading-' + cartonNo + '-' + _itemNo) as HTMLDivElement
    element.style.display = "inline"
    let base64string = await toBase64(file.originFileObj)
    element.style.display = "none"

    if(!base64string){
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: 'Error on upload file process, please try again.'
          });
          return
    }
    
    let fileItem:FileItem = {
        guid: file.uid,
        name: file.name,
        size: file.size,
        type: file.type,
        byteData: base64string
     }

      let _cartonItems:Carton[] = onCacheStorage()
      let index = cartonItems.findIndex( x => x.cartonNo === cartonNo && x.itemNo === _itemNo)
      let fileIndex = _cartonItems[index].files.findIndex( x => x.guid === file.uid)
      if(fileIndex === -1){
        _cartonItems[index].files.push(fileItem)
      }
     
      setCartonItems(_cartonItems)
  }

  const handleBeforUpload = (file:UploadFile<any>) => {
    validateFile(file,true)
  }

  const toBase64 = (file:any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => resolve(null);
  });

  
  const onCheckDate = (itemNo:number) => {
    let dateStart = document.getElementById('date-start-' + cartonNo + '-' + itemNo) as HTMLInputElement
    let dateEnd = document.getElementById('date-end-' + cartonNo + '-' + itemNo) as HTMLInputElement

    if(dateStart && dateStart.value && dateEnd && dateEnd.value){
      if(dateEnd.value < dateStart.value){
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: 'Found document start year greater than end year.'
          });
          
      }
    }
  }

  return (

    <div>
      {current == 0 && (
        <Card >
                  {returnIfElse(isLoading
                      ,
                      <Spin tip="Loading" size="large">
                          <div className="content" />
                      </Spin>
                      , 
                      <></>
                  )
          }
          <Form
            {...layout}
            form={dataForm}
            name="storage-form"
            validateMessages={validateMessages}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
              <Col md={22} >
                <Form.Item name="requesterName" label="Requester" labelCol={{ md: 3 }}   >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                <Form.Item name="owner" label="Owner" rules={[{ required: true }]}>
                  <EmployeeAutoComplete
                    key={Helper.Uniqid()}
                    placeholder='Search by Name'
                                      selectedItems={returnIfElse(owners, owners, [] ) }
                    onChange={item => onOwnerSelect(item)}
                    service={onSearchEmployee}
                    minimumTextLength={3}
                    borderColor={ownersBgColor}
                    pickOnlyOne
                  />
                </Form.Item>
                <Form.Item name="contactPerson" label="Contact Person" >
                  <EmployeeAutoComplete
                    key={Helper.Uniqid()}
                    placeholder='Search by Name'
                                      selectedItems={returnIfElse(contactPerson, contactPerson, []  ) }
                    onChange={item => oncontactPersonSelect(item)}
                    service={onSearchEmployee2}
                    minimumTextLength={3}
                    pickOnlyOne
                  />
                </Form.Item>

                <Form.Item name="projectLocation" label="Project Location">
                   <Input disabled/>
                </Form.Item>
                <Form.Item name="location" label="Location">
                <Input  disabled/>
                </Form.Item>

                <Form.Item name="floor" label="Floor">
                <Input  placeholder={MessageCommon.TitleInput} />
                </Form.Item>
                
                <Form.Item name="place" label="Place" rules={[{ required: true }]} validateStatus={returnIfElse(error.place, "error", undefined ) }>
                  <Input.TextArea placeholder={MessageCommon.TitleInput} />
                 
                </Form.Item>
                              <Form.Item name="approverName" label="Approver" rules={[{ required: true }]} validateStatus={returnIfElse(error.approver, "error", undefined) }>
                  { isApproverLoading ? <Label>Loading...</Label> : <Input  readOnly style={{backgroundColor: 'rgba(0, 0, 0, 0.04)'}}/> }
                </Form.Item>
              </Col>
              <Col md={2} ></Col>
              <Col md={11} >
               
                <Form.Item name="quantity" label="Total Carton Quantity" rules={[{ required: true, type: 'number', min: 1, max: user.systemConfig.maxStorageQty }]} validateStatus={error.quantity ? "error" : undefined}>
                  <InputNumber placeholder={MessageCommon.TitleInput} style={{width:'100%'}}/>
                </Form.Item>
                
                              <Form.Item name="projectId" label="Project Name" rules={[{ required: true }]} validateStatus={returnIfElse(error.projectId, "error", undefined ) }>
                     <Select 
                        showSearch
                        placeholder={MessageCommon.TitleSelect} 
                        options={projectOptions} 
                        onChange={onProjectChange}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </Form.Item>
               

                              <Form.Item name="documentYear" label="Document Year" rules={[{ required: true }]} validateStatus={returnIfElse(error.documentYear, "error", undefined  )}>
                    <DatePicker 
                      placeholder={MessageCommon.TitleDate} 
                      picker='month' 
                      style={{width: '100%'}} 
                      format='MMM YYYY'
                      onChange={onDocumentYearChange}
                                      disabledDate={d => !d || d.isBefore(new Date().setFullYear((new Date()).getFullYear() - 10))} 
                    />
                </Form.Item>
                              <Form.Item name="totalStorageYear" label="Total Storage Year" rules={[{ required: true }]} validateStatus={returnIfElse(error.totalStorageYear, "error", undefined  ) }>
                  <Select placeholder={MessageCommon.TitleSelect} style={{ width: '100%' }} 
                    options={ storageYearOptions.split(",").map(item => { return { value: item, label: item.toString()}}) }
                    onChange={onTotalStorageYearChange}
                  />
                </Form.Item>
                <Form.Item name="destructionDueDateName" label="Destruction Due Date" rules={[{ required: true }]} >
                  <Input disabled />
                </Form.Item>
                              <Form.Item name="pickupDate" label="Expected Pick up Date" rules={[{ required: true }]} validateStatus={returnIfElse(error.pickupDate , "error" ,undefined  ) }>
                  <DatePicker placeholder={MessageCommon.TitleDate} format="DD MMM YYYY" style={{width: '100%'}} disabledDate={disabledDate}/>
                </Form.Item>
                
                <Form.Item name="confidentialityLevel" label="Confidentiality Level" rules={[{ required: true }]} validateStatus={returnIfElse( error.confidentialityLevel, "error" ,undefined  ) }>
                  <Select 
                    placeholder={MessageCommon.TitleSelect} 
                    options={ConfidentialOption} 
                    onChange={onConfidentialLevelChange}
                />
                </Form.Item>

                      <Form.Item name="remark" label="Remark" rules={[{ required: remarkRequire }]} validateStatus={returnIfElse( error.remark, "error", undefined  )}>
                  <Input.TextArea placeholder={MessageCommon.TitleInput} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div style={{ textAlign: 'center' }}>
            <Button id="btn-next" type="primary" style={{ marginRight: 10 }} onClick={nextStep} >
              Next <AiOutlineArrowRight className='icn-btn-right' />
            </Button>
          </div>



        </Card>)}

      {current == 1 && (
        <Card id="storage-carton-form">
          <Row>
            <Col md={6} >
              <Form.Item name="carton" label="Carton"  >
                <Select defaultValue={1}  options={cartonOptions} onChange={onCartonNoChange}/>
              </Form.Item>
            </Col>
            <Col md={2} >
            </Col>
            <Col md={11} >
             
            </Col>
          </Row>
         
          <input style={{ display: 'none' }} ref={inputRef} type="file"/>
          
          <div id="action-panel" style={{ marginRight: 10, marginTop: 25, marginBottom: 5 }}>
            <Space direction="vertical" size="middle">
              <Space.Compact style={{ width: '100%' }}>
                <Input id="row-amount" defaultValue="1" style={{ width: 100, textAlign: 'center' }}  />
                <Button type="primary" className='btn-green mr-btn' onClick={addCartonItem}  >
                  <AiOutlinePlus className='icn-btn' /> Row
                </Button>
              </Space.Compact>

            </Space>
            
        { selectItems.length > 0 ?
          <Popconfirm
            title="Delete the task"
            description="Confirm to delete?"
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
             onConfirm = { () => removeCartonItem(selectItems) }
          >
            <Button type="primary" className='btn-red mr-btn'>
              <AiFillDelete className='icn-btn' /> Delete
            </Button>
          </Popconfirm>
        :
          <Button type="primary" className='btn-red mr-btn' disabled>
            <AiFillDelete className='icn-btn' /> Delete
          </Button>
        }

            <Tooltip title="Upload template excel file after inputting or editing data">
              <Button type="primary" className='mr-btn' onClick={handleUploadTemplateClick}   >
                <AiFillFileExcel className='icn-btn' /> Upload
                <input key={Helper.Uniqid()} type="file" id="upload-template" onChange={handleUploadTemplate} style={{display: 'none'}}/>
              </Button>
            </Tooltip>
            <Tooltip title="Download empty template or download existing data to edit and upload again for replacement">
              <Button type="primary" className='mr-btn'  onClick={handleDownload}  >
                <AiOutlineDownload className='icn-btn' /> Download
              </Button>
            </Tooltip>
            <Tooltip title="Attention! : If you apply this button, all descriptions of every cartons in this request will be the same detail.">
              <Button danger className='mr-btn' style={{ color: 'red' }} onClick={confirmApplyToAll} >
                <AiOutlineExclamation className='icn-btn' style={{ color: 'red' }} /> Apply to all cartons
              </Button>
            </Tooltip>
            { user.roleIds.findIndex( x => x.roleId === UserRole.UserValveRoom) > -1 ?
                <Checkbox defaultChecked={valveCheck} onChange={(e) => onValveChange(e.target.checked)}>Valve Room</Checkbox>
                :<></>
            }
          </div>
          <br></br>
          <label style={{ color: '#70a801' }}>Detail of Carton {cartonNo}/{cartonOptions.length}</label>
          <label className='f-right' style={{ color: '#00338D' }}>File size Max: 10MB  Accepted: *.docx,*.doc,*.xls,*xlsx,*pdf,*.jpg,*.png</label>
          <br></br>
           { isLoading ?
            <Spin tip="Loading" size="large">
              <div className="content" />
            </Spin>
            :
            <>
            <div id="table-container">
              <table className='idoc-table'>
                <thead id="head-table-fixed">
                  <tr>
                    <th></th>
                    <th style={{ textAlign: 'left',width: '50px'}}>
                      <div className="form-check">
                          <input  type="checkbox" id='check-item-all' className="form-check-input check-item"  onChange={(e) => onCheckAll(e.target.checked)}/>
                          <label className="form-check-label" htmlFor={'check-item-all'}/>
                        </div>
                    </th>
                    <th style={{ textAlign: 'left'}}>Item No.</th>
                    <th >User Reference No.</th>
                    <th >
                        Description<span style={{marginLeft:'3px',color:'red'}}>*</span>
                    </th>
                    <th >Document Start Year</th>
                    <th >Document End Year</th>
                    <th >File</th>
                    <th >Remark</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody >
                  { cartonItems.filter( x => x.cartonNo === cartonNo).map((item:Carton,index) => (
                    <>
                    <tr key={Helper.Uniqid()}>
                      <td>
                      { valveCheck ?
                        <>
                           <CiSquarePlus id={'valve-plus-' + cartonNo + '-' + item.itemNo} size={22} color='#009FDA' style={{ verticalAlign: 'top',cursor: 'pointer'}} onClick={() => onValvePlusChange(item.itemNo)}/>
                           <CiSquareMinus id={'valve-minus-' + cartonNo + '-' + item.itemNo} size={22} color='#009FDA' style={{ verticalAlign: 'top',display: 'none',cursor: 'pointer'}} onClick={() => onValveMinusChange(item.itemNo)}/>
                        </>:<></>
                      }   
                      </td>
                      <td style={{ textAlign: 'center',width: '50px'}}>
                        <div className="form-check">
                          <input type="checkbox" id={'check-item-' + item.cartonNo + '-' + item.itemNo} value={item.itemNo} checked={item.checked} className="form-check-input check-item" onChange={(e) => onCheckChange(item.itemNo,e.target.checked)}/>
                          <label className="form-check-label" htmlFor={'check-item-' + item.cartonNo + '-' + item.itemNo}/>
                        </div>
                      </td>
                      <td style={{textAlign: 'center'}}>
                        {index + 1}
                      </td>
                      <td style={{textAlign: 'center'}}>
                        <Input key={Helper.Uniqid()} id={'ref-' + item.cartonNo + '-' + item.itemNo} defaultValue={item.userReferenceNo} placeholder={MessageCommon.TitleInput} />
                      </td>
                      <td style={{textAlign: 'center'}}>
                        <Input key={Helper.Uniqid()} id={'des-' + item.cartonNo + '-' + item.itemNo} defaultValue={item.description}  placeholder={MessageCommon.TitleInput} />
                      </td>
                      <td >
                        <DatePicker 
                          key={Helper.Uniqid()} 
                          id={'date-start-' + item.cartonNo + '-' + item.itemNo}  
                          picker="year" 
                          placeholder={MessageCommon.TitleDate}  
                          onChange={() => onCheckDate(item.itemNo)}
                          defaultValue={item.documentStartYear ? dayjs(new Date(item.documentStartYear,1,1)) : undefined}
                        />
                      </td>
                      <td style={{textAlign: 'center'}}>
                       <DatePicker 
                        key={Helper.Uniqid()}  
                        id={'date-end-' + item.cartonNo + '-' + item.itemNo}  
                        picker="year" 
                        placeholder={MessageCommon.TitleDate} 
                        onChange={() => onCheckDate(item.itemNo)}
                        defaultValue={ item.documentEndYear ? dayjs(new Date(item.documentEndYear,1,1)) : undefined}
                      />
                      </td>
                    
                      <td style={{textAlign: 'center'}}>
                          <Upload 
                            id={'upload-' + item.cartonNo + '-' + item.itemNo}
                            beforeUpload={handleBeforUpload}
                            onChange={(info) => onUploadChange(info,item.itemNo)}
                            showUploadList={false}
                            multiple
                          >
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                          </Upload>
                         
                            {item.files.length > 0 && item.files.map( file => (
                            <div style={{ display: 'flex', justifyContent:'space-between'}}>
                              <div style={{textAlign: 'left'}}>{file.name}</div>
                              <div>
                                
                                <Popconfirm
                                  title="Delete file"
                                  description="Confirm to delete?"
                                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                  onConfirm = { () => onUploadRemove(file.guid,item.cartonNo,item.itemNo) }
                                  >
                                    <AiOutlineDelete size={15} style={{cursor: 'pointer'}} />
                                </Popconfirm>
                              </div>
                            </div>
                            ))}
                            <div id={'uploading-' + item.cartonNo + '-' + item.itemNo} style={{display:'none'}}>
                                Uploading...
                            </div>
                      </td>
                      <td>
                        <Input key={Helper.Uniqid()} id={'remark-' + item.cartonNo + '-' + item.itemNo} defaultValue={item.remark}  placeholder={MessageCommon.TitleInput}/>
                      </td>
                      <td>
                        <Popconfirm
                          title="Delete the task"
                          description="Confirm to delete?"
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          onConfirm = { () => removeCartonItem([item.itemNo]) }
      
                        >
                          <AiFillDelete size={20} className='icon-table icon-red'/>
                        </Popconfirm>
                        
                      </td>
                    </tr>
                    <tr key={Helper.Uniqid()}>
                      <td colSpan={10} style={{padding : 0,paddingBottom: valveCheck ? '5px' : 0 }}>
                      <table className='idoc-table' id={'valve-' + item.cartonNo + '-' + item.itemNo} style={{ display: 'none'}} >
                        <thead id="head-table-fixed">
                         <tr>
                            <th >Document Checking Date</th>
                            <th >Partner Of Contract</th>
                            <th >Category</th>
                            <th >Language</th>
                            <th >Number Of Original</th>
                            <th >Reference DDOK No.</th>
                            <th >Originator</th>
                            <th>Business Unit</th>
                          </tr>
                          <tr>
                            
                            <td style={{textAlign: 'center'}}>
                              <DatePicker key={Helper.Uniqid()} id={'doc-check-' + item.cartonNo + '-' + item.itemNo}  placeholder={MessageCommon.TitleDate} format="DD MMM YYYY"  defaultValue={item.documentCheckingDate ? dayjs(item.documentCheckingDate) : undefined}/>
                            </td>
                            <td style={{textAlign: 'center'}}>
                              <Input key={Helper.Uniqid()} id={'partner-' + item.cartonNo + '-' + item.itemNo} defaultValue={item.partnerOfContract} placeholder={MessageCommon.TitleInput} />
                            </td>
                            <td style={{textAlign: 'center'}}>
                              <Input key={Helper.Uniqid()} id={'category-' + item.cartonNo + '-' + item.itemNo} defaultValue={item.category} placeholder={MessageCommon.TitleInput} />
                            </td>
                            <td style={{textAlign: 'center'}}>
                              <Input key={Helper.Uniqid()} id={'language-' + item.cartonNo + '-' + item.itemNo} defaultValue={item.language} placeholder={MessageCommon.TitleInput} />
                            </td>
                            <td style={{textAlign: 'center'}}>
                              <Input key={Helper.Uniqid()} id={'number-of-' + item.cartonNo + '-' + item.itemNo} defaultValue={item.numberOfOriginal} placeholder={MessageCommon.TitleInput} />
                            </td>
                            <td style={{textAlign: 'center'}}>
                              <Input key={Helper.Uniqid()} id={'refer-dok-' + item.cartonNo + '-' + item.itemNo} defaultValue={item.referenceDDOKNo} placeholder={MessageCommon.TitleInput} />
                            </td>
                            <td style={{textAlign: 'center'}} >
                             <Input key={Helper.Uniqid()} id={'originator-' + item.cartonNo + '-' + item.itemNo} defaultValue={item.originator} placeholder={MessageCommon.TitleInput} />
                            </td>
                            <td style={{textAlign: 'center'}}>
                            <Input key={Helper.Uniqid()} id={'business-' + item.cartonNo + '-' + item.itemNo} defaultValue={item.businessUnit} placeholder={MessageCommon.TitleInput} />
                            </td>
                          </tr>
                        </thead>
                      </table>
                      </td>
                    </tr>
                    </>
                    ))
                  }
                </tbody>
              </table>
            </div>
           
          </>
        }
          <div style={{ textAlign: 'center' }}>
            <Button type="default" htmlType="button" onClick={prevStep} className='mr-btn'>
              <AiOutlineArrowLeft className='icn-btn' />Previous
            </Button>
            <Button type="primary" className='mr-btn'  onClick={() => confirmSave(false)}>
              <AiOutlineSave className='icn-btn' />Save Draft
            </Button>
            <Button type="primary"  onClick={() => confirmSave(true)}>
              <AiFillSave className='icn-btn' />Submit
            </Button>
          </div>
        </Card>)}
      {contextHolderMessage}
      {contextHolder}
    </div>
  );

}

export default StorageCartonRequest
