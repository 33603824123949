import { useEffect, useState } from 'react';
import { Button, Card, Checkbox, Col, Collapse, DatePicker,message, Form, Input, InputNumber,Spin,Typography, Modal, Row, Select,Popconfirm, Pagination } from 'antd';
import { AiFillEdit, AiFillEye, AiOutlinePlusSquare,AiOutlineCloseSquare, AiOutlinePrinter, AiOutlineSave, AiOutlineSearch, AiOutlineSync } from 'react-icons/ai';
import { CloseCircleOutlined } from '@ant-design/icons';
import MessageCommon from '../../enum/Message';
import {  Warehouse, Option ,WarehouseParameter} from '../../models'
import { Helper } from '../../helpers';
import { FileManageService, WarehouseService } from '../../services';
import dayjs from 'dayjs'
import { Color, ExportType } from '../../enum';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import WarehouseHistory from './WarehouseHistory';
import Loading from '../../components/Loading';
import { SelectPagination } from '../../components/SelectPagination';
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { User} from '../../models';
import { useNavigate } from 'react-router-dom';

interface AppState {
    currentPage:number
    perpage:number
    total: number
    items:Warehouse[]
    itemNoList:string[]
    isLoading:boolean
}

interface Error
{
    warehouseName:number
    contractNo:number
    contactPeriod:number
}

const WarehouseRequest = () => {
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }

    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const [dataForm] = Form.useForm();
    const [searchForm] = Form.useForm();
    const [messageApi, contextHolderMessage] = message.useMessage();
    const { Panel } = Collapse;
    const [isModalHistoryOpen, setIsModalHistoryOpen] = useState(false);
    const [warehouseId,setWarehouseId] = useState(0)
    const [openWarehouseId,setOpenWarehouseId] = useState(0)
    const [dateStart,setDateStart] = useState<any>(undefined)
    const [dateEnd,setDateEnd] = useState<any>(undefined)
    const [defaultLocation,setDefaultLocation] = useState(0)
    const [active,setActive] = useState(1)
    const [roomTypeId,setRoomTypeId] = useState(1)
    const [isAdd,setIsAdd] = useState(false)
    const [warehouseOptions, setWarehouseOptions] = useState<Option[]>([])
    const [cartonLocation, setCartonLocation] = useState("")
    const [parameter,setParameter] = useState<WarehouseParameter>();
    const [statusId, setStatusId] = useState(0)
    const initialParameter:WarehouseParameter = {
        page: 1,
        limit: 25,
        cartonLocation : [],
        contractNo: "",
        statusIds: [],
        active: -1
    }
    const [saveParameter,setSaveParameter] = useState<WarehouseParameter>(initialParameter)
    const [isPrint,setIsprint] = useState(true)
    const [appState,setAppState] = useState<AppState>({
        currentPage : 1,
        total : 0,
        perpage: 100,
        items: [],
        itemNoList:[],
        isLoading : true
    })
    const [error,setError] = useState<Error>({
        warehouseName:0,
        contractNo:0,
        contactPeriod:0
    })
    const [isLoadingPrint,setIsLoadingPrint] = useState(false)

    useEffect(() => {
        if(user.permissions.warehouse === 0){
            navigate('/permissiondenided')
        }
        initial()

    }, [])
    
    const initial = async () => {
        let warehouses = await getWarehouse()
        let warehouseNames = warehouses?.filter(x => x.value != "").map(x => x.value.toString())
        let parameter = initialParameter
        parameter.cartonLocation  = warehouseNames ? warehouseNames : []

        if(warehouses){
            setWarehouseOptions(warehouses)
          }
        getData(1, appState.perpage,parameter)
    }

    const getWarehouse = async () => {
        let token = await getToken()
        let result = await new WarehouseService(token).GetAllWarehouses()
        if(result && result.success){
            let options:Option[] = [{ label:'All' , value: "" }]
            let Locationoptions:Option[] = result.data.map((item) => { return { value: item.warehouseName, label: item.warehouseName  }})
            return options.concat(Locationoptions)
        }
    }

    const onFinish = (values: any) => {
        console.log('Success Message:', values);
    };

    const layout = {
        labelCol: { lg: 6 },
        wrapperCol: { lg: 18 }
    };

    const handleAdd = () => {
        setDateStart(undefined)
        setDateEnd(undefined)
        setDefaultLocation(0)
        setWarehouseId(0)
        setActive(1)
        setRoomTypeId(1)
        setIsAdd(true)
    }

    const handleEdit = (_warehouseId:number) => {
        let _warehouse = appState.items.find(x => x.warehouseId === _warehouseId)
        if(_warehouse){
            setDateStart(_warehouse.dateStart ? dayjs(_warehouse.dateStart) : undefined)
            setDateEnd(_warehouse.dateEnd ? dayjs(_warehouse.dateEnd) : undefined)
            setDefaultLocation(_warehouse.defaultLocation)
            setWarehouseId(_warehouse.warehouseId)
            setActive(_warehouse.active)
            setRoomTypeId(_warehouse.roomTypeId)
        }
       
    }

    const searchData = async () => {
       let parameter:WarehouseParameter = Object.assign({},initialParameter)
       let warehouseNames:string[] = warehouseOptions.filter(x => x.value != "").map(x =>x.value.toString())
        parameter.cartonLocation = warehouseNames ? warehouseNames : []
       
        let field = searchForm.getFieldsValue()
       
        if(cartonLocation){
            parameter.cartonLocation = [cartonLocation]
        }
           
       
       if(field.contractNo)
       {
        parameter.contractNo = field.contractNo
       }
       
       
       parameter.statusIds = []
       if(field.status)
       {
           parameter.statusIds = [statusId]
       }
       parameter.active = field.status

        getData(1, appState.perpage,parameter)
    
    }

    const searchReset = () => {
        searchForm.resetFields()
        let parameter:WarehouseParameter = Object.assign({},initialParameter)
        let warehouseNames:string[] = warehouseOptions.filter(x => x.value != "").map(x =>x.value.toString())
        parameter.statusIds =[]
        parameter.cartonLocation = warehouseNames ? warehouseNames : []
        parameter.contractNo = ""
        setStatusId(0)
        getData(1, appState.perpage,parameter)
    }

    const reset = () => {
        setDateStart(undefined)
        setDateEnd(undefined)
        setDefaultLocation(0)
        setWarehouseId(0)
        dataForm.resetFields()
    }

    const getData = async (currentPage: number,perpage: number , _parameter:WarehouseParameter | null = null) => {
        let _state:AppState = Object.assign({},appState)
            _state.isLoading = true
            setAppState(_state)

        let parameter:WarehouseParameter = Object.assign({},saveParameter)
        if(_parameter !== null){
            parameter = _parameter;
        }

        parameter.page = currentPage
        parameter.limit = perpage
   
        console.log(parameter)

        let token = await getToken()
        setParameter(parameter)
        let result = await new WarehouseService(token).Search(parameter)
      
        console.log(result)
        if(result && result.success)
        {
            let state = Object.assign({},appState)
                state.total =  result.data.total
                state.currentPage = currentPage
                state.perpage = perpage
                state.items =  result.data.items
            state.isLoading = false
            if(result.data.total > 0){
                setIsprint(false)
            }else{
                setIsprint(true)
            }
            setTimeout(() => {
                setSaveParameter(parameter)
                setAppState(state)
            }, 500);
        }
        else{
            let state = Object.assign({},appState)
            state.isLoading = false
            setAppState(state)
        }
    }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const validate = () => {

        let errorData:Error = {
            warehouseName:0,
            contractNo:0,
            contactPeriod: 0
        }

        let errorCount = 0

        if(!dataForm.getFieldValue('warehouseName')){
            errorCount++
            errorData.warehouseName = 1
        }
        if(!dataForm.getFieldValue('contractNo')){
            errorCount++
            errorData.contractNo = 1
        }
        if(!dateStart || !dateEnd){
            errorCount++
            errorData.contactPeriod = 1
        }
       
        if(errorCount > 0){
            let element = document.getElementById('add-form') as HTMLDivElement
            messageApi.open({
                type: 'error',
                content: 'Please fill required fields',
                duration: 1.5,
                style: { marginTop: element.getBoundingClientRect().top - 100 , marginLeft: element.getBoundingClientRect().left - 100  }
            });
            setError(errorData)
            return false
        }
        else{
            setError(errorData)
            return true;
        }
    }

    const validateUpdate = () => {
        let content = ""
       
        if(!dateStart || !dateEnd){
            content = "Contract Period is required fields."
        }
       
        if(content){
            let element = document.getElementById('table-container') as HTMLDivElement
            messageApi.open({
                type: 'error',
                content:  content,
                duration: 1.5,
                style: { marginTop: element.getBoundingClientRect().top - 300 , marginLeft: element.getBoundingClientRect().left - 100  }
            });
        
            return false
        }
        else{
            return true;
        }
    }

    const validateRoom = () => {
        let content = ""
        let previousContractNo = dataForm.getFieldValue('previousContractNo') ? dataForm.getFieldValue('previousContractNo') : ""
        if(previousContractNo)
        {
            let previousWarehouse = appState.items.find(x => x.contractNo == previousContractNo)
            if(previousWarehouse && previousWarehouse.roomTypeId != roomTypeId)
            {
                let roomType = roomTypeId == 1 ? 'general room'  : 'valve room'
                content = previousContractNo + " is not " +  roomType
            }
           
            if(content){
                let element = document.getElementById('table-container') as HTMLDivElement
                messageApi.open({
                    type: 'error',
                    content:  content,
                    duration: 1.5,
                    style: { marginTop: element.getBoundingClientRect().top - 300 , marginLeft: element.getBoundingClientRect().left - 100  }
                });
        
                return false
            }
        }
        
         return true;
        
    }
    
    const handleSave = () => {
        
        if(warehouseId == 0){
            handleCreate()
        }
        else{
            handleUpdate()
        }
    };

    const handleCreate = async () => {
        if(validate() && validateRoom()){

            let requestWarehouse:Warehouse = {
                warehouseId: warehouseId,
                warehouseName: dataForm.getFieldValue('warehouseName'),
                previousContractNo: dataForm.getFieldValue('previousContractNo') ? dataForm.getFieldValue('previousContractNo') : "",
                contractNo: dataForm.getFieldValue('contractNo'),
                dateStart: dateStart ? dateStart.format("YYYY-MM-DD") : "",
                dateEnd:  dateEnd ? dateEnd.format('YYYY-MM-DD') : "",
                unitCost: dataForm.getFieldValue('unitCost'),
                unitCost2: dataForm.getFieldValue('unitCost'),
                defaultLocation: defaultLocation,
                active: active,
                roomTypeId: roomTypeId
            }
            console.log(requestWarehouse)
            let token = await getToken()
            let result = await new WarehouseService(token).Create(requestWarehouse);
            console.log(result)
      
            if(result && result.success){
                let element = document.getElementById('add-form') as HTMLElement
                messageApi.open({
                    type: 'success',
                    content: 'Save success',
                    duration: 1,
                    style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
                });
                setTimeout(() => {
                    reset()
                    setIsAdd(false)
                    initial()
                }, 1000);
       
            }
            else{
                Modal.error({
                    icon: <CloseCircleOutlined />,
                    title: 'This is an error message',
                    content: result?.message,
                });
            }
        }
   
    };

    const handleUpdate = async () => {
        if(validateUpdate()){
            let item = appState.items.find(x => x.warehouseId == warehouseId)
            let unitCostElement = document.getElementById('unit-cost-' + warehouseId) as HTMLInputElement
            let unitCost = unitCostElement && unitCostElement.value ? unitCostElement.value : ""

            let requestWarehouse:Warehouse = {
                warehouseId: warehouseId,
                warehouseName: item && item.warehouseName ? item.warehouseName : "",
                previousContractNo: "",
                contractNo: item && item.contractNo ? item.contractNo : "",
                dateStart: dateStart ? dateStart.format("YYYY-MM-DD") : "",
                dateEnd:  dateEnd ? dateEnd.format('YYYY-MM-DD') : "",
                unitCost: 0,
                unitCost2: unitCost,
                defaultLocation: defaultLocation,
                active: active,
                roomTypeId: roomTypeId
            }
            console.log(requestWarehouse)
            let token = await getToken()
            let result = await new WarehouseService(token).Update(requestWarehouse);
            
            console.log(result)
      
            if(result && result.success){
                let element = document.getElementById('table-container') as HTMLElement
                messageApi.open({
                    type: 'success',
                    content: 'Save success',
                    duration: 1,
                    style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
                });
                setTimeout(() => {
                    reset()
                    setIsAdd(false)
                    getData(1,appState.perpage)
                }, 1000);
       
            }
            else{
                Modal.error({
                    icon: <CloseCircleOutlined />,
                    title: 'This is an error message',
                    content: result?.message,
                });
            }
        }
   
    };

    const cancelSave = () => {
        setWarehouseId(0)
    }

    const onPageChange = (page:number,perpage:number) => {
        if(page !== appState.currentPage && perpage === appState.perpage){
            getData(page,appState.perpage)
        }
    
        if(page === appState.currentPage && perpage !== appState.perpage){
            getData(1,perpage)
        }
    }

    const onDateStartChange = (value:any) => {
        setDateStart(value)
    }

    const onDateEndChange = (value:any) => {
        setDateEnd(value)
    }

    const onDefaultLocationChange = (e: CheckboxChangeEvent) => {
        let value = e.target.checked ? 1 : 0
        console.log("🚀 ~ file: WarehouseRequest.tsx:419 ~ onDefaultLocationChange ~ value:", value)
        console.log("🚀 ~ file: WarehouseRequest.tsx:419 ~ onDefaultLocationChange ~ e:", e)
        setDefaultLocation(value)
    };

    const onActiveChange = (value:number) => {
        setActive(value)
    };

    const onRoomTypeChange = (value:number) => {
        setRoomTypeId(value)
    };

    const onCartonLocationChange = (value:string) => {
        setCartonLocation(value)
    }

    const onModalHistoryOpen = (_warehouseId:number) => {
        setOpenWarehouseId(_warehouseId)
        setIsModalHistoryOpen(true)
    }
    const onFinishPrint = async () => {
        setIsLoadingPrint(true)

        let token = await getToken()
        if(!parameter)return
        try{
            await new FileManageService(token).DownloadReport(parameter,ExportType.WAREHOUSE);

        }catch(error){
            console.log("🚀 ~ file: WarehouseRequest.tsx:441 ~ onFinishPrint ~ error:", error)
            
        }finally{
            setIsLoadingPrint(false)

        }
    }

    const onStatusChange = (value:number) => {
        setStatusId(value)
    }
  return (
    <div>
    <Collapse>
     {isLoadingPrint?<Loading/>:<></>}
     <Panel header="Search" key="1">
       <Form
         {...layout}
         form={searchForm}
         name="warehouse-form"
         onFinish={onFinish}
         labelAlign="left"
         colon={false}
         labelWrap={true}
       >
           <Row>
               <Col md={11} >
                 <Form.Item name="cartonLocation" label="Carton Location"  initialValue={""}>
                    <Select 
                        showSearch
                        onChange={onCartonLocationChange}
                        placeholder="Please select..." 
                        options={warehouseOptions} 
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                 </Form.Item>
               </Col>
               <Col md={2} >
               </Col>
               <Col md={11} >
                   <Form.Item name="contractNo" label="Contract No"  >
                   <Input placeholder={MessageCommon.TitleInput}/>
                 </Form.Item>
               </Col>
             </Row>
             <Row>
               <Col md={11} >
               <Form.Item name="status" label="Status"  >
                <Select placeholder={MessageCommon.TitleSelect} defaultValue={-1}
                  options={[
                    { value: -1, label: 'All' },
                    { value: 1, label: 'Active' },
                    { value: 0, label: 'Inactive' },
                  ]}
                  onChange={onStatusChange}
                />
              </Form.Item>
               </Col>
               <Col md={2} >
               </Col>
               <Col md={11} >

               </Col>
             </Row>


           </Form>
           <div style={{ textAlign: 'center' }}>
             <Button type="default" className='mr-btn'  onClick={searchReset}>
               <AiOutlineSync className='icn-btn' /> Reset
             </Button>
             <Button type="primary"  onClick={searchData}>
               <AiOutlineSearch className='icn-btn' /> Search
             </Button>


       </div>

     </Panel>
   </Collapse>
   <br></br>
   <Card >
   
   { isAdd ?
         <>
           <div style={{marginTop: '6px',paddingBottom: '15px'}}>
               <div style={{marginBottom: '10px'}}>
                   <span style={{cursor: 'pointer'}} onClick={() => setIsAdd(false)}>
                     <AiOutlineCloseSquare  size={25} color={Color.Grey} style={{ verticalAlign: 'bottom'}}/>
                     <span style={{paddingLeft: '3px'}}>Close</span>
                 </span>
               </div>
        
               <Form
                    {...layout}
                    form={dataForm}
                    name="add-form"
                    onFinish={onFinish}
                    labelAlign="left"
                    colon={false}
                    labelWrap={true}
                >
                    <Row>
                        <Col md={11} >
                                      <Form.Item name="warehouseName" label="Carton Location" rules={[{ required: true }]} validateStatus={returnIfElse(error.warehouseName, "error"  , undefined )} >
                                <Input placeholder={MessageCommon.TitleInput} />
                            </Form.Item>
                        </Col>
                        <Col md={2} ></Col>
                        <Col md={11} >
                            <Form.Item name="contractPeriod" label="Contract Period"  rules={[{ required: true }]}>
                                <table>
                                    <tr>
                                        <td>
                                            <DatePicker 
                                                key={Helper.Uniqid()}
                                                          defaultValue={returnIfElse(dateStart, dayjs(dateStart), undefined  ) }
                                                placeholder={MessageCommon.TitleDate}
                                                format={'DD-MMM-YYYY'}
                                                onChange={onDateStartChange}
                                            />
                                        </td>
                                        <td width={80} style={{ textAlign: 'center' }}>To</td>
                                        <td>
                                            <DatePicker 
                                                key={Helper.Uniqid()}
                                                          defaultValue={returnIfElse(dateEnd, dayjs(dateEnd), undefined ) }
                                                placeholder={MessageCommon.TitleDate}
                                                format={'DD-MMM-YYYY'}
                                                onChange={onDateEndChange}
                                            />
                                        </td>
                                    </tr>
                                </table>
                                          {returnIfElse(error.contactPeriod, <div className='error'>Contact period is required.</div>, <></>  ) }
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row>
                        <Col md={11} >
                            <Form.Item name="unitCost" label="Unit Cost"  >
                                <InputNumber placeholder={MessageCommon.TitleInput} className='t-number' />
                            </Form.Item>
                        </Col>
                        <Col md={2} ></Col>
                        <Col md={11} >
                            <Form.Item name="previousContractNo" label="Previous Contract No."  >
                                <Input placeholder={MessageCommon.TitleInput}></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                     <Row>
                         <Col md={11} >
                            <Form.Item name="status" label="Status"  initialValue={active}>
                                <Select 
                                    placeholder={MessageCommon.TitleSelect} 
                                    options={[ { value: 0, label: 'Inactive' },{ value: 1, label: 'Active' }]} 
                                    onChange={onActiveChange}
                            />
                            </Form.Item>
                        </Col>
                        <Col md={2} ></Col>
                        <Col md={11} >
                                      <Form.Item name="contractNo" label="New Contract No." rules={[{ required: true }]} validateStatus={returnIfElse(error.warehouseName, "error", undefined  ) }>
                                <Input placeholder={MessageCommon.TitleInput} ></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={11} >
                            <Form.Item name="roomTypeId" label="Room Type"  initialValue={roomTypeId}>
                                <Select placeholder={MessageCommon.TitleSelect} 
                                    options={[{ value: 1, label: 'General Room' }, { value: 2, label: 'Valve Room' }]}
                                    onChange={onRoomTypeChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={2} ></Col>
                        <Col md={11} >
           
                        </Col>
                    </Row>
                    <Row>
                        <Col md={11} >
                            <Form.Item name="defaultLocation" label=" " >
                                <Checkbox checked={defaultLocation ? true : false } onChange={onDefaultLocationChange}>Use this location to default</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col md={2} ></Col>
                        <Col md={11} >

                        </Col>
                    </Row>
                </Form>
                 <div style={{ textAlign: 'center' }}>
                    <Button type="default" className='mr-btn'  onClick={reset}>
                        <AiOutlineSync className='icn-btn' /> Reset
                    </Button>
                    <Button type="primary" onClick={handleSave}>
                        <AiOutlineSave className='icn-btn' /> Add
                    </Button>
                </div>
           </div>
           <hr/>
         </>:<></>
       }
       <div>
           <div style={{float: 'left'}}>
           { !isAdd ?
             <span style={{cursor: 'pointer'}} onClick={handleAdd}>
               <AiOutlinePlusSquare size={25} color='#009FDA' style={{ verticalAlign: 'bottom'}}/>
               <span style={{paddingLeft: '3px',color:'#009FDA'}}>Add</span>
             </span>
             :<></>
           }
           </div>
           <div style={{float: 'right'}}>
             
           </div>
       </div>
     
   <br></br>
   <br></br>
       <Button type="primary" htmlType="button" className='mr-btn' disabled={isPrint} onClick={()=>onFinishPrint()}>
         <AiOutlinePrinter className='icn-btn' />Print
       </Button>
       { appState.total>0?<div style={{float: 'right'}}>
                        <SelectPagination  onSelectChange={onPageChange} currentPage={appState.currentPage}/>
                        <span style={{marginLeft:'8px'}}>/ Page</span>
                    </div>:<></>}
      
       <div id="panel-action" style={{paddingTop: '8px'}}>
                <div>
                  <div className='search-result' style={{ float: 'left'}}>Search found { appState.total } record(s).</div>
                  <div style={{float: 'right'}}>
                     
                  </div>
                </div>
              </div>
       <div id="table-container">
                { appState.isLoading ?
                    <div style={{ margin: 'auto',paddingBottom: '30px'}}>
                        <Spin tip="Loading" size="large">
                            <div className="content" />
                        </Spin>
                    </div>
                    :
                    <table className='idoc-table'>
                        <thead id="head-table-fixed">
                            <tr>
                                <th>Carton Location</th>
                                <th >Contract No</th>
                                <th >Contract Period</th>
                                <th >Unit Cost</th>
                                <th >Default Location</th>
                                <th>Status</th>
                                <th >Room Type</th>
                                <th>History Valid Period</th>
                                <th style={{minWidth:'125px'}}>Edit</th>
                            </tr>
                        </thead>
           
                        <tbody >
                            { appState.items.map((item:Warehouse) => (
                                <tr>
                                    <td style={{textAlign: 'center'}}>{item.warehouseName}</td>
                                    <td style={{textAlign: 'center'}}>
                                       {item.contractNo}
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        { item.warehouseId === warehouseId ?
                                                <table>
                                                 <tr>
                                                     <td>
                                                         <DatePicker 
                                                             key={Helper.Uniqid()}
                                                             defaultValue={dateStart}
                                                             placeholder={MessageCommon.TitleDate}
                                                             format={'DD-MMM-YYYY'}
                                                             onChange={onDateStartChange}
                                                         />
                                                     </td>
                                                     <td width={80} style={{ textAlign: 'center' }}>To</td>
                                                     <td>
                                                         <DatePicker 
                                                             key={Helper.Uniqid()} 
                                                             defaultValue={dateEnd}
                                                             placeholder={MessageCommon.TitleDate}
                                                             format={'DD-MMM-YYYY'}
                                                             onChange={onDateEndChange}
                                                         />
                                                     </td>
                                                 </tr>
                                                </table>
                                                :
                                                <>
                                                {returnIfElse(item.dateStart && item.dateEnd,
                                                    <>
                                                        {dayjs(item.dateStart).format('DD MMM YYYY')}
                                                        -
                                                        {dayjs(item.dateEnd).format('DD MMM YYYY')}
                                                    </>,
                                                    <></>
                                                ) 
                                                    }
                                                </>
                                        }
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        {item.warehouseId === warehouseId ?
                                            <Input id={'unit-cost-' + warehouseId} defaultValue={item.unitCost2}/>
                                            :
                                            <span>{item.unitCost2}</span>
                                        }
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        {returnIfElse(item.warehouseId === warehouseId,
                                            <Checkbox
                                                id={'default-location-' + warehouseId}
                                                checked={returnIfElse(defaultLocation, true, false)}
                                                onChange={onDefaultLocationChange}
                                            />,
                                            <span>{returnIfElse(item.defaultLocation, 'Yes', 'No')}</span>  )
                                        }
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        {returnIfElse(item.warehouseId === warehouseId,
                                            <Select
                                                placeholder={MessageCommon.TitleSelect}
                                                defaultValue={active}
                                                options={[{ value: 0, label: 'Inactive' }, { value: 1, label: 'Active' }]}
                                                onChange={onActiveChange}
                                            />,
                                            <span>{returnIfElse(item.active, 'Active', 'Inactive')}</span>)
                                        }

                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        {returnIfElse(item.warehouseId === warehouseId,
                                            <Select placeholder={MessageCommon.TitleSelect}
                                                defaultValue={roomTypeId}
                                                options={[{ value: 1, label: 'General Room' }, { value: 2, label: 'Valve Room' }]}
                                                onChange={onRoomTypeChange}
                                            />,
                                            <span>  {returnIfElse(item.roomTypeId === 1, 'General Room', 'Valve Room')}</span> )
                                        }
                                      
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        <a  onClick={() => onModalHistoryOpen(item.warehouseId)}>
                                            <AiFillEye className='icon-table'></AiFillEye>
                                            </a>
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        {returnIfElse(item.warehouseId === warehouseId,
                                            <div>
                                                <span>
                                                    <Popconfirm title="Confirm to update?" onConfirm={handleSave}>
                                                        <Typography.Link>Update</Typography.Link>
                                                    </Popconfirm>
                                                    <a style={{ marginLeft: 8 }} onClick={cancelSave}>
                                                        Cancel
                                                    </a>
                                                </span>
                                            </div>,
                                            <AiFillEdit size={20} onClick={() => handleEdit(item.warehouseId)} style={{ cursor: 'pointer' }} /> ) 
                                        }
                                        
                                    </td>
                                </tr>
                            ))
                            }
            
                        </tbody>
  
                    </table>
                }
        </div>
        <div style={{marginTop:'10px',textAlign:'right'}}>
        { appState.total > 0 ?
            <Pagination 
                key={Helper.Uniqid()}
                current={appState.currentPage} 
                pageSize={appState.perpage}
                total={appState.total} 
                onChange={onPageChange}
                showSizeChanger={false}
            />:<></>
        }
        </div>
   </Card>
   <Modal title="History Valid Period" open={isModalHistoryOpen} footer={''} width={600} onCancel={() => setIsModalHistoryOpen(false)}>
     <WarehouseHistory warehouseId={openWarehouseId}/>
   </Modal>

   {contextHolderMessage}
 </div>
  );

}

export default WarehouseRequest
