import { useEffect, useState } from 'react';
import { Button, Col, Collapse, DatePicker, Form, Input, Row, Select,Modal,Pagination,Spin } from 'antd';
import { AiOutlinePrinter, AiOutlineSearch, AiOutlineSync,AiOutlineFile ,AiOutlineMessage} from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import { Label } from 'reactstrap';
import secureLocalStorage  from  "react-secure-storage";
import {CartonStatus, ExportType, UserRole } from '../../enum';
import { CartonList ,Parameter ,Item, User,Option} from '../../models'
import { Helper, InitHelper } from '../../helpers';
import { StorageCartonService ,HrWebService, FileManageService,PropertyService} from '../../services';
import dayjs from 'dayjs'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import { ProjectAutoComplete } from '../../autocomplete/ProjectAutoComplete'
import StorageCartonRequestDetail from '../StorageCarton/StorageCartonRequestDetail'
import CartonDetail from '../Share/CartonDetail';
import Loading from '../../components/Loading';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
  currentPage:number
  perpage:number
  total: number
  items:CartonList[]
  isLoading:boolean
}

interface StorageCarton{
  requestNo:string
  cartonNo:string
}

const MyDocument = () => {
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }


    const { instance, accounts } = useMsal();
    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const pageSizeOptions = user.systemConfig.tablePageSize
    const [dataForm] = Form.useForm();
    const { Panel } = Collapse;
    const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
    const [isModalCartonDetailOpen, setIsModalCartonDetailOpen] = useState(false);
    const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter)
    const [recordNo, setRecordNo] = useState(0);
    const [modal, contextHolder] = Modal.useModal();
    const [appState,setAppState] = useState<AppState>({
      currentPage : 1,
      total : 0,
      perpage: 10,
      items: [],
      isLoading : true
    })
    const [requesters, setRequesters] = useState<Item[]>([])
    const [owners, setOwners] = useState<Item[]>([])
    const [requestStartDate, setRequestStartDate] = useState<Date|null>(null);
    const [requestEndDate, setRequestEndDate] =  useState<Date|null>(null);
    const [storageCarton,setStorageCarton] = useState<StorageCarton>({requestNo: "",cartonNo: ""})
    const [documentStartYear, setDocumentStartYear] = useState<number|null|undefined>(undefined);
    const [documentEndYear, setDocumentEndYear] = useState<number|null|undefined>(undefined);
    const [isPrint,setIsprint] = useState(true)
    const [projects, setProjects] = useState<Item[]>([])
    const [isLoadingPrint,setIsLoadingPrint] = useState(false)
    const [isDesOpen,setIsDesOpen] = useState(false)
    const [desc,setDesc] = useState("")
    
    useEffect(() => {
      initial()
  
    }, [])
  
    const initial = () => {
        let parameter = Object.assign({},InitHelper.InitialParameter())
        parameter.cartonStatusIds = [
           CartonStatus.Storage
         ]
        
      getData(1,appState.perpage,parameter)
    }
    const onFinish = (values: any) => {
    };
  
  
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
   
    const showModalCarton = async (item:CartonList) => {
        setRecordNo(item.recordNo)
        setIsModalCartonOpen(true);
    };
    
    const handleCancelCarton = () => {
        setIsModalCartonOpen(false);
    
    };

    const onDescOpen = (_desc:string) => {
        setDesc(_desc)
        setIsDesOpen(true)
    }

    const showModalCartonDetailOpen = async (_recordNo: number, _requestNo: string, _cartonNo:string) => {
        let _storageCarton:StorageCarton = {
            requestNo: _requestNo,
            cartonNo: _cartonNo
        }
        setRecordNo(_recordNo)
        setStorageCarton(_storageCarton)
        setIsModalCartonDetailOpen(true);
    }

    const handleCartonDetailCancel = () => {
        setIsModalCartonDetailOpen(false);
      }
      
    const getData = async (currentPage: number,perpage: number,_parameter:Parameter | null = null) => {
      let _state:AppState = Object.assign({},appState)
         _state.isLoading = true
         setAppState(_state)
      let parameter = Object.assign({},saveParameter)
      if(_parameter !== null){
          parameter = Object.assign({},_parameter);
      }
      
        parameter.page = currentPage
        parameter.limit = perpage
        
        parameter.requester = user.employeeId
        parameter.requesterOrgUnit = user.orgUnit
        parameter.employeeLevel = user.level
         parameter.userConfidential = user.employeeId
      parameter.statusIds = [CartonStatus.Done]
      
      console.log(parameter)
        let token = await getToken()
        let result = await new StorageCartonService(token).SearchMyDoc(parameter)
        console.log(result)
        if(result && result.success)
        {
            let state = Object.assign({},appState)
                state.total = result.data.total
                state.perpage = perpage
                state.currentPage = currentPage
                state.items = result.data.items
                state.isLoading = false
                state.perpage = perpage
            if(result.data.total>0){
              setIsprint(false)
            }else{
              setIsprint(true)
            }
            setTimeout(() => {
                setSaveParameter(parameter)
                setAppState(state)
            }, 500);
        }
        else{
          
          let state = Object.assign({},appState)
          state.isLoading = false
          setAppState(state)
        }
        
    }
  
    const searchData = async () => {
      let field = dataForm.getFieldsValue()
      console.log(field)
      let parameter = InitHelper.InitialParameter();
          parameter.page =1
          parameter.limit = appState.perpage
          parameter.cartonStatusIds = [
            CartonStatus.Storage,
            CartonStatus.Collect,
            CartonStatus.Return,
            CartonStatus.Remove,
            CartonStatus.Destruction
        ]
        parameter.condition = "and"
        parameter.documentStartYear = documentStartYear
        parameter.documentEndYear = documentEndYear
        parameter.requestStartDate = requestStartDate
        parameter.requestEndDate = requestEndDate
      
      if(field.cartonStatusIds){
        parameter.cartonStatusIds = field.cartonStatusIds
      }

      if(field.description){
        parameter.description = field.description
      }

      if(requesters.length > 0){
        parameter.requesters = requesters.map(item => item.key)
      }

      if(owners.length > 0){
        parameter.owners = owners.map(item => item.key)
      }

      if(projects.length > 0){
        parameter.projectIds = projects.map(item => parseInt(item.key))
      }

      if(field.requestNo){
        parameter.requestNoList = field.requestNo.trim().split(",")
      }

      if(field.confidentialLevel !== undefined){
        parameter.confidentialLevel = field.confidentialLevel
      }
  
      let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
      let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
      let cartonNoStart = ""
      let cartonNoEnd = ""
      if(elementStart){
        cartonNoStart = elementStart.value
      }
      if(elementEnd){
        cartonNoEnd = elementEnd.value
      }
  
      if(cartonNoStart && cartonNoEnd){
        parameter.cartonNo = cartonNoStart + '&' + cartonNoEnd
      }
  
      if(field.cartonNoList){
        parameter.cartonNo = field.cartonNoList
      }
  
      if(field.cartonNo){
        parameter.cartonNo = field.cartonNo
      }
  
      console.log(parameter)
      getData(1,appState.perpage,parameter)
      
   }
   
   const reset = () => {
    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    elementStart.value = ""
    elementEnd.value = ""
     dataForm.resetFields()
     setRequesters([])
     setOwners([])
     setProjects([])
     setRequestStartDate(null)
     setRequestEndDate(null)
     let parameter = Object.assign({},InitHelper.InitialParameter())
     
     parameter.cartonStatusIds = [
        CartonStatus.Storage
      ]
     getData(1,appState.perpage,parameter)
  }

   const onSearchEmployee = async(key:string) => {
     
    let token = await getToken()
    let result = await new HrWebService(token).SearchEmployee(key)
    if(result && result.success)
    {
        let _orgUnit = user.orgUnit
            if(user.orgUnit.indexOf("/") > -1) {
                _orgUnit = user.orgUnit.split("/")[0]
            }
            return result.data.filter(x => x.orgUnit.indexOf(_orgUnit) > -1)
    }
    else{
      console.log(result?.message)
      return []
    }
  }

  const onSearchProject = async(key:string) => {
     
    let token = await getToken()
    let result = await new PropertyService(token).SearchProject(key)
    if(result && result.success)
    {
        return result.data
    }
    else{
      console.log(result?.message)
      return []
    }
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

  
    const onRequestStartDateChange = (date: any) => {
      setRequestStartDate(date);
    }
  
    const onRequestEndDateChange = (date: any) => {
      setRequestEndDate(date)
    }
  
    const onPageChange = (page:number,perpage:number) => {
        if(page !== appState.currentPage && perpage === appState.perpage){
            getData(page,appState.perpage)
        }
    
        if(page === appState.currentPage && perpage !== appState.perpage){
            getData(1,perpage)
        }
    }
  
  const onRequesterSelect = (item:any) => {
    setRequesters(item)
  }
  
  const onOwnerSelect = (item:any) => {
    setOwners(item)
  }
  
  
  
  const onDocumentStartYearChange = (value: any) => {
    let year = parseInt(dayjs(value).format('YYYY'))
    setDocumentStartYear(year)
  }
  
  const onDocumentEndYearChange = (value: any) => {
    let year = parseInt(dayjs(value).format('YYYY'))
    setDocumentEndYear(year)
  }
  const onFinishPrint = async () => {
    setIsLoadingPrint(true)

    let token = await getToken()
    if(!saveParameter)return
    try{

      await new FileManageService(token).DownloadReport(saveParameter,ExportType.ORG_UNIT_DOCUMENT);
    }catch(error){
      console.log("🚀 ~ file: MyDocument.tsx:297 ~ onFinishPrint ~ error:", error)
      
    }finally{
      setIsLoadingPrint(false)

    }
  }

  const onProjectSelect = (item:any) => {
    setProjects(item)
}

  return (

    <div>
      <Collapse>
      {isLoadingPrint?<Loading/>:<></>}
        <Panel header="Carton Search" key="1">
          <Form
            {...layout}
            form={dataForm}
            name="nest-messages"
            onFinish={onFinish}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >

            <Row>
              <Col md={11} >
                  <Form.Item label="Carton No:(Range)" >
                      <table style={{width: '100%'}}>
                        <tr>
                          <td>
                            <input id="cartonNoStart" className='form-control form-control-sm' placeholder='ex. 03PCS001'/>
                          </td>
                          <td>
                            <Label style={{ marginLeft: 10, marginRight: 5 }}>To</Label>
                          </td>
                          <td>
                            <input id="cartonNoEnd" className='form-control form-control-sm' placeholder='ex. 03PCS001'/>
                        
                          </td>
                        </tr>
                      </table>
                    </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                  <Form.Item name="requestNo" label="Request No."  >
                    <Input placeholder="ex. B230001,B230002,B230003" />
                  </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                  <Form.Item name="cartonNoList" label="Carton No (Multiple)" >
                        <Input.TextArea rows={5} placeholder='ex. 03PCS001,03PCS002,03PCS003'/>
                  </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                <Form.Item name="documentYear" label="Document Year">
                  <table style={{ width: '100%'}}>
                    <tr>
                      <td>
                        <DatePicker 
                          placeholder={MessageCommon.TitleDate} 
                          picker='year' style={{width: '100%'}} 
                          onChange={onDocumentStartYearChange}
                        />
                      </td>
                      <td>
                        <Label style={{ marginLeft: 10, marginRight: 10 ,verticalAlign:'top'}}>To</Label>
                      </td>
                      <td>
                        <DatePicker 
                          placeholder={MessageCommon.TitleDate} 
                          picker='year' 
                          style={{width: '100%'}}
                          onChange={onDocumentEndYearChange}
                        />
                      </td>
                    </tr>
                  </table>


                </Form.Item>
                <Form.Item name="requestDate" label="Request Date">
                      <table style={{width : '100%'}}>
                        <tr>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate} className='date-picker-full' format={"DD MMM YYYY"} onChange={onRequestStartDateChange}/>
                          </td>
                          <td>
                            <Label style={{ marginLeft: 10, marginRight: 10 }}>To</Label>
                          </td>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate} className='date-picker-full' format={"DD MMM YYYY"} onChange={onRequestEndDateChange}/>
                          </td>
                        </tr>
                      </table>
                </Form.Item>
                    <Form.Item name="requester" label="Requester"  >
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={requesters ? requesters : []}
                            onChange={item => onRequesterSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                        />
                    </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                <Form.Item name="cartonNo" label="Carton No" >
                        <Input placeholder='ex. 03PCS001'/>
                </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                    <Form.Item name="owner" label="Owner"  >
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={owners ? owners : []}
                            onChange={item => onOwnerSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                        />
                    </Form.Item>
              </Col>
            </Row>
      
            <Row>
              <Col md={11} >
              
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
               
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                <Form.Item name="description" label="Description"  >
                  <Input placeholder={MessageCommon.TitleInput} />
                </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                  <Form.Item name="projectName" label="Project Name"  >
                    <ProjectAutoComplete
                                          key={Helper.Uniqid()}
                                          placeholder='Search by Name'
                                          selectedItems={returnIfElse(projects, projects, [] ) }
                                          onChange={item => onProjectSelect(item)}
                                          service={onSearchProject}
                                          minimumTextLength={2}
                                      />
                  </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                <Form.Item name="confidentialLevel" label="Confidentiality Level" >
                  <Select placeholder={MessageCommon.TitleSelect}
                    defaultValue={-1}
                    options={[
                        { value: -1, label: 'All' },
                        { value: 0, label: 'Non-Confident' },
                        { value: 1, label: 'Confident' }
                    ]} />
                </Form.Item>

              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                <Form.Item name="cartonStatusIds" label="Carton Status"  >
                  <Select placeholder={MessageCommon.TitleSelect}
                    mode="multiple"
                    allowClear
                    options={[
                      { value: CartonStatus.Storage, label: 'Storage' },
                        { value: CartonStatus.Collect, label: 'Collect' },
                        { value: CartonStatus.Return, label: 'Return' },
                        { value: CartonStatus.Remove, label: 'Remove' },
                      { value: CartonStatus.Destruction, label: 'Destroy' }
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>


          </Form>
          <div style={{ textAlign: 'center' }}>
            <Button type="default" className='mr-btn'  onClick={reset}>
              <AiOutlineSync className='icn-btn' /> Reset
            </Button>
            <Button type="primary"  onClick={searchData}>
              <AiOutlineSearch className='icn-btn' /> Search
            </Button>


          </div>

        </Panel>
      </Collapse>


      <br></br>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Data Result" key="1" >
          <Button type="primary" htmlType="button" className='mr-btn' disabled={isPrint} onClick={()=>onFinishPrint()} >
            <AiOutlinePrinter className='icn-btn' />Print
          </Button>
          <br></br>
          <br></br>
          { appState.isLoading ?
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>
          :
          <>
          <div className='search-result' style={{ float: 'left'}}>Search found { appState.total } record(s).</div>
            <div id="table-container" style={{paddingTop: '45px'}}>
              <table className='idoc-table'>
                <thead id="head-table-fixed">
                  <tr>
                    
                    <th>Request No.</th>
                    <th >Carton No.</th>
                    <th style={{textAlign: 'left'}}>Description</th>
                    <th style={{textAlign: 'left'}}>Project</th>
                    <th style={{textAlign: 'left'}}>Requester</th>
                    <th style={{textAlign: 'left'}}>Owner</th>
                    <th >Carton Status</th>
                    <th >Org Unit.</th>
                    <th >File</th>
                  </tr>
                </thead>
                <tbody >
                  { appState.items.map((item:CartonList) => (
                    <tr key={Helper.Uniqid()}>
                      <td style={{textAlign: 'center'}}>
                       
                        <a className='a-table' onClick={() => showModalCarton(item)}>{item.requestNo}</a>
                           
                      </td>
                      <td style={{textAlign: 'center',width:'120px'}}>
                        
                        <a className={ item.cartonStatusId === CartonStatus.Remove || item.cartonStatusId === CartonStatus.Destruction ? 'a-table c-red' : 'a-table'}  onClick={() => showModalCartonDetailOpen(item.storageRecordNo, item.requestNo, item.cartonNo)}>{item.cartonNo}</a>
                             
                      </td>
                      <td style={{textAlign: 'left',wordBreak: 'break-word'}}>
                        
                        {item.description }
                        
                        
                    </td>
                      <td style={{textAlign: 'left'}}>{ item.projectName}</td>
                      <td style={{textAlign: 'left'}}>{ item.requesterName}</td>
                      <td style={{textAlign: 'left'}}>{ item.ownerName }</td>
                      <td style={{textAlign: 'center'}}>
                        { item.cartonStatusId === CartonStatus.Destruction ? 'Destroy' : 
                          item.cartonStatusId === CartonStatus.Extend ? 'Extend' : 
                          item.cartonStatus
                        }
                      
                      
                      </td>
                      <td style={{textAlign: 'center'}}>{ item.orgUnit }</td>
                      <td style={{textAlign: 'center'}}>
                              {returnIfElse(item.confidentialLevel === 1 && (item.requester === user.employeeId || item.owner === user.employeeId),
                                  <a onClick={() => showModalCartonDetailOpen(item.storageRecordNo, item.requestNo, item.cartonNo)}>
                                      <AiOutlineFile className='icon-table' />
                                  </a>,
                                  <></>)
                        }
                              {returnIfElse(item.confidentialLevel === 0,
                                  <a onClick={() => showModalCartonDetailOpen(item.storageRecordNo, item.requestNo, item.cartonNo)}>
                                      <AiOutlineFile className='icon-table' />
                                  </a>,
                                  <></>) 
                        }
                        </td>
                    </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <div style={{marginTop:'10px',textAlign:'right'}}>
            { appState.total > 0 ?
              <Pagination 
                key={Helper.Uniqid()}
                current={appState.currentPage} 
                pageSize={appState.perpage}
                total={appState.total} 
                onChange={onPageChange} 
                pageSizeOptions={pageSizeOptions}
                showSizeChanger={true}
              />:<></>
            }
            </div>
          </>
        }
        </Panel>
      </Collapse>

      <Modal title="" open={isDesOpen} footer={''} width={1200} onCancel={() => setIsDesOpen(false)}>
          <div>
            {desc}
          </div>
            
      </Modal>

      <Modal title="" open={isModalCartonDetailOpen} footer={''} width={1200} onCancel={handleCartonDetailCancel}>
            <CartonDetail key={Helper.Uniqid()} requestNo={storageCarton.requestNo} box={storageCarton.cartonNo} />
            
      </Modal>

        <Modal title="Request Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton}>
            <StorageCartonRequestDetail 
              key={Helper.Uniqid()}
              recordNo={recordNo}  
                  approveLevel={0} cartonNumber={""}
            />
          </Modal>
          {modal ? <span></span> : <></>}
        {contextHolder}

    </div>
  );

}

export default MyDocument
