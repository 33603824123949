import React, { useEffect, useState } from 'react';
import {  Card } from 'antd';
import { Button, Col, Collapse, DatePicker, Form, Input, Spin, Modal, Row, Pagination } from 'antd';
import {  AiOutlineSearch, AiOutlineSync ,AiFillInteraction,AiFillDelete } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import { Label } from 'reactstrap';
import CartonDetail from '../Share/CartonDetail';
import DestructionCartonExtenModal from './DestructionCartonExtenModal';
import DestructionCartonDestroyModal from './DestructionCartonDestroyModal';
import { Parameter,CartonList, Item, User} from '../../models'
import { Helper, InitHelper } from '../../helpers';
import { StorageCartonService, PropertyService,HrWebService} from '../../services';
import dayjs from 'dayjs'
import { CartonStatus, RequestType, UserRole} from '../../enum'
import StorageCartonRequestDetail from '../StorageCarton/StorageCartonRequestDetail';
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import secureLocalStorage  from  "react-secure-storage";
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'

interface AppState {
  currentPage:number
  perpage:number
  total: number
  items:CartonList[]
  itemNoList:string[]
  isLoading:boolean
}

const DestructCartonDueDateView = () => {
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }


    const { instance, accounts } = useMsal();
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const dateStore = secureLocalStorage.getItem('date')
  const currentdate = dateStore ? dayjs(dateStore.toString()) : dayjs()
  const pageSizeOptions = user.systemConfig.tablePageSize
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataForm] = Form.useForm();
  const { Panel } = Collapse;
  const [isModalStorageCartonOpen, setIsModalStorageCartonOpen] = useState(false);
  const [isModalCartonDetailOpen, setIsModalCartonDetailOpen] = useState(false);
  const [requestStartDate, setRequestStartDate] = useState<Date|null|undefined>(null);
  const [requestEndDate, setRequestEndDate] =  useState<Date|null|undefined>(null);
  const [documentStartYear, setDocumentStartYear] = useState<number|null|undefined>(undefined);
  const [documentEndYear, setDocumentEndYear] = useState<number|null|undefined>(undefined);
  const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter)
  const [recordNo,setRecordNo] = useState(0)
  const [requestNo ,setRequestNo] = useState("")
  const [owners, setOwners] = useState<Item[]>([])
  const [box,setBox] = useState("")
  const [selectItems, setSelectItems] = useState<string[]>([])
  const [checkAll,setCheckAll] = useState(false)
  const [requestTypeId,setRequestTypeId] = useState(0)
  const [searchStatus,setSearchStatus] = useState(false)
  const [orgunits, setOrgUnits] = useState<Item[]>([])
  const [extendStatus,setExtendStatus] = useState(true)
  const [appState,setAppState] = useState<AppState>({
        currentPage : 1,
        total : 0,
        perpage: 20 ,
        items: [],
        itemNoList:[],
        isLoading : false
  })
  useEffect(() => {
    initial()
}, [])

    const initial =() => {
    
            let parameter = InitHelper.InitialParameter()
            getData(1,appState.perpage,parameter)
        
    }

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };

  const showModal = (_requestTypeId:number) => {
    setRequestTypeId(_requestTypeId)
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const selectItemAll = () => {
    setSelectItems(appState.itemNoList)
    setCheckAll(true)
    setItemAllChecked(appState.itemNoList)
   
  }

const setItemAllChecked = (_selectItems:string[]) => {
    let elementAll = document.getElementById('check-item-all') as HTMLInputElement
    elementAll.checked = true

    _selectItems.forEach(item => {
        let element = document.getElementById('check-item-' + item) as HTMLInputElement
        if(element)
            element.checked = true
     })
 }

  
 

 
 
 
  const getData = async (currentPage: number,perpage: number,_parameter:Parameter | null = null) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)
       
    let parameter = Object.assign({},saveParameter)
    
    if(_parameter !== null){
        parameter = _parameter;
    }
    
    parameter.page = currentPage
    parameter.limit = perpage
    parameter.owners = [user.employeeId]

    console.log(parameter)
      let token = await getToken()
      let result = await new StorageCartonService(token).SearchDestructDuedateReadonly(parameter)
      console.log(result)
      if(result && result.success)
      {
        let state = Object.assign({},appState)
          state.total = result.data.total
          state.currentPage = currentPage
          state.perpage = perpage
          state.items = result.data.items
          state.itemNoList = result.data.itemNoList
          state.isLoading = false

          setSaveParameter(parameter)
          setAppState(state)

          setTimeout(() => {
            if(checkAll){
                setItemAllChecked(selectItems)
              }
          }, 500);
          
         
         
      }
      else{
        let state = Object.assign({},appState)
        state.isLoading = false
        setAppState(state)
      }
  }
  

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }
    const onOwnerSelect = (item:any) => {
        setOwners(item)
      }

  const onPageChange = (page:number,perpage:number) => {
     if(page !== appState.currentPage && perpage === appState.perpage){
        getData(page,appState.perpage)
    }

    if(page === appState.currentPage && perpage !== appState.perpage){
        getData(1,perpage)
    }
    if(checkAll){
        setItemAllChecked(selectItems)
    }
  }
  
  const handleStorageCartonOpen = (_recordNo:number) => {
    setRecordNo(_recordNo)
    setIsModalStorageCartonOpen(true)
  }
  const handleCancelStorageCarton = () => {
    setIsModalStorageCartonOpen(false)
  }

  const handleCartonDetailCancel = () => {
    setIsModalCartonDetailOpen(false);
  }
  
  const handleCartonDetailOpen = (_requestNo:string,_box:string) => {
    setRequestNo(_requestNo)
    setBox(_box)
    setIsModalCartonDetailOpen(true);
  }

  const onRequestStartDateChange = (date: any) => {
    setRequestStartDate(date);
  }
  
  const onRequestEndDateChange = (date: any) => {
    setRequestEndDate(date)
  }
  
  const onDocumentStartYearChange = (value: any) => {
    let year = parseInt(dayjs(value).format('YYYY'))
    setDocumentStartYear(year)
  }
  
  const onDocumentEndYearChange = (value: any) => {
    let year = parseInt(dayjs(value).format('YYYY'))
    setDocumentEndYear(year)
  }

const onOrgUnitSelect = (item:any) => {
    setOrgUnits(item)
}

const onSearchOrgUnit = async(key:string) => {
   
    let token = await getToken()
    let result = await new PropertyService(token).GetOrgUnits(key)
    if(result && result.success)
    {
      return result.data
    }
    else{
      console.log(result?.message)
      return []
    }
}
const renderPagination =() =>{
  if(appState.total > 0){
    return (<Pagination 
    key={Helper.Uniqid()}
    current={appState.currentPage} 
    pageSize={appState.perpage}
    total={appState.total} 
    onChange={onPageChange} 
    pageSizeOptions={pageSizeOptions}
    showSizeChanger={true}
  />)
  }
 return (<></>)

}

  return (

    <div>
    <Card >
      <br></br>
    { !appState.isLoading ?
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Data Result" key="1" >
          <div>Search found {appState.total } record(s).</div>
          <br></br>
          <div id="table-container">
        { appState.isLoading ?
            <div style={{ margin: 'auto',paddingBottom: '30px'}}>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
          :
          <table className='idoc-table'>
            <thead id="head-table-fixed">
              <tr>
                <th >Carton No.</th>
                <th >Requested No.</th>
                <th >Requester</th>
                <th >Requested date</th>
                <th >Org. Unit</th>
                <th >Owner</th>
                <th >Total Storage Year</th>
                <th>Auto Extend Status</th>
                <th >Previous Destruction Due Date(Extend)</th>
                <th>Destruction Due Date</th>
              
              </tr>
            </thead>
           
            <tbody >
            { appState.items.map((item:CartonList) => (
                <tr>
                    <td style={{textAlign: 'center',width:'120px'}}>
                    <a className='a-table' onClick={() => handleCartonDetailOpen(item.requestNo, item.cartonNo)}>{item.cartonNo}</a>
                    </td>
                    <td style={{textAlign: 'center'}}>
                    <a className='a-table' onClick={() => handleStorageCartonOpen(item.referenceRecNo)}>{item.requestNo}</a>
                    </td>
                      <td style={{textAlign: 'center'}}>{item.requesterName}</td>
                      <td style={{textAlign: 'center'}}>{ dayjs(item.requestDate).format('DD MMM YYYY')}</td>
                      <td style={{textAlign: 'center'}}>{item.orgUnit}</td>
                      <td style={{textAlign: 'center'}}>{item.ownerName}</td>
                     
                      <td style={{textAlign: 'center'}}>{ dayjs(item.expireDate).diff(dayjs(item.documentYear),'year')}</td>
                      <td style={{textAlign: 'center'}}>{item.autoExtend == 1 ? 'Enable' : '-'}</td>
                      <td style={{ textAlign: 'center' }}>{returnIfElse(item.latestExtensionDate, dayjs(item.latestExtensionDate).format('DD MMM YYYY'),  "-" ) }</td>
                      <td style={{textAlign: 'center'}}>{ dayjs(item.expireDate).format('DD MMM YYYY')}</td>
                 
                </tr>
              ))
            }
            
            </tbody>
  
          </table>
        }
          </div>
          <div style={{marginTop:'10px',textAlign:'right'}}>
            {renderPagination()}
          </div>
        </Panel>
      </Collapse>
      :
        <div style={{ margin: 'auto',paddingBottom: '30px'}}>
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
      </div>
    }
    </Card >

        <Modal title="Request Detail" open={isModalStorageCartonOpen} footer={''} width={1550} onCancel={handleCancelStorageCarton}>
            <StorageCartonRequestDetail 
              key={Helper.Uniqid()}
                  recordNo={recordNo} cartonNumber={""}
              approveLevel={0}
            />
        </Modal>
        <Modal  title="" open={isModalCartonDetailOpen} footer={''} width={1200} onCancel={handleCartonDetailCancel}>
        <CartonDetail key={Helper.Uniqid()} requestNo={requestNo} box={box} />
      </Modal>
      
    </div>
  );

}

export default DestructCartonDueDateView
