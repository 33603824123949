import { useRef,useEffect, useState } from 'react';
import { Button, Card, Col, Form, Select, Row, Spin, Modal, message, SelectProps } from 'antd';
import { AiOutlineSave } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import  { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import MailMessageService from '../../services/MailMessageService';
import MailMessageDto from '../../models/MailMessageDto';
import { ExclamationCircleOutlined,CloseCircleOutlined } from '@ant-design/icons';
import secureLocalStorage from 'react-secure-storage';
import { User } from '../../models';
import { useMsal } from "@azure/msal-react";
import { apiRequest } from "../../configurations/authConfig";

const Block = Quill.import('blots/block');
class DivBlock extends Block {} 

DivBlock.tagName = 'DIV';

Quill.register('blots/block', DivBlock, true);  

const MailMessage = () => {
  let editor:any = null
  const { instance, accounts } = useMsal();
  const urlParams = new URLSearchParams(window.location.search);
  const [mailMessageReq,setMailMessageReq] = useState({
    requestTypes:1,
    versionTypes:1
  })

  const ref = useRef<HTMLDivElement>(null);

  const [modal, contextHolder] = Modal.useModal();
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [errorMessage, setErrorMessage] = useState('');
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null

  const requestTypes:SelectProps['options'] = [
    { value: 1, label: 'New Carton' },
    { value: 2, label: 'Storage Carton' },
    { value: 3, label: 'Collect Carton' },
    { value: 4, label: 'Collect Carton (Extend)' },
    { value: 5, label: 'Return Carton' },
    { value: 6, label: 'Remove Carton' },
    { value: 7, label: 'Destruction Carton (Due Date)' },
    { value: 8, label: 'Destruction Carton (Wait for Approval)' },
    { value: 9, label: 'Destruction Carton (Completed)' },
    { value: 10, label: 'Auto Extend (Round)' },
    { value: 11, label: 'Auto Extend (Final)' },
    { value: 12, label: 'Auto Extend (Warning Auto Remove)' },
    { value: 13, label: 'Auto Extend (Remove)' },
    { value: 14, label: 'Change Owner' },
    { value: 15, label: 'Change OrgUnit' },
    { value: 16, label: 'Warehouse Due Date' },
  ]
  const versionTypes:SelectProps['options']=[
    { value: 1, label: 'CurrentVersion' },
    { value: 2, label: 'LastedVersion' },
    { value: 3, label: 'BerforeLastedVersion' },
  ]
  
  const [selectedValue, setSelectedValue] = useState<MailMessageDto[]>([]);

  const [isLoad,setIsLoad] = useState(false)

  const accessToken = "token"
 
  const getToken = async () => {
    const acc = accounts && accounts.length ? accounts[0] : null;
    apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
    if (acc) {
        const { accessToken: token } = await instance.acquireTokenSilent({
            account: acc,
            scopes: apiRequest.scopes
        });
        return token;
    }
    return ""
}

  const getMessage = async () =>{

    try {
        let token = await getToken()
      const result = await new MailMessageService(token).getMailMessage(mailMessageReq.requestTypes,mailMessageReq.versionTypes)
      if(result && result.data)
      {
        setSelectedValue(result.data)
        textEditor(result.data[0].mailMessage1)
      }
      
    } catch (error) {
      console.log("🚀 ~ file: MailMessage.tsx:66 ~ getMessage ~ error:", error)
    }finally{
      setIsLoad(false)
    }
    
  }
  const onChangeVersionType =(value:any) =>{
    setMailMessageReq({...mailMessageReq,versionTypes:value})
  }
  const onChangeRequestType = (value: any) => {
    setMailMessageReq({...mailMessageReq,requestTypes:value,versionTypes:1})
  };

  const textEditor = (text:string) => {
    editor = new Quill('#editor', {
      modules: {
        toolbar: '#toolbar'
      },
      theme: 'snow'
    })
    if(selectedValue){
      editor.clipboard.dangerouslyPasteHTML(text)
    }
  } 
  const onConfirmUpdate = () => {
    
    console.log("🚀 ~ file: MailMessage.tsx:92 ~ onConfirmUpdate ~ innerHTML:", quill.root.innerHTML)
    modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Confirm to submit?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => updateMessage()
    });
    
  };


  const updateMessage = async () =>{
    const element = ref.current;
    if(element){

      let body = selectedValue[0]
      body.mailMessage1 = quill.root.innerHTML.replaceAll(" ","&nbsp;")
      body.updateBy = user.employeeId
      try {
        let token = await getToken()
          const res = await new  MailMessageService(token).updateMailMessage(body)
          if (!res)
              return null;
        if(!res.success){
          Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: res?.message,
          });
        }else{

            if (!res)
                return null;
          quill.setText(res.data[0].mailMessage1)
          messageApi.open({
            type: 'success',
            content: 'Save success',
            duration: 1,
          });
        }
        

      } catch (error) {
        console.log("🚀 ~ file: MailMessage.tsx:99 ~ updateMessage ~ error:", error)
      }finally{
        setIsLoad(false)
        setMailMessageReq({...mailMessageReq,versionTypes:1})  
      }

    }
  }

  const quill = new Quill('#editor',  {
    modules: {
      toolbar: '#toolbar'
    },
    theme: 'snow'
  });
  const handleInputChange = () =>{

      if (isQuillEmpty(quill)) {

          setErrorMessage('MailMessage is required!')
      } else {
          setErrorMessage('')
      }
  }

  const isQuillEmpty= (quill:any)=> {
    if ((quill.getContents()['ops'] || []).length !== 1) { return false }
    return quill.getText().trim().length === 0
  }


 
  useEffect(() => {
    
    const onInit = async () =>{
      setIsLoad(true)
      await getMessage()
      
    }
    onInit()

    
  }, [mailMessageReq])



  return (
    <div>
        <Card>
           
            <Row>
              <Col md={11} >
                <Form>
                <Form.Item name="mail-message" label="Request Type"  >
                    <Select placeholder={MessageCommon.TitleSelect}
                     defaultValue={mailMessageReq.requestTypes} 
                     options={requestTypes} onChange={onChangeRequestType} />
                </Form.Item>
                </Form>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
            

              </Col>
            </Row>
            {isLoad&&
              <div style={{ margin: 'auto',paddingBottom: '30px'}}>
                <Spin tip="Loading" size="large">
                  <div className="content" />
                </Spin>
              </div>
            }
            <Row style={{display:isLoad?'none':'block'}}>
              <Col md={18} >

                <div id="toolbar">
                  <select className="ql-size">
                    <option value="small"></option>
                    <option selected></option>
                    <option value="large"></option>
                    <option value="huge"></option>
                  </select>
                  <button className="ql-bold"></button>
                  <button className="ql-italic"></button>
                  <button className="ql-underline"></button>
                  <button className="ql-link"></button>
                  <button className="ql-list" value="ordered"></button>
                  <button className="ql-list" value="bullet"></button>
                  <button className="ql-clean"></button>

                  <Select style={{width:'20%'}} placeholder={MessageCommon.TitleSelect} value={mailMessageReq.versionTypes} options={versionTypes} onChange={onChangeVersionType} />
                </div>
                
                  <div id="editor" ref={ref} onInput={handleInputChange} ></div>   
                      <div>{errorMessage}</div>         
                      
                <div style={{ textAlign: 'center',marginTop:'20px'}}>
                    <Button type="primary"  onClick={()=>onConfirmUpdate()} >
                        <AiOutlineSave className='icn-btn' /> Update
                        </Button>
                </div>
              </Col>
            </Row>
            <div style={{height:'100px'}}>
                
            </div>
        </Card>
      {contextHolder}
      {contextHolderMessage}
    </div >
  );

}

export default MailMessage
