import React, { useEffect, useState } from 'react';
import { Button, Col, Collapse, DatePicker, Form, Input, Spin, Modal, Row, Pagination } from 'antd';
import {  AiOutlineSearch, AiOutlineSync ,AiFillInteraction,AiFillDelete,AiOutlineFileAdd,AiOutlineCloseCircle} from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import { Label } from 'reactstrap';
import CartonDetail from '../Share/CartonDetail';
import DestructionCartonExtenModal from './DestructionCartonExtenModal';
import DestructionCartonDestroyModal from './DestructionCartonDestroyModal';
import { Parameter,CartonList, Item, User} from '../../models'
import { Helper, InitHelper } from '../../helpers';
import { StorageCartonService, PropertyService} from '../../services';
import dayjs from 'dayjs'
import { CartonStatus, RequestType, UserRole} from '../../enum'
import StorageCartonRequestDetail from '../StorageCarton/StorageCartonRequestDetail';
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import secureLocalStorage  from  "react-secure-storage";
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
  currentPage:number
  perpage:number
  total: number
  items:CartonList[]
  itemNoList:string[]
  isLoading:boolean
}

interface Props {
    requestTyeId:number
    onBehalf:number
    onSelect:any
    items:CartonList[]
  }

const DestructionCartonAdd = (props: Props) => {
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }


    const { instance, accounts } = useMsal();
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const pageSizeOptions = user.systemConfig.tablePageSize
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataForm] = Form.useForm();
  const { Panel } = Collapse;
  const [isModalStorageCartonOpen, setIsModalStorageCartonOpen] = useState(false);
  const [isModalCartonDetailOpen, setIsModalCartonDetailOpen] = useState(false);
  const [requestStartDate, setRequestStartDate] = useState<Date|null|undefined>(null);
  const [requestEndDate, setRequestEndDate] =  useState<Date|null|undefined>(null);
  const [documentStartYear, setDocumentStartYear] = useState<number|null|undefined>(undefined);
  const [documentEndYear, setDocumentEndYear] = useState<number|null|undefined>(undefined);
  const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter)
  const [recordNo,setRecordNo] = useState(0)
  const [requestNo ,setRequestNo] = useState("")
  const [box,setBox] = useState("")
  const [selectItems, setSelectItems] = useState<string[]>([])
  const [checkAll,setCheckAll] = useState(false)
  const [requestTypeId,setRequestTypeId] = useState(0)
  const [searchStatus,setSearchStatus] = useState(false)
  const [orgunits, setOrgUnits] = useState<Item[]>([])
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
      perpage: returnIfElse(props.onBehalf === 1, 100, 10) ,
    items: [],
    itemNoList:[],
    isLoading : !props.onBehalf 
  })
  useEffect(() => {
    if(!props.onBehalf){
        setSearchStatus(true)
        let parameter = InitHelper.InitialParameter()
        parameter.owners = [user.employeeId]
        parameter.condition = "or"
        parameter.requesters = [user.employeeId]
        parameter.requester = user.employeeId
        parameter.requesterOrgUnit = user.orgUnit
        getData(1,appState.perpage,parameter)
    }
}, [])

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };

  const showModal = (_requestTypeId:number) => {
    setRequestTypeId(_requestTypeId)
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const selectItemAll = () => {
    setSelectItems(appState.itemNoList)
    setCheckAll(true)
    setItemAllChecked(appState.itemNoList)
   
  }

const setItemAllChecked = (_selectItems:string[]) => {
    let elementAll = document.getElementById('check-item-all') as HTMLInputElement
    elementAll.checked = true

    _selectItems.forEach(item => {
        let element = document.getElementById('check-item-' + item) as HTMLInputElement
        if(element)
            element.checked = true
     })
 }

  const onCheckAll = (e:React.ChangeEvent<HTMLInputElement>) => {
    let items:string[] = []
    if(e.target.checked){
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.cartonNo) as HTMLInputElement
        element.checked = true
        items.push(element.value)
      })
    }
    else{
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.cartonNo) as HTMLInputElement
        element.checked = false
      })
    }
    setSelectItems(items)
  };

  const onCheckChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    console.log('test-check')
    let checked = e.target.checked
    let value = e.target.value
    let element = document.getElementById('check-item-all') as HTMLInputElement
    let items = Object.assign([],selectItems)
    if(checked){
      console.log('check')
        items.push(value)
       
        if(items.length === appState.items.length){
          element.checked = true
          element.indeterminate = false
        }
        else{
          element.checked = false
          element.indeterminate = true
        }
        
    }
    else{
      console.log('uncheck')
      let index = items.indexOf(value);
      if (index > -1) {
        console.log(index)
        items.splice(index, 1);
      }

      if(items.length == 0){
        element.checked = false
        element.indeterminate = false
      }
      else{
        element.checked = false
        element.indeterminate = true
      }
    }

    console.log(items)
    setSelectItems(items)
  }

  const searchData = async () => {
    let field = dataForm.getFieldsValue()
    console.log(field)
    let parameter = InitHelper.InitialParameter();
    if(props.onBehalf === 1)
    {
        parameter.onBehalf = 1
        parameter.confidentialLevel = 2
    }
    else
    {
        parameter.requesters =  []
        parameter.owners = []
        parameter.requester = user.employeeId
        parameter.requesterOrgUnit = user.orgUnit
        
    }
    
    if(field.requestNo){
        parameter.requestNoList = field.requestNo.split(',')
    }

    if(requestStartDate && requestEndDate){
        parameter.requestStartDate = requestStartDate
        parameter.requestEndDate = requestEndDate
    }

    if(documentStartYear && documentEndYear){
        parameter.documentStartYear = documentStartYear
        parameter.documentEndYear = documentEndYear
    }
    
    if(user.roleIds.findIndex(x => x.roleId === UserRole.SuperAdmin) === -1)
    {
        parameter.orgUnits = [user.orgUnit]
        parameter.confidentialLevel = 0
    }
    
    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    let cartonNoStart = ""
    let cartonNoEnd = ""
    if(elementStart){
      cartonNoStart = elementStart.value
    }
    if(elementEnd){
      cartonNoEnd = elementEnd.value
    }

    if(cartonNoStart && cartonNoEnd){
      parameter.cartonNo = cartonNoStart + '&' + cartonNoEnd
    }

    if(field.cartonNoList){
      parameter.cartonNo = field.cartonNoList
    }

    if(field.cartonNo){
      parameter.cartonNo = field.cartonNo
    }

    console.log(parameter)
    setSearchStatus(true)
    getData(1,appState.perpage,parameter)
    
  }
  const reset = () => {
    if(!searchStatus){
        return
    }
    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    elementStart.value = ""
    elementEnd.value = ""

    dataForm.resetFields()
    setRequestStartDate(undefined)
    setRequestEndDate(undefined)
    setDocumentStartYear(undefined)
    setDocumentEndYear(undefined)
    let parameter = InitHelper.InitialParameter()
    if(props.onBehalf === 1)
    {
        parameter.onBehalf = 1
        parameter.confidentialLevel = 2
    }
    else{
        parameter.requester =  user.employeeId
        parameter.requesters =  [user.employeeId]
        parameter.owners = [user.employeeId]
        parameter.condition = "or"
        parameter.requesterOrgUnit = user.orgUnit
    }
    

    getData(1,appState.perpage,parameter)
  }

  const getData = async (currentPage: number,perpage: number,_parameter:Parameter | null = null) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)
       
    let parameter = Object.assign({},saveParameter)
    
    if(_parameter !== null){
        parameter = _parameter;
    }
    
    parameter.page = currentPage
    parameter.limit = perpage
    parameter.statusIds =[CartonStatus.Done]
    parameter.cartonStatusIds = [CartonStatus.Storage,CartonStatus.Collect]
  
    if(props.onBehalf){
        parameter.itemNoList = 1
    }
    console.log(parameter)
      let token = await getToken()
      let result = await new StorageCartonService(token).SearchDestructRequest(parameter)
      console.log(result)
      if(result && result.success)
      {
        let state = Object.assign({},appState)
        let storageItems = props.items.map(x => x.cartonNo)
          state.total = result.data.total
          state.currentPage = currentPage
          state.perpage = perpage
          state.items = result.data.items.filter(x => storageItems.indexOf(x.cartonNo) == -1)
          state.itemNoList = result.data.itemNoList
          state.isLoading = false

          setSaveParameter(parameter)
          setAppState(state)

          setTimeout(() => {
            if(checkAll){
                setItemAllChecked(selectItems)
              }
          }, 500);
          
         
         
      }
      else{
        let state = Object.assign({},appState)
        state.isLoading = false
        setAppState(state)
      }
  }
  

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const onPageChange = (page:number,perpage:number) => {
     if(page !== appState.currentPage && perpage === appState.perpage){
        getData(page,appState.perpage)
    }

    if(page === appState.currentPage && perpage !== appState.perpage){
        getData(1,perpage)
    }
    if(checkAll){
        setItemAllChecked(selectItems)
    }
  }
  
  const handleStorageCartonOpen = (_recordNo:number) => {
    setRecordNo(_recordNo)
    setIsModalStorageCartonOpen(true)
  }
  const handleCancelStorageCarton = () => {
    setIsModalStorageCartonOpen(false)
  }

  const handleCartonDetailCancel = () => {
    setIsModalCartonDetailOpen(false);
  }
  
  const handleCartonDetailOpen = (_requestNo:string,_box:string) => {
    setRequestNo(_requestNo)
    setBox(_box)
    setIsModalCartonDetailOpen(true);
  }

  const onRequestStartDateChange = (date: any) => {
    setRequestStartDate(date);
  }
  
  const onRequestEndDateChange = (date: any) => {
    setRequestEndDate(date)
  }
  
  const onDocumentStartYearChange = (value: any) => {
    let year = parseInt(dayjs(value).format('YYYY'))
    setDocumentStartYear(year)
  }
  
  const onDocumentEndYearChange = (value: any) => {
    let year = parseInt(dayjs(value).format('YYYY'))
    setDocumentEndYear(year)
  }

const onOrgUnitSelect = (item:any) => {
    setOrgUnits(item)
}

const onSearchOrgUnit = async(key:string) => {
   
    let token = await getToken()
    let result = await new PropertyService(token).GetOrgUnits(key)
    if(result && result.success)
    {
      return result.data
    }
    else{
      console.log(result?.message)
      return []
    }
}
const renderPagination =() =>{
  if(appState.total > 0){
    if(props.onBehalf===1){
      return( <Pagination 
        key={Helper.Uniqid()}
        current={appState.currentPage} 
        pageSize={appState.perpage}
        total={appState.total} 
        onChange={onPageChange} 
        showSizeChanger={false}
      />)
    }
    return (<Pagination 
    key={Helper.Uniqid()}
    current={appState.currentPage} 
    pageSize={appState.perpage}
    total={appState.total} 
    onChange={onPageChange} 
    pageSizeOptions={pageSizeOptions}
    showSizeChanger={true}
  />)
  }
 return (<></>)

}


const handleAdd =() => {
    
    props.onSelect(selectItems)
   
 }
  return (

    <div>
   
          <Collapse defaultActiveKey={returnIfElse(props.onBehalf, [1], [] ) }>
        <Panel header="Carton Search" key="1">
          <Form
            {...layout}
            form={dataForm}
            name="nest-messages"
            onFinish={onFinish}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
                <Col md={11} >
                    <Form.Item label="Carton No:(Range)" >
                        <table style={{width: '100%'}}>
                            <tr>
                                <td>
                                    <input id="cartonNoStart" className='form-control form-control-sm' placeholder='ex. 03PCS001'/>
                                </td>
                                <td>
                                    <Label style={{ marginLeft: 10, marginRight: 5 }}>To</Label>
                                </td>
                                <td>
                                    <input id="cartonNoEnd" className='form-control form-control-sm' placeholder='ex. 03PCS001'/>
                                </td>
                            </tr>
                        </table>
                    </Form.Item>
                    <Form.Item name="cartonNoList" label="Carton No (Multiple)" >
                        <Input.TextArea rows={5} placeholder='03PCS001,03PCS002,03PCS003'/>
                    </Form.Item>
                    <Form.Item name="cartonNo" label="Carton No" >
                        <Input placeholder='ex. 03PCS001'/>
                    </Form.Item>
                </Col>
                <Col md={2} >

                </Col>
                <Col md={11} >
                { !props.onBehalf ?
                <>
                    <Form.Item name="requestNo" label="Storage Requested No."  >
                        <Input placeholder='B230004,B230005' />
                    </Form.Item>
                    <Form.Item  name="storageRequestedDate" label="Storage Requested Date">
                        <table style={{width : '100%'}}>
                            <tr>
                                <td>
                                    <DatePicker 
                                          format={'DD MMM YYYY'}
                                        placeholder={MessageCommon.TitleDate} 
                                        className='date-picker-full'
                                        onChange={onRequestStartDateChange}
                                    />
                                </td>
                                <td>
                                    <Label style={{ marginLeft: 10, marginRight: 10 }}>To</Label>
                                </td>
                                 <td>
                                    <DatePicker 
                                          format={'DD MMM YYYY'}
                                        placeholder={MessageCommon.TitleDate} 
                                        className='date-picker-full' 
                                        onChange={onRequestEndDateChange}
                                    />
                                </td>
                            </tr>
                        </table>
                    </Form.Item>
                </>:<></>
                }
                    <Form.Item name="documentYear" label="Document Year">
                        <table style={{ width: '100%'}}>
                            <tr>
                                <td>
                                    <DatePicker 
                                        placeholder={MessageCommon.TitleDate} 
                                        picker='year' style={{width: '100%'}} 
                                        onChange={onDocumentStartYearChange}
                                    />
                                </td>
                                <td>
                                    <Label style={{ marginLeft: 10, marginRight: 10 ,verticalAlign:'top'}}>To</Label>
                                </td>
                                <td>
                                    <DatePicker 
                                        placeholder={MessageCommon.TitleDate} 
                                        picker='year' 
                                        style={{width: '100%'}}
                                        onChange={onDocumentEndYearChange}
                                    />
                                </td>
                            </tr>
                        </table>
                    </Form.Item>

                { props.onBehalf ?
                <>
                    <Form.Item name="orgUnit" label="Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                                              selectedItems={returnIfElse(orgunits, orgunits, [] )}
                            onChange={item => onOrgUnitSelect(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
                </>:<></>
                }
                </Col>
            </Row>
          </Form>
          <div style={{ textAlign: 'center' }}>
            <Button type="default" className='mr-btn' onClick={reset} >
              <AiOutlineSync className='icn-btn' /> Reset
            </Button>
            <Button type="primary" onClick={searchData}  >
              <AiOutlineSearch className='icn-btn' /> Search
            </Button>


          </div>

        </Panel>
      </Collapse>:<></>


      <br></br>
    { searchStatus ?
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Data Result" key="1" >
          

          <div >
            { props.onBehalf ?
                <div  className='div-select' style={{marginBottom: '10px'}}>
                    All {appState.items.length} cartons on this page are selected.<a className='a-table mlt-label' onClick={selectItemAll}>Select all {appState.total} cartons in Primary</a>
                </div>:<></>
            }
             <Button type="primary" className='btn-blue mr-btn' style={{width:'94px'}} onClick={handleAdd} disabled={selectItems.length === 0}>
            <AiOutlineFileAdd className='icn-btn' /> Add
          </Button>
          <Button type="default" className='mr-btn' onClick={handleAdd}  >
            <AiOutlineCloseCircle className='icn-btn' />Cancel
          </Button>
              { appState.total>0 && props.onBehalf===1?<div style={{float: 'right'}}>
                        <SelectPagination  onSelectChange={onPageChange} currentPage={appState.currentPage}/>
                        <span style={{marginLeft:'8px'}}>/ Page</span>
                    </div>:<></>}
          </div>
          <br></br>
          <div id="table-container">
        { appState.isLoading ?
            <div style={{ margin: 'auto',paddingBottom: '30px'}}>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
          :
          <table className='idoc-table'>
            <thead id="head-table-fixed">
              <tr>
                <th style={{ textAlign: 'left',width: '50px'}}>
                                              {returnIfElse(appState.items.length > 0, 
                                                  <div className="form-check">
                                                      <input type="checkbox" id={'check-item-all'} className="form-check-input check-item" onChange={(e) => onCheckAll(e)} />
                                                      <label className="form-check-label" htmlFor={'check-item-all'} />
                                                  </div>
                                                  ,
                                                  <></>) 
                }
                </th>
                <th >Carton No.</th>
                <th >Requested No.</th>
                <th >Requester</th>
                <th >Requested date</th>
                <th >Org. Unit</th>
                <th >Owner</th>
                <th >Latest Extension Date</th>
                <th>Destruction Due Date</th>
              
              </tr>
            </thead>
           
            <tbody >
            { appState.items.map((item:CartonList) => (
                <tr>
                    <td style={{ textAlign: 'center',width: '50px'}}>
                      <div className="form-check">
                          <input  
                            type="checkbox" 
                            id={'check-item-' + item.cartonNo} 
                            defaultValue={selectItems} 
                            value={item.cartonNo} 
                            className="form-check-input check-item" 
                            onChange={(e) => onCheckChange(e)}
                        />
                          <label className="form-check-label" htmlFor={'check-item-' + item.cartonNo}/>
                        </div>
                    </td>
                    <td style={{textAlign: 'center',width:'120px'}}>
                    <a className='a-table' onClick={() => handleCartonDetailOpen(item.requestNo, item.cartonNo)}>{item.cartonNo}</a>
                    </td>
                    <td style={{textAlign: 'center'}}>
                    <a className='a-table' onClick={() => handleStorageCartonOpen(item.referenceRecNo)}>{item.requestNo}</a>
                    </td>
                      <td style={{textAlign: 'center'}}>{item.requesterName}</td>
                      <td style={{textAlign: 'center'}}>{ dayjs(item.requestDate).format('DD MMM YYYY')}</td>
                      <td style={{textAlign: 'center'}}>{item.orgUnit}</td>
                      <td style={{textAlign: 'center'}}>{item.ownerName}</td>
                    <td style={{ textAlign: 'center' }}>{returnIfElse(item.latestExtensionDate, dayjs(item.latestExtensionDate).format('DD MMM YYYY'), '-'  ) }</td>
                      <td style={{textAlign: 'center'}}>{ dayjs(item.expireDate).format('DD MMM YYYY')}</td>
                 
                </tr>
              ))
            }
            
            </tbody>
  
          </table>
        }
          </div>
          <div style={{marginTop:'10px',textAlign:'right'}}>
            {renderPagination()}
          </div>
        </Panel>
      </Collapse>:<></>
    }
          <Modal title={returnIfElse(requestTypeId === RequestType.ExtendCarton, 'Extend', 'Destroy' ) } open={isModalOpen} footer={''} width={1550} onCancel={handleCancel} maskClosable={false} >
        { requestTypeId === RequestType.ExtendCarton ?
            <DestructionCartonExtenModal  
              key={Helper.Uniqid()} 
              destroyId={0} 
              items={selectItems} 
                      page={returnIfElse(props.onBehalf, 'onBehalf', "destruction" )  }
              onBehalf={props.onBehalf}
              onReload={null}
            />:<></>
        }
        { requestTypeId === RequestType.DestructionCarton ?
            <DestructionCartonDestroyModal  
            key={Helper.Uniqid()} 
            destroyId={0} 
            items={selectItems} 
                      page={returnIfElse(props.onBehalf, 'onBehalf', "destruction") }
            onBehalf={props.onBehalf}
            onReload={null}
            />:<></>
        }
      </Modal>

        <Modal title="Request Detail" open={isModalStorageCartonOpen} footer={''} width={1550} onCancel={handleCancelStorageCarton}>
            <StorageCartonRequestDetail 
              key={Helper.Uniqid()}
                  recordNo={recordNo} cartonNumber={""}
              approveLevel={0}
            />
        </Modal>
        <Modal  title="" open={isModalCartonDetailOpen} footer={''} width={1200} onCancel={handleCartonDetailCancel}>
        <CartonDetail key={Helper.Uniqid()} requestNo={requestNo} box={box} />
      </Modal>

    </div>
  );

}

export default DestructionCartonAdd
