import React, {  useEffect, useState } from 'react';
import { Button, Card, Col, Collapse, Typography, Form, Input, Modal, Popconfirm, Row, Select, Spin, message } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { AiFillEdit, AiOutlinePlusSquare, AiOutlineCloseSquare, AiOutlineSave, AiOutlineSearch, AiOutlineSync, AiOutlinePrinter } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import Color from '../../enum/Color';
import MasterDataService from '../../services/MasterDataService';
import { Project } from '../../models/MasterDataDto';
import { ExclamationCircleOutlined,CloseCircleOutlined } from '@ant-design/icons';
import secureLocalStorage from 'react-secure-storage';
import { SearchProjectName, User } from '../../models';
import moment from 'moment';
import { SelectPagination } from '../../components/SelectPagination';
import { useMsal } from "@azure/msal-react";
import { apiRequest } from "../../configurations/authConfig";
import { useNavigate } from 'react-router-dom';
import { FileManageService } from '../../services';
import { ExportType } from '../../enum';
import Loading from '../../components/Loading';

interface ICounterProps { }
interface ICounterState {
  currentCount: number;
}
interface DataType {
  key: string;
  request_no: string;
  request_date: string;
  expecrted_receive_date: string;
  box_quantity: string;
  status: string;
}
interface Item {
  key: string;
  request_no: string;
  owner: string;
  dept: string;
  new_owner: string;
  id: string;
}
const ManageProjectName = () => {
    
    const navigate = useNavigate();
const { instance, accounts } = useMsal();
  const urlParams = new URLSearchParams(window.location.search);
  const id:string| null| undefined = urlParams.get('id')
  const [modal, contextHolder] = Modal.useModal();
  const [messageApi, contextHolderMessage] = message.useMessage();
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const [dataForm] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [dataOnGoing, setDataOnGoing] = useState<Project[]>([]);
  const [dataOnGoingProcess, setDataOnGoingProcess] = useState<Project>();
  const { Panel } = Collapse;
  const [isAdd,setIsAdd] = useState(false)
  const [editingKey, setEditingKey] = useState(0);
  const [isLoad,setIsLoad]= useState(false)
  const [pageSize,setPageSize] = useState(10)
  const [isLoadingPrint,setIsLoadingPrint] = useState(false)
  const isEditing = (record: any) => {
    if(record.projectId === editingKey){
      return true
    }
    return false
  };
  const cancel = () => {
    setEditingKey(0);
  };
  const edit = (record: Partial<Project> & { key: React.Key }) => {
    
    setEditingKey(record.projectId||0);
    setDataOnGoingProcess(record as Project|undefined)
  };

  const getToken = async () => {
    const acc = accounts && accounts.length ? accounts[0] : null;
    apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
    if (acc) {
        const { accessToken: token } = await instance.acquireTokenSilent({
            account: acc,
            scopes: apiRequest.scopes
        });
        return token;
    }
    return ""
}

  const getDataProject = async (project_name:string,location:number) =>{
    try {
      setIsLoad(true)
      let token = await getToken()
      let result = await new MasterDataService(token).getManageProjectName(project_name,location)
      if(result && result.data)
      {
        setDataOnGoing(result.data);
      }
        
      
    } catch (error) {
      
    }finally{
      setIsLoad(false)
    }
  }
 
  const handleReset = () => {
    dataForm.setFieldsValue({
      projectname: undefined,
      projectlocationid: 1,
      active:1
    });
  };
  const onFinishSearch = (values: any) => {
    getDataProject(values.name==undefined?"":values.name,values.location==undefined?0:values.location)
  };
  const onAddProject = (values: any) => {
    modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Confirm to submit?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => onFinishAdd(values)
    });
    
  };
  const onFinishAdd = async (values: any) => {
    let body = {...values,updateBy:user.employeeId}
    let token = await getToken()
      const res = await new MasterDataService(token).createManageProjectName(body)
      if (!res)
          return null;
    if(!res.success){
      Modal.error({
        icon: <CloseCircleOutlined />,
        title: 'This is an error message',
        content: res?.data.projectName
      });
    }else{
      
     
      messageApi.open({
        type: 'success',
        content: 'Save success',
        duration: 1,
      });
    }
    await getDataProject("",0)
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const onConfirmUpdate = (item:any) => {
    console.log(dataOnGoingProcess);
    
    modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Confirm to submit?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => onUpdateById(item)
    });
    
  };
  const onUpdateById = async (item:any) =>{
    let token = await getToken()
    let res = await new MasterDataService(token).updateManageProjectName({...dataOnGoingProcess,updateBy:user.employeeId});
      console.log("🚀 ~ file: ManageProjectName.tsx:208 ~ onUpdateById ~ res:", res)
      if (!res)
          return null;
    if(!res.success){
      Modal.error({
        icon: <CloseCircleOutlined />,
        title: 'This is an error message',
        content: res?.data.projectName,
      });
    }else{
      
     
      messageApi.open({
        type: 'success',
        content: 'Save success',
        duration: 1,
      });
    }
    console.log("🚀updateeeeee", dataOnGoingProcess)
    setEditingKey(0)
    getDataProject("",0)
  }

  const onChange = (key: string,item:any,type:string) => {
    if(dataOnGoingProcess){

      switch (type) {
        case "NAME":
          setDataOnGoingProcess({...dataOnGoingProcess,projectName:key})
        break;
        case "LOCATION":
          setDataOnGoingProcess({...dataOnGoingProcess,projectLocationId:Number(key)})
        break;
        case "ACTIVE":
          setDataOnGoingProcess({...dataOnGoingProcess,active:Number(key)})
        break;
      
        default:
          break;
      }
    }
  };

  const columnsOngoing: ColumnsType<Project> = [
    {
      title: 'Project Name',
      dataIndex: 'projectName',
      key: 'projectName',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        if (editable) {
          return  <Input placeholder={MessageCommon.TitleInput} defaultValue={record.projectName} onChange={(e)=>onChange(e.target.value,record,"NAME")}/> 
        } else {
          return <span>{record.projectName}</span>
        }
      }
    },
    {
      title: 'Project Location',
      dataIndex: 'projectLocationName',
      key: 'projectLocationName',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        if (editable) {
          return   <Select placeholder={MessageCommon.TitleSelect} defaultValue={record.projectLocationId} onChange={(e)=>onChange(e,record,"LOCATION")}
          options={[
            { value: 1, label: 'Oversea' },
            { value: 2, label: 'Domestic' },
          ]}
        />
        } else {
          return <span>{record.projectLocationName}</span>
        }
      }
    },
    {
      title: 'Updated By',
      dataIndex: 'updateBy',
      key: 'updateBy',
    },
    {
      title: 'Updated Date',
      dataIndex: 'updateDate',
      key: 'updateDate',
      render: (_: any, record: any) => {
       
  
          return <span>{record.updateDate==null?"":moment(record.updateDate).format("DD MMM YYYY")}</span>
        
      }
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        if (editable) {
          return   <Select placeholder={MessageCommon.TitleSelect} defaultValue={record.active} onChange={(e)=>onChange(e,record,"ACTIVE")}
          options={[
            { value: 1, label: 'Active' },
            { value: 0, label: 'Inactive' },
          ]}
        />
        } else {
          return <span>{record.active==1?"Active":"Inactive"}</span>
        }
      }
    },
    {
      title: 'Edit',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        console.log("🚀 ~ file: ManageProjectName.tsx:341 ~ ManageProjectName ~ editable:", editable)
        return editable ? (
          <span>
            <Typography.Link style={{ marginRight: 8 }} onClick={()=>onConfirmUpdate(record)}>
              Update
            </Typography.Link>
            <Popconfirm title="Confirm to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <AiFillEdit className='icon-table' onClick={() => edit(record)} ></AiFillEdit>

        );
      },
    },
   
  ];

  const handleResetSearchForm = async () =>{

    searchForm.setFieldsValue({
      "name":undefined,
      "location":"0"
    })
    await getDataProject("",0)
  }

const handleTableChange = (pagination:any) => {
  console.log("🚀 ~ file: ManageProjectName.tsx:429 ~ handleTableChange ~ pagination:", pagination)
  setPageSize(pagination.pageSize);
};
const handleTableChange2 = (currentPage:number,perpage:number) => {
  setPageSize(perpage);
};
const onFinishPrint = async () => {
  setIsLoadingPrint(true)

  let token = await getToken()
  
  try{

    let data = searchForm.getFieldsValue()
    let body:SearchProjectName={
      name :data.name===undefined?"":data.name,
      location:data.location===undefined?0:data.location
    }
   
    await new FileManageService(token).DownloadReport(body,ExportType.EXPORT_PROJECTNAME_LIST);

  }catch(error){
      console.log("🚀 ~ file: WarehouseRequest.tsx:441 ~ onFinishPrint ~ error:", error)
      
  }finally{
      setIsLoadingPrint(false)

  }
}

  useEffect(() => {
    if(user.permissions.masterData === 0){
        navigate('/permissiondenided')
    }
    const onInit = async()=>{
     await getDataProject("",0)
    }
    onInit()
  }, [])


  return (
    <div>

      <div>
      <Collapse>
      {isLoadingPrint?<Loading/>:<></>}
        <Panel header="Search" key="1">
          <Form
            {...layout}
            form={searchForm}
            name="nest-messages"
            onFinish={onFinishSearch}
            validateMessages={validateMessages}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
              <Row>
                  <Col md={11} >
                    <Form.Item name="name" label="Project Name"  >
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col md={2} >
                  </Col>
                  <Col md={11} >

                  </Col>
                </Row>
                <Row>
                  <Col md={11} >
                    <Form.Item name="location" label="Project Location"  >
                      <Select placeholder={MessageCommon.TitleSelect} defaultValue={'0'} 
                        options={[
                          { value: '0', label: 'All' },
                          { value: '1', label: 'Oversea' },
                          { value: '2', label: 'Domestic' },
                        
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={2} >
                  </Col>
                  <Col md={11} >

                  </Col>
                </Row>


                <div style={{ textAlign: 'center' }}>
                  <Button type="default" className='mr-btn' onClick={handleResetSearchForm} >
                    <AiOutlineSync className='icn-btn' /> Reset
                  </Button>
                  <Button type="primary" htmlType='submit' >
                    <AiOutlineSearch className='icn-btn' /> Search
                  </Button>
                </div>
              </Form>

        </Panel>
      </Collapse>
      <br></br>

        <Card >
        { isAdd ?
            <>
              <div style={{marginTop: '6px',paddingBottom: '15px'}}>
                  <div style={{marginBottom: '10px'}}>
                      <span style={{cursor: 'pointer'}} onClick={() => setIsAdd(false)}>
                        <AiOutlineCloseSquare  size={25} color={Color.Grey} style={{ verticalAlign: 'bottom'}}/>
                        <span style={{paddingLeft: '3px'}}>Close</span>
                    </span>
                  </div>
           
                  <Form
            {...layout}
            form={dataForm}
            name="nest-messages"
            onFinish={onAddProject}
            initialValues={{"projectlocationid":1,"active":1}}

            validateMessages={validateMessages}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
              <Col md={11} >
                <Form.Item name="projectlocationid" label="Project Location"  >
                  <Select placeholder={MessageCommon.TitleSelect} style={{ width: '100%' }} 
                    options={[{ value: 1, label: 'Oversea' }, { value: 2, label: 'Domestic' }]}
                  />
                </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                

              </Col>
            </Row>

            <Row>
              <Col md={11} >
                <Form.Item name="projectname" label="Project Name" rules={[{ required: true, message: 'Please input your Project name!' }]} >
                  <Input placeholder={MessageCommon.TitleInput} 
                  
                 />
                </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                

              </Col>
            </Row>
           
            <Row>
              <Col md={11} >
                <Form.Item name="active" label="Status"  >
                  <Select placeholder={MessageCommon.TitleSelect} style={{ width: '100%' }} 
                    options={[{ value: 0, label: 'Inactive' }, { value:1, label: 'Active' }]}
                  />
                </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                

              </Col>
            </Row>

          <div style={{ textAlign: 'center' }}>
            
            <Button type="default" className='mr-btn' onClick={handleReset}  >
              <AiOutlineSync className='icn-btn' /> Reset
            </Button>
            <Button type="primary" hidden={!isAdd} htmlType='submit' >
              <AiOutlineSave className='icn-btn' /> Add
            </Button>

          </div>
          </Form>
              </div>
              <hr/>
            </>:<></>
          }
          <div>
              <div style={{float: 'left'}}>
              { !isAdd ?
                <span style={{cursor: 'pointer'}} onClick={() => setIsAdd(true)}>
                  <AiOutlinePlusSquare size={25} color='#009FDA' style={{ verticalAlign: 'bottom'}}/>
                  <span style={{paddingLeft: '3px',color:'#009FDA'}}>Add</span>
                </span>
                :<></>
              }
              </div>
              <div style={{float: 'right'}}>
                {/* <Select
                  value={pageSize}
                  style={{ width: 120 }}
                  onChange={handleChangePageSize}
                  options={[
                    { value: 10, label: '10 / Page' },
                    { value: 20, label: '20 / Page' },
                    { value: 100, label: '100 / Page' },
                    { value: 200, label: '200 / Page' },
                  ]}
              /> */}
              </div>
          </div>

      <br></br>
      <br></br>
      {isLoad?
      <div style={{ margin: 'auto',paddingBottom: '30px'}}>
        <Spin tip="Loading" size="large">
          <div className="content" />
          
        </Spin>
      </div>
      :
      <div>
        { dataOnGoing.length>0?
        <>
          <div style={{float: 'left'}}>
          <Button type="primary" htmlType="button" className='mr-btn' disabled={dataOnGoing.length===0} onClick={()=>onFinishPrint()}>
            <AiOutlinePrinter className='icn-btn' />Print
          </Button>
          </div>
          <div style={{float: 'right'}}>
            <SelectPagination  onSelectChange={handleTableChange2} currentPage={1}/>
            <span style={{marginLeft:'8px'}}>/ Page</span>
          </div>
        </>
        :
        <></>
        }
        <div id="panel-action" style={{paddingTop: '32px'}}>
                <div>
                  <div className='search-result' style={{ float: 'left'}}>Search found { dataOnGoing.length } record(s).</div>
                  <div style={{float: 'right'}}>
                     
                  </div>
                </div>
              </div>
        <Table 
          columns={columnsOngoing} 
          dataSource={dataOnGoing} 
          pagination={{ pageSize: pageSize, showSizeChanger: false }}  
          onChange={handleTableChange}/>
      </div>
      }
        </Card>
      </div>
      {contextHolder}
      {contextHolderMessage}
    </div >
  );

}

export default ManageProjectName
