import { useEffect, useState } from 'react';
import {  Card, Col, Form,  Row,Spin } from 'antd';
import { Label } from 'reactstrap';
import CartonList from '../Share/CartonList'
import CartonStatus  from '../../enum/CartonStatus';
import { RequestDetail } from '../../models';
import { CollectCartonService } from '../../services';
import { InitHelper } from '../../helpers';
import dayjs from 'dayjs'
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import {  User} from '../../models'

interface Props{
  recordNo: number
  showNote?:boolean
  disableDetail?: boolean
  onBehalf?: boolean
}

const CollectCartonRequestDetail = (props: Props) => {
    const { instance, accounts } = useMsal();
    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
  const [dataForm] = Form.useForm();
  
  const [detail, setDetail] = useState<RequestDetail>(InitHelper.InitialRequestDetail)
  const [isLoading, setIsLoading] = useState(true)

  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
  

  useEffect(() => {
    getData()
  }, [])
  
  const getData = async () => {
    let token = await getToken()
    let result = await new CollectCartonService(token).GetDetail(props.recordNo)
    console.log(result)
    if(result && result.success){
      setDetail(result.data)
    }
    setIsLoading(false)
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }




  return (

    <div>
      <Card >
      { isLoading ?
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>
          :<></>
      }
        <Form
          {...layout}
          form={dataForm}
          name="nest-messages"
          labelAlign="left"
          colon={false}
          labelWrap={true}
        >
          <Row>
            <Col md={22} >
              <Form.Item name="requester" label="Requester" labelCol={{ md: 3 }}  >

                <Label className='label-det'>{ detail.requesterName}</Label>
                <Label className='mr-label' >Org. Unit</Label>
                <Label className='label-det'>{ detail.orgUnit}</Label>
                <Label className='mr-label' >Position</Label>
                <Label className='label-det'>{ detail.position}</Label>
                <Label className='mr-label' >Tel No.</Label>
                <Label className='label-det'>{ detail.tel}</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
              <Form.Item name="recordNo" label="Request No"  >
                <Label className='label-det'>{ detail.requestNo}</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item name="status" label="Status"  >
                <Label className='label-det'>{ detail.status}</Label>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={11} >
              <Form.Item name="deliverto" label="Deliver to.."  >
                <Label className='label-det'>{ detail.delivertoName}</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
                <Form.Item name="location" label="Location"  >
                <Label className='label-det'>{ detail.location}</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
                <Form.Item name="floor" label="Floor"  >
                <Label className='label-det'>{ detail.floor}</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item name="place" label="Place"  >
                <Label className='label-det'>{ detail.place}</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
            <Form.Item name="quantity" label="Carton Quantity"  >
                <Label className='label-det'>{ detail.quantity} carton(s)</Label>
              </Form.Item>
             
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
            <Form.Item name="requestDate" label="Request Date"  >
                <Label className='label-det'>{ detail.requestDate ? dayjs(detail.requestDate).format('DD MMM YYYY'): ""}</Label>
              </Form.Item>
            </Col>
          </Row>
   
          <Row>
            <Col md={11} >
                
            { detail.statusId === CartonStatus.Reject ?
                 <Form.Item name="noteReject" label="Rejected Reason"  >
                        <Label className='label-det'>{detail.notApproveReason}</Label>
                    </Form.Item>:<></>
            }
            <Form.Item name="note" label="Note"  >
              { detail.note.length > 0 ?
                <>
                    <Label className='label-det'>{detail.note[ detail.note.length - 1 ]}</Label>
                    <a className='a-table ml-label' >Display Log</a>
                </>:<>-</>
                }
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
            { detail.returnDate ?
                <Form.Item name="returnDate" label="Expected Return Date"  >
                <Label className='label-det'>{ detail.returnDate ? dayjs(detail.returnDate).format('DD MMM YYYY'): ""}</Label>
              </Form.Item>:<></>
}
            {detail.serviceDate ?
              <Form.Item name="serviceDate" label="Service Date"  >
                <Label className='label-det'>{ detail.serviceDate ? dayjs(detail.serviceDate).format('DD MMM YYYY'): ""}</Label>
              </Form.Item>:<></>
            }
            </Col>
          </Row>
          <Row>
            <Col md={11} >
                    { detail.requester !== detail.onBehalf && props.onBehalf ?
                          <Form.Item name="onbehaft" label="On Behalf"  >
                              <Label className='label-det'>{detail.onBehalfName}</Label>
                          </Form.Item>:<></>
}
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
             
            </Col>
          </Row>



        </Form>
        <hr></hr>
        <CartonList requestNo={detail.requestNo} cartons={detail.cartons} disableDetail={props.disableDetail} statusId={detail.statusId}/>

      </Card>
    

     
    </div>
  );

}

export default CollectCartonRequestDetail
