import { useEffect, useState } from 'react';
import { Card , Button} from 'antd';
import {Item,User } from '../../models';
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import { Helper } from '../../helpers';
import { HrWebService,UserService } from '../../services';
import  secureLocalStorage  from  "react-secure-storage";
import { PageOption } from '../../enum';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const { instance, accounts } = useMsal();
  const [users, setUsers] = useState<Item[]>([])
  const navigate = useNavigate();
  const bypass = secureLocalStorage.getItem('bypass')
    
  useEffect(() => {
    if(!bypass || bypass == 0)
    {
        navigate('/permissiondenided')
    }
  }, [])

  const onUserSelect = async (items:any) => {
        setUsers(items)
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const onSearchEmployee = async(key:string) => {
   
      let token = await getToken()
      let result = await new HrWebService(token).SearchEmployee(key)
    if(result && result.success)
    {
      return result.data
    }
    else{
      return []
    }
}

 const login = () => {
    if(users.length === 0 ){
        return
    }

     let employeeId = users[0].key
     getToken().then((token_result) => {
         let accessToken = token_result
        new UserService(accessToken).getUserById(employeeId).then((result) => {
            console.log(result)
              if(result && result.success){
                if( result.data)
                {
                  let user:User = result.data
                  console.log(user)
                  secureLocalStorage.setItem('testFlag',1)
                  secureLocalStorage.setItem('user',JSON.stringify(user))
                  let storePagination = PageOption.map((item)=>{return {value:item.value,label:item.value}})
                  secureLocalStorage.setItem('pagination',JSON.stringify(storePagination))
                    if (result.date) {
                        secureLocalStorage.setItem('date', result.date)
                    }
                  window.location.href = '/'
                }

            }
        })
     })
   
 }

  return (
    <Card >
        <div className='row'>
            <div className='col-md-4'></div>
            <div className='col-md-4'>
                <EmployeeAutoComplete
                    key={Helper.Uniqid()}
                    placeholder='Search by Name'
                    selectedItems={users ? users : []}
                    onChange={item => onUserSelect(item)}
                    service={onSearchEmployee}
                    minimumTextLength={3}
                    pickOnlyOne
                  />
            </div>
        </div>
        <div className='row'>
            <div className='col-md-4'></div>
            <div className='col-md-4' style={{textAlign: 'center'}}>
             <Button type="primary" onClick={() => login()}>
              Login
            </Button>
            </div>
            <div className='col-md-4'></div>
        </div>
        
  </Card>
  );

}

export default Login
