
enum RequestType {
    All = 0,
    NewCarton = 1,
    StorageCarton = 2,
    CollectCarton = 3,
    ReturnCarton = 4,
    RemoveCarton = 5,
    RemoveCartonStorage = 6,
    ExtendCarton = 7,
    DestructionCarton = 8,
    ChangeOwner = 9
}

export default RequestType