import React, {  useEffect, useState } from 'react';
import { Button, Card,  Col,  DatePicker, Form, Input, Pagination, Modal,Spin, Popconfirm, Row,  message } from 'antd';
import {  AiFillDelete, AiFillSave, AiOutlineCloseCircle, AiFillEye, AiOutlineSave,AiOutlinePlusCircle } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import dayjs, { Dayjs } from 'dayjs';
import { ExclamationCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { CartonList , RequestReturnCarton, Item,User} from '../../models';
import {ReturnCartonService, HrWebService,CollectCartonService} from '../../services';
import { CartonStatus } from '../../enum';
import { Helper ,InitHelper} from '../../helpers';
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import  secureLocalStorage  from  "react-secure-storage";
import { useNavigate } from 'react-router-dom';
import CollectCartonRequestDetail from '../CollectCarton/CollectCartonRequestDetail';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import ReturnCartonAdd  from "./ReturnCartonAdd"
interface Props{
  returnId:number
  items: string[]
  page:string
  onBehalf:number
  onReload:any
}

interface Error
{
  owner: number
  place: number
  returnDate:number
}

const ReturnCartonModal = (props: Props) => {

    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }


    const doIfElse = (flag: any, f1: any, f2: any) => {
        if (flag) {
            if (f1 != null) {
                f1();
            }
        }
        else {
            if (f2 != null) {
                f2();
            }
        }
    }

    const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
    const orgUnit = returnIfElse(user.orgUnit , user.orgUnit ,"") 
    const position = returnIfElse(user.position , user.position , "") 
    const directPhone = returnIfElse(user.directPhone , user.directPhone , "") 
  const currentDate = secureLocalStorage.getItem('date')
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [dataForm] = Form.useForm();
  const [page,setPage] = useState(1)
  const [perpage ,setPerpage] = useState(10);
  const [selectItems, setSelectItems] = useState<string[]>([])
  const [allCartonItems,setAllCartonItems] = useState<CartonList[]>([])
  const [cartonItems,setCartonItems] = useState<CartonList[][]>([])
  const [modal, contextHolder] = Modal.useModal();
  const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
  const [owners, setOwners] = useState<Item[]>([])
  const [onBehalfs, setonBehalfs] = useState<Item[]>([])
  const [checkAll, setCheckAll] = useState(false)
  const [isLoading ,setIsLoading] = useState(true)
  const [ownersBgColor,setOwnersBgColor] = useState("")
  const [recordNo,setRecordNo] = useState(0)
  const [isAdd,setIsAdd] = useState(false)
  const [error,setError] = useState<Error>({
    owner: 0,
    place: 0,
    returnDate:0
  })
  const onFinish = (values: any) => {
  };


  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
  
  const onOwnerSelect = (item:any) => {
    setOwners(item)
    if(item.length > 0){
      dataForm.setFieldsValue({
        contactPerson: item[0].key,
        location: item[0].location,
        place:item[0].location
      });
    }
    else{
        dataForm.setFieldsValue({
            location: "" 
        });
    }
  }
  
  const onBehalfSelect = (item:any) => {
    setonBehalfs(item)
    
    if(item.length > 0){
        setOwners(item)
      dataForm.setFieldsValue({
        onBehalf: item[0].key,
        contactPerson: item[0].key,
        location: item[0].location,
        place:item[0].location
      });
      
    }
    
  }

  const onSearchEmployee = async(key:string) => {
   
      let token = await getToken()
      let result = await new HrWebService(token).SearchEmployee(key)
      if(result && result.success)
      {
        if(props.onBehalf === 1)
        {
            return result.data
        }
        else{
            let _orgUnit = user.orgUnit
            if(user.orgUnit.indexOf("/") > -1) {
                _orgUnit = user.orgUnit.split("/")[0]
            }
            return result.data.filter(x => x.orgUnit.indexOf(_orgUnit) > -1)
        }
      }
      else{
       
        return []
      }
  }

  const onSearchEmployee2 = async(key:string) => {
   
    let token = await getToken()
    let result = await new HrWebService(token).SearchEmployee(key)
    if(result && result.success)
    {
          return result.data
    }
    else{
      
      return []
    }
}

  const validate = () => {
    let errorData:Error = {
      owner: 0,
      place: 0,
      returnDate:0
    }

    let errorCount = 0
    setOwnersBgColor('')

    if(owners.length === 0)
    {
      console.log('e_owner')
      setOwnersBgColor('red')
       errorCount++
    }

    if(!dataForm.getFieldValue('place')){
      errorCount++
      errorData.place = 1
    }

    if(!dataForm.getFieldValue('pickUpReturnDate')){
      errorCount++
      errorData.returnDate = 1
     
    }

    

    setError(errorData)

    if(errorCount !== 0){
      let element = document.getElementById('return-carton-form') as HTMLFormElement
      messageApi.open({
        type: 'error',
        content: 'Please fill required fields',
        duration: 1.5,
        style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
      });
      return false
    }
    else{
      return true;
    }
   
  }

const handleSave = async (saveType: boolean) => {
  if(validate())
  {
    let returnDate =  dataForm.getFieldValue('pickUpReturnDate')
    let requestCarton:RequestReturnCarton = {
          recordNo:dataForm.getFieldValue('recordNo'),
          requestType:'return',
          requester:user.employeeId,
        onBehalf: returnIfElse(props.onBehalf, dataForm.getFieldValue('onBehalf'), user.employeeId)  ,
          contactPerson:dataForm.getFieldValue('contactPerson'),
          contactPersonName:dataForm.getFieldValue('contactPersonName'),
          returnDate: dayjs(returnDate).format("YYYY-MM-DD"),
          location: dataForm.getFieldValue('location'),
          floor: dataForm.getFieldValue('floor'),
          place: dataForm.getFieldValue('place'),
        statusId: returnIfElse(saveType, CartonStatus.WaitAdmin, CartonStatus.SaveDraft)  ,
          extendReason:"",
          cartons:allCartonItems
       }
      console.log(requestCarton)
      let token = await getToken()
      let result:any = null
      
      if(requestCarton.recordNo === 0){
        result = await new ReturnCartonService(token).Create(requestCarton);
      }
      else{
        result = await new ReturnCartonService(token).Update(requestCarton);
      }
      console.log(result)
      let element = document.getElementById('return-carton-form') as HTMLElement
      if(result && result.success){
    
          messageApi.open({
            type: 'success',
            content: 'Save success',
            duration: 1,
            style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
          });
          setTimeout(() => {
            handleCancel()

              doIfElse(props.page === 'return' ,
                  function () {

                      doIfElse(saveType ,
                          function () {
                            props.onReload()
                              navigate('/return-carton-tracking')
                          }
                          ,
                          function () {
                            props.onReload()
                              navigate('/return-carton-save')
                          }
                      );

                  }
                  ,
                  null
              );


              doIfElse(props.page === 'onBehalf' ,
                  function () {
                      doIfElse(saveType,
                          function () {
                            props.onReload()
                            navigate('/onbehalf-2')
                          }
                          ,
                          function () {
                            props.onReload()
                            navigate('/onbehalf-3')
                          }
                      );

                  }
                  ,
                  null
              );
          }, 1000);
         
      }
      else{
          Modal.error({
              icon: <CloseCircleOutlined />,
              title: 'This is an error message',
              content: result?.message,
          });
        
      }
  }
 
  
};

  const handleDelete = async () => {
    let _allCartonItems:CartonList[] = Object.assign([],allCartonItems)
    selectItems.forEach(item => { 
      let index = allCartonItems.findIndex( x => x.cartonNo === item)
      if(index > -1){
        _allCartonItems.splice(index,1)
      }
    })
    console.log(selectItems)
    console.log(_allCartonItems)
    let items = Helper.Chunk(_allCartonItems,perpage)
    let expectReturnDate = _allCartonItems.map(x => dayjs(x.returnDate).format("YYYYMMDD") ).sort(function(a:string,b:string){ return  parseInt(a) - parseInt(b) ;})[0]
    setAllCartonItems(_allCartonItems)
    setCartonItems(items)
    setPage(1)
    setSelectItems([])
    dataForm.setFieldValue('expectReturnDate',dayjs(expectReturnDate).format('DD MMM YYYY'))
    onCheckAll(false)
    let element = document.getElementById('return-carton-form') as HTMLElement
    messageApi.open({
        type: 'success',
        content: 'Delete success',
        duration: 1,
        style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
    });
}

  const handleDeleteByItem = async (cartonNo:string) => {
  
    let _allCartonItems:CartonList[] = Object.assign([],allCartonItems)
    console.log(cartonNo)
    let index = allCartonItems.findIndex( item => item.cartonNo === cartonNo)
    console.log(index)
    if(index > -1)
    {
      _allCartonItems.splice(index,1)
      let expectReturnDate = _allCartonItems.map(x => dayjs(x.returnDate).format("YYYYMMDD") ).sort(function(a:string,b:string){ return  parseInt(a) - parseInt(b) ;})[0]
      let items = Helper.Chunk(_allCartonItems,perpage)
      setAllCartonItems(_allCartonItems)
      setCartonItems(items)
      setPage(1)
      setSelectItems([])
      dataForm.setFieldValue('expectReturnDate',dayjs(expectReturnDate).format('DD MMM YYYY'))
      onCheckAll(false)
    }
    
    let element = document.getElementById('return-carton-form') as HTMLElement
      messageApi.open({
        type: 'success',
        content: 'Delete success',
        duration: 1,
        style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
    });
}

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

  

  const confirmSave = () => {
    modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Confirm to submit?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => handleSave(true)
    });
  };

  
  const handleCancel = () => {
    let element = document.getElementsByClassName('ant-modal-close') as HTMLCollection
    let btnClose = element[0] as HTMLButtonElement
    btnClose.click()
    
  };

  
  const getReturnData = async () => {

      let token = await getToken()
      let result = await new ReturnCartonService(token).GetRequest(props.returnId)
      console.log(result)
      if(result && result.success && result.data)
      {
        let data = result.data
        let _allCartonItems = data.cartonList
        let expectReturnDate = _allCartonItems.map(x => dayjs(x.returnDate).format("YYYYMMDD") ).sort(function(a:string,b:string){ return  parseInt(a) - parseInt(b) ;})[0]
        if(_allCartonItems.length > 0)
        {
          let items = Helper.Chunk(_allCartonItems,perpage)
          setAllCartonItems(_allCartonItems)
          setCartonItems(items)
          
          setIsLoading(false)
        }
        
        let owners:Item[] = [{
          key:data.contactPerson,
          location:data.location,
          name: data.contactPersonName,
          floor: "",
          orgUnit:""
        }]
        onOwnerSelect(owners)

        if(props.onBehalf === 1){
          let onBehalfUser:Item[] = [{
            key:data.onBehalf,
            location:data.location,
            name: data.onBehalfName,
            floor: "",
            orgUnit:""
          }]
          onBehalfSelect(onBehalfUser)
        }

        dataForm.setFieldsValue({
          recordNo: props.returnId,
          requester: user.employeeId,
          requesterName: `Name: ${user.employeeName}  Org. Unit: ${orgUnit} Position: ${position}, Officer Services Tel: ${directPhone}`,
          contactPersonoName: data.contactPersonName,
          onBehalfName:data.onBehalfName,
          floor: data.floor,
          place: data.place,
          expectReturnDate: dayjs(expectReturnDate).format('DD MMM YYYY'),
          pickUpReturnDate: data.returnDate ? dayjs(data.returnDate) : undefined
        })
        setTimeout(() => {
          setIsLoading(false)
        }, 500);
       
      }
      else{
        setIsLoading(false)
      }
     

  }

  const getCollectData = async () => {
  
    let token = await getToken()
    let parameter = InitHelper.InitialParameter();
      parameter.page =1
      parameter.limit = 0
      parameter.cartonNoList = props.items

    let result = await new CollectCartonService(token).SearchCarton(parameter)
    
    console.log(result)
    if(result && result.success && result.data)
    {
      let data = result.data
      let _allCartonItems = data.items
      let expectReturnDate = data.items.map(x => dayjs(x.returnDate).format("YYYYMMDD") ).sort(function(a:string,b:string){ return  parseInt(a) - parseInt(b) ;})[0]
      if(_allCartonItems.length > 0)
      {
        let items = Helper.Chunk(_allCartonItems,perpage)
        setAllCartonItems(_allCartonItems)
        setCartonItems(items)
        setIsLoading(false)
      }
      dataForm.setFieldsValue({
        recordNo: props.returnId,
        requester: user.employeeId,
        requesterName: `Name: ${user.employeeName}  Org. Unit: ${orgUnit} Position: ${position}, Officer Services Tel: ${directPhone}`,
        delivertoName: "",
        floor: "",
        place: "",
        expectReturnDate:  dayjs(expectReturnDate).format('DD MMM YYYY'),
        pickUpReturnDate: dayjs(expectReturnDate)
      })
      
    }
    else{
      setIsLoading(false)
    }
   

  }

  const getCollectData2 = async (items:string[]) => {
  
    let token = await getToken()
    let parameter = InitHelper.InitialParameter();
      parameter.page =1
      parameter.limit = 0
      parameter.cartonNoList = items

    let result = await new CollectCartonService(token).SearchCarton(parameter)
    
    console.log(result)
    if(result && result.success && result.data)
    {
      let data = result.data
      let collectItems = data.items
      
      if(collectItems.length > 0)
      {
        let _allCartonItems = collectItems.concat(allCartonItems)
        let items = Helper.Chunk(_allCartonItems,perpage)
        setAllCartonItems(_allCartonItems)
        setCartonItems(items)
        setIsLoading(false)
      }
      
      
    }
    else{
      setIsLoading(false)
    }
   

  }

  useEffect(() => {
    console.log(props.returnId)
    if(props.returnId > 0){
        getReturnData()
    }
    else{
        getCollectData()
    }
    
  }, [])

  const onCheckAll = (checked:boolean) => {
    if(checked){
      setCheckAll(true)
      let _selectItems = allCartonItems.map( x => x.cartonNo)
      setSelectItems(_selectItems)
    }
    else{
      setCheckAll(false)
      setSelectItems([])
    }
  };

  const onCheckChange = (checked:boolean,cartonNo:string) => {
    let checkAllElement = document.getElementById('check-item-all') as HTMLInputElement
    let _selectItems:string[] = Object.assign([],selectItems)
    if(checked){
        _selectItems.push(cartonNo)
        if(_selectItems.length === allCartonItems.length){
          checkAllElement.checked = true
          checkAllElement.indeterminate = false
        }
        else{
          checkAllElement.checked = false
          checkAllElement.indeterminate = true
        }
        
    }
    else{
      let index = _selectItems.indexOf(cartonNo)
      if (index > -1) {
        _selectItems.splice(index, 1);
      }

      if(_selectItems.length === 0){
        checkAllElement.checked = false
        checkAllElement.indeterminate = false
      }
      else{
        checkAllElement.checked = false
        checkAllElement.indeterminate = true
      }
    }

    console.log(_selectItems)
    setSelectItems(_selectItems)
  }

  const onPageChange = (_page:number,_perpage: number) => {
    if(_page !== page){
      setPage(_page)
    }
    if(_perpage !== perpage)
    {
      let items = Helper.Chunk(allCartonItems,_perpage)
      setCartonItems(items)
      setPerpage(_perpage)
    }
  }

  
  const disableMinMaxdDate = (value:any) => {
    let date = currentDate ? currentDate.toString() : new Date().toString()
    let expectReturnDate = dataForm.getFieldValue('expectReturnDate')
    return Helper.disableMinMaxdDate(value,date,expectReturnDate.toString())
  }

  const showModalCarton = async (_recordNo:number) => {
    setRecordNo(_recordNo)
    setIsModalCartonOpen(true);
  };
  const handleCancelCarton = () => {
    setIsModalCartonOpen(false);
  
  };

  const handleAdd = () =>{
    setIsAdd(true)
  }

  const AddItem = (items:string[]) => {
        console.log(items)
        setIsAdd(false)
        if(items.length > 0)
        {
            getCollectData2(items)
        }
       
  }


  return (

    <div>
         { isAdd ?
            <ReturnCartonAdd requestType='return' onBehalf={props.onBehalf} onSelect={AddItem} items={allCartonItems}/>:
        
      <Card >
      { isLoading ? 
         <Spin tip="Loading" size="large">
            <div className="content" />
        </Spin>
        :
      <>
        <Form
          {...layout}
          form={dataForm}
          name="return-carton-form"
          onFinish={onFinish}
          validateMessages={validateMessages}
          labelAlign="left"
          colon={false}
          labelWrap={true}
        >

          <Row>
            <Col md={22} >
              <Form.Item name="requesterName" label="Requester" labelCol={{ md: 3 }}   >
                <Input disabled style={{ widows: '100%' }} />

              </Form.Item>
            </Col>
          </Row>
          { props.onBehalf ?
          <Row>
            <Col md={11} >
              <Form.Item name="onBehalfName" label="On Behalf"  >
               <EmployeeAutoComplete
                    key={Helper.Uniqid()}
                    placeholder='Search by Name'
                                              selectedItems={returnIfElse(onBehalfs, onBehalfs, [] ) }
                    onChange={item => onBehalfSelect(item)}
                    service={onSearchEmployee}
                    minimumTextLength={3}
                    borderColor={ownersBgColor}
                    pickOnlyOne
                  />

              </Form.Item>
            </Col>
            <Col md={13} ></Col>
          </Row>:<></>
        }
          <Row>
            <Col md={11} >
   
                <Form.Item name="contactPerson" label="Contact Person" rules={[{ required: true }]} tooltip='Please change name of contact person to pick up carton, if required.' >
                <EmployeeAutoComplete
                    key={Helper.Uniqid()}
                    placeholder='Search by Name'
                                          selectedItems={returnIfElse(owners, owners, [] ) }
                    onChange={item => onOwnerSelect(item)}
                    service={onSearchEmployee2}
                    minimumTextLength={3}
                    borderColor={ownersBgColor}
                    pickOnlyOne
                  />
                </Form.Item>
             


            </Col>
            <Col md={2} >
            </Col>
            <Col md={11} >
                <Form.Item name="location" label="Location">
                    <Input disabled/>
                </Form.Item>

            </Col>
          </Row>
          <Row>
            <Col md={11} >
              <Form.Item name="floor" label="Floor">
              <Input  placeholder={MessageCommon.TitleInput} />
              </Form.Item>

                                  <Form.Item name="place" label="Place" rules={[{ required: true }]} validateStatus={returnIfElse(error.place, "error", undefined ) }>
                <Input.TextArea placeholder='Please specify pick up location...' />
              </Form.Item>



            </Col>
            <Col md={2} ></Col>
            <Col md={11} >

            <Form.Item name="expectReturnDate" label="Expected Return Date">
              <Input  disabled style={{ width: '158px'}}/>
              </Form.Item>

            <Form.Item name="pickUpReturnDate" label="Pick Up Return Date" rules={[{ required: true }]} validateStatus={returnIfElse(error.returnDate, "error"  ,undefined  )  }>
                <DatePicker 
                    format={'DD MMM YYYY'}
                    disabledDate={disableMinMaxdDate}
                />
              </Form.Item>

            </Col>

          </Row>
        </Form>

        <div style={{ marginRight: 10, marginTop: 15, marginBottom: 5 }}>

{ selectItems.length > 0 ?
<Popconfirm
    title="Delete the task"
    description="Confirm to delete?"
    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
     onConfirm = { () => handleDelete() }
  >
   <Button type="primary" className='btn-red mr-btn'>
    <AiFillDelete className='icn-btn' /> Delete
  </Button>
</Popconfirm>
:
<Button type="primary" className='btn-red mr-btn' disabled>
    <AiFillDelete className='icn-btn' /> Delete
  </Button>
}

<Button key={Helper.Uniqid()} type="primary" className='btn-blue mr-btn' style={{width:'94px'}} onClick={handleAdd}>
                <AiOutlinePlusCircle className='icn-btn' />Add
          </Button>
        </div>
          <br></br>
        <div id="table-container">
       
       <table className='idoc-table'>
         <thead id="head-table-fixed">
           <tr>
             <th style={{ textAlign: 'left',width: '50px'}}>
                     <div className="form-check">
                     <input  
                            type="checkbox" 
                            id={'check-item-carton-all'} 
                            className="form-check-input check-item" 
                            checked={checkAll} 
                            onChange={e => onCheckAll(e.target.checked)}
                          />
                       <label className="form-check-label" htmlFor={'check-item-carton-all'}/>
                     </div>
                   
             </th>
             <th >Carton No.</th>
             <th >Collect Requested No.</th>
             <th >Collect By</th>
             <th >Received By</th>
             <th >Expected Return Date</th>
             <th >Carton Status</th>
             <th >Detail</th>
             <th> Delete</th>
           </tr>
         </thead>
        
         <tbody >
         { cartonItems.length > 0 && cartonItems[page -1].map((item:CartonList) => (
             <tr>
               <td style={{ textAlign: 'left',width: '50px'}}>
                     <div className="form-check">
                     <input  
                            type="checkbox" 
                            checked={checkAll || selectItems.indexOf(item.cartonNo) > -1}
                            id={'check-item-carton-' + item.cartonNo} 
                            value={item.cartonNo} 
                            className="form-check-input check-item" 
                            onChange={ e => onCheckChange(e.target.checked,item.cartonNo)}
                          />
                       <label className="form-check-label" htmlFor={'check-item-carton-' + item.recordNo}/>
                     </div>
                   </td>
                  <td style={{textAlign: 'center',width:'120px'}}>{item.cartonNo}</td>
                  <td style={{textAlign: 'center'}}>{item.collectRequestNo}</td>
                  <td style={{textAlign: 'center'}}>{item.collectByName}</td>
                  <td style={{textAlign: 'center'}}>{item.receivedByName}</td>
                 <td style={{ textAlign: 'center' }}>{returnIfElse(item.returnDate, dayjs(item.returnDate).format("DD MMM YYYY"), ""  ) }</td>
                  <td style={{textAlign: 'center'}}>Collect</td>
                  <td  style={{textAlign: 'center'}}>
                    <a onClick={() => showModalCarton(item.collectRecordNo)}>
                       <AiFillEye className='icon-table'></AiFillEye>
                    </a>
                 </td>
                  <td style={{textAlign: 'center'}}>
                   
                    <Popconfirm
                          title="Delete the task"
                          description="Confirm to delete?"
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          onConfirm = { () => handleDeleteByItem(item.cartonNo) }
      
                        >
                          <AiFillDelete size={20} color="red" style={{ cursor: 'pointer'}}/>
                        </Popconfirm>
                  
                  </td>
             </tr>
           ))
         }
         
         </tbody>

       </table>
     
       </div>
       <div style={{marginTop:'10px',textAlign:'right'}}>
       { allCartonItems.length > 0 ?
            <Pagination 
                key={Helper.Uniqid()}
                current={page} 
                pageSize={perpage}
                total={allCartonItems.length} 
                onChange={onPageChange} 
                showSizeChanger={true}
              />:<></>
       }
       </div>

        <div style={{ textAlign: 'center' }}>
          <Button type="default" className='mr-btn' onClick={handleCancel}  >
            <AiOutlineCloseCircle className='icn-btn' />Cancel
          </Button>
          <Button type="primary" className='mr-btn'  onClick={() => handleSave(false)} disabled={cartonItems.length === 0 }>
            <AiOutlineSave className='icn-btn' />Save Draft
          </Button>
          <Button type="primary" onClick={confirmSave} disabled={cartonItems.length === 0 }>
            <AiFillSave className='icn-btn' />Submit
          </Button>
        </div>
      </>
      }
      </Card>
}
        <Modal title="Request Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton}>
          <CollectCartonRequestDetail 
              key={Helper.Uniqid()}
              recordNo={recordNo} 
            />
        </Modal>

      {contextHolderMessage}
      {contextHolder}
    </div>
  );

}

export default ReturnCartonModal
