import {useEffect, useState } from 'react';
import { Pagination, Spin } from 'antd';
import dayjs from 'dayjs';
import { WarehouseService } from '../../services';
import { WarehouseLog , User} from '../../models';
import { Helper } from '../../helpers';
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface Props{
  warehouseId:number
}

interface AppState {
    currentPage:number
    perpage:number
    total: number
    items:WarehouseLog[]
    isLoading:boolean
  }

const WarehouseHistory = (props: Props) => {
    const { instance, accounts } = useMsal();
    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const pageSizeOptions = user.systemConfig.tablePageSize
    const [appState,setAppState] = useState<AppState>({
        currentPage : 1,
        total : 0,
        perpage: 20,
        items: [],
        isLoading : true
    })

    const clearData = () => {
        let state = Object.assign({}, appState)
        state.total = 0
        state.currentPage = 1
        state.items = []
        state.isLoading = true
        setAppState(state)

    }

    const getData = async (currentPage: number, perpage: number) => {
        clearData()
    let token = await getToken()
        let result = await new WarehouseService(token).GetLogs(currentPage, perpage, props.warehouseId)
    console.log(result)
    if(result && result.success){
        let state = Object.assign({},appState)
            state.total = result.data.total
            state.currentPage = currentPage
            state.items = result.data.items
            state.isLoading = false
  
            setTimeout(() => {
                setAppState(state)
            }, 500);
    }
    else{
        let state = Object.assign({},appState)
        state.isLoading = false
        setAppState(state)
    }
      
}
    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


    useEffect(() => {
    getData(1,appState.perpage)
    }, [props])

const onPageChange = (page:number,perpage:number) => {
    if(page !== appState.currentPage && perpage === appState.perpage){
        getData(page,appState.perpage)
    }

    if(page === appState.currentPage && perpage !== appState.perpage){
        getData(1,perpage)
    }
}

  return (

    <div style={{minHeight: '150px'}}>
      { appState.isLoading ?
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>:
        <>
          <div id="table-container" style={{paddingTop: '15px'}}>
          <table className='idoc-table'>
            <thead id="head-table-fixed">
              <tr>
                                  <th >Carton Location</th>
                                  <th >Contract No</th>
                <th> Contract Period</th>
              </tr>
            </thead>
            <tbody >
              { appState.items.map((item:WarehouseLog,index:number) => (
                <tr>
                  <td style={{textAlign: 'center'}}>{item.warehouseName}</td>
                  <td style={{textAlign: 'center'}}>{item.contractNo}</td>
                  <td style={{textAlign: 'center'}}>{ dayjs(item.dateStart).format("DD MMM YYYY")} - {dayjs(item.dateEnd).format("DD MMM YYYY")}</td>
                </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <div style={{marginTop:'10px',textAlign:'right'}}>
        { appState.total > 0 ?
            <Pagination 
                key={Helper.Uniqid()}
                current={appState.currentPage} 
                pageSize={appState.perpage}
                total={appState.total} 
                onChange={onPageChange} 
                pageSizeOptions={pageSizeOptions}
                showSizeChanger={true}
            />:<></>
        }
        </div>
        </>
      }
    
    </div>
  );

}

export default WarehouseHistory
